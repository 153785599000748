import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { CurrentSeason } from "src/data/Converters";
import { DataContext } from "src/data/contexts/DataContext";
import { LinkType } from "src/data/types/ApiTypes";

const LinkSelect: FC<{ linkType: string; link: string; setLink: (link: string) => void }> = ({
  linkType,
  link,
  setLink,
}) => {
  const { t } = useTranslation("notification");
  const { news, videos, events, matches, shopProducts } = useContext(DataContext);

  const getItems = (type: string): any => {
    switch (type) {
      case LinkType.news:
        return news.filter((n) => n.published).slice(0, 15);
      case LinkType.video:
        return videos.filter((v) => v.published).slice(0, 15);
      case LinkType.event:
        return events.filter((e) => e.published).slice(0, 15);
      case LinkType.match:
        return matches.filter((m) => m.season === CurrentSeason);
      case LinkType.shop:
        return shopProducts;
      default:
        return [];
    }
  };

  return (
    <FormControl sx={{ width: "300px", marginRight: ".4em" }}>
      <InputLabel id="input-label-linkType">{`${t("link")}${t("mandatory")}`}</InputLabel>
      <Select
        value={link as string}
        labelId="input-label-link"
        id="select-link"
        label={`${t("link")}${t("mandatory")}`}
        onChange={(e) => setLink(e.target.value)}>
        {getItems(linkType).map((item: any) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LinkSelect;
