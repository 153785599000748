import { Business, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Avatar,
  Card,
  CircularProgress,
  IconButton,
  Input,
  Modal,
  Paper,
  Tab,
  Table,
  TableCell,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { FC, FormEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AbMatchList from "src/components/tickets/AbMatchList";
import AbButton from "../../components/AbButton";
import AbDocumentsList from "../../components/AbDocumentsList";
import { formatNumber, getSeasonLabel } from "../../data/Converters";
import { DataContext } from "../../data/contexts/DataContext";
import { Partner, PartnerProduct, UploadType } from "../../data/types/ApiTypes";
import PartnerUpdateModal from "./PartnerUpdateModal";
import PartnerUsers from "./PartnerUsers";

const PartnerInfo = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",
  marginLeft: 32,
});

const EditBtnContainer = styled("div")({
  marginLeft: 16,
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "flex-end",
  flex: 1,
});

const getPrice = (product: PartnerProduct): string => {
  if (product.totalPrice > 0) return formatNumber(product.totalPrice, 2, true);
  return formatNumber(product.quantity * product.unitPrice, 2, true);
};

const PartnerProducts: FC<{ products: PartnerProduct[]; title: string; noProductLabel: string }> = ({
  products,
  title,
  noProductLabel,
}) => (
  <div>
    <Typography variant="h6">{title}</Typography>
    {products.length === 0 ? (
      <Typography className="center margin-top">{noProductLabel}</Typography>
    ) : (
      <Card variant="outlined">
        <Table>
          {products.map((p) => (
            <TableRow key={`${p.id}_${p.totalPrice}`}>
              <TableCell>
                <Typography>
                  {p.quantity} x {p.name}
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography>{getPrice(p)} € HT</Typography>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </Card>
    )}
  </div>
);

interface PartnerModalProps {
  open: boolean;
  onClose: () => void;
  partner?: Partner;
}

const PartnerModal: FC<PartnerModalProps> = ({ open, onClose, partner }) => {
  const [uploading, setUploading] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [curTab, setCurTab] = useState(0);
  const { upload, publishPartner, users } = useContext(DataContext);
  const { t } = useTranslation("partner");

  const uploadToApi = async (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>): Promise<void> => {
    const { files } = e.target as HTMLInputElement;
    if (files && partner?.id) {
      setUploading(true);
      await upload(files[0], partner?.id, UploadType.PARTNER_LOGO);
      setUploading(false);
    }
  };
  const publish = async (): Promise<void> => {
    if (!partner) return;
    setPublishing(true);
    await publishPartner(partner.id, !partner.listed);
    setPublishing(false);
  };

  const partnerUsers = users.filter((u) => u.partnersId && partner?.id && u.partnersId.indexOf(partner?.id) !== -1);
  let panel = (
    <PartnerProducts products={partner?.products || []} title={t("products")} noProductLabel={t("noProducts")} />
  );
  switch (curTab) {
    case 1:
      panel = <PartnerUsers partnerId={partner?.id} partnerUsers={partnerUsers} />;
      break;
    case 2:
      // panel = <AbTicketsList tickets={partner?.tickets} />;
      panel = <AbMatchList tickets={partner?.tickets} partnerId={partner?.id || ""} />;
      break;
    case 3:
      panel = <AbDocumentsList documents={partner?.documents} />;
      break;
    default:
      break;
  }

  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper">
        <div>
          <div className="row margin-bottom">
            <Tooltip title={t("changeLogo")} arrow>
              <label htmlFor="upload-logo-button">
                <Input
                  id="upload-logo-button"
                  type="file"
                  style={{ display: "none" }}
                  onChange={uploadToApi}
                  inputProps={{
                    accept: ".png,.jpg,.jpeg,image/png,image/jpeg",
                  }}
                  disabled={uploading}
                />
                {uploading ? (
                  <span className="partner-modal-logo">
                    <CircularProgress />
                  </span>
                ) : (
                  <span className="partner-modal-logo clickable">
                    {partner?.logo ? (
                      <Avatar src={partner?.logo} alt="Logo" sx={{ width: 160, height: 160 }} />
                    ) : (
                      <Business fontSize="large" htmlColor="rgba(255, 255, 255, 0.9)" />
                    )}
                  </span>
                )}
              </label>
            </Tooltip>
            <PartnerInfo>
              <Typography variant="h6">{partner?.name}</Typography>
              <Typography>{partner?.sector}</Typography>
              <Typography>{partner?.city}</Typography>
              {partner?.email ? (
                <Typography>
                  {t("referenceEmail")} <a href={`mailto:${partner.email}`}>{partner.email}</a>
                </Typography>
              ) : undefined}
              {partner?.ticketsEmail ? (
                <Typography>
                  {t("ticketsEmail")} <a href={`mailto:${partner.ticketsEmail}`}>{partner.ticketsEmail}</a>
                </Typography>
              ) : undefined}
              {partner?.website ? (
                <Typography>
                  {t("website")}{" "}
                  <a
                    href={partner.website.indexOf("http") === -1 ? `http://${partner.website}` : partner.website}
                    target="_blank"
                    rel="noreferrer">
                    {partner.website}
                  </a>
                </Typography>
              ) : undefined}
            </PartnerInfo>
            <EditBtnContainer>
              <Tooltip title={t("updatePartner.title")} arrow>
                <IconButton color="primary" onClick={() => setUpdateOpen(true)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </EditBtnContainer>
          </div>
          <Typography className="margin-bottom center">
            {t("applicableSeasons")} {(partner?.seasons || []).map((s) => getSeasonLabel(s)).join(" / ")}
          </Typography>
          <div className="row margin-bottom space-between">
            <div className="row space-between">
              {partner?.listed ? <Visibility /> : <VisibilityOff />}
              <Typography style={{ marginLeft: 8 }}>{t(`is${partner?.listed ? "" : "Not"}Listed`)}</Typography>
            </div>
            <AbButton
              label={t(`${partner?.listed ? "un" : ""}listPartner`)}
              className="margin-left"
              onClick={publish}
              isLoading={publishing}
            />
          </div>
          <Typography className="margin-bottom center">
            {t("owner")} <b>{partner?.owner?.name}</b>
          </Typography>
          <Tabs value={curTab} onChange={(_, value) => setCurTab(value)}>
            <Tab label={t("modalTabs.products", { count: partner?.products?.length || 0 })} />
            <Tab label={t("modalTabs.members", { count: partnerUsers?.length || 0 })} />
            <Tab label={t("modalTabs.tickets", { count: partner?.tickets?.length || 0 })} />
            <Tab label={t("modalTabs.docs", { count: partner?.documents?.length || 0 })} />
          </Tabs>
          <div className="partner-panel margin-top">{panel}</div>
          <PartnerUpdateModal open={updateOpen} onClose={() => setUpdateOpen(false)} partner={partner} />
        </div>
      </Paper>
    </Modal>
  );
};

PartnerModal.defaultProps = { partner: undefined };

export default PartnerModal;
