import { Card, Modal, Paper, Table, TableCell, TableRow, Typography } from "@mui/material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import AbCenteredContent from "../../components/AbCenteredContent";
import { DataContext } from "../../data/contexts/DataContext";
import { formatDate } from "../../data/Converters";
import { Event, EventRegistration } from "../../data/types/ApiTypes";

interface EventRegistrationsModalProps {
  open: boolean;
  onClose: () => void;
  event: Event | null;
  getting: boolean;
  registrations: EventRegistration[];
}

const EventRegistrationsModal: FC<EventRegistrationsModalProps> = ({
  open,
  onClose,
  event,
  getting,
  registrations,
}) => {
  const { users, partners } = useContext(DataContext);
  const { t } = useTranslation("event");
  const options = event?.program?.options || [];
  const eventGetsAttendees = options.findIndex((o) => (o.maxAttendees || 0) > 0) !== -1;

  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" style={{ minWidth: 450, minHeight: 450, maxHeight: 900 }}>
        <>
          <Typography variant="h5" className="margin-bottom center">
            {t("registrations")} - {event?.title} ({t("partners", { count: registrations.length })})
          </Typography>
          {getting ? (
            <AbCenteredContent loading />
          ) : registrations.length === 0 ? (
            <AbCenteredContent infoText={t("noRegistrationYet")} normalText />
          ) : (
            <Card variant="outlined" style={{ overflowY: "auto" }}>
              <Table>
                <TableRow key="tablehead">
                  <TableCell align="center">{t("regisTable.date")}</TableCell>
                  <TableCell align="center">{t("regisTable.partner")}</TableCell>
                  <TableCell align="center">{t("regisTable.user")}</TableCell>
                  {eventGetsAttendees ? (
                    <TableCell align="center">{t("regisTable.nbRegistered")}</TableCell>
                  ) : undefined}
                </TableRow>
                {registrations.map((r) => {
                  const user = users.find((u) => u.id === r.userId);
                  const partner = partners.find((p) => p.id === r.partnerId);
                  const nbRegistered = Math.max(...r.options.map((o) => o.attendees.length));
                  return (
                    <TableRow key={r.id}>
                      <TableCell>{formatDate(r.latestUpdate)}</TableCell>
                      <TableCell>
                        {partner?.name}
                        <br />({partner?.sector})
                      </TableCell>
                      <TableCell>
                        {user?.firstName} {user?.lastName}
                      </TableCell>
                      {eventGetsAttendees ? <TableCell align="center">{nbRegistered}</TableCell> : undefined}
                    </TableRow>
                  );
                })}
              </Table>
            </Card>
          )}
        </>
      </Paper>
    </Modal>
  );
};

export default EventRegistrationsModal;
