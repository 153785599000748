import { Partner } from "../../data/types/ApiTypes";

export interface BMRun {
  iteration: number;
  tables: {
    id: number;
    partners: string[];
  }[];
}

const generateRun = (inPartners: Partner[], nbTables: number, nbPartnersPerTable: number, iteration: number): BMRun => {
  const tables: { id: number; partners: string[] }[] = [];
  const partnersLeft = [...inPartners].sort(() => (Math.random() > 0.5 ? 1 : -1));

  for (let i = 0; i < nbTables; i += 1) {
    const consideredPartners = partnersLeft.splice(0, nbPartnersPerTable);
    tables.push({ id: i + 1, partners: consideredPartners.map((p) => p.id) });
  }

  return {
    iteration,
    tables,
  };
};

export const generateBM = (
  partners: Partner[],
  nbTables: number,
  nbPartnersPerTable: number,
  nbRuns: number,
): BMRun[] => {
  const result: BMRun[] = [];

  for (let i = 0; i < nbRuns; i += 1) {
    result.push(generateRun(partners, nbTables, nbPartnersPerTable, i + 1));
  }

  return result;
};
