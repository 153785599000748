import { Place } from "@mui/icons-material";
import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatLongDate } from "../data/Converters";
import { Event, EventOption } from "../data/types/ApiTypes";
import CloudinaryIdToImage from "../helpers/CloudinaryIdToImage";

const gMapsUrl = "https://www.google.com/maps/search/?api=1&query=";

const Option: FC<{ option: EventOption; last?: boolean }> = ({ option, last }) => {
  const { t } = useTranslation("event");

  return (
    <Card variant="outlined" className={`card-root ${last ? "big-" : ""}margin-bottom center`}>
      <Typography variant="h6">{option.times}</Typography>
      <Typography>{option.title}</Typography>
      {option.registrationsLimit ? (
        <Typography variant="subtitle2" className="small-margin-top">
          {t("registrationsLimit", { count: option.registrationsLimit })}
        </Typography>
      ) : undefined}
    </Card>
  );
};
Option.defaultProps = { last: false };

const AbEventViewer: FC<{ event: Event }> = ({ event }) => {
  const { t } = useTranslation("event");
  const nbCurItemOptions = event.program?.options?.length || 0;

  return (
    <Card className="card-root article" style={{ width: "100%" }}>
      {event.pictures && event.pictures.length > 0 ? (
        <img
          src={CloudinaryIdToImage(event.pictures[0].url as string, 404, undefined, "fill")}
          width="100%"
          height="250"
          style={{ objectFit: "cover" }}
          alt="Event showcase"
        />
      ) : undefined}
      <Typography variant="h4">{event.title}</Typography>
      {event.eventDate || event.datePlaceholder ? (
        <Typography variant="h6" className="margin-bottom">
          {event.eventDate ? formatLongDate(event.eventDate, true) : event.datePlaceholder}
        </Typography>
      ) : undefined}
      <Typography className="margin-bottom ql-editor" dangerouslySetInnerHTML={{ __html: event.body || "" }} />
      {event.place ? (
        <div className="center returns margin-bottom">
          <Typography variant="h6" className="margin-bottom flex-center">
            <Place /> {t("event:place.place")}
          </Typography>
          <a
            href={`${gMapsUrl}${event.place.name}, ${event.place.address}, ${event.place.zipCode} ${event.place.city}`}
            target="_blank"
            rel="noreferrer">
            <Typography variant="subtitle1">
              {event.place.name}
              {"\n"}
              {event.place.address}
              {"\n"}
              {event.place.zipCode} {event.place.city}
            </Typography>
          </a>
        </div>
      ) : undefined}
      {event.program?.options && nbCurItemOptions > 0 ? (
        <>
          <Typography variant="h6" className="center margin-bottom">
            {t("event:program")}
          </Typography>
          {event.program.options.map((o, i) => (
            <Option option={o} key={`option_${o.id}`} last={i === nbCurItemOptions - 1} />
          ))}
          {event.program?.openDate ? (
            <Typography className="center returns margin-bottom">
              {t("event:dateOpen")}
              {"\n"}
              {formatLongDate(event.program.openDate, true, true)}
            </Typography>
          ) : undefined}
          {event.program?.limitDate ? (
            <Typography className="center returns margin-bottom">
              {t("event:dateLimit")}
              {"\n"}
              {formatLongDate(event.program.limitDate, true, true)}
            </Typography>
          ) : undefined}
        </>
      ) : undefined}
      {event.nota ? (
        <Typography variant="body2" className="center margin-bottom">
          <em>{event.nota}</em>
        </Typography>
      ) : undefined}
    </Card>
  );
};

export default AbEventViewer;
