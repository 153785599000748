import { Modal, Paper, TextField, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "../../components/AbButton";
import { DataContext } from "../../data/contexts/DataContext";
import { Partner, PartnerUpdate } from "../../data/types/ApiTypes";

interface PartnerUpdateModalProps {
  open: boolean;
  onClose: () => void;
  partner?: Partner;
}

const PartnerUpdateModal: FC<PartnerUpdateModalProps> = ({ open, onClose, partner }) => {
  const { updatePartner } = useContext(DataContext);
  const extractPartnerData = (p?: Partner): PartnerUpdate => ({
    id: p?.id || "",
    name: p?.name || "",
    city: p?.city || "",
    website: p?.website || "",
  });
  const [data, setData] = useState<PartnerUpdate>(extractPartnerData(partner));
  const [updating, setUpdating] = useState(false);
  useEffect(() => {
    setData(extractPartnerData(partner));
  }, [partner]);
  const { t } = useTranslation("partner");

  const sendPartnerData = async (): Promise<void> => {
    setUpdating(true);
    await updatePartner(data);
    setUpdating(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper">
        <Typography variant="h6" className="center margin-bottom returns">
          {t("updatePartner.title")}
        </Typography>
        <Typography variant="subtitle2" className="center big-margin-bottom returns">
          {t("updatePartner.subtitle")}
        </Typography>
        <TextField
          label={t("updatePartner.name")}
          className="big-margin-bottom"
          value={data.name}
          onChange={(e) => setData({ ...data, name: e.target.value })}
        />
        <TextField
          label={t("updatePartner.city")}
          className="big-margin-bottom"
          value={data.city}
          onChange={(e) => setData({ ...data, city: e.target.value })}
        />
        <TextField
          label={t("updatePartner.website")}
          className="big-margin-bottom"
          value={data.website}
          onChange={(e) => setData({ ...data, website: e.target.value })}
        />
        <AbButton
          label={t("updatePartner.save")}
          onClick={sendPartnerData}
          isLoading={updating}
          disabled={data.name.length === 0 || data.city.length === 0}
        />
      </Paper>
    </Modal>
  );
};

PartnerUpdateModal.defaultProps = { partner: undefined };

export default PartnerUpdateModal;
