/* eslint-disable import/order */
import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentSeason, getAllSeasons, getSeasonShortLabel } from "src/data/Converters";
import { v4 as uuidv4 } from "uuid";
import AbButton from "../../components/AbButton";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import AbImageUpload from "../../components/AbImageUpload";
import AbModificationAlert from "../../components/AbModificationAlert";
import { DataContext } from "../../data/contexts/DataContext";
import {
  PlayerPositions,
  PlayerStatuses,
  RoleStaff,
  StaffDepartments,
  TeamMember,
  TeamMemberType,
} from "../../data/types/ApiTypes";
import Country from "../../data/types/Countries";
import CloudinaryIdToImage from "../../helpers/CloudinaryIdToImage";

interface TeamMemberModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
  typeOfMember: TeamMemberType;
}

const TeamMemberModal: FC<TeamMemberModalProps> = ({ open, onClose, id, typeOfMember }) => {
  const { teamMembers, updateTeamMember, createTeamMember } = useContext(DataContext);
  const originalTeamMember = teamMembers.find((m) => m.id === id);
  const [teamMember, setTeamMember] = useState<TeamMember | null>(originalTeamMember || null);
  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation(["team", "countries"]);
  const { enqueueSnackbar } = useSnackbar();
  const creation = id.length === 0;
  const allSeasons: number[] = getAllSeasons();

  useEffect(() => {
    if (id.length === 0) {
      setTeamMember({
        id: uuidv4(),
        firstName: "",
        lastName: "",
        typeOfMember,
        published: false,
        infos: {
          position: undefined,
          heightInCm: undefined,
          weightInKg: undefined,
          status: undefined,
          startInClub: null,
          department: undefined,
          role: undefined,
          number: undefined,
        },
        birthDate: null,
        country: undefined,
        description: {
          bio: "",
          career: "",
          trackRecord: "",
          funFact: "",
        },
        category: undefined,
        nickName: "",
        birthPlace: "",
        infirmary: {
          hurt: undefined,
          causeOfInjury: "",
          unvailabilityInWeeks: undefined,
        },
        socialNetwork: {
          instagramUrl: "",
          facebookUrl: "",
          twitterUrl: "",
        },
        seasons: [CurrentSeason],
      });
    } else {
      const newOriginalTeamMember = teamMembers.find((s) => s.id === id);
      if (newOriginalTeamMember) setTeamMember({ ...newOriginalTeamMember });
      setModified(false);
    }
  }, [id, teamMembers, typeOfMember]);

  const sendTeamMemberToApi = async (): Promise<void> => {
    if (teamMember === null) return;
    if (teamMember.typeOfMember === TeamMemberType.player) {
      if (
        !teamMember.firstName ||
        teamMember.firstName.length === 0 ||
        !teamMember.lastName ||
        teamMember.lastName.length === 0 ||
        !teamMember.infos?.position ||
        teamMember.infos.position.length === 0 ||
        !teamMember.seasons ||
        teamMember.seasons.length === 0
      ) {
        enqueueSnackbar(t(`mandatoryInfo.${typeOfMember}`), { variant: "error" });
        return;
      }
    } else if (teamMember.typeOfMember === TeamMemberType.staff) {
      if (
        !teamMember.firstName ||
        teamMember.firstName.length === 0 ||
        !teamMember.lastName ||
        teamMember.lastName.length === 0 ||
        !teamMember.infos?.role ||
        teamMember.infos.role.length === 0 ||
        !teamMember.infos?.department ||
        teamMember.infos.department.length === 0 ||
        !teamMember.seasons ||
        teamMember.seasons.length === 0
      ) {
        enqueueSnackbar(t(`mandatoryInfo.${typeOfMember}`), { variant: "error" });
        return;
      }
    }
    setSending(true);
    if (id.length === 0) await createTeamMember(teamMember);
    else {
      await updateTeamMember(teamMember);
    }

    setSending(false);
    onClose();
  };

  const changeTeamMemberValue = (key: string, value: unknown, subLevel = ""): void => {
    if (teamMember) {
      let newItem = { ...teamMember };

      if (subLevel === "socialNetwork") {
        newItem.socialNetwork = teamMember.socialNetwork
          ? { ...teamMember.socialNetwork, [key]: value }
          : { [key]: value };
      } else if (subLevel === "infos") {
        newItem.infos = teamMember.infos ? { ...teamMember.infos, [key]: value } : { [key]: value };
      } else if (subLevel === "description") {
        newItem.description = teamMember.description ? { ...teamMember.description, [key]: value } : { [key]: value };
      } else if (key === "profile" || key === "hero" || key === "slider") {
        newItem.pictures = teamMember.pictures
          ? { ...teamMember.pictures, [key]: value as string }
          : { [key]: value as string };
      } else {
        newItem = { ...teamMember, [key]: value };
      }
      setTeamMember(newItem);
      if (JSON.stringify(originalTeamMember) !== JSON.stringify(newItem)) {
        setModified(true);
      }
    }
  };

  const changeDate =
    (key: string, subLevel = ""): ((e: ChangeEvent<HTMLInputElement> | any) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const newDate = e ? new Date(e.toString()).getTime() / 1000 : undefined;
      changeTeamMemberValue(key, newDate, subLevel);
    };

  const changeTeamMemberSocialNetwork =
    (key: string): ((e: ChangeEvent<HTMLInputElement> | any) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeTeamMemberValue(key, e.target.value, "socialNetwork");
    };
  const changeTeamMemberInfos =
    (key: string): ((e: ChangeEvent<HTMLInputElement> | any) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeTeamMemberValue(key, e.target.value, "infos");
    };

  const changeTeamMember =
    (key: string): ((event: ChangeEvent<HTMLInputElement> | any) => void) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      changeTeamMemberValue(key, event.target.value);
    };
  const onTryClose = (): void => {
    if (!modified) onClose();
    else setModifiedAlertOpen(true);
  };

  const changeSeasonsValue = (value: number[]): void => {
    const seasons: number[] = [];
    value.map((s) => seasons.push(s));
    changeTeamMemberValue("seasons", seasons);
  };

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper">
        <Typography variant="h4" sx={{ textAlign: "center" }} className="margin-bottom">
          {t(`modalTitle.${typeOfMember}`)}
        </Typography>
        <div className="row">
          <AbImageUpload
            teamMember
            thumbnail={CloudinaryIdToImage(teamMember?.pictures?.profile?.url || "")}
            updateImageInState={(e) => {
              changeTeamMemberValue("profile", e);
            }}
          />
          <Grid width="100%" marginLeft="1.2em" marginTop=".3em" marginBottom="1.2em">
            <TextField
              label={`${t("firstName")}${t("mandatory")}`}
              value={teamMember?.firstName || ""}
              onChange={changeTeamMember("firstName")}
              sx={{ marginBottom: "1em", width: "100%" }}
            />
            <TextField
              label={`${t("lastName")}${t("mandatory")}`}
              value={teamMember?.lastName || ""}
              onChange={changeTeamMember("lastName")}
              sx={{ marginBottom: "1em", width: "100%" }}
            />
            {typeOfMember === TeamMemberType.player ? (
              <div className="row" style={{ width: "100%" }}>
                <FormControl sx={{ width: "25%", marginRight: ".6em" }}>
                  <InputLabel id="input-label-position">{`${t("infos.position")}${t("mandatory")}`}</InputLabel>
                  <Select
                    value={teamMember?.infos?.position || ""}
                    labelId="input-label-position"
                    id="select-position"
                    label={`${t("infos.position")}${t("mandatory")}`}
                    onChange={changeTeamMemberInfos("position")}>
                    {Object.keys(PlayerPositions).map((p) => (
                      <MenuItem key={p} value={p}>
                        {t(`playerPositions.${p}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  label={t("infos.number")}
                  value={teamMember?.infos?.number || ""}
                  onChange={changeTeamMemberInfos("number")}
                  type="number"
                  sx={{ marginRight: ".6em", width: "16%" }}
                />
                <Autocomplete
                  limitTags={2}
                  multiple
                  id="size-small-outlined-multi"
                  value={teamMember?.seasons}
                  sx={{ width: "60%" }}
                  options={allSeasons}
                  getOptionLabel={(option) => getSeasonShortLabel(option)}
                  onChange={(_, value) => {
                    changeSeasonsValue(value);
                  }}
                  renderInput={(params) => <TextField {...params} label={`${t("seasons")}${t("mandatory")}`} />}
                />
              </div>
            ) : (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <FormControl sx={{ width: "36%" }}>
                  <InputLabel id="input-label-role">{`${t("infos.role")}${t("mandatory")}`}</InputLabel>
                  <Select
                    value={teamMember?.infos?.role || ""}
                    labelId="input-label-role"
                    id="select-role"
                    label={`${t("infos.role")}${t("mandatory")}`}
                    onChange={changeTeamMemberInfos("role")}>
                    {Object.keys(RoleStaff).map((p) => (
                      <MenuItem key={p} value={p}>
                        {t(`role.${p}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "16%", marginLeft: ".4em" }}>
                  <InputLabel id="input-label-department">{`${t("infos.department")}${t("mandatory")}`}</InputLabel>
                  <Select
                    value={teamMember?.infos?.department || ""}
                    labelId="input-label-department"
                    label={`${t("infos.department")}${t("mandatory")}`}
                    onChange={changeTeamMemberInfos("department")}>
                    {Object.keys(StaffDepartments).map((sd) => (
                      <MenuItem key={sd} value={sd}>
                        {t(`staffDepartments.${sd}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Autocomplete
                  limitTags={2}
                  multiple
                  id="size-small-outlined-multi"
                  value={teamMember?.seasons}
                  sx={{ width: "49%", marginLeft: ".4em" }}
                  options={allSeasons}
                  getOptionLabel={(option) => getSeasonShortLabel(option)}
                  onChange={(_, value) => {
                    changeSeasonsValue(value);
                  }}
                  renderInput={(params) => <TextField {...params} label={`${t("seasons")}${t("mandatory")}`} />}
                />
              </div>
            )}
          </Grid>
        </div>

        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>{t("infos.infos")}</AccordionSummary>
          <AccordionDetails>
            <FormControl sx={{ m: 1, minWidth: 300 }} fullWidth>
              <div className="row">
                {typeOfMember === TeamMemberType.player ? (
                  <>
                    <TextField
                      label={t("infos.heightInCm")}
                      value={teamMember?.infos?.heightInCm || ""}
                      onChange={changeTeamMemberInfos("heightInCm")}
                      type="number"
                      sx={{ marginBottom: "1.4em", width: "30%" }}
                    />
                    <TextField
                      label={t("infos.weightInKg")}
                      value={teamMember?.infos?.weightInKg || ""}
                      onChange={changeTeamMemberInfos("weightInKg")}
                      type="number"
                      sx={{ marginBottom: "1.4em", width: "30%", marginLeft: "1.4em" }}
                    />
                    <FormControl
                      sx={{ marginBottom: "1.4em", marginRight: "1.4em", width: "30%", marginLeft: "1.4em" }}>
                      <InputLabel id="input-label-status">{t("infos.status")}</InputLabel>
                      <Select
                        value={teamMember?.infos?.status || ""}
                        labelId="input-label-status"
                        label={t("infos.status")}
                        onChange={changeTeamMemberInfos("status")}
                        autoWidth>
                        {Object.keys(PlayerStatuses).map((ps) => (
                          <MenuItem key={ps} value={ps}>
                            {t(`playerStatuses.${ps}`)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <InputLabel id="input-label-department">{t("infos.department")}</InputLabel>
                    <Select
                      value={teamMember?.infos?.department || ""}
                      labelId="input-label-department"
                      sx={{ marginBottom: "1.4em", marginRight: "1.4em" }}
                      label={t("infos.department")}
                      onChange={changeTeamMemberInfos("department")}
                      fullWidth>
                      {Object.keys(StaffDepartments).map((sd) => (
                        <MenuItem key={sd} value={sd}>
                          {t(`staffDepartments.${sd}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                )}
                <DatePicker
                  label={t("infos.startInClub")}
                  value={teamMember?.infos?.startInClub ? teamMember.infos.startInClub * 1000 : null}
                  onChange={changeDate("startInClub", "infos")}
                  renderInput={(params) => (
                    <TextField {...params} inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa" }} />
                  )}
                />
              </div>
              <div className="row">
                <DatePicker
                  label={t("birthDate")}
                  value={teamMember?.birthDate ? teamMember.birthDate * 1000 : null}
                  onChange={changeDate("birthDate")}
                  renderInput={(params) => (
                    <TextField {...params} inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa" }} />
                  )}
                />
                <TextField
                  label={t("birthPlace")}
                  value={teamMember?.birthPlace || ""}
                  onChange={changeTeamMember("birthPlace")}
                  sx={{ marginBottom: "1.4em", marginLeft: "1.4em", width: "25%" }}
                />
                <Autocomplete
                  sx={{ marginBottom: "1.4em", marginLeft: "1.4em", width: "25%" }}
                  disablePortal
                  id="country"
                  options={Object.keys(Country)}
                  value={teamMember?.country || ""}
                  getOptionLabel={(c) => t(`countries:${c}`)}
                  onChange={(_, value) => {
                    changeTeamMemberValue("country", value);
                  }}
                  renderInput={(params) => <TextField label={t("country")} {...params} />}
                />{" "}
                <TextField
                  label={t("nickName")}
                  value={teamMember?.nickName || ""}
                  onChange={changeTeamMember("nickName")}
                  sx={{ marginBottom: "1.4em", marginLeft: "1.4em", width: "25%" }}
                  fullWidth
                />
              </div>
            </FormControl>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>{t("description.description")}</AccordionSummary>
          <AccordionDetails>
            <Box mb="1.4em">
              <Typography>{t("description.bio")}</Typography>
              <AbHtmlTextField
                strict
                placeholder={t("description.bio")}
                value={teamMember?.description?.bio || ""}
                setValue={(n) => {
                  changeTeamMemberValue("bio", n, "description");
                  setModified(true);
                }}
              />
            </Box>
            <Box mb="1.4em">
              <Typography>{t("description.career")}</Typography>
              <AbHtmlTextField
                strict
                placeholder={t("description.career")}
                value={teamMember?.description?.career || ""}
                setValue={(n) => {
                  changeTeamMemberValue("career", n, "description");
                  setModified(true);
                }}
              />
            </Box>
            <Box mb="1.4em">
              <Typography>{t("description.trackRecord")}</Typography>
              <AbHtmlTextField
                strict
                placeholder={t("description.trackRecord")}
                value={teamMember?.description?.trackRecord || ""}
                setValue={(n) => {
                  changeTeamMemberValue("trackRecord", n, "description");
                  setModified(true);
                }}
              />
            </Box>
            <Box mb="1.4em">
              <Typography>{t("description.funFact")}</Typography>
              <AbHtmlTextField
                strict
                placeholder={t("description.funFact")}
                value={teamMember?.description?.funFact || ""}
                setValue={(n) => {
                  changeTeamMemberValue("funFact", n, "description");
                  setModified(true);
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>{t("socialNetwork.socialNetwork")}</AccordionSummary>
          <AccordionDetails>
            <div className="row">
              <TextField
                label={t("socialNetwork.instagramUrl")}
                value={teamMember?.socialNetwork?.instagramUrl || ""}
                onChange={changeTeamMemberSocialNetwork("instagramUrl")}
                sx={{ marginBottom: "1.4em", marginRight: ".5em" }}
                fullWidth
              />{" "}
              <TextField
                label={t("socialNetwork.twitterUrl")}
                value={teamMember?.socialNetwork?.twitterUrl || ""}
                onChange={changeTeamMemberSocialNetwork("twitterUrl")}
                sx={{ marginBottom: "1.4em", marginRight: ".5em" }}
                fullWidth
              />{" "}
              <TextField
                label={t("socialNetwork.facebookUrl")}
                value={teamMember?.socialNetwork?.facebookUrl || ""}
                onChange={changeTeamMemberSocialNetwork("facebookUrl")}
                sx={{ marginBottom: "1.4em" }}
                fullWidth
              />
            </div>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMore />}>{t("pictures.pictures")}</AccordionSummary>
          <AccordionDetails>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <Typography>{t("pictures.hero")}</Typography>
                <AbImageUpload
                  thumbnail={CloudinaryIdToImage(teamMember?.pictures?.hero?.url || "")}
                  updateImageInState={(e) => {
                    changeTeamMemberValue("hero", e);
                  }}
                />
              </div>
              <div>
                <Typography>{t("pictures.slider")}</Typography>
                <AbImageUpload
                  thumbnail={CloudinaryIdToImage(teamMember?.pictures?.slider?.url || "")}
                  updateImageInState={(e) => {
                    changeTeamMemberValue("slider", e);
                  }}
                />
              </div>
            </div>
          </AccordionDetails>
        </Accordion>

        <div className="margin-top">
          <AbButton
            label={t(creation ? "create" : "save")}
            onClick={sendTeamMemberToApi}
            isLoading={sending}
            disabled={!modified}
          />
        </div>
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalTeamMember) setTeamMember({ ...originalTeamMember });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

export default TeamMemberModal;
