import { Add, Delete } from "@mui/icons-material";
import { Card, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import { TextPair } from "../../data/types/ApiTypes";

export interface TextItemsFieldProps {
  value: TextPair[];
  onChange: (newValue: TextPair[]) => void;
}

const TextItemsField: FC<TextItemsFieldProps> = ({ value, onChange }) => {
  const { t } = useTranslation("content");

  const changeText = (itemId: string, key: string, textValue: string): void =>
    onChange(value.map((ti) => (ti.id === itemId ? { ...ti, [key]: textValue } : ti)));

  const addItem = (): void => onChange([...value, { id: uuidv4(), title: "", description: "" }]);

  const deleteItem = (itemId: string): void => onChange(value.filter((ti) => ti.id !== itemId));

  const body =
    value.length === 0 ? (
      <div className="v-center" style={{ height: 100 }}>
        <Typography variant="subtitle1">{t("pageComponentModal.textItemsEmpty")}</Typography>
      </div>
    ) : (
      value.map((ti) => (
        <Card key={ti.id} variant="outlined" className="card-root margin-bottom">
          <div
            className="margin-bottom"
            style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
            <TextField
              value={ti.title}
              onChange={(e) => changeText(ti.id, "title", e.target.value)}
              fullWidth
              label={t("pageComponentModal.textItemTitle")}
            />
            <Tooltip title={t("pageComponentModal.textItemDelete")} arrow style={{ marginLeft: 16 }}>
              <IconButton onClick={() => deleteItem(ti.id)}>
                <Delete color="primary" />
              </IconButton>
            </Tooltip>
          </div>
          <AbHtmlTextField
            setValue={(newValue) => changeText(ti.id, "description", newValue)}
            value={ti.description || ""}
            strict={false}
            placeholder={t("pageComponentModal.textItemContent")}
          />
        </Card>
      ))
    );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <Tooltip title={t("pageComponentModal.textItemAdd")} arrow>
          <IconButton onClick={addItem}>
            <Add color="primary" />
          </IconButton>
        </Tooltip>
      </div>
      {body}
    </div>
  );
};

export default TextItemsField;
