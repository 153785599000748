import Country from "./Countries";

export interface AuthData {
  userid: string;
  username: string;
  admin: boolean;
  partnerId: string;
  referent: boolean;
}

export interface AuthResponse {
  access_token: string;
  refresh_token: string;
}

// export interface PartnerTicket {
//   entryId: number;
//   seat: { section: string | null; rank: string | null; number: string | null } | null;
//   holder: { firstName: string | null; lastName: string | null } | null;
//   title: string;
//   url: string;
//   error?: string;
// }

export interface PartnerTicket {
  entryId: number;
  eventDate?: string;
  seat: {
    category: string | null;
    entrance: string | null;
    section: string | null;
    rank: string | null;
    number: string | null;
  } | null;
  holder: { id?: string | null; firstName?: string | null; lastName?: string | null } | null;
  title: string;
  url: string;
  digitickUrl?: string;
  ticketId?: number;
  orderId?: number;
  matchId?: string;
  barcode?: string;
  error?: string;
}

export enum DocumentType {
  quote = "quote",
  invoice = "invoice",
}

export interface DocumentMetadata {
  documentId: string;
  entityId: string;
  opportunityId: string;
  opportunityName?: string;
  contentVersionId: string;
  name: string;
  status: string;
  type: DocumentType;
  size: number;
  created: number;
  modified: number;
  checksum: string;
  entityName: string;
  totalAmount?: number;
  numerotation: string | null;
}

export interface PartnerDocument {
  metadata: DocumentMetadata;
  url: string;
}

export interface PartnerProduct {
  id: string;
  name: string;
  quantity: number;
  unitPrice: number;
  totalPrice: number;
}

export interface Partner {
  id: string;
  listed: boolean;
  name: string;
  logo?: string;
  city?: string;
  sector?: string;
  email?: string;
  website?: string;
  tickets?: PartnerTicket[];
  documents?: PartnerDocument[];
  products?: PartnerProduct[];
  owner?: { id: string; name: string };
  isGc?: boolean;
  ticketsEmail?: string;
  seasons?: number[];
}

export interface PartnersList {
  id: string;
  name?: string;
  automatic?: boolean;
  gc?: boolean;
  minAmount?: number;
  maxAmount?: number;
  productFilter?: string;
}

export interface User {
  id: string;
  active?: boolean;
  admin?: boolean;
  referent?: boolean;
  username?: string;
  partnersId?: string[];
  firstName?: string;
  lastName?: string;
  digitickId?: number;
  ticketsId?: number | string;
  todoAssigneee?: boolean;
}

export interface UserCreated extends User {
  passwordSetLink?: string;
}

export interface Picture {
  url?: string;
  id?: string;
  title?: string;
}

export interface Article {
  id: string;
  active?: boolean;
  creationDate?: number;
  title?: string;
  subtitle?: string;
  body?: string;
  pictures?: Picture[];
  isHomePageHero?: boolean;
  published: boolean;
  publicationDate: number | null;
  category?: CategoryNewsType;
  associatedTeamMember?: string[];
  publicationChannels?: PublicationChannelName[];
  slug?: string;
}

export enum CategoryNewsType {
  match = "match",
  inside = "inside",
  player = "player",
  diverse = "diverse",
  fdd = "fdd",
}

export enum PublicationChannelName {
  appB2B = "appB2B",
  web = "web",
}

export interface Place {
  name?: string;
  address?: string;
  zipCode?: string;
  city?: string;
}

export enum ResourceType {
  news = "news",
  event = "event",
  players = "players",
  match = "match",
  partner = "partner",
  conditions = "conditions",
  teamMember = "teamMember",
  videos = "videos",
  room = "room",
  shopProduct = "shopProduct",
  notification = "notification",
  sponsoring = "sponsoring",
}

export interface Publication {
  type: ResourceType;
  id: string;
  publish: boolean;
  slug?: string;
}

export enum EventType {
  business = "business",
  culture = "culture",
}

export enum EventQuestionType {
  value = "value",
  choice = "choice",
}

export interface EventQuestion {
  question?: string;
  type?: EventQuestionType;
  choices?: string[];
}

export interface EventAnswer {
  question: string;
  answer: string;
}

export interface EventOptionRegistration {
  optionId: number;
  attendees: string[];
  answers: EventAnswer[];
  availableSlots?: number;
}

export interface EventRegistration {
  id: string;
  latestUpdate: number;
  eventId: string;
  userId: string;
  partnerId: string;
  options: EventOptionRegistration[];
}

export interface EventOption {
  id: number;
  title: string;
  registrationsLimit?: number;
  times?: string;
  questions?: EventQuestion[];
  maxAttendees?: number;
}

export interface EventProgram {
  openDate?: number;
  limitDate?: number;
  options?: EventOption[];
}

export interface Event {
  id: string;
  active?: boolean;
  creationDate?: number;
  type: EventType;
  title: string;
  body?: string;
  pictures?: Picture[];
  published: boolean;
  eventDate?: number;
  datePlaceholder?: string;
  place?: Place;
  program?: EventProgram;
  nota?: string;
}

export interface Players {
  id: string;
  firstName: string;
  lastName: string;
  position: string;
  height: string;
  width: string;
  status: string;
  birthdate: string;
  nationality: string;
  pictureUrl: string;
  heroPictureUrl: string;
}

export interface Team {
  id: string;
  name: string;
  logo?: string;
  stadium?: Place;
}

export enum Championship {
  top14 = "top14",
  proD2 = "proD2",
  europeChallenge = "europeChallenge",
  europeChampions = "europeChampions",
  amical = "amical",
}

export interface SecutixMatch {
  id?: number;
  externalName_FR?: string;
  state?: "PREPARING" | "VALIDATED" | "RUNNING" | "SUSPENDED" | "CANCELED" | "CLOSED" | "CANCELED_CLOSED";
  eventId?: number;
  start?: string;
  season?: string;
  hostTeam?: string;
  opposingTeam?: string;
  Day?: string;
  venue?: string;
  city?: string;
  active?: boolean;
  creationDate?: number;
  latestUpdate?: number;
}

export interface Match {
  id: string;
  active?: boolean;
  title?: string;
  body?: string;
  pictures?: Picture[];
  background?: string;
  published?: boolean;
  date?: number;
  time?: string;
  home?: boolean;
  place?: Place;
  opponent?: Team;
  championship?: Championship;
  score?: string;
  ticketsLink?: string;
  championshipDay?: number;
  season?: number;
  damFolderPath?: string;
  parkingTicketsCode?: string;
  tvChannelLogoUrl?: string;
  secutixData?: SecutixMatch;
}

export interface AllData {
  users: User[];
  partners: Partner[];
  news: Article[];
  videos: Article[];
  conversations: ApiConversation[];
  events: Event[];
  staff: Staff[];
  pages: Page[];
  teamMember: TeamMember[];
  matches: Match[];
  conditions: Conditions[];
  partnersLists: PartnersList[];
  players: Players[];
  damSelectors: DamSelectors;
  rooms: Room[];
  roomBookings: RoomBooking[];
  shopProducts: ShopProduct[];
  notifications: Notification[];
  tasks: Task[];
  sponsorings: Sponsoring[];
}

export interface DamSelectors {
  tags: string[];
  folders: string[];
}

export enum UploadType {
  PARTNER_LOGO = "PARTNER_LOGO",
  EVENT_PICTURE = "EVENT_PICTURE",
  MATCH_PICTURE = "MATCH_PICTURE",
  NEWS_PICTURE = "NEWS_PICTURE",
  CONTENT_PICTURE = "CONTENT_PICTURE",
  TEAM_MEMBER_PICTURE = "TEAM_MEMBER_PICTURE",
}

export enum ConditionsType {
  cgu = "cgu",
  privacy_policy = "privacy_policy",
  cgv = "cgv",
  legal = "legal",
}
export enum ConditionsApplication {
  partners_app = "partners_app",
  website = "website",
  tickets = "tickets",
  charity = "charity",
  shop = "shop",
}

export interface Conditions {
  id: string;
  type?: ConditionsType;
  application?: ConditionsApplication;
  version?: number;
  body?: string;
  published?: boolean;
}

export interface UserCreation {
  partnersId: string[];
  username: string;
  firstName: string;
  lastName: string;
  sendEmailReset: boolean;
  digitickId?: number;
  ticketsId?: number | string;
}

export interface PartnerUpdate {
  id: string;
  name: string;
  city: string;
  website: string;
}

export enum ConversationType {
  club = "club",
  individual = "individual",
}

export interface ApiMessage {
  id: string;
  senderId: string;
  body: string;
  sent: number;
}

export interface ApiConversation {
  id: string;
  type: ConversationType;
  usersId: string[];
  messages: ApiMessage[];
  latestUpdate: number;
  isHandled?: boolean;
}

export interface ConversationMessageAdd {
  conversationId?: string;
  messageBody: string;
}

export interface Staff {
  id: string;
  firstName: string;
  lastName: string;
  role: string;
  pictureUrl: string;
}

export enum TeamMemberType {
  player = "player",
  staff = "staff",
}

export enum StaffDepartments {
  sport = "sport",
  medical = "medical",
  data = "data",
}

export enum PlayerStatuses {
  jiff = "jiff",
  nonJiff = "nonJiff",
}

export enum PlayerPositions {
  pilierGauche = "pilierGauche",
  talonneur = "talonneur",
  pilierDroit = "pilierDroit",
  deuxiemeLigne = "deuxiemeLigne",
  troisiemeLigne = "troisiemeLigne",
  demiDeMelee = "demiDeMelee",
  demiDouverture = "demiDouverture",
  centre = "centre",
  ailier = "ailier",
  arriere = "arriere",
}

export enum RoleStaff {
  generalManager = "generalManager",
  skillsCoach = "skillsCoach",
  backsCoach = "backsCoach",
  forwardCoach = "forwardCoach",
  defenseCoach = "defenseCoach",
  performanceManager = "performanceManager",
  teamManager = "teamManager",
  steward = "steward",
  physicalTrainer = "physicalTrainer",
  videoAnalyst = "videoAnalyst",
  dataScientist = "dataScientist",
  doctor = "doctor",
  physiotherapist = "physiotherapist",
  osteopath = "osteopath",
  touchCoach = "touchCoach",
}

export interface TeamMember {
  id: string;
  typeOfMember: TeamMemberType;
  category?: string;
  firstName: string;
  lastName: string;
  nickName?: string;
  birthDate?: number | null;
  birthPlace?: string;
  country?: Country;
  published: boolean;
  seasons?: number[];
  infirmary?: {
    hurt?: boolean;
    causeOfInjury?: string;
    unvailabilityInWeeks?: number;
  };
  socialNetwork?: {
    instagramUrl?: string;
    facebookUrl?: string;
    twitterUrl?: string;
  };
  infos?: {
    position?: PlayerPositions;
    number?: number;
    heightInCm?: number;
    weightInKg?: number;
    status?: PlayerStatuses;
    startInClub?: number | null;
    department?: StaffDepartments;
    role?: RoleStaff;
  };
  description?: {
    bio?: string;
    career?: string;
    trackRecord?: string;
    funFact?: string;
  };
  pictures?: {
    profile?: Picture;
    hero?: Picture;
    slider?: Picture;
  };
}

export enum ComponentType {
  newsHero = "newsHero",
  staticHero = "staticHero",
  newsList = "newsList",
  matchList = "matchList",
  contentCard = "contentCard",
  edito = "edito",
  newsSlider = "newsSlider",
  matchsSlider = "matchsSlider",
  playersSlider = "playersSlider",
  staticBanner = "staticBanner",
  newsCategories = "newsCategories",
  videoEmbed = "videoEmbed",
  gallery = "gallery",
  faq = "faq",
  shopProductSlider = "shopProductSlider",
  zenChefModule = "zenChefModule",
  helloAssoModule = "helloAssoModule",
  cardsGrid = "cardsGrid",
}

export enum NewsMode {
  articles = "articles",
  videos = "videos",
}

export interface TextPair {
  id: string;
  title?: string;
  description?: string;
}

export interface CardItem {
  id: string;
  title?: string;
  picture?: Picture;
  link?: string;
  limit?: number;
}

export interface Component {
  id: string;
  type: ComponentType;
  picture?: Picture;
  title?: string;
  newsType?: NewsMode;
  limit?: number;
  category?: string;
  htmlContent?: string;
  picturePosition?: "start" | "end";
  cropPicture?: boolean;
  position?: "start" | "end";
  actionTitle?: string;
  actionUrl?: string;
  actionImage?: ButtonIconProps;
  videoUrl?: string;
  chrome?: boolean;
  tag?: string;
  folder?: string;
  textItems?: TextPair[];
  data?: unknown[];
  fullWidth?: boolean;
  shopProductCategory?: ProductCategory;
  cardItems?: CardItem[];
  externalId?: string;
  slider?: boolean;
  newsCategory?: CategoryNewsType;
  associatedTeamMember?: string[];
  backgroundColor?: string;
}

export interface ComponentWithPageWidth extends Component {
  pageMaxWidth?: number;
}

export interface PageSeo {
  title?: string;
  description?: string;
  notIndexed?: boolean;
  keywords?: string;
  additionalMetaTags?: string;
}

export interface Page {
  id: string;
  href?: string;
  published?: boolean;
  language?: string;
  seo?: PageSeo;
  content: Component[];
  maxWidth?: number;
}

export interface MatchType {
  id: string;
  title?: string;
  body?: string;
  pictures?: Picture[];
  background?: string;
  published?: boolean;
  date?: number;
  time?: string;
  home?: boolean;
  place?: Place;
  opponent?: Team;
  championship?: Championship;
  score?: string;
  ticketsLink?: string;
  championshipDay?: number;
  season?: number;
  damFolderPath?: string;
}

export enum RoomType {
  lodge = "lodge",
  lounge = "lounge",
  seminar = "seminar",
}

export enum RoomEquipment {
  wifi = "wifi",
  pmr = "pmr",
  screen = "screen",
  coffee = "coffee",
  videoProjector = "videoProjector",
}

export interface Equipment {
  standard: Record<RoomEquipment, boolean>;
  other?: string;
}

export enum ConfigRoomName {
  plenaryConference = "plenaryConference",
  class = "class",
  UTable = "UTable",
  cocktail = "cocktail",
  gala = "gala",
}

export interface ConfigRoom {
  possible: boolean;
  capacity?: number;
}
export interface Room {
  id: string;
  name?: string;
  published?: boolean;
  picture?: Picture;
  roomType?: RoomType;
  presentation?: {
    description?: string;
    services?: string;
    prices?: string;
  };
  features: {
    surface?: number;
    configuration?: Record<ConfigRoomName, ConfigRoom>;
    equipment?: Equipment;
  };
  assets?: {
    location?: {
      picture?: Picture;
      text?: string;
    };
    damFolderPath?: string;
    videoLink?: string;
    brochureLink?: string;
    cta?: {
      label?: string;
      href?: string;
    };
  };
}

export enum RoomBookingStatus {
  option = "option",
  confirmed = "confirmed",
}

export interface RoomBooking {
  id?: string;
  roomId?: string;
  status?: RoomBookingStatus;
  configuration?: ConfigRoomName;
  subject?: string;
  description?: string;
  startTime?: number;
  endTime?: number;
}

export enum KanbanCardStatus {
  Open = "Open",
  InProgress = "InProgress",
  Testing = "Testing",
  Close = "Close",
}
export interface Task {
  id?: string;
  title?: string;
  summary?: string;
  assignee?: string;
  status?: KanbanCardStatus;
}

export enum DiscountType {
  price = "price",
  percentage = "percentage",
}

export enum ProductCategory {
  accessory = "accessory",
  tShirt = "tShirt",
  polo = "polo",
}
export interface ShopProduct {
  id: string;
  title?: string;
  picture?: Picture;
  category?: ProductCategory;
  url?: string;
  highlighted?: boolean;
  description?: string;
  price?: number;
  discount?: {
    value?: number;
    type?: DiscountType;
  };
  published?: boolean;
}

export enum NotificationType {
  news = "news",
  video = "video",
  event = "event",
  match = "match",
  shop = "shop",
  info = "info",
}

export enum LinkType {
  none = "none",
  news = "news",
  video = "video",
  event = "event",
  match = "match",
  shop = "shop",
  url = "url",
}

export interface Notification {
  id: string;
  title?: string;
  picture?: Picture;
  notificationType?: NotificationType;
  linkType?: LinkType;
  link?: string;
  message?: string;
  sent?: boolean;
  sendingTimestamp?: number;
}

export enum ColorButtonProps {
  primary = "primary",
  secondary = "secondary",
  white = "white",
  whiteWithBorder = "whiteWithBorder",
}

export enum ButtonIconProps {
  shopping = "shopping",
  arrowBack = "arrowBack",
  arrowForward = "arrowForward",
  picture = "picture",
  send = "send",
}

export interface Sponsoring {
  id: string;
  title?: string;
  sponsors?: string;
  published?: boolean;
  link?: string;
  pictures?: {
    // bigBanner?: Picture;
    // b2bHomeBanner?: Picture;
    // webSmallBanner?: Picture;
    // article?: Picture;
    // square?: Picture;
    b2bTicket1?: Picture;
    b2bTicket2?: Picture;
    b2bTicketFooter?: Picture;
  };
  message?: string;
  adLocations?: Record<Location, AdLocation>;
}

export interface AdLocation {
  id?: string;
  location?: Location;
  isActive?: boolean;
}

export enum Location {
  // b2bHomePage = "b2bHomePage",
  // webHomePageBigBanner = "webHomePageBigBanner",
  // webHomePageSmallBanner = "webHomePageSmallBanner",
  // b2bFakeContent = "b2bFakeContent",
  // webFakeContent = "webFakeContent",
  b2bTicket = "b2bTicket",
}
