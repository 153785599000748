import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";

export enum TvChannels {
  BeinSports = "BeinSports",
  Canal = "Canal",
  CanalSport = "CanalSport",
  France2 = "France2",
  France3 = "France3",
  FranceTv = "FranceTv",
  Rugby = "Rugby",
}

const getTvChannelName = (channel: TvChannels): string => {
  switch (channel) {
    case TvChannels.BeinSports:
      return "beIN SPORTS";
    case TvChannels.Canal:
      return "Canal+";
    case TvChannels.CanalSport:
      return "Canal+ Sport";
    case TvChannels.France2:
      return "France 2";
    case TvChannels.France3:
      return "France 3";
    case TvChannels.FranceTv:
      return "France TV";
    case TvChannels.Rugby:
      return "Rugby+";
    default:
      return "?";
  }
};

const getChannelFromLogoUrl = (url: string): TvChannels =>
  Object.values(TvChannels).find((v) => url.includes(v)) as TvChannels;

const getLogoUrlFromChannel = (channel: TvChannels): string =>
  `https://avironbayonnais.blob.core.windows.net/static/Tv_${channel}.png`;

interface AbTvChannelSelectorProps {
  logoUrl?: string | null;
  changeLogoUrl: (newLogoUrl: string | null) => void;
}

const AbTvChannelSelector: FC<AbTvChannelSelectorProps> = ({ logoUrl, changeLogoUrl }) => {
  const { t } = useTranslation("match");
  const channel = logoUrl ? getChannelFromLogoUrl(logoUrl) : "";
  return (
    <FormControl sx={{ width: "30%" }}>
      <InputLabel id="input-label-roomType">{t("tvChannelSet")}</InputLabel>
      <Select
        value={channel || ""}
        labelId="input-label-roomType"
        id="select-roomType"
        label={t("tvChannelSet")}
        onChange={(e) =>
          changeLogoUrl(e.target.value.length > 0 ? getLogoUrlFromChannel(e.target.value as TvChannels) : null)
        }>
        <MenuItem value="">
          <em>{t("chooseTvChannel")}</em>
        </MenuItem>
        {Object.keys(TvChannels).map((tc) => (
          <MenuItem key={tc} value={tc}>
            {getTvChannelName(tc as TvChannels)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

AbTvChannelSelector.defaultProps = { logoUrl: "" };

export default AbTvChannelSelector;
