import { styled } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AbCenteredContent from "src/components/AbCenteredContent";
import NotificationPage from "src/pages/NotificationPage";
import RoomBookingPage from "src/pages/RoomBookingPage/RoomBookingPage";
import RoomsPage from "src/pages/RoomsPage";
import ShopPage from "src/pages/ShopPage";
import TodoPage from "src/pages/TodoPage/TodoPage";
import SponsoringPage from "src/pages/SponsoringPage";
import { AuthContext } from "../data/contexts/AuthContext";
import { DataContext } from "../data/contexts/DataContext";
import { EventType } from "../data/types/ApiTypes";
import ActusPage from "../pages/ActusPage";
import ConditionsPage from "../pages/ConditionsPage";
import ConversationsPage from "../pages/ConversationsPage";
import DashboardPage from "../pages/DashboardPage";
import EventsPage from "../pages/EventsPage";
import MatchesPage from "../pages/MatchesPage";
import PartnersPage from "../pages/PartnersPage";
import ProfilePage from "../pages/ProfilePage";
import TeamPage from "../pages/TeamPage";
import TemplatesPage from "../pages/TemplatesPage";
import AppBar from "./AppBar";
import UserMenu from "./UserMenu";
import AppDrawer, { drawerWidth } from "./AppDrawer";

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(({ theme, about }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `56px`,
  ...(about === "opened" && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: `${drawerWidth}px`,
  }),
}));

let launched = false;

const Navigation: FC = () => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const { signOut } = useContext(AuthContext);
  const { getData } = useContext(DataContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (!launched) {
      launched = true;
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeMenu = (): void => {
    setAnchorEl(null);
  };
  const navIfRelevant = (path: string): void => {
    if (path !== pathname) {
      navigate(path);
    }
    setMenuOpen(false);
  };
  const closeDrawer = (): void => setMenuOpen(false);

  return process.env.REACT_APP_BO_IS_CLOSED === "true" ? (
    <AbCenteredContent infoText="Le Back-Office est en maintenance" />
  ) : (
    <div className="nav-root" style={{ background: 'url("/logo_bg.png") no-repeat 100% 100%' }}>
      <AppBar menuOpen={menuOpen} setMenuOpen={setMenuOpen} setAnchorEl={setAnchorEl} />
      <Main className="page-root" about={menuOpen ? "opened" : "closed"}>
        <Routes>
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/partners" element={<PartnersPage />} />
          <Route path="/news" element={<ActusPage />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/events" element={<EventsPage eventType={EventType.business} />} />
          <Route path="/matches" element={<MatchesPage />} />
          <Route path="/conversations" element={<ConversationsPage />} />
          <Route path="/conditions" element={<ConditionsPage />} />
          <Route path="/pages/:pageId" element={<TemplatesPage />} />
          <Route path="/pages" element={<TemplatesPage />} />
          {process.env.REACT_APP_FEATURE_FLIPPING_ROOM_BOOKING === "true" ? (
            <Route path="/roomBooking" element={<RoomBookingPage />} />
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_ROOM === "true" ? (
            <Route path="/rooms" element={<RoomsPage />} />
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_TODO === "true" ? (
            <Route path="/todo" element={<TodoPage />} />
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_SHOP === "true" ? (
            <Route path="/shop" element={<ShopPage />} />
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_NOTIFICATION === "true" ? (
            <Route path="/notification" element={<NotificationPage />} />
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_SPONSORING === "true" ? (
            <Route path="/sponsoring" element={<SponsoringPage />} />
          ) : undefined}
          <Route path="/" element={<DashboardPage />} />
        </Routes>
      </Main>
      <UserMenu anchorEl={anchorEl} close={closeMenu} nav={navIfRelevant} signOut={signOut} />
      <AppDrawer open={menuOpen} closeDrawer={closeDrawer} nav={navIfRelevant} />
    </div>
  );
};

export default Navigation;
