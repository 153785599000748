/* eslint-disable no-restricted-syntax */
import jwt, { JwtPayload } from "jwt-decode";
import { useSnackbar } from "notistack";
import { FC, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  checkDigitickUser,
  createComponent,
  delEvent,
  delNews,
  delNotification,
  delRoom,
  delSponsoring,
  delRoomBooking,
  delShopProduct,
  delTask,
  delTeamMember,
  deleteComponent,
  getAllData,
  getEventRegistrations,
  getExport,
  getGalleryPictures,
  getNews,
  getNotification,
  getPage,
  getRoom,
  getShopProduct,
  getTask,
  getSponsoring,
  getTeamMember,
  markConversationAsHandled,
  patchComponent,
  patchConditions,
  patchConversation,
  patchEvent,
  patchMatch,
  patchNews,
  patchNotification,
  patchPage,
  patchPartner,
  patchRoom,
  patchSponsoring,
  patchRoomBooking,
  patchShopProduct,
  patchTask,
  patchTeamMember,
  patchUser,
  postConditions,
  postEvent,
  postMatch,
  postNews,
  postNotification,
  postPage,
  postPublish,
  postRoom,
  postSponsoring,
  postRoomBooking,
  postShopProduct,
  postTask,
  postTeamMember,
  postUser,
  resetApiCache,
  resetPassword,
  uploadFile,
  getTicketPdfBlob,
  getTicketsArchiveBlob,
} from "../Api";
import { apiConversationToConversation, downloadFile } from "../Converters";
import { log } from "../Logger";
import {
  Article,
  AuthData,
  Component,
  ComponentType,
  Conditions,
  DamSelectors,
  Event,
  EventRegistration,
  Match,
  Notification,
  Page,
  Partner,
  PartnerUpdate,
  PartnersList,
  Picture,
  ResourceType,
  Room,
  RoomBooking,
  ShopProduct,
  Task,
  TeamMember,
  UploadType,
  Sponsoring,
  User,
  UserCreated,
  UserCreation,
} from "../types/ApiTypes";
import { Conversation } from "../types/ClientTypes";
import { AuthContext } from "./AuthContext";

const initialNewUser = { username: "", firstName: "", lastName: "", partnersId: [] };

export interface DataContextProps {
  users: User[];
  partners: Partner[];
  news: Article[];
  videos: Article[];
  teamMembers: TeamMember[];
  conversations: Conversation[];
  events: Event[];
  pages: Page[];
  damSelectors: DamSelectors;
  matches: Match[];
  conditions: Conditions[];
  rooms: Room[];
  roomBookings: RoomBooking[];
  shopProducts: ShopProduct[];
  notifications: Notification[];
  tasks: Task[];
  sponsorings: Sponsoring[];
  partnersLists: PartnersList[];
  registrations: Record<string, EventRegistration[]>;
  getData: () => Promise<void>;
  initApp: () => Promise<void>;
  createUser: (newUser: UserCreation, userCreateSuccess: (createdUser: UserCreated) => void) => Promise<void>;
  checkDUser: (email: string, partnerId: string) => Promise<UserCreation | null>;
  updateUser: (newUser: User) => Promise<void>;
  updatePartner: (newPartner: PartnerUpdate) => Promise<void>;
  createEvent: (newEvent: Event) => Promise<void>;
  updateEvent: (newEvent: Event) => Promise<void>;
  deleteEvent: (eventId: string) => Promise<void>;
  createTeamMember: (newTeamMember: TeamMember) => Promise<void>;
  updateTeamMember: (newTeamMember: TeamMember) => Promise<void>;
  deleteTeamMember: (id: string) => Promise<void>;
  refreshTeamMember: (id: string) => Promise<void>;
  createRoom: (newRoom: Room) => Promise<void>;
  updateRoom: (newRoom: Room) => Promise<void>;
  deleteRoom: (id: string) => Promise<void>;
  refreshRoom: (id: string) => Promise<void>;
  publishRoom: (id: string, publish: boolean) => Promise<void>;
  createShopProduct: (newShopProduct: ShopProduct) => Promise<void>;
  updateShopProduct: (newShopProduct: ShopProduct) => Promise<void>;
  deleteShopProduct: (id: string) => Promise<void>;
  refreshShopProduct: (id: string) => Promise<void>;
  publishShopProduct: (id: string, publish: boolean) => Promise<void>;
  createNotification: (newNotification: Notification) => Promise<void>;
  updateNotification: (newNotification: Notification) => Promise<void>;
  deleteNotification: (id: string) => Promise<void>;
  refreshNotification: (id: string) => Promise<void>;
  createTask: (newTask: Task) => Promise<void>;
  updateTask: (newTask: Task) => Promise<void>;
  deleteTask: (id: string) => Promise<void>;
  refreshTask: (id: string) => Promise<void>;
  createSponsoring: (newSponsoring: Sponsoring) => Promise<void>;
  updateSponsoring: (newSponsoring: Sponsoring) => Promise<void>;
  deleteSponsoring: (id: string) => Promise<void>;
  refreshSponsorings: (id: string) => Promise<void>;
  createRoomBooking: (newRoomBooking: RoomBooking) => Promise<void>;
  updateRoomBooking: (newRoomBooking: RoomBooking) => Promise<void>;
  deleteRoomBooking: (id: string) => Promise<void>;
  createPage: (page: Partial<Page>) => Promise<void>;
  updatePage: (page: Partial<Page>) => Promise<void>;
  pageAddComponent: (pageId: string, component: Component) => Promise<void>;
  pageUpdateComponent: (pageId: string, component: Component) => Promise<void>;
  pageRemoveComponent: (pageId: string, id: string) => Promise<void>;
  refreshPage: (id: string) => Promise<void>;
  deleteNews: (id: string) => Promise<void>;
  createNews: (newNews: Article) => Promise<void>;
  updateNews: (newNews: Article) => Promise<void>;
  refreshNews: (id: string) => Promise<void>;
  publishEvent: (eventId: string, publish: boolean) => Promise<void>;
  publishArticle: (
    articleId: string,
    publish: boolean,
    handleHttpError: (httpStatus: number) => void,
    slug?: string,
  ) => Promise<void>;
  publishVideo: (videoId: string, publish: boolean) => Promise<void>;
  publishTeamMember: (id: string, publish: boolean) => Promise<void>;
  publishPartner: (articleId: string, publish: boolean) => Promise<void>;
  publishSponsoring: (id: string, publish: boolean) => Promise<void>;
  upload: (file: File, ressourceId: string, type: UploadType) => Promise<boolean | string>;
  createMatch: (newMatch: Match) => Promise<void>;
  updateMatch: (newMatch: Match) => Promise<void>;
  publishMatch: (articleId: string, publish: boolean) => Promise<void>;
  createConditions: (newConditions: Conditions) => Promise<void>;
  updateConditions: (newConditions: Conditions) => Promise<void>;
  publishConditions: (conditionsId: string, publish: boolean) => Promise<void>;
  sendMessage: (messageBody: string, conversationId?: string) => Promise<void>;
  handledConversation: (id: string) => Promise<void>;
  exportPartners: () => Promise<void>;
  getRegistrations: (eventId: string) => Promise<EventRegistration[]>;
  resetCache: () => Promise<boolean>;
  resetPasswordUrl: (id: string) => Promise<boolean | string>;
  downloadTicket: (ticketId: number, fileName: string, partnerId: string) => Promise<void>;
  downloadTicketsArchive: (matchId: string, fileName: string, partnerId: string, category?: string) => Promise<void>;
}

export const DataContext = createContext<DataContextProps>({
  users: [],
  partners: [],
  news: [],
  videos: [],
  conversations: [],
  events: [],
  teamMembers: [],
  pages: [],
  damSelectors: { tags: [], folders: [] },
  matches: [],
  conditions: [],
  rooms: [],
  sponsorings: [],
  roomBookings: [],
  shopProducts: [],
  notifications: [],
  partnersLists: [],
  registrations: {},
  tasks: [],
  getData: () => Promise.resolve(),
  initApp: () => Promise.resolve(),
  createUser: () => Promise.resolve(),
  checkDUser: () => Promise.resolve({ ...initialNewUser, partnersId: [], sendEmailReset: false }),
  updateUser: () => Promise.resolve(),
  updatePartner: () => Promise.resolve(),
  createEvent: () => Promise.resolve(),
  updateEvent: () => Promise.resolve(),
  deleteEvent: () => Promise.resolve(),
  publishEvent: () => Promise.resolve(),
  publishTeamMember: () => Promise.resolve(),
  createTeamMember: () => Promise.resolve(),
  updateTeamMember: () => Promise.resolve(),
  deleteTeamMember: () => Promise.resolve(),
  refreshTeamMember: () => Promise.resolve(),
  publishRoom: () => Promise.resolve(),
  createRoom: () => Promise.resolve(),
  updateRoom: () => Promise.resolve(),
  deleteRoom: () => Promise.resolve(),
  refreshRoom: () => Promise.resolve(),
  publishShopProduct: () => Promise.resolve(),
  createShopProduct: () => Promise.resolve(),
  updateShopProduct: () => Promise.resolve(),
  deleteShopProduct: () => Promise.resolve(),
  refreshShopProduct: () => Promise.resolve(),
  createNotification: () => Promise.resolve(),
  updateNotification: () => Promise.resolve(),
  deleteNotification: () => Promise.resolve(),
  refreshNotification: () => Promise.resolve(),
  createTask: () => Promise.resolve(),
  updateTask: () => Promise.resolve(),
  deleteTask: () => Promise.resolve(),
  refreshTask: () => Promise.resolve(),
  createSponsoring: () => Promise.resolve(),
  updateSponsoring: () => Promise.resolve(),
  deleteSponsoring: () => Promise.resolve(),
  refreshSponsorings: () => Promise.resolve(),
  createRoomBooking: () => Promise.resolve(),
  updateRoomBooking: () => Promise.resolve(),
  deleteRoomBooking: () => Promise.resolve(),
  createPage: () => Promise.resolve(),
  updatePage: () => Promise.resolve(),
  pageAddComponent: () => Promise.resolve(),
  pageUpdateComponent: () => Promise.resolve(),
  pageRemoveComponent: () => Promise.resolve(),
  refreshPage: () => Promise.resolve(),
  createNews: () => Promise.resolve(),
  updateNews: () => Promise.resolve(),
  deleteNews: () => Promise.resolve(),
  refreshNews: () => Promise.resolve(),
  publishArticle: () => Promise.resolve(),
  publishPartner: () => Promise.resolve(),
  publishVideo: () => Promise.resolve(),
  publishSponsoring: () => Promise.resolve(),
  upload: () => Promise.resolve(false),
  createMatch: () => Promise.resolve(),
  updateMatch: () => Promise.resolve(),
  publishMatch: () => Promise.resolve(),
  createConditions: () => Promise.resolve(),
  updateConditions: () => Promise.resolve(),
  publishConditions: () => Promise.resolve(),
  sendMessage: () => Promise.resolve(),
  handledConversation: () => Promise.resolve(),
  exportPartners: () => Promise.resolve(),
  getRegistrations: () => Promise.resolve([]),
  resetCache: () => Promise.resolve(false),
  resetPasswordUrl: () => Promise.resolve(false),
  downloadTicket: () => Promise.resolve(),
  downloadTicketsArchive: () => Promise.resolve(),
});

const sortPartners = (p1: Partner | null, p2: Partner | null): number => {
  if ((!p1 && !p2) || (!p1 && p2) || (p1 && p2 && p1.name.toLowerCase() > p2.name.toLowerCase())) return 1;
  return -1;
};
const sortNews = (a1: Article, a2: Article): number => {
  if (a1.publicationDate && a2.publicationDate) if (a1.publicationDate > a2.publicationDate) return -1;
  return 1;
};
const sortVideo = (a1: Article, a2: Article): number => {
  if (a1.publicationDate && a2.publicationDate) if (a1.publicationDate > a2.publicationDate) return -1;
  return 1;
};
const sortEvents = (e1: Event, e2: Event): number => {
  if (!e1.eventDate && !e2.eventDate && (e1.creationDate || 0) > (e2.creationDate || 0)) return -1;
  if ((!e1.eventDate && e2.eventDate) || (e1.eventDate || 0) > (e2.eventDate || 0)) return -1;
  return 1;
};
export const sortNotifications = (n1: Notification, n2: Notification): number => {
  if (!n1.sendingTimestamp) return -1;
  if (n1.sent && n2.sent && n1.sendingTimestamp && n2.sendingTimestamp && n1.sendingTimestamp > n2.sendingTimestamp)
    return -1;
  return 1;
};
const sortMatches = (e1: Match, e2: Match): number => {
  if (e1.date && e2.date && e1.date > e2.date) return -1;
  return 1;
};
const sortConditions = (e1: Conditions, e2: Conditions): number => {
  if (e2.version && (!e1.version || e1.version < e2.version)) return -1;
  return 1;
};
const sortUsers = (u1: User, u2: User): number => {
  if (u1.referent && !u2.referent) return -1;
  if (!u1.referent && u2.referent) return 1;
  if (u2.lastName && u1.lastName && u2.lastName < u1.lastName) return 1;
  return -1;
};
const sortConversations = (c1: Conversation, c2: Conversation): number => {
  const msg1Dates = c1.messages.map((m) => m.sent);
  const msg2Dates = c2.messages.map((m) => m.sent);
  return Math.max(...msg1Dates) > Math.max(...msg2Dates) ? -1 : 1;
};

export const DataProvider: FC<{ children: JSX.Element }> = ({ children }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [partners, setPartners] = useState<Partner[]>([]);
  const [news, setNews] = useState<Article[]>([]);
  const [videos, setVideos] = useState<Article[]>([]);
  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [events, setEvents] = useState<Event[]>([]);
  const [teamMembers, setTeamMembers] = useState<TeamMember[]>([]);
  const [pages, setPages] = useState<Page[]>([]);
  const [matches, setMatches] = useState<Match[]>([]);
  const [conditions, setConditions] = useState<Conditions[]>([]);
  const [partnersLists, setPartnersLists] = useState<PartnersList[]>([]);
  const [registrations, setRegistrations] = useState<Record<string, EventRegistration[]>>({});
  const [latestFetchAll, setLatestFetchAll] = useState(0);
  const [damSelectors, setDamsSelectors] = useState<DamSelectors>({ tags: [], folders: [] });
  const [galleries, setGalleries] = useState<Record<string, Picture[]>>({});
  const [rooms, setRooms] = useState<Room[]>([]);
  const [roomBookings, setRoomBookings] = useState<RoomBooking[]>([]);
  const [shopProducts, setShopProducts] = useState<ShopProduct[]>([]);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const todoAssignees = [];
  users.map((u) => (u.todoAssigneee ? todoAssignees.push(u.firstName) : undefined));
  const [sponsorings, setSponsorings] = useState<Sponsoring[]>([]);
  const { initLocalAuth, getToken, auth } = useContext(AuthContext);
  const { t } = useTranslation([
    "global",
    "partner",
    "event",
    "staff",
    "content",
    "teamMember",
    "rooms",
    "shop",
    "notification",
  ]);
  const { enqueueSnackbar } = useSnackbar();

  const syncGalleries = useCallback(async (): Promise<void> => {
    let onePageChanged = false;
    const newPages: Page[] = [];
    for (const page of pages) {
      const newPage = { ...page };
      for (const component of newPage.content) {
        if (component.type === ComponentType.gallery) {
          const cacheKey = `${component.tag || ""}_${component.folder || ""}_${component.limit || ""}`;
          let gallery = galleries[cacheKey];
          if (typeof gallery === "undefined") {
            // eslint-disable-next-line no-await-in-loop
            const token = await getToken();
            if (token) {
              // eslint-disable-next-line no-await-in-loop
              const result = await getGalleryPictures(
                token,
                component.limit?.toString() || "20",
                component.tag,
                component.folder,
              );
              const newGallery = { ...galleries };
              gallery = typeof result === "number" ? [] : (result as Picture[]);
              newGallery[cacheKey] = gallery;
              setGalleries(newGallery);
            }
          }
          if (component.data !== gallery) {
            onePageChanged = true;
            component.data = gallery;
          }
        } else if (component.type === ComponentType.shopProductSlider) {
          let componentData = [];
          if (component.shopProductCategory !== undefined) {
            componentData = shopProducts.filter((sp) => sp.published && sp.category === component.shopProductCategory);
          } else {
            componentData = shopProducts.filter((sp) => sp.published);
          }
          if ((component.data?.length || 0) !== componentData.length) {
            component.data = componentData;
            onePageChanged = true;
          }
        } else if (component.type === ComponentType.newsSlider) {
          const componentData = news
            .filter(
              (n) =>
                n.published &&
                (component.newsCategory === undefined || n.category === component.newsCategory) &&
                ((component.associatedTeamMember?.length || 0) === 0 ||
                  (component.associatedTeamMember?.filter((atm) => n.associatedTeamMember?.includes(atm)).length || 0) >
                    0),
            )
            .slice(0, component.limit);
          if ((component.data?.length || 0) !== componentData.length) {
            component.data = componentData;
            onePageChanged = true;
          }
        }
      }
      newPages.push(newPage);
    }
    if (onePageChanged) setPages(newPages);
  }, [pages, galleries, getToken, shopProducts, news]);

  useEffect(() => {
    setTimeout(() => syncGalleries(), 500);
  }, [syncGalleries]);

  const contextValue = useMemo(() => {
    const handleError = (e: Error): void => {
      log.error("Error while getting all data", e);
      enqueueSnackbar(t("unkownError"), { variant: "error" });
    };

    const getData = async (token?: string, force?: boolean): Promise<void> => {
      if (latestFetchAll + 10 * 60000 > new Date().getTime() && !force) return;
      setLatestFetchAll(new Date().getTime());
      try {
        const finalToken = token || (await getToken());
        if (finalToken) {
          const user = jwt(finalToken) as AuthData & JwtPayload;
          const result = await getAllData(finalToken);
          if (typeof result === "object") {
            setUsers(result.users.sort(sortUsers));
            setPartners(result.partners.sort(sortPartners));
            setNews(result.news.sort(sortNews));
            setVideos(result.videos.sort(sortVideo));
            setConversations(
              result.conversations
                .map((c) => apiConversationToConversation(c, result.users, result.partners, user.userid))
                .sort(sortConversations),
            );
            setEvents(result.events.sort(sortEvents));
            setMatches(result.matches.sort(sortMatches));
            setTeamMembers(result.teamMember);
            setConditions(result.conditions.sort(sortConditions));
            setPartnersLists(result.partnersLists);
            setPages(result.pages);
            setDamsSelectors(result.damSelectors);
            setRooms(result.rooms);
            setRoomBookings(result.roomBookings);
            setShopProducts(result.shopProducts);
            setNotifications(result.notifications.sort(sortNotifications));
            setTasks(result.tasks);
            setSponsorings(result.sponsorings);
          }
        }
      } catch (e) {
        handleError(e as Error);
      }
    };

    const initApp = async (): Promise<void> => {
      const token = await initLocalAuth();
      if (token) {
        return getData(token);
      }
      return Promise.resolve();
    };

    const requestApi = async (
      request: (token: string) => Promise<unknown>,
      updateState: (data: unknown) => boolean | void,
      dontShowSuccessSnackbar?: boolean,
      handleHttpError?: (httpStatus: number) => void,
    ): Promise<void> => {
      try {
        const token = await getToken();
        if (token) {
          const result = await request(token);
          if (typeof result === "number") {
            if (handleHttpError) handleHttpError(result);
            else {
              log.error("Request failed with status", result);
              enqueueSnackbar(t("unkownError"), { variant: "error" });
            }
          } else {
            try {
              updateState(result);
              if (!dontShowSuccessSnackbar) enqueueSnackbar(t("updateSuccess"), { variant: "success" });
            } catch (_) {
              // If updateState threw an exception, just don't enqueueSnackbar
            }
          }
        }
      } catch (e) {
        handleError(e as Error);
      }
    };

    const createUser = async (
      newUser: UserCreation,
      userCreateSuccess: (createdUser: UserCreated) => void,
    ): Promise<void> => {
      await requestApi(
        (token) => postUser(token, newUser),
        (result) => {
          const createdUser = result as UserCreated;
          const newUsers = [...users, createdUser];
          setUsers(newUsers.sort(sortUsers));
          userCreateSuccess(createdUser);
        },
      );
    };

    const updateUser = async (newUser: User): Promise<void> => {
      await requestApi(
        (token) => patchUser(token, newUser),
        (result) => {
          const updatedUser = result as User;
          setUsers(users.map((u) => (u.id === updatedUser.id ? updatedUser : u)));
        },
      );
    };

    const createEvent = async (newEvent: Event): Promise<void> => {
      await requestApi(
        (token) => postEvent(token, newEvent),
        (result) => {
          const createdEvent = result as Event;
          const newEvents = [createdEvent, ...events];
          newEvents.sort(sortEvents);
          setEvents(newEvents);
        },
      );
    };

    const updateEvent = async (newEvent: Event): Promise<void> => {
      await requestApi(
        (token) => patchEvent(token, newEvent),
        (result) => {
          const updatedEvent = result as Event;
          setEvents(events.map((e) => (e.id === updatedEvent.id ? updatedEvent : e)));
        },
      );
    };

    const deleteEvent = async (eventId: string): Promise<void> => {
      await requestApi(
        (token) => delEvent(token, eventId),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("event:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setEvents(events.filter((e) => e.id !== eventId));
        },
      );
    };

    const createTeamMember = async (oneMoreTeamMember: TeamMember): Promise<void> => {
      await requestApi(
        (token) => postTeamMember(token, oneMoreTeamMember),
        (result) => {
          const createdTeamMember = result as TeamMember;
          const newTeamMember = [createdTeamMember, ...teamMembers];
          setTeamMembers(newTeamMember);
        },
      );
    };

    const updateTeamMember = async (newTeamMember: TeamMember): Promise<void> => {
      await requestApi(
        (token) => patchTeamMember(token, newTeamMember),
        (result) => {
          const updatedTeamMember = result as TeamMember;
          setTeamMembers(teamMembers.map((tm) => (tm.id === updatedTeamMember.id ? updatedTeamMember : tm)));
        },
      );
    };

    const deleteTeamMember = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delTeamMember(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("teamMember:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setTeamMembers(teamMembers.filter((tm) => tm.id !== id));
        },
      );
    };

    const publishTeamMember = async (id: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.teamMember, id, publish }),
        (result) => {
          const updatedTeamMember = result as TeamMember;
          setTeamMembers(teamMembers.map((tm) => (tm.id === updatedTeamMember.id ? updatedTeamMember : tm)));
        },
      );
    };

    const refreshTeamMember = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getTeamMember(token, id),
        (result) => {
          const refreshedTeamMember = result as TeamMember;
          setTeamMembers(teamMembers.map((tm) => (tm.id !== id ? tm : refreshedTeamMember)));
        },
        true,
      );
    };

    const createRoom = async (oneMoreRoom: Room): Promise<void> => {
      await requestApi(
        (token) => postRoom(token, oneMoreRoom),
        (result) => {
          const createdRoom = result as Room;
          const newRoom = [createdRoom, ...rooms];
          setRooms(newRoom);
        },
      );
    };

    const updateRoom = async (newRoom: Room): Promise<void> => {
      await requestApi(
        (token) => patchRoom(token, newRoom),
        (result) => {
          const updatedRoom = result as Room;
          setRooms(rooms.map((r) => (r.id === updatedRoom.id ? updatedRoom : r)));
        },
      );
    };

    const deleteRoom = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delRoom(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("teamMember:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setRooms(rooms.filter((r) => r.id !== id));
        },
      );
    };

    const publishRoom = async (id: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.room, id, publish }),
        (result) => {
          const updatedRoom = result as Room;
          setRooms(rooms.map((r) => (r.id === updatedRoom.id ? updatedRoom : r)));
        },
      );
    };

    const refreshRoom = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getRoom(token, id),
        (result) => {
          const refreshedRoom = result as Room;
          setRooms(rooms.map((r) => (r.id !== id ? r : refreshedRoom)));
        },
        true,
      );
    };
    const createShopProduct = async (oneMoreShopProduct: ShopProduct): Promise<void> => {
      await requestApi(
        (token) => postShopProduct(token, oneMoreShopProduct),
        (result) => {
          const createdShopProduct = result as ShopProduct;
          const newShopProduct = [createdShopProduct, ...shopProducts];
          setShopProducts(newShopProduct);
        },
      );
    };

    const updateShopProduct = async (newShopProduct: ShopProduct): Promise<void> => {
      await requestApi(
        (token) => patchShopProduct(token, newShopProduct),
        (result) => {
          const updatecShopProduct = result as ShopProduct;
          setShopProducts(shopProducts.map((sp) => (sp.id === updatecShopProduct.id ? updatecShopProduct : sp)));
        },
      );
    };

    const deleteShopProduct = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delShopProduct(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("shop:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setShopProducts(shopProducts.filter((sp) => sp.id !== id));
        },
      );
    };

    const publishShopProduct = async (id: string, published: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.shopProduct, id, publish: published }),
        (result) => {
          const updatedShopProduct = result as ShopProduct;
          setShopProducts(shopProducts.map((sp) => (sp.id === updatedShopProduct.id ? updatedShopProduct : sp)));
        },
      );
    };

    const refreshShopProduct = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getShopProduct(token, id),
        (result) => {
          const refreshedShopProduct = result as ShopProduct;
          setShopProducts(shopProducts.map((sp) => (sp.id !== id ? sp : refreshedShopProduct)));
        },
        true,
      );
    };

    const createNotification = async (oneMoreNotification: Notification): Promise<void> => {
      await requestApi(
        (token) => postNotification(token, oneMoreNotification),
        (result) => {
          const createdNotification = result as Notification;
          const newNotification = [createdNotification, ...notifications];
          setNotifications(newNotification);
        },
      );
    };

    const updateNotification = async (newNotification: Notification): Promise<void> => {
      await requestApi(
        (token) => patchNotification(token, newNotification),
        (result) => {
          const updatecNotification = result as Notification;
          setNotifications(notifications.map((n) => (n.id === updatecNotification.id ? updatecNotification : n)));
        },
      );
    };

    const deleteNotification = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delNotification(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("notification:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setNotifications(notifications.filter((n) => n.id !== id));
        },
      );
    };

    const refreshNotification = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getNotification(token, id),
        (result) => {
          const refreshedNotification = result as Notification;
          setNotifications(notifications.map((n) => (n.id !== id ? n : refreshedNotification)));
        },
        true,
      );
    };

    const createRoomBooking = async (oneMoreRoomBooking: RoomBooking): Promise<void> => {
      await requestApi(
        (token) => postRoomBooking(token, oneMoreRoomBooking),
        (result) => {
          const createdRoomBooking = result as RoomBooking;
          const newRoomBooking = [createdRoomBooking, ...roomBookings];
          setRoomBookings(newRoomBooking);
        },
      );
    };

    const updateRoomBooking = async (newRoomBooking: RoomBooking): Promise<void> => {
      await requestApi(
        (token) => patchRoomBooking(token, newRoomBooking),
        (result) => {
          const updatedRoomBooking = result as RoomBooking;
          setRoomBookings(roomBookings.map((r) => (r.id === updatedRoomBooking.id ? updatedRoomBooking : r)));
        },
      );
    };

    const deleteRoomBooking = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delRoomBooking(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("teamMember:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setRoomBookings(roomBookings.filter((r) => r.id !== id));
        },
      );
    };

    const createSponsoring = async (oneMoreSponsoring: Sponsoring): Promise<void> => {
      await requestApi(
        (token) => postSponsoring(token, oneMoreSponsoring),
        (result) => {
          const createdSponsoring = result as Sponsoring;
          const newSponsoring = [createdSponsoring, ...sponsorings];
          setSponsorings(newSponsoring);
        },
      );
    };

    const updateSponsoring = async (newSponsoring: Sponsoring): Promise<void> => {
      await requestApi(
        (token) => patchSponsoring(token, newSponsoring),
        (result) => {
          const updatedSponsoring = result as Sponsoring;
          setSponsorings(sponsorings.map((s) => (s.id === updatedSponsoring.id ? updatedSponsoring : s)));
        },
      );
    };

    const deleteSponsoring = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delSponsoring(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("sponsoring:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setSponsorings(sponsorings.filter((s) => s.id !== id));
        },
      );
    };

    const refreshSponsorings = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getSponsoring(token, id),
        (result) => {
          const refreshedSponsoring = result as Sponsoring;
          setSponsorings(sponsorings.map((s) => (s.id !== id ? s : refreshedSponsoring)));
        },
        true,
      );
    };

    const publishSponsoring = async (id: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.sponsoring, id, publish }),
        (result) => {
          const updatedSponsoring = result as Sponsoring;
          setSponsorings(sponsorings.map((s) => (s.id === updatedSponsoring.id ? updatedSponsoring : s)));
        },
      );
    };

    const createNews = async (oneMoreNews: Article): Promise<void> => {
      await requestApi(
        (token) => postNews(token, oneMoreNews),
        (result) => {
          const createdNews = result as Article;
          const newNews = [createdNews, ...news];
          setNews(newNews);
        },
      );
    };

    const updateNews = async (newNews: Article): Promise<void> => {
      await requestApi(
        (token) => patchNews(token, newNews),
        (result) => {
          const updatedNews = result as Article;
          setNews(news.map((tm) => (tm.id === updatedNews.id ? updatedNews : tm)));
        },
      );
    };

    const deleteNews = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delNews(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("news:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setNews(news.filter((n) => n.id !== id));
        },
      );
    };

    const refreshNews = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getNews(token, id),
        (result) => {
          const refreshedNews = result as Article;
          setNews(news.map((n) => (n.id !== id ? n : refreshedNews)));
        },
        true,
      );
    };

    const createTask = async (oneMoreTask: Task): Promise<void> => {
      await requestApi(
        (token) => postTask(token, oneMoreTask),
        (result) => {
          const createdTask = result as Article;
          const newTasks = [createdTask, ...tasks];
          setTasks(newTasks);
        },
      );
    };

    const updateTask = async (newTask: Task): Promise<void> => {
      await requestApi(
        (token) => patchTask(token, newTask),
        (result) => {
          const updatedTask = result as Task;
          setTasks(tasks.map((ta) => (ta.id === updatedTask.id ? updatedTask : ta)));
        },
      );
    };

    const deleteTask = async (id: string): Promise<void> => {
      await requestApi(
        (token) => delTask(token, id),
        (result) => {
          const success = result === "OK";
          if (!success) {
            enqueueSnackbar(t("task:deletionFailed"), { variant: "error" });
            throw new Error("No success");
          }
          setTasks(tasks.filter((ta) => ta.id !== id));
        },
      );
    };

    const refreshTask = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getTask(token, id),
        (result) => {
          const refreshedTask = result as Task;
          setTasks(tasks.map((ta) => (ta.id !== id ? ta : refreshedTask)));
        },
        true,
      );
    };

    const publishEvent = async (eventId: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.event, id: eventId, publish }),
        (result) => {
          const updatedEvent = result as Event;
          setEvents(events.map((e) => (e.id === updatedEvent.id ? updatedEvent : e)));
        },
      );
    };

    const publishArticle = async (
      articleId: string,
      publish: boolean,
      handleHttpError: (httpStatus: number) => void,
      slug?: string,
    ): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.news, id: articleId, publish, slug }),
        (result) => {
          const updatedArticle = result as Article;
          setNews(news.map((a) => (a.id === updatedArticle.id ? updatedArticle : a)));
        },
        undefined,
        handleHttpError,
      );
    };

    const publishVideo = async (videoId: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.videos, id: videoId, publish }),
        (result) => {
          const updatedArticle = result as Article;
          setVideos(videos.map((v) => (v.id === updatedArticle.id ? updatedArticle : v)));
        },
      );
    };

    const publishPartner = async (partnerId: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.partner, id: partnerId, publish }),
        (result) => {
          const updatedPartner = result as Partner;
          setPartners(partners.map((p) => (p.id === updatedPartner.id ? updatedPartner : p)));
        },
      );
    };

    const upload = async (file: File, resourceId: string, type: UploadType): Promise<boolean | string> => {
      try {
        const token = await getToken();
        if (token) {
          const result = await uploadFile(token, file, resourceId, type);
          switch (type) {
            case UploadType.PARTNER_LOGO:
              setPartners(partners.map((p) => (p.id === resourceId ? (result as Partner) : p)));
              break;
            case UploadType.EVENT_PICTURE:
              return result as string;
            case UploadType.MATCH_PICTURE:
              setMatches(matches.map((m) => (m.id === resourceId ? (result as Match) : m)));
              break;
            case UploadType.TEAM_MEMBER_PICTURE:
              return result as string;
            case UploadType.CONTENT_PICTURE:
              return result as string;
            case UploadType.NEWS_PICTURE:
              return result as string;
            default:
              throw new Error("Not supported yet!");
          }
          return true;
        }
        return false;
      } catch (err) {
        handleError(err as Error);
        return false;
      }
    };

    const updatePartner = async (newPartner: PartnerUpdate): Promise<void> => {
      await requestApi(
        (token) => patchPartner(token, newPartner),
        (result) => {
          const updatedPartner = result as Partner;
          setPartners(partners.map((p) => (p.id === updatedPartner.id ? updatedPartner : p)));
        },
      );
    };

    const createMatch = async (newMatch: Match): Promise<void> => {
      await requestApi(
        (token) => postMatch(token, newMatch),
        (result) => {
          const createdMatch = result as Match;
          const newMatches = [...matches, createdMatch];
          setMatches(newMatches.sort(sortMatches));
        },
      );
    };

    const updateMatch = async (newMatch: Match): Promise<void> => {
      await requestApi(
        (token) => patchMatch(token, newMatch),
        (result) => {
          const updatedMatch = result as Match;
          setMatches(matches.map((m) => (m.id === updatedMatch.id ? updatedMatch : m)));
        },
      );
    };

    const publishMatch = async (matchId: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.match, id: matchId, publish }),
        (result) => {
          const updatedMatch = result as Match;
          setMatches(matches.map((m) => (m.id === updatedMatch.id ? updatedMatch : m)));
        },
      );
    };

    const createConditions = async (newConditions: Conditions): Promise<void> => {
      await requestApi(
        (token) => postConditions(token, newConditions),
        (result) => {
          const createdConditions = result as Conditions;
          const newConditionsValue = [...conditions, createdConditions];
          setConditions(newConditionsValue.sort(sortConditions));
        },
      );
    };

    const updateConditions = async (newConditions: Conditions): Promise<void> => {
      await requestApi(
        (token) => patchConditions(token, newConditions),
        (result) => {
          const updatedConditions = result as Conditions;
          setConditions(conditions.map((c) => (c.id === updatedConditions.id ? updatedConditions : c)));
        },
      );
    };

    const publishConditions = async (conditionsId: string, publish: boolean): Promise<void> => {
      await requestApi(
        (token) => postPublish(token, { type: ResourceType.conditions, id: conditionsId, publish }),
        (result) => {
          const updatedConditions = result as Conditions;
          setConditions(conditions.map((c) => (c.id === updatedConditions.id ? updatedConditions : c)));
        },
      );
    };

    const sendMessage = async (messageBody: string, conversationId?: string): Promise<void> => {
      await requestApi(
        (token) => patchConversation(token, { conversationId, messageBody }),
        (result) => {
          const updatedConversation = result as Conversation;
          const newConv = apiConversationToConversation(updatedConversation, users, partners, auth?.user?.userid || "");
          const newConversations = conversations.map((c) => (c.id === updatedConversation.id ? newConv : c));
          setConversations(newConversations.sort(sortConversations));
        },
      );
    };

    const handledConversation = async (id: string): Promise<void> => {
      await requestApi(
        (token) => markConversationAsHandled(token, id),
        (result) => {
          const updatedConversation = result as Conversation;
          const newConv = apiConversationToConversation(updatedConversation, users, partners, auth?.user?.userid || "");
          const newConversations = conversations.map((c) => (c.id === updatedConversation.id ? newConv : c));
          setConversations(newConversations);
        },
      );
    };

    const exportPartners = async (): Promise<void> => {
      const token = await getToken();
      if (token) {
        await getExport(token);
      }
    };

    const getRegistrations = async (eventId: string): Promise<EventRegistration[]> => {
      const local = registrations[eventId];
      if (local) return local;
      const token = await getToken();
      if (token) {
        const result = await getEventRegistrations(token, eventId);
        if (typeof result !== "number") {
          setRegistrations({ ...registrations, [eventId]: result });
          return result;
        }
      }
      return [];
    };

    const checkDUser = async (email: string, partnerId: string): Promise<UserCreation | null> => {
      const defaultResult: UserCreation = {
        ...initialNewUser,
        username: email,
        partnersId: [partnerId],
        sendEmailReset: true,
      };
      const token = await getToken();
      if (token) {
        const result = await checkDigitickUser(token, email);
        if (typeof result === "number") {
          if (result === 409) {
            // Bad request, user already exists in Db
            return null;
          }
          enqueueSnackbar(t("partner:createUser.noDigitickUser"), { variant: "info" });
          return defaultResult;
        }
        // Email is in Digitick
        return { ...defaultResult, ...result, sendEmailReset: false };
      }
      return defaultResult;
    };

    const resetCache = async (): Promise<boolean> => {
      const token = await getToken();
      if (token) {
        const result = await resetApiCache(token);
        if (typeof result === "string" && result === "OK") {
          await getData(token, true);
          enqueueSnackbar(t("updateSuccess"), { variant: "success" });
          return true;
        }
        handleError(new Error("Wrong result from API"));
      }
      return false;
    };

    const resetPasswordUrl = async (userId: string): Promise<boolean | string> => {
      const token = await getToken();
      if (token) {
        const result = await resetPassword(token, userId);
        if (typeof result === "string") {
          return result;
        }
        handleError(new Error("Wrong result from API"));
      }
      return false;
    };

    const handlePageUpdated = (result: unknown): void => {
      const updatedPage = result as Page;
      let found = false;
      const newPages = pages.map((p) => {
        if (p.id === updatedPage.id) {
          found = true;
          return updatedPage;
        }
        return p;
      });
      if (!found) newPages.push(updatedPage);
      setPages(newPages);
    };

    const createPage = async (newPage: Partial<Page>): Promise<void> => {
      await requestApi((token) => postPage(token, newPage), handlePageUpdated);
    };

    const updatePage = async (newPage: Partial<Page>): Promise<void> => {
      await requestApi((token) => patchPage(token, newPage), handlePageUpdated);
    };

    const pageAddComponent = async (pageId: string, component: Component): Promise<void> => {
      await requestApi((token) => createComponent(token, pageId, component), handlePageUpdated);
    };

    const pageUpdateComponent = async (pageId: string, component: Component): Promise<void> => {
      await requestApi((token) => patchComponent(token, pageId, component), handlePageUpdated);
    };

    const pageRemoveComponent = async (pageId: string, componentId: string): Promise<void> => {
      await requestApi((token) => deleteComponent(token, pageId, componentId), handlePageUpdated);
    };

    const refreshPage = async (id: string): Promise<void> => {
      await requestApi(
        (token) => getPage(token, id),
        (result) => {
          const refreshedPage = result as Page;
          setPages(pages.map((p) => (p.id !== id ? p : refreshedPage)));
        },
        true,
      );
    };

    const downloadTicket = async (ticketId: number, fileName: string, partnerId: string): Promise<void> => {
      const token = await getToken();
      const result = await getTicketPdfBlob(token || "", ticketId, partnerId);
      if (typeof result === "number") {
        enqueueSnackbar(t("downloadError"), { variant: "error" });
      } else {
        downloadFile(result, fileName);
      }
    };

    const downloadTicketsArchive = async (
      matchId: string,
      fileName: string,
      partnerId: string,
      category?: string,
    ): Promise<void> => {
      const token = await getToken();
      const result = await getTicketsArchiveBlob(token || "", matchId, partnerId, category);
      if (typeof result === "number") {
        enqueueSnackbar(t("downloadError"), { variant: "error" });
      } else {
        downloadFile(result, fileName);
      }
    };

    return {
      users,
      partners,
      news,
      videos,
      conversations,
      teamMembers,
      rooms,
      roomBookings,
      shopProducts,
      notifications,
      tasks,
      events,
      pages,
      matches,
      damSelectors,
      conditions,
      partnersLists,
      registrations,
      sponsorings,
      getData,
      initApp,
      createUser,
      checkDUser,
      updateUser,
      updatePartner,
      createNews,
      updateNews,
      deleteNews,
      refreshNews,
      createTeamMember,
      updateTeamMember,
      deleteTeamMember,
      publishTeamMember,
      refreshTeamMember,
      createRoom,
      updateRoom,
      deleteRoom,
      publishRoom,
      refreshRoom,
      createShopProduct,
      updateShopProduct,
      deleteShopProduct,
      publishShopProduct,
      refreshShopProduct,
      createNotification,
      updateNotification,
      deleteNotification,
      refreshNotification,
      createTask,
      updateTask,
      deleteTask,
      refreshTask,
      createSponsoring,
      updateSponsoring,
      deleteSponsoring,
      refreshSponsorings,
      publishSponsoring,
      createRoomBooking,
      updateRoomBooking,
      deleteRoomBooking,
      createPage,
      updatePage,
      pageAddComponent,
      pageUpdateComponent,
      pageRemoveComponent,
      refreshPage,
      createEvent,
      updateEvent,
      deleteEvent,
      publishEvent,
      publishArticle,
      publishPartner,
      publishVideo,
      upload,
      createMatch,
      updateMatch,
      publishMatch,
      createConditions,
      updateConditions,
      publishConditions,
      sendMessage,
      handledConversation,
      exportPartners,
      getRegistrations,
      resetCache,
      resetPasswordUrl,
      downloadTicket,
      downloadTicketsArchive,
    };
  }, [
    users,
    partners,
    news,
    videos,
    conversations,
    teamMembers,
    rooms,
    roomBookings,
    shopProducts,
    notifications,
    tasks,
    events,
    pages,
    matches,
    damSelectors,
    conditions,
    partnersLists,
    registrations,
    sponsorings,
    enqueueSnackbar,
    t,
    latestFetchAll,
    getToken,
    initLocalAuth,
    auth?.user?.userid,
  ]);

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};
