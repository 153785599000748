import { Add, Delete, Edit, PeopleAlt, Visibility, VisibilityOff, Work } from "@mui/icons-material";
import { CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbTwoCards } from "../components/AbBaseCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbEventViewer from "../components/AbEventViewer";
import AbItemsList from "../components/AbItemsList";
import AbListItem from "../components/AbListItem";
import { DataContext } from "../data/contexts/DataContext";
import { compareWithoutAccent, formatDate } from "../data/Converters";
import { Event, EventRegistration, EventType } from "../data/types/ApiTypes";
import EventModal from "../modals/EventModal";
import EventRegistrationsModal from "../modals/EventRegistrationsModal";
import BusinessMeetingModal from "../modals/EventRegistrationsModal/BusinessMeetingModal";
import AbFilter from "../components/AbFilter";

const EventsPage: FC<{ eventType: EventType }> = ({ eventType }) => {
  const [currentItem, setCurrentItem] = useState<Event | null>(null);
  const [modifying, setModifying] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [showingRegistrations, setShowingRegistrations] = useState(false);
  const [showingBusinessMeeting, setShowingBusinessMeeting] = useState(false);
  const [getting, setGetting] = useState(false);
  const [registrations, setRegistrations] = useState<EventRegistration[]>([]);
  const { events, publishEvent, deleteEvent, getRegistrations } = useContext(DataContext);
  const [filter, setFilter] = useState("");
  const [published, setPublished] = useState<boolean | null>(null);
  const { t } = useTranslation(["global", "event"]);

  const filteredEvents: Event[] = useMemo(() => {
    const eventsTmp = events
      .filter((e) => compareWithoutAccent(e.title || "", filter))
      .filter((e) =>
        published === null
          ? e.published || e.published === undefined || !e.published
          : published
          ? e.published
          : e.published === undefined || !e.published,
      );
    return eventsTmp;
  }, [filter, events, published]);

  useEffect(() => {
    if (currentItem) {
      const newCurrent = filteredEvents.find((e) => e.id === currentItem.id);
      if (newCurrent) setCurrentItem(newCurrent);
      else setCurrentItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredEvents]);

  const finalEvents = filteredEvents.filter((e) => e.type === eventType);

  const publish = async (): Promise<void> => {
    if (!currentItem) return;
    setPublishing(true);
    await publishEvent(currentItem.id, !currentItem.published);
    setPublishing(false);
  };

  const selectItem = async (e: Event): Promise<void> => {
    setCurrentItem(e);
    setGetting(true);
    const result = await getRegistrations(e.id);
    setRegistrations(result);
    setGetting(false);
  };
  const tryDelete = async (): Promise<void> => {
    setDeleting(true);
    await deleteEvent(currentItem?.id || "");
    setDeleting(false);
  };

  return (
    <>
      <div className="row" style={{ display: "flex", justifyContent: "center", marginBottom: "1.5em" }}>
        <AbFilter
          textSearch
          textSearchString={filter}
          textSearchLabel={t("event:search")}
          setTextSearchString={setFilter}
          togglePublished
          published={published}
          setPublished={setPublished}
        />
      </div>
      {finalEvents.length > 0 ? (
        <AbTwoCards
          left={
            <AbItemsList
              items={finalEvents}
              renderRow={(e: Event) => (
                <AbListItem
                  data={{
                    pictureUrl: e.pictures && e.pictures.length > 0 ? e.pictures[0] : undefined,
                    title: e.title,
                    subtitle: e.eventDate ? formatDate(e.eventDate) : e.datePlaceholder,
                    published: e.published,
                  }}
                  onClick={() => selectItem(e)}
                  selected={currentItem?.id === e.id}
                  key={e.id}
                />
              )}
            />
          }
          right={
            currentItem ? (
              <div className="row">
                <AbEventViewer event={currentItem} />
                <div className="margin-left" style={{ width: 40 }}>
                  <Tooltip title={t("event:modify")} arrow>
                    <IconButton onClick={() => setModifying(true)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t(`event:${currentItem.published ? "un" : ""}publish`)} arrow className="margin-top">
                    <IconButton onClick={publish} disabled={publishing}>
                      {publishing ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : currentItem.published ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("event:seeRegistrations")} arrow className="margin-top">
                    <IconButton onClick={() => setShowingRegistrations(true)}>
                      <PeopleAlt />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("event:delete")} arrow className="margin-top">
                    <IconButton onClick={tryDelete} disabled={getting || registrations.length !== 0}>
                      {deleting ? <CircularProgress color="secondary" size={24} /> : <Delete />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("event:businessMeeting.tooltip")} arrow className="margin-top">
                    <IconButton onClick={() => setShowingBusinessMeeting(true)} disabled>
                      <Work />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
          rightWidth={550}
        />
      ) : (
        <AbCenteredContent infoText={t("noItemYet")} />
      )}
      <Fab
        color="primary"
        style={{ position: "absolute", bottom: 32, right: 48 }}
        onClick={() => {
          setCurrentItem(null);
          setModifying(true);
        }}>
        <Add />
      </Fab>
      <EventModal
        eventId={currentItem?.id || ""}
        open={modifying}
        onClose={() => setModifying(false)}
        eventType={eventType}
      />
      <EventRegistrationsModal
        open={showingRegistrations}
        onClose={() => setShowingRegistrations(false)}
        event={currentItem}
        getting={getting}
        registrations={registrations}
      />
      <BusinessMeetingModal
        open={showingBusinessMeeting}
        onClose={() => setShowingBusinessMeeting(false)}
        eventId={currentItem?.id || ""}
      />
    </>
  );
};

export default EventsPage;
