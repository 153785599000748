import { createRef, FC } from "react";
import { CssBaseline, IconButton, ThemeProvider } from "@mui/material";
import { Close } from "@mui/icons-material";
import frLocale from "date-fns/locale/fr";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SnackbarKey, SnackbarProvider } from "notistack";
import "./i18n";
import Theme from "./Theme";
import { AuthProvider } from "../data/contexts/AuthContext";
import { DataProvider } from "../data/contexts/DataContext";
import { DamProvider } from "../data/contexts/DamContext";

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};
const SnackBarCloser = (key: SnackbarKey): JSX.Element => (
  <IconButton onClick={onClickDismiss(key)} sx={{ color: "#fff" }}>
    <Close />
  </IconButton>
);

const Wrapper: FC<{ children: JSX.Element }> = ({ children }) => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          anchorOrigin={{ horizontal: "center", vertical: "top" }}
          preventDuplicate
          autoHideDuration={10000}
          action={SnackBarCloser}>
          <AuthProvider>
            <DataProvider>
              <DamProvider>{children}</DamProvider>
            </DataProvider>
          </AuthProvider>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default Wrapper;
