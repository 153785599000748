import { FC } from "react";
import { SocialSvgProps } from "./SocialSvgProps";

const FacebookSvg: FC<SocialSvgProps> = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.854 20.438" width={width} height={height}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.65427 23H8V12.7209H11.2101L11.8519 8.5686H8V5.42894C8 4.26446 9.08679 3.60631 10.1233 3.60631H12V0.162999L8.64187 0.0111738C5.43181 -0.191502 3.65427 2.39098 3.65427 5.47979V8.5686H0V12.7209H3.65427V23Z"
      fill="white"
    />
  </svg>
);

export default FacebookSvg;
