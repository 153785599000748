import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frGlobal from "./fr/global.json";
import frProfile from "./fr/profile.json";
import frPartner from "./fr/partner.json";
import frEvent from "./fr/event.json";
import frMatch from "./fr/match.json";
import frContent from "./fr/content.json";
import frNews from "./fr/news.json";
import frTeam from "./fr/team.json";
import frConditions from "./fr/conditions.json";
import frCountries from "./fr/countries.json";
import frConversations from "./fr/conversations.json";
import frRooms from "./fr/rooms.json";
import frShop from "./fr/shop.json";
import frNotification from "./fr/notification.json";
import frTask from "./fr/task.json";
import frSponsoring from "./fr/sponsoring.json";
import frTicket from "./fr/ticket.json";

i18n.use(initReactI18next).init({
  resources: {
    fr: {
      global: frGlobal,
      profile: frProfile,
      partner: frPartner,
      event: frEvent,
      match: frMatch,
      content: frContent,
      news: frNews,
      team: frTeam,
      conditions: frConditions,
      countries: frCountries,
      conversations: frConversations,
      rooms: frRooms,
      shop: frShop,
      notification: frNotification,
      task: frTask,
      sponsoring: frSponsoring,
      ticket: frTicket,
    },
  },
  lng: "fr",
  fallbackLng: "fr",
  interpolation: {
    escapeValue: false,
  },
});
