/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { MuiColorInput } from "mui-color-input";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbButton from "../../components/AbButton";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import AbImageUpload from "../../components/AbImageUpload";
import AbModificationAlert from "../../components/AbModificationAlert";
import { DataContext } from "../../data/contexts/DataContext";
import {
  CategoryNewsType,
  Component,
  ComponentType,
  Picture,
  ProductCategory,
  TeamMember,
} from "../../data/types/ApiTypes";
import { CompConfig, ComponentsConfig } from "../../data/types/ClientTypes";
import CardItemsField from "./CardItemsField";
import TextItemsField from "./TextItemsField";

interface PageComponentModalProps {
  open: boolean;
  onClose: () => void;
  pageId: string;
  id: string;
  componentToCreate?: ComponentType;
}

const primaryColor = "#7ebae6";
const secondaryColor = "#00186c";

const PageComponentModal: FC<PageComponentModalProps> = ({ open, onClose, pageId, id, componentToCreate }) => {
  const [confirm, setConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { pages, pageUpdateComponent, pageAddComponent, damSelectors, teamMembers } = useContext(DataContext);
  const page = pages.find((p) => p.id === pageId);
  const comp = id.length > 0 ? page?.content.find((c) => c.id === id) : undefined;
  const [component, setComponent] = useState<Component>(
    comp || { id: uuidv4(), type: componentToCreate || ComponentType.contentCard },
  );
  const { t } = useTranslation("content");
  const { enqueueSnackbar } = useSnackbar();
  const config = ComponentsConfig[comp?.type || componentToCreate || ComponentType.contentCard];
  useEffect(() => {
    const newPage = pages.find((p) => p.id === pageId);
    const newComp = id.length > 0 ? newPage?.content.find((c) => c.id === id) : undefined;
    const limit =
      config.limit === "mandatory"
        ? componentToCreate === ComponentType.newsSlider
          ? 6
          : componentToCreate === ComponentType.cardsGrid
          ? 2
          : 20
        : undefined;
    setComponent(
      newComp ? { ...newComp } : { id: uuidv4(), type: componentToCreate || ComponentType.contentCard, limit },
    );
  }, [pageId, id, pages, config.limit, componentToCreate]);
  if (!comp && !componentToCreate) return null;
  const onTryClose = (): void => {
    if (id.length === 0 || JSON.stringify(comp) === JSON.stringify(component)) onClose();
    else setConfirm(true);
  };
  const close = (): void => {
    setConfirm(false);
    setComponent(comp || { id: "", type: componentToCreate || ComponentType.contentCard });
    onClose();
  };
  const save = async (): Promise<void> => {
    const valueIsSet = (value?: string | number | boolean | Picture | unknown[]): boolean => {
      if (typeof value === "string") {
        return value.length > 0;
      }
      if (typeof value === "number") {
        return value > 0;
      }
      if (typeof value === "boolean") return true;
      return Array.isArray(value) ? value.length > 0 : typeof value?.url !== "undefined" && value.url.length > 0;
    };
    // Check if mandatory fields are ok
    let allOk = true;
    Object.keys(config).forEach((configKey) => {
      if (
        config[configKey as keyof CompConfig] === "mandatory" &&
        (typeof component[configKey as keyof Component] === "undefined" ||
          !valueIsSet(component[configKey as keyof Component]))
      )
        allOk = false;
    });
    if (!allOk) enqueueSnackbar(t("missingMandatoryFields"), { variant: "error" });
    else {
      setSaving(true);
      const { data, ...finalComponent } = component;
      if (id) await pageUpdateComponent(pageId, finalComponent);
      else await pageAddComponent(pageId, finalComponent);
      setSaving(false);
      onClose();
    }
  };
  const changeAssociatedTeamMemberValue = (value: TeamMember[]): void => {
    const associatedTeamMember: string[] = [];
    value.map((v) => associatedTeamMember.push(v.id));
    setComponent({ ...component, associatedTeamMember });
  };
  return (
    <>
      <Modal open={open} onClose={onTryClose} className="ab-modal-root" disableRestoreFocus>
        <Paper className="ab-modal-paper" sx={{ minWidth: "1050px", minHeight: "400px", maxHeight: "90%" }}>
          <Typography variant="h5" className="margin-bottom center">
            {t(id ? "pageComponentModal.modalTitle" : "pageComponentModal.modalTitleCreation", {
              replace: { type: t(`addSpeedDial.${comp?.type || componentToCreate}`) },
            })}
          </Typography>
          {typeof config.title !== "undefined" ? (
            <div>
              <Typography>{`${t("pageComponentModal.title")}${
                config.title === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextField
                value={component.title}
                onChange={(e) => setComponent({ ...component, title: e.target.value })}
                sx={{ padding: "8px 0" }}
                fullWidth
              />
            </div>
          ) : undefined}
          {typeof config.htmlContent !== "undefined" ? (
            <div>
              <Typography>{`${t("pageComponentModal.htmlContent")}${
                config.htmlContent === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <AbHtmlTextField
                setValue={(newValue) => setComponent({ ...component, htmlContent: newValue })}
                value={component.htmlContent || ""}
                strict={false}
              />
            </div>
          ) : undefined}
          {typeof config.backgroundColor !== "undefined" ? (
            <div>
              <Typography>{`${t("pageComponentModal.backgroundColor")}${
                config.htmlContent === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
                <div style={{ display: "flex", gap: "4px" }}>
                  <div
                    onClick={() => setComponent({ ...component, backgroundColor: primaryColor })}
                    onKeyDown={() => setComponent({ ...component, backgroundColor: primaryColor })}
                    style={{
                      width: "32px",
                      height: "24px",
                      borderRadius: "5px",
                      background: primaryColor,
                      cursor: "pointer",
                      boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11),0 1px 3px rgba(0, 0, 0, 0.08)",
                    }}
                  />
                  <div
                    onKeyDown={() => setComponent({ ...component, backgroundColor: secondaryColor })}
                    onClick={() => setComponent({ ...component, backgroundColor: secondaryColor })}
                    style={{
                      width: "32px",
                      height: "24px",
                      borderRadius: "5px",
                      background: secondaryColor,
                      cursor: "pointer",
                      boxShadow: "0 4px 6px rgba(50, 50, 93, 0.11),0 1px 3px rgba(0, 0, 0, 0.08)",
                    }}
                  />
                </div>
                <MuiColorInput
                  format="hex"
                  value={component.backgroundColor || ""}
                  onChange={(e) => setComponent({ ...component, backgroundColor: e })}
                />
              </div>
            </div>
          ) : undefined}
          {typeof config.picture !== "undefined" ? (
            <div className="row">
              <div style={{ marginRight: 32 }}>
                <Typography>{`${t("pageComponentModal.picture")}${
                  config.picture === "mandatory" ? t("pageComponentModal.mandatory") : ""
                }`}</Typography>
                <AbImageUpload
                  thumbnail={component.picture?.url || ""}
                  updateImageInState={(newValue) => setComponent({ ...component, picture: newValue })}
                />
              </div>
              {typeof config.picturePosition !== "undefined" || typeof config.cropPicture !== "undefined" ? (
                <div>
                  {typeof config.picturePosition !== "undefined" ? (
                    <>
                      <Typography>{`${t("pageComponentModal.picturePosition")}${
                        config.picturePosition === "mandatory" ? t("pageComponentModal.mandatory") : ""
                      }`}</Typography>
                      <RadioGroup
                        value={component.picturePosition}
                        onChange={(_, newValue) =>
                          setComponent({ ...component, picturePosition: newValue as "start" | "end" })
                        }>
                        <FormControlLabel
                          value="start"
                          label={t("pageComponentModal.start")}
                          control={<Radio checked={component.picturePosition === "start"} />}
                        />
                        <FormControlLabel
                          value="end"
                          label={t("pageComponentModal.end")}
                          control={
                            <Radio
                              checked={
                                typeof component.picturePosition === "undefined" || component.picturePosition === "end"
                              }
                            />
                          }
                        />
                      </RadioGroup>
                    </>
                  ) : undefined}
                  {typeof config.cropPicture !== "undefined" ? (
                    <>
                      <Typography>{`${t("pageComponentModal.cropPicture")}${
                        config.cropPicture === "mandatory" ? t("pageComponentModal.mandatory") : ""
                      }`}</Typography>
                      <RadioGroup
                        value={component.cropPicture}
                        onChange={(_, newValue) => setComponent({ ...component, cropPicture: newValue === "crop" })}>
                        <FormControlLabel
                          value="crop"
                          label={t("pageComponentModal.crop")}
                          control={
                            <Radio
                              checked={typeof component.cropPicture === "undefined" || component.cropPicture === true}
                            />
                          }
                        />
                        <FormControlLabel
                          value="noCrop"
                          label={t("pageComponentModal.noCrop")}
                          control={<Radio checked={component.cropPicture === false} />}
                        />
                      </RadioGroup>
                    </>
                  ) : undefined}
                </div>
              ) : undefined}
            </div>
          ) : undefined}
          {typeof config.actionTitle !== "undefined" ? (
            <div>
              <Typography>{`${t("pageComponentModal.actionTitle")}${
                config.actionTitle === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextField
                value={component.actionTitle}
                onChange={(e) => setComponent({ ...component, actionTitle: e.target.value })}
                sx={{ padding: "8px 0" }}
                fullWidth
              />
            </div>
          ) : undefined}
          {typeof config.actionUrl !== "undefined" ? (
            <div>
              <Typography>{`${t("pageComponentModal.actionUrl")}${
                config.actionUrl === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextField
                value={component.actionUrl}
                onChange={(e) => setComponent({ ...component, actionUrl: e.target.value })}
                sx={{ padding: "8px 0" }}
                fullWidth
              />
            </div>
          ) : undefined}
          {typeof config.videoUrl !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <Typography>{`${t("pageComponentModal.videoUrl")}${
                config.videoUrl === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextField
                value={component.videoUrl}
                onChange={(e) => setComponent({ ...component, videoUrl: e.target.value })}
                sx={{ padding: "8px 0" }}
                fullWidth
              />
            </div>
          ) : undefined}
          {typeof config.chrome !== "undefined" ? (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={Boolean(component.chrome)}
                  onChange={(_, newChecked) => setComponent({ ...component, chrome: newChecked })}
                  label={t("pageComponentModal.chrome")}
                />
              </FormGroup>
            </div>
          ) : undefined}
          {typeof config.tag !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <Typography>{`${t("pageComponentModal.tag")}${
                config.folder === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <Autocomplete
                sx={{ width: "25%" }}
                disablePortal
                id="tag"
                clearOnBlur
                options={damSelectors.tags}
                value={component.tag || ""}
                onChange={(_, value) => setComponent({ ...component, tag: value || undefined })}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
          ) : undefined}
          {typeof config.folder !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <Typography>{`${t("pageComponentModal.folder")}${
                config.folder === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextField
                value={component.folder}
                onChange={(e) => setComponent({ ...component, folder: e.target.value })}
                sx={{ width: "25%" }}
              />
            </div>
          ) : undefined}
          {typeof config.limit !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <Typography>{`${t(
                `pageComponentModal.${component.type === ComponentType.cardsGrid ? "nbColumns" : "limit"}`,
              )}${config.limit === "mandatory" ? t("pageComponentModal.mandatory") : ""}`}</Typography>
              <TextField
                value={component.limit || ""}
                type="number"
                onChange={(e) => setComponent({ ...component, limit: parseFloat(e.target.value) })}
                sx={{ width: "25%" }}
                inputProps={component.type === ComponentType.cardsGrid ? { max: 3, min: 2 } : undefined}
              />
            </div>
          ) : undefined}
          {typeof config.textItems !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <Typography>{`${t("pageComponentModal.textItems")}${
                config.textItems === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextItemsField
                value={component.textItems || []}
                onChange={(newValue) => setComponent({ ...component, textItems: newValue })}
              />
            </div>
          ) : undefined}
          {typeof config.cardItems !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <Typography>{`${t("pageComponentModal.cardItems")}${
                config.cardItems === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <CardItemsField
                value={component.cardItems || []}
                onChange={(newValue) => setComponent({ ...component, cardItems: newValue })}
              />
            </div>
          ) : undefined}
          {typeof config.fullWidth !== "undefined" ? (
            <div>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={Boolean(component.fullWidth)}
                  onChange={(_, newChecked) => setComponent({ ...component, fullWidth: newChecked })}
                  label={t("pageComponentModal.fullWidth")}
                />
              </FormGroup>
            </div>
          ) : undefined}
          {typeof config.shopProductCategory !== "undefined" ? (
            <div style={{ margin: "16px 0" }}>
              <InputLabel id="input-label-shopProduct">
                {`${t("shop:category.category")}${
                  config.folder === "mandatory" ? t("pageComponentModal.mandatory") : ""
                }`}
              </InputLabel>
              <Select
                sx={{ width: "500px" }}
                value={component.shopProductCategory || ""}
                labelId="input-label-shopProduct"
                id="select-shopProduct"
                onChange={(e) =>
                  setComponent({ ...component, shopProductCategory: e.target.value as ProductCategory })
                }>
                {Object.keys(ProductCategory).map((spc) =>
                  process.env.REACT_APP_FEATURE_FLIPPING_SHOP !== "true" ? undefined : (
                    <MenuItem key={spc} value={spc}>
                      {t(`shop:category.${spc}`)}
                    </MenuItem>
                  ),
                )}
              </Select>
            </div>
          ) : undefined}
          {typeof config.slider !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              {" "}
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox />}
                  checked={Boolean(component.slider)}
                  onChange={(_, newChecked) => setComponent({ ...component, slider: newChecked })}
                  label={t("pageComponentModal.slider")}
                />
              </FormGroup>
            </div>
          ) : undefined}
          {typeof config.newsCategory !== "undefined" ? (
            <div style={{ marginTop: "16px" }}>
              <FormControl sx={{ width: "20%" }}>
                <InputLabel id="input-label-category">{t("pageComponentModal.newsCategory")}</InputLabel>
                <Select
                  value={component.newsCategory || "all"}
                  labelId="input-label-category"
                  id="input-label-category"
                  label={t("pageComponentModal.newsCategory")}
                  onChange={(e) =>
                    setComponent({
                      ...component,
                      newsCategory: e.target.value === "all" ? undefined : (e.target.value as CategoryNewsType),
                    })
                  }>
                  <MenuItem key="all" value="all">
                    {t("news:category.all")}
                  </MenuItem>
                  {Object.keys(CategoryNewsType).map((c) => (
                    <MenuItem key={c} value={c}>
                      {t(`news:category.${c}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          ) : undefined}
          {typeof config.associatedTeamMember !== "undefined" ? (
            <div style={{ marginTop: "16px", marginBottom: "16px" }}>
              <Autocomplete
                multiple
                id="size-small-outlined"
                value={teamMembers.filter((tm: { id: string }) => component.associatedTeamMember?.includes(tm.id))}
                sx={{ width: "80%" }}
                options={teamMembers}
                getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                onChange={(_, value) => {
                  changeAssociatedTeamMemberValue(value);
                }}
                renderInput={(params) => <TextField {...params} label={t("pageComponentModal.associatedTeamMember")} />}
              />
            </div>
          ) : undefined}
          {typeof config.externalId !== "undefined" ? (
            <div style={{ margin: "16px 0" }}>
              <Typography>{`${t("pageComponentModal.externalId")}${
                config.externalId === "mandatory" ? t("pageComponentModal.mandatory") : ""
              }`}</Typography>
              <TextField
                value={component.externalId}
                type="number"
                onChange={(e) => setComponent({ ...component, externalId: e.target.value })}
                fullWidth
              />
            </div>
          ) : undefined}
          <AbButton
            label={t(id ? "modalSave" : "modalCreate")}
            onClick={save}
            isLoading={saving}
            disabled={JSON.stringify(comp) === JSON.stringify(component)}
          />
        </Paper>
      </Modal>
      <AbModificationAlert open={confirm} onClose={() => setConfirm(false)} onConfirm={close} />
    </>
  );
};

PageComponentModal.defaultProps = { componentToCreate: undefined };

export default PageComponentModal;
