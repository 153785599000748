import { Add } from "@mui/icons-material";
import { Card, Fab, TextField, Typography } from "@mui/material";
import { FC, ReactNode, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate } from "react-router-dom";
import Image from "src/components/Image";
import AddTemplatePageModal from "src/modals/AddTemplatePageModal";
import AbCenteredContent from "../../components/AbCenteredContent";
import { DataContext } from "../../data/contexts/DataContext";
import { Page } from "../../data/types/ApiTypes";
import { getPictureOrFallbackUrl } from "../../helpers/UrlHelpers";
import TemplatePage from "./TemplatePage";
import styles from "./templatesPage.module.scss";

const renderPageItem = (p: Page): ReactNode => (
  <Link to={`/pages/${p.id}`} key={p.id}>
    <Card className={styles.page__card}>
      <div className={styles.page__card__heroContainer}>
        <Image
          picture={getPictureOrFallbackUrl(p.content[0]?.picture)}
          alt="Image de la page"
          className={styles.page__hero}
          width={250}
          height={150}
        />
        {p.content[0]?.title ? (
          <Typography variant="h5" className={styles.page__card__hero__title}>
            {p.content[0].title}
          </Typography>
        ) : undefined}
      </div>
      <div className={styles.page__card__href}>
        <Typography variant="h5" style={{ wordBreak: "break-all", maxWidth: 250 }}>
          {p.href}
        </Typography>
      </div>
    </Card>
  </Link>
);

const TemplatesPage: FC = () => {
  const [refreshing, setRefreshing] = useState(false);
  const [addOpen, setAddOpen] = useState(false);
  const { pages, refreshPage } = useContext(DataContext);
  const [hrefFilter, setHrefFilter] = useState("");
  const { pageId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation("content");
  useEffect(() => {
    (async () => {
      if (pageId && pageId.length > 0) {
        setRefreshing(true);
        await refreshPage(pageId);
        setRefreshing(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId]);
  if (pageId && pageId.length > 0) {
    // Page details
    const page = pages.find((p) => p.id === pageId);
    return refreshing ? (
      <AbCenteredContent loading />
    ) : page ? (
      <TemplatePage page={page} />
    ) : (
      <div>Page introuvable</div>
    );
  }
  const onPageCreated = (pId: string): void => {
    setAddOpen(false);
    navigate(`/pages/${pId}`);
  };
  const finalPages = pages.filter((p) => p.href?.includes(hrefFilter.toLowerCase()));
  return (
    <>
      <div className="row center" style={{ alignItems: "center" }}>
        <Typography variant="h5" style={{ marginRight: 32 }}>
          {t("nbPages")} {pages.length}
          {hrefFilter.length > 0 ? t("nbFiltered", { replace: { nbFiltered: finalPages.length } }) : undefined}
        </Typography>
        <TextField label={t("filterHref")} onChange={(e) => setHrefFilter(e.target.value)} />
      </div>
      <div className={styles.page}>{finalPages.map(renderPageItem)}</div>
      <Fab color="primary" style={{ position: "absolute", bottom: 32, right: 48 }} onClick={() => setAddOpen(true)}>
        <Add />
      </Fab>
      <AddTemplatePageModal open={addOpen} onClose={() => setAddOpen(false)} onPageCreated={onPageCreated} />
    </>
  );
};

export default TemplatesPage;
