import { Add, Edit, Remove } from "@mui/icons-material";
import {
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventOption, EventQuestion, EventQuestionType } from "../../data/types/ApiTypes";
import QuestionModal from "./QuestionModal";

const ProgramOption: FC<{
  option: EventOption;
  onOptionChange: (newOption: EventOption) => void;
  onOptionRemove: (optionId: number) => void;
}> = ({ option, onOptionChange, onOptionRemove }) => {
  const [changingQuestion, setChangingQuestion] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(-1);
  const { t } = useTranslation("event");
  const changeOption =
    (key: string, isNumber?: boolean): ((e: ChangeEvent<HTMLInputElement>) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const value = isNumber
        ? e.target.value.length > 0
          ? Number.parseInt(e.target.value, 10)
          : undefined
        : e.target.value;
      const newOption = { ...option, [key]: value };
      onOptionChange(newOption);
    };
  const changeAttendees = (e: SelectChangeEvent<number>): void => {
    const { value } = e.target;
    const nVal = typeof value === "string" ? Number.parseInt(value, 10) : value;
    onOptionChange({ ...option, maxAttendees: nVal === -1 ? undefined : nVal });
  };
  const changeQuestion = (newQuestion: EventQuestion): void => {
    const newQuestions = option.questions
      ? currentQuestionIndex === -1
        ? [...option.questions, newQuestion]
        : [
            ...option.questions.slice(0, currentQuestionIndex),
            newQuestion,
            ...option.questions.slice(currentQuestionIndex + 1, option.questions.length - 1),
          ]
      : [newQuestion];
    onOptionChange({ ...option, questions: newQuestions });
    setCurrentQuestionIndex(-1);
    setChangingQuestion(false);
  };
  const removeQuestion = (questionIndex: number): void => {
    const newQuestions = option.questions
      ? [
          ...option.questions.slice(0, questionIndex),
          ...option.questions.slice(questionIndex + 1, option.questions.length - 1),
        ]
      : [];
    onOptionChange({ ...option, questions: newQuestions });
  };
  return (
    <Card variant="outlined" className="margin-bottom card-root" key={`option_${option.id}`}>
      <div className="row">
        <div style={{ flex: 1 }}>
          <div className="row">
            <TextField
              label={t("option.times")}
              value={option.times || ""}
              onChange={changeOption("times")}
              style={{ marginRight: 16 }}
            />
            <TextField
              label={t("option.title")}
              value={option.title || ""}
              onChange={changeOption("title")}
              fullWidth
            />
          </div>
          <div className="row margin-top">
            <Tooltip title={t("option.emptyIfNoLimit")} arrow>
              <TextField
                label={t("option.registrationsLimit")}
                value={typeof option.registrationsLimit === "number" ? option.registrationsLimit : ""}
                onChange={changeOption("registrationsLimit", true)}
                style={{ marginRight: 16 }}
                inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
              />
            </Tooltip>
            <FormControl fullWidth>
              <InputLabel id="sector-select-label">{t("option.maxAttendees")}</InputLabel>
              <Select
                labelId="sector-select-label"
                label={t("option.maxAttendees")}
                variant="outlined"
                value={option.maxAttendees || -1}
                onChange={changeAttendees}
                fullWidth>
                <MenuItem value={-1}>{t("option.noAttendees")}</MenuItem>
                {[1, 2, 3, 4, 5, 6].map((n) => (
                  <MenuItem key={n} value={n}>
                    {n}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <div style={{ marginLeft: 16, alignItems: "flex-start" }}>
          <Tooltip title={t("option.removeOption")} arrow>
            <IconButton onClick={() => onOptionRemove(option.id)}>
              <Remove color="primary" />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div className="row space-between margin-top">
        <Typography>{t("questions.questions")}</Typography>
        <Tooltip title={t("questions.addQuestion")} arrow>
          <IconButton
            onClick={() => {
              setCurrentQuestionIndex(-1);
              setChangingQuestion(true);
            }}>
            <Add color="primary" />
          </IconButton>
        </Tooltip>
        <QuestionModal
          open={changingQuestion}
          onClose={() => setChangingQuestion(false)}
          option={option}
          onSave={changeQuestion}
          question={
            currentQuestionIndex === -1 || !option.questions ? undefined : option.questions[currentQuestionIndex]
          }
        />
      </div>
      <div className="questions-root">
        {option.questions && option.questions.length > 0 ? (
          <ul>
            {option.questions.map((q, i) => (
              <li key={q.type}>
                <div className="row" style={{ alignItems: "center" }}>
                  <Typography>
                    <strong>{q.question}</strong> &gt;&gt;{" "}
                    {q.type === EventQuestionType.value
                      ? t("questions.value")
                      : t("questions.choice", { replace: { choices: q.choices?.join(" / ") || "" } })}
                  </Typography>
                  <Tooltip title={t("questions.modify")} arrow>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        setCurrentQuestionIndex(i);
                        setChangingQuestion(true);
                      }}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("questions.delete")} arrow>
                    <IconButton color="primary" onClick={() => removeQuestion(i)}>
                      <Remove />
                    </IconButton>
                  </Tooltip>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <Typography>{t("questions.noQuestion")}</Typography>
        )}
      </div>
    </Card>
  );
};

export default ProgramOption;
