import { FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentSeason, getAllSeasons, getSeasonLabel } from "src/data/Converters";
import AbFilter from "../components/AbFilter";
import { TabsContext } from "../data/contexts/TabsContext";
import { TeamMemberType } from "../data/types/ApiTypes";
import TeamMembersPage from "./TeamMembersPage";

const TeamMemberTypes = [TeamMemberType.staff, TeamMemberType.player];

const TeamPage: FC = () => {
  const { t } = useTranslation("team");
  const { teamTabsValue, setTeamTabsValue } = useContext(TabsContext);
  const [filter, setFilter] = useState("");
  const [publishedTeamMembers, setPublishedTeamMembers] = useState<boolean | null>(null);
  const allSeasons: number[] = getAllSeasons();
  const [seasonSelected, setSeasonSelected] = useState<number>(CurrentSeason);

  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setTeamTabsValue(newValue);
  };
  const changeSeason = (season: number): void => {
    setSeasonSelected(season);
  };

  return (
    <>
      <div className="row" style={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          sx={{ marginBottom: "1.5em" }}
          value={teamTabsValue}
          onChange={handleChange}
          selectionFollowsFocus
          centered
          indicatorColor="primary"
          textColor="inherit">
          <Tab label={t("tabs.players")} value={1} sx={{ color: "black", fontSize: "1.4em" }} />
          <Tab label={t("tabs.staff")} value={0} sx={{ color: "black", fontSize: "1.4em" }} />
        </Tabs>
        <FormControl sx={{ marginLeft: "1.2em", height: "56px" }}>
          <InputLabel id="seasonFilter">{t("seasonFilter")}</InputLabel>
          <Select
            value={seasonSelected}
            label={t("seasonFilter")}
            id="seasonFilter"
            onChange={(e) => changeSeason(e.target.value as number)}>
            <MenuItem key={0} value={0}>
              {t("noFilter")}
            </MenuItem>
            {allSeasons.map((s) => (
              <MenuItem key={s} value={s}>
                {getSeasonLabel(s)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <AbFilter
          textSearch
          textSearchString={filter}
          textSearchLabel={t("search")}
          setTextSearchString={setFilter}
          togglePublished
          published={publishedTeamMembers}
          setPublished={setPublishedTeamMembers}
        />
      </div>
      <TeamMembersPage
        season={seasonSelected}
        filter={filter}
        publishedTeamMembers={publishedTeamMembers}
        teamMemberType={TeamMemberTypes[teamTabsValue]}
      />
    </>
  );
};

export default TeamPage;
