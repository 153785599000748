import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  styled,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import AbBaseCard from "../components/AbBaseCard";
import AbPartner from "../components/AbPartner";
import { DataContext } from "../data/contexts/DataContext";
import { Partner, PartnersList } from "../data/types/ApiTypes";
import { dedupArray } from "../data/types/TechnicalTypes";
import AbPartnersList from "../components/AbPartnersList";
import PartnerModal from "../modals/PartnerModal";
import { getPartnersFromList } from "../data/types/ClientTypes";
import { CurrentSeason, getAllSeasons, getSeasonLabel } from "../data/Converters";

const cardWidth = 245;

const PartnerContainer = styled("div")({
  width: cardWidth,
  margin: 8,
});

const PartnersPage: FC = () => {
  const [filter, setFilter] = useState("");
  const [sector, setSector] = useState("");
  const [list, setList] = useState<PartnersList | undefined>(undefined);
  const [selected, setSelected] = useState<Partner | undefined>(undefined);
  const [currentPage, setCurrentPage] = useState(0);
  const allSeasons: number[] = getAllSeasons();
  const [seasonSelected, setSeasonSelected] = useState<number | undefined>(CurrentSeason);
  const { partners, partnersLists } = useContext(DataContext);
  const { t } = useTranslation("partner");
  const sectors = useMemo(
    () => dedupArray(partners.map((p) => p.sector || "").sort(Intl.Collator().compare)),
    [partners],
  );
  const filteredPartners: (Partner | null)[] = useMemo(() => {
    const partnersTmp = partners
      .filter((p) => typeof seasonSelected !== "number" || p.seasons?.includes(seasonSelected))
      .filter((p) => (sector.length ? p.sector === sector : true))
      .filter(
        (p) =>
          p.name?.toLowerCase().indexOf(filter.toLowerCase()) !== -1 ||
          (p.city && p.city?.toLowerCase().indexOf(filter.toLowerCase()) !== -1),
      );
    return list ? getPartnersFromList(partnersTmp, list) : partnersTmp;
  }, [filter, list, partners, sector, seasonSelected]);
  const nbListed = partners.filter((p) => p.listed).length;
  useEffect(() => {
    if (!selected) return;
    const newSelected = partners.find((p) => p.id === selected.id);
    setSelected(newSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partners]);
  useEffect(() => {
    setCurrentPage(0);
  }, [filter, sector]);
  return (
    <AbBaseCard>
      <>
        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
          className="margin-bottom">
          <Typography variant="h6" className="returns" style={{ marginRight: 16 }}>
            {t("partners", { replace: { nbPartners: partners.length } })}
            {"\n"}
            {t("listed", { replace: { nbListed } })}
            {partners.length === filteredPartners.length && typeof seasonSelected !== "number" ? undefined : (
              <>
                {"\n"}
                {t("filtered", {
                  replace: { nbFiltered: filteredPartners.length },
                })}
              </>
            )}
          </Typography>
          <FormControl style={{ width: 150, marginRight: 16 }}>
            <InputLabel id="seasonFilter">{t("seasonFilter")}</InputLabel>
            <Select
              value={seasonSelected}
              label={t("seasonFilter")}
              id="seasonFilter"
              onChange={(e) => setSeasonSelected(e.target.value === "" ? undefined : (e.target.value as number))}
              style={{ width: 150 }}>
              <MenuItem key="none" value="">
                <em>{t("noFilter")}</em>
              </MenuItem>
              {allSeasons.map((as) => (
                <MenuItem key={as} value={as}>
                  {getSeasonLabel(as)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: 180, marginRight: 16 }}>
            <InputLabel id="sector-select-label">{t("sectorFilter")}</InputLabel>
            <Select
              labelId="sector-select-label"
              label={t("sectorFilter")}
              variant="outlined"
              value={sector}
              onChange={(e) => setSector(e.target.value)}
              style={{ width: 180 }}>
              <MenuItem value="">
                <em>{t("allFilter")}</em>
              </MenuItem>
              {sectors.map((s) => (
                <MenuItem key={s} value={s}>
                  {s}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: 180, marginRight: 16 }}>
            <InputLabel id="sector-select-label">{t("listFilter")}</InputLabel>
            <Select
              labelId="sector-select-label"
              label={t("listFilter")}
              variant="outlined"
              value={list?.id}
              onChange={(e) => setList(partnersLists.find((pl) => pl.id === e.target.value))}
              style={{ width: 180 }}>
              <MenuItem value="">
                <em>{t("allFilter")}</em>
              </MenuItem>
              {partnersLists.map((pl) => (
                <MenuItem key={pl.id} value={pl.id}>
                  {pl.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: 250 }} variant="outlined">
            <InputLabel htmlFor="article-filter">{t("nameFilter")}</InputLabel>
            <OutlinedInput
              id="article-filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={() => setFilter("")} edge="end">
                    <Close />
                  </IconButton>
                </InputAdornment>
              }
              label={t("nameFilter")}
            />
          </FormControl>
        </div>
        <AbPartnersList
          renderRow={(p: Partner | null) =>
            p !== null ? (
              <PartnerContainer key={p.id}>
                <AbPartner partner={p} onClick={() => setSelected(p)} small />
              </PartnerContainer>
            ) : (
              <div key={`fake${Math.random()}`} style={{ width: cardWidth, margin: 8 }} />
            )
          }
          partners={filteredPartners}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <PartnerModal open={Boolean(selected)} onClose={() => setSelected(undefined)} partner={selected} />
      </>
    </AbBaseCard>
  );
};

export default PartnersPage;
