/* eslint-disable react/no-danger */
import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { formatLongDate } from "../data/Converters";
import { Article, ComponentType } from "../data/types/ApiTypes";
import { ImageType, NewsType } from "../data/types/ClientTypes";
import Image from "./Image";
import VideoEmbed from "./videoEmbed";

const AbNewsViewer: FC<{ news: Article; typeOfNews?: NewsType }> = ({ news, typeOfNews }) => {
  const body = news.body !== undefined ? news.body : "";
  return (
    <Card className="card-root article" style={{ width: "375px", padding: 0, position: "relative" }}>
      {typeOfNews === NewsType.videos ? (
        <VideoEmbed
          videoUrl={news.id || ""}
          picture={news.pictures && news.pictures[0]}
          title={news.title || ""}
          id={news.id}
          type={ComponentType.videoEmbed}
        />
      ) : (
        <>
          <div style={{ height: "400px", width: "100%" }}>
            <Image
              picture={news.pictures?.[0]}
              type={ImageType.Hero}
              forceMobile
              alt={news.pictures?.[0]?.title || ""}
              className="article-hero"
            />
          </div>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: "16px" }}>
            <Typography variant="h1">{news.title}</Typography>
            <Typography variant="caption">
              {formatLongDate(news.publicationDate ? news.publicationDate : "")}
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: body }} />
          </div>
        </>
      )}
    </Card>
  );
};

AbNewsViewer.defaultProps = { typeOfNews: undefined };

export default AbNewsViewer;
