import { CircularProgress, Menu, MenuItem, useTheme } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../data/contexts/AuthContext";
import { DataContext } from "../data/contexts/DataContext";

interface UserMenuProps {
  anchorEl: Element | null;
  signOut: () => void;
  close: () => void;
  nav: (to: string) => void;
}

const UserMenu: FC<UserMenuProps> = ({ anchorEl, signOut, close, nav }) => {
  const { t } = useTranslation(["global", "profile"]);
  const [resettingCache, setResettingCache] = useState(false);
  const theme = useTheme();
  const { auth } = useContext(AuthContext);
  const { resetCache, users } = useContext(DataContext);
  const currentUser = users.find((u) => u.id === auth?.user.userid);
  const isAllowedToResetTheCache = currentUser?.partnersId?.findIndex((p) => p === "douze") !== -1;
  const resetTheCache = async (): Promise<void> => {
    setResettingCache(true);
    await resetCache();
    setResettingCache(false);
    close();
  };
  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      id="auth-menu"
      keepMounted
      transformOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={Boolean(anchorEl)}
      onClose={close}>
      <MenuItem
        key="myProfile"
        onClick={() => {
          nav("/profile");
          close();
        }}>
        {t("titles./profile")}
      </MenuItem>
      {isAllowedToResetTheCache ? (
        <MenuItem
          key="resetCache"
          disabled={resettingCache}
          onClick={resetTheCache}
          style={{ backgroundColor: theme.palette.warning.light }}>
          {t("profile:resetCache")}
          {resettingCache ? (
            <div style={{ position: "absolute", left: 0, right: 0, display: "flex", justifyContent: "center" }}>
              <CircularProgress size={24} style={{ color: "#444" }} />
            </div>
          ) : undefined}
        </MenuItem>
      ) : undefined}
      <MenuItem
        key="logOut"
        onClick={() => {
          nav("/");
          close();
          signOut();
        }}>
        {t("logOut")}
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
