import { Place } from "@mui/icons-material";
import { FormControlLabel, Modal, Paper, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbButton from "../../components/AbButton";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import AbModificationAlert from "../../components/AbModificationAlert";
import AbTvChannelSelector from "../../components/AbTvChannelSelector";
import { DataContext } from "../../data/contexts/DataContext";
import { Match } from "../../data/types/ApiTypes";

interface MatchModalProps {
  open: boolean;
  onClose: () => void;
  matchId: string;
}

const MatchModal: FC<MatchModalProps> = ({ open, onClose, matchId }) => {
  const { matches, updateMatch, createMatch } = useContext(DataContext);
  const originalMatch = matches.find((m) => m.id === matchId);
  const [match, setMatch] = useState<Match | null>(originalMatch || null);
  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation("match");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (matchId.length === 0) {
      setMatch({ id: uuidv4(), title: "", published: false, date: undefined });
    } else {
      const newOriginalMatch = matches.find((e) => e.id === matchId);
      if (newOriginalMatch) setMatch({ ...newOriginalMatch });
      setModified(false);
    }
  }, [matchId, matches]);

  const sendEventToApi = async (): Promise<void> => {
    if (match) {
      if (!match.title || match.title.length === 0 || !match.date)
        enqueueSnackbar(t("nameAndDateMandatory"), { variant: "error" });
      else {
        setSending(true);
        if (matchId.length === 0) await createMatch(match);
        else await updateMatch(match);
        setSending(false);
        onClose();
      }
    }
  };

  const changeMatchValue = (key: string, value: unknown, place = false): void => {
    if (match) {
      const newItem = place ? { ...match } : { ...match, [key]: value };
      if (place) newItem.place = match.place ? { ...match.place, [key]: value } : { [key]: value };
      setMatch(newItem);
      if (JSON.stringify(originalMatch) !== JSON.stringify(newItem)) {
        setModified(true);
      }
    }
  };

  const changeMatch =
    (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeMatchValue(key, e.target.value);
    };

  const changeMatchPlace =
    (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeMatchValue(key, e.target.value, true);
    };

  const onTryClose = (): void => {
    if (!modified) onClose();
    else setModifiedAlertOpen(true);
  };

  const creation = matchId.length === 0;

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" style={{ width: creation ? undefined : 1050 }}>
        {creation ? (
          <>
            <Typography variant="h6" className="margin-bottom">
              {t("new")}
            </Typography>
            <div className="info-column">
              <TextField label={t("title")} value={match?.title || ""} onChange={changeMatch("title")} fullWidth />
              <DatePicker
                label={t("date")}
                value={match?.date ? match.date * 1000 : null}
                onChange={(value) => changeMatchValue("date", value ? Math.round(value / 1000) : undefined)}
                renderInput={(params) => (
                  <TextField {...params} inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa" }} fullWidth />
                )}
              />
            </div>
          </>
        ) : (
          <div style={{ overflowY: "auto", paddingBottom: 32 }}>
            <Typography variant="h6" className="margin-bottom">
              {t("general")}
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly",
                alignSelf: "stretch",
                flex: 1,
                minHeight: 270,
              }}
              className="margin-bottom">
              <div className="row space-between">
                <TextField label={t("title")} value={match?.title || ""} onChange={changeMatch("title")} fullWidth />
                <div style={{ width: 48 }} />
                <DatePicker
                  label={t("date")}
                  value={match?.date ? match.date * 1000 : null}
                  onChange={(value) => changeMatchValue("date", value ? Math.round(value / 1000) : undefined)}
                  renderInput={(params) => (
                    <TextField {...params} inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa" }} fullWidth />
                  )}
                />
              </div>
              <div className="row space-between">
                <TextField label={t("time")} value={match?.time || ""} onChange={changeMatch("time")} fullWidth />
                <div style={{ width: 48 }} />
                <TextField label={t("score")} value={match?.score || ""} onChange={changeMatch("score")} fullWidth />
              </div>
              <div className="row space-between">
                <TextField
                  label={t("ticketsLink")}
                  value={match?.ticketsLink || ""}
                  onChange={changeMatch("ticketsLink")}
                  fullWidth
                />
                <div style={{ width: 28 }} />
                <TextField
                  label={t("parkingTicketsCode")}
                  value={match?.parkingTicketsCode || ""}
                  onChange={changeMatch("parkingTicketsCode")}
                  sx={{ width: "30%" }}
                />
              </div>
              <div className="row space-between">
                <TextField
                  label={t("damFolderPath")}
                  value={match?.damFolderPath || ""}
                  onChange={changeMatch("damFolderPath")}
                  fullWidth
                />
                <div style={{ width: 28 }} />
                <AbTvChannelSelector
                  logoUrl={match?.tvChannelLogoUrl}
                  changeLogoUrl={(newLogoUrl: string | null) => changeMatchValue("tvChannelLogoUrl", newLogoUrl)}
                />
              </div>
            </div>
            <Typography variant="h6" className="margin-bottom">
              <Place />
              {t("modalPlace")}
            </Typography>
            <div className="row margin-bottom">
              <TextField
                label={t("place.name")}
                value={match?.place?.name || ""}
                onChange={changeMatchPlace("name")}
                style={{ flex: 1 }}
              />
              <TextField
                label={t("place.address")}
                value={match?.place?.address || ""}
                onChange={changeMatchPlace("address")}
                className="margin-left"
                style={{ flex: 1 }}
              />
              <TextField
                label={t("place.zipCode")}
                value={match?.place?.zipCode || ""}
                onChange={changeMatchPlace("zipCode")}
                className="margin-left"
                style={{ width: 100 }}
              />
              <TextField
                label={t("place.city")}
                value={match?.place?.city || ""}
                onChange={changeMatchPlace("city")}
                className="margin-left"
                style={{ width: 200 }}
              />
            </div>
            <Typography variant="h6" className="margin-bottom">
              {t("body")}
            </Typography>
            <AbHtmlTextField strict={false} value={match?.body || ""} setValue={(v) => changeMatchValue("body", v)} />
            <div className="row margin-top">
              <Typography variant="h6" style={{ marginRight: 32, alignSelf: "center" }}>
                {t("type")}
              </Typography>
              <RadioGroup row onChange={(_, value) => changeMatchValue("home", value === "home")}>
                <FormControlLabel value="home" control={<Radio />} label={t("home")} checked={match?.home === true} />
                <FormControlLabel
                  value="outside"
                  control={<Radio />}
                  label={t("outside")}
                  checked={match?.home === false}
                />
              </RadioGroup>
            </div>
          </div>
        )}
        <div className="margin-top">
          <AbButton
            label={t(creation ? "create" : "save")}
            onClick={sendEventToApi}
            isLoading={sending}
            disabled={!modified}
          />
        </div>
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalMatch) setMatch({ ...originalMatch });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

export default MatchModal;
