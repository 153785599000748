import { Modal, Paper, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "../../components/AbButton";
import { DataContext } from "../../data/contexts/DataContext";
import { BMRun, generateBM } from "./BMGenerator";

interface BusinessMeetingModalProps {
  open: boolean;
  onClose: () => void;
  eventId: string;
}

const BusinessMeetingModal: FC<BusinessMeetingModalProps> = ({ open, onClose, eventId }) => {
  const { registrations, partners } = useContext(DataContext);
  const [nbTables, setNbTables] = useState("15");
  const [nbPartnersPerTable, setNbPartnersPerTable] = useState("4");
  const [nbRuns, setNbRuns] = useState("6");
  const [order, setOrder] = useState<BMRun[]>([]);
  const { t } = useTranslation("event");
  const { enqueueSnackbar } = useSnackbar();

  const eventRegistrations = registrations[eventId] || [];
  const registeredPartnersId = eventRegistrations.map((er) => er.partnerId);
  const registeredPartners = partners.filter((p) => registeredPartnersId.indexOf(p.id) !== -1);

  const generate = (): void => {
    const nbTablesN = parseInt(nbTables, 10);
    const nbPartnersPerTableN = parseInt(nbPartnersPerTable, 10);
    const nbRunsN = parseInt(nbRuns, 10);
    if (Number.isNaN(nbTablesN) || Number.isNaN(nbPartnersPerTableN) || Number.isNaN(nbRunsN)) {
      enqueueSnackbar(t("businessMeeting.inputError"), { variant: "error" });
    } else {
      const result = generateBM(registeredPartners, nbTablesN, nbPartnersPerTableN, nbRunsN);
      setOrder(result);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" style={{ minWidth: 450, minHeight: 450, maxHeight: 900 }}>
        <>
          <Typography variant="h5" className="margin-bottom center">
            <>
              {t("businessMeeting.title") || ""} ({eventRegistrations.length} {t("businessMeeting.attendees")})
            </>
          </Typography>
          <div className="row margin-bottom">
            <TextField
              label={t("businessMeeting.nbTables")}
              value={nbTables}
              onChange={(e) => setNbTables(e.target.value)}
            />
            <TextField
              label={t("businessMeeting.nbPartnersPerTable")}
              value={nbPartnersPerTable}
              onChange={(e) => setNbPartnersPerTable(e.target.value)}
              className="margin-left"
            />
            <TextField
              label={t("businessMeeting.nbRuns")}
              className="margin-left"
              value={nbRuns}
              onChange={(e) => setNbRuns(e.target.value)}
            />
          </div>
          <AbButton label={t("businessMeeting.generate")} onClick={generate} />
          {order.length === 0 ? (
            <Typography className="margin-top center">{t("businessMeeting.proceed")}</Typography>
          ) : (
            <div style={{ overflowY: "auto" }}>
              {order.map((r) => (
                <div key={r.iteration}>
                  <Typography>
                    {t("businessMeeting.round")} {r.iteration} :
                  </Typography>
                  <div style={{ flexDirection: "row", flexWrap: "wrap" }}>
                    {r.tables.map((table) => (
                      <div
                        key={table.id}
                        style={{ padding: 8, border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: 4 }}>
                        <Typography>
                          {t("businessMeeting.table")} {table.id}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      </Paper>
    </Modal>
  );
};

export default BusinessMeetingModal;
