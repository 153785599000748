import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "../components/AbButton";
import { AuthContext } from "../data/contexts/AuthContext";
import { validateEmail } from "../data/types/TechnicalTypes";

import "./SignInPage.css";

const SignInPage: FC = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const { authenticate } = useContext(AuthContext);
  const { t } = useTranslation("global");

  const signIn = async (): Promise<void> => {
    setSigningIn(true);
    await authenticate(login, password);
    setSigningIn(false);
  };

  return (
    <div className="sign-in-page">
      <div className="sign-in-root">
        <img src="/logo.png" width="360px" alt="ab logo" />
        <div className="sign-in-form">
          <Typography variant="h5" className="sign-in-label margin-bottom">
            {t("authenticate.label")}
          </Typography>
          <TextField
            fullWidth
            label={t("authenticate.email")}
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            className="margin-bottom"
            error={login.length > 0 && !validateEmail(login)}
          />
          <FormControl variant="outlined" className="big-margin-bottom" fullWidth>
            <InputLabel htmlFor="outlined-adornment-password">{t("authenticate.password")}</InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label={t("authenticate.password")}
            />
          </FormControl>
          <AbButton
            label={t("authenticate.connect")}
            fullWidth
            isLoading={signingIn}
            onClick={signIn}
            disabled={login.length === 0 || password.length === 0 || !validateEmail(login)}
          />
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
