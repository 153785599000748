import { Download, ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  IconButton,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, MouseEvent, SyntheticEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "src/data/contexts/DataContext";
import { PartnerTicket } from "../../data/types/ApiTypes";
import { dedupArray } from "../../data/types/TechnicalTypes";
import ZipLoader from "./zipLoader";
import "./tickets.scss";

const getTicketSubtitle = (t: PartnerTicket): string => {
  if (!t.seat || (!t.seat.section && !t.seat.number && !t.seat.rank)) return "";
  let result = t.seat.section
    ? `${
        t.seat.section.lastIndexOf(" -") === t.seat.section.length - 2
          ? t.seat.section.substring(0, t.seat.section.length - 2) // Remove final " -" if present
          : t.seat.section
      } / `
    : "";
  if (t.seat.rank) result += `Rang : ${t.seat.rank} / `;
  if (t.seat.number) result += `Numéro : ${t.seat.number} / `;
  return result.substring(0, result.length - 3); // Delete the final " / "
};

export interface TicketRepresentation {
  id: number;
  title: string;
  subtitle: string;
  category: string;
  url: string;
  fileName: string;
  error?: string;
}

const sort2Subtitles = (st1: string, st2: string): number => {
  const prefix1 = st1.substring(0, st1.lastIndexOf(" "));
  const prefix2 = st2.substring(0, st2.lastIndexOf(" "));
  const lastNumber1 = st1.substring(st1.lastIndexOf(" ") + 1);
  const lastNumber2 = st2.substring(st2.lastIndexOf(" ") + 1);
  const ln1 = parseInt(lastNumber1, 10);
  const ln2 = parseInt(lastNumber2, 10);
  if (prefix1 !== prefix2) return st1 < st2 ? -1 : 1;
  if (!Number.isNaN(ln1) && !Number.isNaN(ln2) && ln1 < ln2) return -1;
  return 1;
};

const getTicketCatEnt = (t: PartnerTicket, catPrefix: string, entPrefix: string): string => {
  if (!t.seat || (!t.seat.entrance && !t.seat.category)) return "";
  let result = "";
  if (t.seat.category) result += `${catPrefix}${t.seat.category} / `;
  if (t.seat.entrance) result += `${entPrefix}${t.seat.entrance} / `;
  return `${result.substring(0, result.length - 3)}\n`; // Delete the final " / "
};

const getTicketSuffix = (t: PartnerTicket): string => {
  let result = t.seat?.section || "";
  if (t.seat?.rank) result += `_${t.seat.rank}`;
  if (t.seat?.number) result += `_${t.seat.number}`;
  return result;
};

const TicketsList: FC<{ tickets: TicketRepresentation[]; partnerId: string; disabled: boolean }> = ({
  tickets,
  partnerId,
  disabled,
}) => {
  const { t } = useTranslation("ticket");
  const [downloading, setDownloading] = useState<Record<number, boolean>>({});
  const { downloadTicket } = useContext(DataContext);
  const downloadThisTicket = async (ticket: TicketRepresentation): Promise<void> => {
    setDownloading({ ...downloading, [ticket.id]: true });
    await downloadTicket(ticket.id, ticket.fileName, partnerId);
    setDownloading({ ...downloading, [ticket.id]: false });
  };

  return (
    <Card variant="outlined" style={{ width: "100%" }}>
      <Table>
        {tickets.map((ticket) => (
          <TableRow key={ticket.id}>
            <TableCell>
              {ticket.subtitle.length > 0 ? <div className="ticket__subtitle">{ticket.subtitle}</div> : undefined}
            </TableCell>
            <TableCell align="right">
              <Tooltip title={t("see")} arrow>
                <IconButton onClick={() => downloadThisTicket(ticket)} disabled={disabled || downloading[ticket.id]}>
                  <Download />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </Card>
  );
};

const CategoriesList: FC<{
  tickets?: PartnerTicket[];
  matchName: string;
  matchId: string;
  partnerId: string;
  isMatchVisible: boolean;
}> = ({ tickets, matchName, matchId, partnerId, isMatchVisible }) => {
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});
  const [expanded, setExpanded] = useState<string | false>(false);
  const { downloadTicketsArchive } = useContext(DataContext);
  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  const { t } = useTranslation("ticket");
  const finalTickets: TicketRepresentation[] =
    !tickets || tickets.length === 0
      ? []
      : tickets.map((ticket) => ({
          id: ticket.ticketId || -1,
          title: getTicketCatEnt(ticket, t("category"), t("entrance")),
          subtitle: getTicketSubtitle(ticket),
          category: ticket.seat?.category || "",
          url: ticket.url,
          fileName: `${matchName.replace(/ /g, "")}_${getTicketSuffix(ticket)}.pdf`,
          error: ticket.error,
        }));
  finalTickets.sort((t1, t2) => {
    if (t1.title < t2.title) return -1;
    if (t1.title > t2.title) return 1;
    return sort2Subtitles(t1.subtitle, t2.subtitle);
  });
  const categories = dedupArray(finalTickets.map((tk) => tk.category));

  const dlTickets = async (e: MouseEvent<HTMLButtonElement>, category: string): Promise<boolean> => {
    e.stopPropagation();
    setDownloading({ ...downloading, [category]: true });
    await downloadTicketsArchive(
      matchId,
      `Billets_${(matchName || "").replace(/ /g, "")}_${category}.zip`,
      partnerId,
      category,
    );
    setDownloading({ ...downloading, [category]: false });
    return false;
  };

  return finalTickets.length === 0 ? (
    <Typography>{t("noTicket")}</Typography>
  ) : categories.length === 1 ? (
    <TicketsList
      tickets={finalTickets.filter((ft) => ft.category === categories[0])}
      partnerId={partnerId}
      disabled={!isMatchVisible}
    />
  ) : (
    <>
      {categories.map((c) => (
        <Accordion sx={{ width: "700px" }} expanded={expanded === c} onChange={handleChange(c || "")} key={c}>
          <AccordionSummary sx={{ display: "flex", justifyContent: "space-between" }} expandIcon={<ExpandMore />}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginRight: "16px",
              }}>
              <h6 style={{ fontSize: "20px", margin: 0 }}>{c}</h6>
              <div className="categories__container__tickets">
                {t("ticket", {
                  count: finalTickets.filter((ticket) => ticket.category === c).length,
                })}
                <Tooltip
                  sx={{
                    marginLeft: window.innerWidth > 900 ? "16px" : "0",
                  }}
                  title={t("downloadCategoryTickets")}
                  arrow>
                  <IconButton onClick={(e) => dlTickets(e, c)} disabled={!isMatchVisible || downloading[c]}>
                    <Download />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <TicketsList
              tickets={finalTickets.filter((ft) => ft.category === c)}
              partnerId={partnerId}
              disabled={!isMatchVisible}
            />
          </AccordionDetails>
          <ZipLoader
            open={downloading[c] === true}
            numberOfTickets={finalTickets.filter((ticket) => ticket.category === c).length}
          />
        </Accordion>
      ))}
    </>
  );
};

CategoriesList.defaultProps = { tickets: undefined };

export default CategoriesList;
