import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FC } from "react";
import { Component, ShopProduct } from "../../data/types/ApiTypes";
import ShopProductCard from "./ShopProductCard";
import styles from "./shopProductSlider.module.scss";

const ShopProductSlider: FC<Component> = ({ data, title }) => {
  const items = data !== undefined ? (data as ShopProduct[]) : [];
  return (
    <div className={styles.shopProductSlider}>
      <h2 className={styles.shopProductSlider__title}>{title}</h2>
      <div className={styles.shopProductSlider__container}>
        <Splide
          className={styles.shopProductSlider__ShopProductslider}
          options={{
            width: "100%",
            type: "slide",
            perMove: 1,
            autoplay: false,
            drag: "free",
            gap: "8px",
            pagination: false,
            snap: true,
            rewind: true,
            perPage: 3,
            fixedWidth: "316px",
            breakpoints: {
              990: { perPage: 2 },
              768: { perPage: 1, padding: { right: "20%", left: "8%" }, width: "100vw" },
            },
          }}>
          {items.map((shopProduct) => (
            <SplideSlide key={shopProduct.id} className={styles.shopProductSlider__slide}>
              <ShopProductCard shopProduct={shopProduct} />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};

export default ShopProductSlider;
