import { Checkbox, FormControlLabel, Modal, Paper, TextField, Typography } from "@mui/material";
import { ChangeEvent, FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "../../components/AbButton";
import AbModificationAlert from "../../components/AbModificationAlert";
import { DataContext } from "../../data/contexts/DataContext";
import { PageSeo } from "../../data/types/ApiTypes";

interface PageSettingsModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const PageSettingsModal: FC<PageSettingsModalProps> = ({ open, onClose, id }) => {
  const [confirm, setConfirm] = useState(false);
  const [saving, setSaving] = useState(false);
  const { pages, updatePage } = useContext(DataContext);
  const page = pages.find((p) => p.id === id);
  const [seo, setSeo] = useState<PageSeo>(page?.seo || { description: "", title: "", notIndexed: false });
  const [maxWidth, setMaxWidth] = useState<number | undefined>(page?.maxWidth || undefined);
  const { t } = useTranslation("content");
  if (!page) return null;
  const onTryClose = (): void => {
    if (JSON.stringify(seo) === JSON.stringify(page.seo)) onClose();
    else setConfirm(true);
  };
  const close = (): void => {
    setConfirm(false);
    onClose();
    setSeo(page?.seo || { description: "", title: "" });
  };
  const save = async (): Promise<void> => {
    setSaving(true);
    await updatePage({ id, seo, maxWidth });
    setSaving(false);
    onClose();
  };
  const changeMaxWidth = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length === 0) setMaxWidth(undefined);
    const asNumber = parseInt(e.target.value, 10);
    if (Number.isNaN(asNumber)) return;
    setMaxWidth(asNumber);
  };
  return (
    <>
      <Modal open={open} onClose={onTryClose} className="ab-modal-root">
        <Paper className="ab-modal-paper" sx={{ minWidth: "800px", minHeight: "400px" }}>
          <Typography variant="h5" className="big-margin-bottom">
            {t("pageSettingsModal.title")}
          </Typography>
          <TextField
            label={t("pageSettingsModal.seoTitle")}
            className="big-margin-bottom"
            value={seo.title}
            onChange={(e) => setSeo({ ...seo, title: e.target.value })}
            error={!seo.title || seo.title.length === 0}
          />
          <TextField
            className="big-margin-bottom"
            label={t("pageSettingsModal.seoDescription")}
            value={seo.description}
            onChange={(e) => setSeo({ ...seo, description: e.target.value })}
            multiline
            rows={4}
            error={!seo.description || seo.description.length === 0}
          />
          <TextField
            label={t("pageSettingsModal.maxWidth")}
            className="margin-bottom"
            value={`${maxWidth || ""}`}
            onChange={changeMaxWidth}
          />
          <FormControlLabel
            className="margin-bottom"
            control={
              <Checkbox
                defaultChecked={seo.notIndexed}
                onChange={(e) => setSeo({ ...seo, notIndexed: e.target.checked })}
              />
            }
            label={t("notIndex")}
          />
          <AbButton
            label={t("modalSave")}
            onClick={save}
            isLoading={saving}
            disabled={JSON.stringify(seo) === JSON.stringify(page.seo) && maxWidth === page.maxWidth}
          />
        </Paper>
      </Modal>
      <AbModificationAlert open={confirm} onClose={() => setConfirm(false)} onConfirm={close} />
    </>
  );
};

export default PageSettingsModal;
