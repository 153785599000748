import { FC } from "react";
import { SocialSvgProps } from "./SocialSvgProps";

const InstagramSvg: FC<SocialSvgProps> = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21" width={width} height={height}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.3937 0H14.6063C17.5657 0 20 2.56214 20 5.67702V15.323C20 18.4379 17.5657 21 14.6063 21H5.3937C2.43427 21 0 18.4379 0 15.323V5.67702C0 2.56214 2.43427 0 5.3937 0ZM15.3697 3.66763C15.9903 3.66763 16.5154 4.22038 16.5154 4.87356C16.5154 5.52674 15.9903 6.07949 15.3697 6.07949C14.7017 6.07949 14.2239 5.52674 14.2239 4.87356C14.2239 4.22038 14.701 3.66763 15.3697 3.66763ZM9.97597 4.77313H10.024C12.9835 4.77313 15.4658 7.38585 15.4658 10.5007C15.4658 13.6655 12.9835 16.2283 10.024 16.2283H9.97597C7.01654 16.2283 4.58227 13.6662 4.58227 10.5007C4.58227 7.38585 7.01654 4.77313 9.97597 4.77313ZM9.97597 6.73267H10.024C11.9812 6.73267 13.604 8.44076 13.604 10.5007C13.604 12.6106 11.9812 14.3186 10.024 14.3186H9.97597C8.01881 14.3186 6.39596 12.6106 6.39596 10.5007C6.39596 8.44076 8.01881 6.73267 9.97597 6.73267ZM5.44176 1.80926H14.5582C16.6108 1.80926 18.281 3.56793 18.281 5.72761V15.2731C18.281 17.4335 16.6101 19.1915 14.5582 19.1915H5.44176C3.38917 19.1915 1.71896 17.4328 1.71896 15.2731V5.72761C1.71896 3.5672 3.38987 1.80926 5.44176 1.80926Z"
      fill="white"
    />
  </svg>
);

export default InstagramSvg;
