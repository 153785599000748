import React from "react";
import ReactDOM from "react-dom/client";
import "react-quill/dist/quill.snow.css";
import { L10n, registerLicense, setCulture, loadCldr } from "@syncfusion/ej2-base";
import { ScheduleComponent } from "@syncfusion/ej2-react-schedule";
import * as numberingSystems from "cldr-data/supplemental/numberingSystems.json";
import * as gregorian from "cldr-data/main/fr/ca-gregorian.json";
import * as numbers from "cldr-data/main/fr/numbers.json";
import * as timeZoneNames from "cldr-data/main/fr/timeZoneNames.json";
import "./index.scss";
import App from "./global/App";
import Wrapper from "./global/Wrapper";
import { getEnvVariable } from "./helpers/EnvHelpers";
import fr from "./global/i18n/shedulerOverrides";

registerLicense(getEnvVariable(process.env.REACT_APP_SYNCFUSION_LICENSE));
loadCldr(numberingSystems, gregorian, numbers, timeZoneNames);
L10n.load({ fr });
setCulture("fr");
// @ts-ignore
ScheduleComponent.prototype.animateLayout = () => {
  return null;
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <Wrapper>
      <App />
    </Wrapper>
  </React.StrictMode>,
);
