import { Add, ContentCopy } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "../../components/AbButton";
import AbCenteredContent from "../../components/AbCenteredContent";
import AbUsersList from "../../components/AbUsersList";
import { DataContext } from "../../data/contexts/DataContext";
import { User, UserCreated, UserCreation } from "../../data/types/ApiTypes";
import { validateEmail } from "../../data/types/TechnicalTypes";

const PartnerUsers: FC<{ partnerUsers: User[]; partnerId?: string }> = ({ partnerUsers, partnerId }) => {
  const [newUserOpen, setNewUserOpen] = useState(false);
  const [newUserConfirmOpen, setNewUserConfirmOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [email, setEmail] = useState("");
  const [resetPasswordUrl, setResetPasswordUrl] = useState("");
  const [newUser, setNewUser] = useState<UserCreation | null>(null);
  const [passwordSetLink, setPasswordSetLink] = useState("");
  const { createUser, checkDUser } = useContext(DataContext);
  const { t } = useTranslation(["partner", "global"]);
  const { enqueueSnackbar } = useSnackbar();

  const launchUserCreation = (): void => {
    setNewUser(null);
    setEmail("");
    setPasswordSetLink("");
    setResetPasswordUrl("");
    setNewUserOpen(true);
  };

  const isTicketsUser = typeof newUser?.digitickId !== "undefined" || typeof newUser?.ticketsId !== "undefined";

  const createTheNewUser = async (): Promise<void> => {
    if (!newUser) return;
    setNewUserConfirmOpen(false);
    setCreating(true);
    await createUser(newUser, (createdUser: UserCreated) => {
      if (!isTicketsUser && !newUser.sendEmailReset) {
        setPasswordSetLink(createdUser.passwordSetLink || "");
      } else {
        setNewUserOpen(false);
      }
    });
    setCreating(false);
  };

  const createClicked = (): void => {
    if (newUser?.sendEmailReset) setNewUserConfirmOpen(true);
    else createTheNewUser();
  };

  const getDigitickUser = async (): Promise<void> => {
    if (!validateEmail(email)) {
      enqueueSnackbar(t("createUser.invalidEmail"), { variant: "error" });
    } else {
      setCreating(true);
      const dUser = await checkDUser(email, partnerId || "");
      if (dUser) setNewUser(dUser);
      else enqueueSnackbar(t("createUser.userAlreadyExists"), { variant: "error" });
      setCreating(false);
    }
  };

  const passwordUrl = resetPasswordUrl || passwordSetLink || "";

  return (
    <>
      <div className="row space-between margin-bottom">
        <Typography variant="h6">{t("user.users")}</Typography>
        <Tooltip title={t("addUser")} arrow>
          <IconButton color="primary" onClick={launchUserCreation}>
            <Add />
          </IconButton>
        </Tooltip>
      </div>
      {partnerUsers.length > 0 ? (
        <AbUsersList users={partnerUsers} setResetPasswordUrl={setResetPasswordUrl} />
      ) : (
        <AbCenteredContent infoText={t("user.noUser")} normalText />
      )}
      <Modal
        open={Boolean(newUserOpen || resetPasswordUrl)}
        onClose={() => {
          setNewUserOpen(false);
          setResetPasswordUrl("");
        }}
        className="ab-modal-root">
        <Paper className="ab-modal-paper">
          <Typography variant="h6" className="center margin-bottom returns">
            {t(
              resetPasswordUrl
                ? "resetPassword.title"
                : passwordSetLink
                ? "createUser.createdTitle"
                : "createUser.title",
            )}
          </Typography>
          {passwordUrl ? (
            <>
              <Typography className="big-margin-top center returns">
                {t(resetPasswordUrl ? "resetPassword.description" : "createUser.passwordSetLink")}
              </Typography>
              <TextField className="big-margin-bottom margin-top" value={passwordUrl} disabled />
              <AbButton
                label={t("createUser.copyPaswordSetLink")}
                icon={<ContentCopy />}
                onClick={() =>
                  navigator.clipboard.writeText(passwordUrl).then(
                    () => {
                      enqueueSnackbar(t("createUser.copySuccess"), { variant: "success" });
                    },
                    () => {
                      enqueueSnackbar(t("createUser.copyFailure"), { variant: "error" });
                    },
                  )
                }
              />
            </>
          ) : (
            <>
              <Typography variant="subtitle2" className="center big-margin-bottom returns">
                {t("createUser.subtitle")}
              </Typography>
              <TextField
                label={t("createUser.username")}
                className="big-margin-bottom"
                value={newUser ? newUser.username : email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={Boolean(newUser)}
              />
              {newUser ? (
                <>
                  <TextField
                    label={t("createUser.firstName")}
                    className="big-margin-bottom"
                    value={newUser.firstName}
                    onChange={(e) => setNewUser({ ...newUser, firstName: e.target.value })}
                  />
                  <TextField
                    label={t("createUser.lastName")}
                    className="big-margin-bottom"
                    value={newUser.lastName}
                    onChange={(e) => setNewUser({ ...newUser, lastName: e.target.value })}
                  />
                  <Typography className="returns center">
                    {t(isTicketsUser ? "createUser.passwordIsDigitick" : "createUser.sendEmailReset")}
                  </Typography>
                  {isTicketsUser ? undefined : (
                    <RadioGroup value={newUser.sendEmailReset ? "yes" : "no"}>
                      <div className="row space-evenly">
                        <FormControlLabel
                          value="yes"
                          control={<Radio />}
                          label={t("global:yesNo.yes")}
                          onChange={(_, checked) =>
                            checked ? setNewUser({ ...newUser, sendEmailReset: true }) : undefined
                          }
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label={t("global:yesNo.no")}
                          onChange={(_, checked) =>
                            checked ? setNewUser({ ...newUser, sendEmailReset: false }) : undefined
                          }
                        />
                      </div>
                    </RadioGroup>
                  )}
                  <AbButton
                    label={t("createUser.create")}
                    onClick={createClicked}
                    isLoading={creating}
                    className="margin-top"
                    disabled={
                      newUser.firstName.length === 0 || newUser.lastName.length === 0 || newUser.username.length === 0
                    }
                  />
                </>
              ) : (
                <AbButton
                  label={t("createUser.checkDigitickUser")}
                  onClick={getDigitickUser}
                  isLoading={creating}
                  disabled={email.length === 0}
                />
              )}
            </>
          )}
        </Paper>
      </Modal>
      <Dialog open={newUserConfirmOpen} onClose={() => setNewUserConfirmOpen(false)}>
        <DialogTitle>{t("createUser.confirmTitle")}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t("createUser.confirmDescription")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <AbButton label={t("global:yesNo.no")} color="inherit" onClick={() => setNewUserConfirmOpen(false)} />
          <AbButton label={t("global:yesNo.yes")} color="primary" onClick={createTheNewUser} />
        </DialogActions>
      </Dialog>
    </>
  );
};

PartnerUsers.defaultProps = { partnerId: undefined };

export default PartnerUsers;
