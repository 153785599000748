import { FormControl, InputLabel, MenuItem, Select, Tab, Tabs } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryNewsType, PublicationChannelName } from "src/data/types/ApiTypes";
import AbFilter from "../components/AbFilter";
import { TabsContext } from "../data/contexts/TabsContext";
import { NewsType } from "../data/types/ClientTypes";
import NewsPage from "./NewsPage";

const NewsTypes = [NewsType.news, NewsType.videos];

const ActusPage: FC = () => {
  const { t } = useTranslation("news");
  const { newsTabsValue, setNewsTabsValue } = useContext(TabsContext);
  const [filter, setFilter] = useState("");
  const [publishedNews, setPublishedNews] = useState<boolean | null>(null);
  const [categoryFilter, setCategoryFilter] = useState("all");
  const [channelFilter, setChannelFilter] = useState("all");
  const categories = Object.keys(CategoryNewsType).concat("all").reverse();
  const channels = Object.keys(PublicationChannelName).concat("all").reverse();

  const handleChange = (_: React.SyntheticEvent, newValue: number): void => {
    setNewsTabsValue(newValue);
  };

  return (
    <>
      <div className="row" style={{ display: "flex", justifyContent: "center" }}>
        <Tabs
          sx={{ marginBottom: "1.5em" }}
          value={newsTabsValue}
          onChange={handleChange}
          selectionFollowsFocus
          centered
          indicatorColor="primary"
          textColor="inherit">
          <Tab label={t("tabs.news")} value={0} sx={{ color: "black", fontSize: "1.4em" }} />
          <Tab label={t("tabs.videos")} value={1} sx={{ color: "black", fontSize: "1.4em" }} />
        </Tabs>
        <AbFilter
          textSearch
          textSearchString={filter}
          textSearchLabel={t("search")}
          setTextSearchString={setFilter}
          togglePublished
          published={publishedNews}
          setPublished={setPublishedNews}
          extraFields={
            <>
              <FormControl sx={{ minWidth: "25%", marginLeft: ".5em" }}>
                <InputLabel id="input-label-category">{t("infos.category")}</InputLabel>
                <Select
                  value={categoryFilter}
                  labelId="input-label-category"
                  id="select-category"
                  label={t("infos.category")}
                  onChange={(e) => setCategoryFilter(e.target.value)}>
                  {categories.map((c) => (
                    <MenuItem key={c} value={c}>
                      {t(`category.${c}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "25%", marginLeft: ".5em" }}>
                <InputLabel id="input-label-publicationChannels">
                  {t("publicationChannels.publicationChannels")}
                </InputLabel>
                <Select
                  value={channelFilter}
                  labelId="input-label-publicationChannels"
                  id="select-publicationChannels"
                  label={t("publicationChannels.publicationChannels")}
                  onChange={(e) => setChannelFilter(e.target.value)}>
                  {channels.map((c) => (
                    <MenuItem key={c} value={c}>
                      {t(`publicationChannels.${c}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          }
        />
      </div>
      <NewsPage
        newsType={NewsTypes[newsTabsValue]}
        filter={filter}
        published={publishedNews}
        categoryFilter={categoryFilter}
        channelFilter={channelFilter}
      />
    </>
  );
};

export default ActusPage;
