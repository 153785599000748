import { Add, Place } from "@mui/icons-material";
import { IconButton, Modal, Paper, TextField, Tooltip, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbButton from "../../components/AbButton";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import AbImageUpload from "../../components/AbImageUpload";
import AbModificationAlert from "../../components/AbModificationAlert";
import { DataContext } from "../../data/contexts/DataContext";
import { Event, EventOption, EventType } from "../../data/types/ApiTypes";
import ProgramOption from "./ProgramOption";

interface EventModalProps {
  open: boolean;
  onClose: () => void;
  eventId: string;
  eventType: EventType;
}

const EventModal: FC<EventModalProps> = ({ open, onClose, eventId, eventType }) => {
  const { events, updateEvent, createEvent } = useContext(DataContext);
  const originalEvent = events.find((e) => e.id === eventId);
  const [event, setEvent] = useState<Event | null>(originalEvent || null);
  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation("event");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (eventId.length === 0) {
      setEvent({ id: uuidv4(), type: eventType, title: "", published: false, eventDate: undefined });
    } else {
      const newOriginalEvent = events.find((e) => e.id === eventId);
      if (newOriginalEvent) setEvent({ ...newOriginalEvent });
      setModified(false);
    }
  }, [eventId, eventType, events]);

  const sendEventToApi = async (): Promise<void> => {
    if (event) {
      if (!event.title || event.title.length === 0 || (!event.eventDate && !event.datePlaceholder))
        enqueueSnackbar(t("nameAndDateMandatory"), { variant: "error" });
      else {
        setSending(true);
        if (eventId.length === 0) await createEvent(event);
        else await updateEvent(event);
        setSending(false);
        onClose();
      }
    }
  };

  const onTryClose = (): void => {
    if (!modified) onClose();
    else setModifiedAlertOpen(true);
  };

  const changeEventValue = (key: string, value: unknown, place = false, program = false): void => {
    if (event) {
      let newEvent = place || program ? { ...event } : { ...event, [key]: value };
      if (key === "url") {
        newEvent = { ...newEvent, pictures: [{ [key]: value as string }] };
      }
      if (place) newEvent.place = event.place ? { ...event.place, [key]: value } : { [key]: value };
      else if (program) newEvent.program = event.program ? { ...event.program, [key]: value } : { [key]: value };
      setEvent(newEvent);
      if (JSON.stringify(originalEvent) !== JSON.stringify(newEvent)) {
        setModified(true);
      }
    }
  };

  const changeEvent =
    (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeEventValue(key, e.target.value);
    };

  const changeEventPlace =
    (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeEventValue(key, e.target.value, true);
    };
  const changeEventOption = (newOption: EventOption): void => {
    const newOptions = event?.program?.options
      ? event.program.options.map((o) => {
          if (o.id === newOption.id) return newOption;
          return o;
        })
      : [newOption];
    changeEventValue("options", newOptions, false, true);
  };

  const addEventOption = (): void => {
    const maxId =
      event?.program?.options && event?.program?.options.length > 0
        ? Math.max(...event.program.options.map((o) => o.id))
        : 0;
    const newOption = { id: maxId + 1 };
    changeEventValue(
      "options",
      event?.program?.options ? [...event.program.options, newOption] : [newOption],
      false,
      true,
    );
  };

  const removeEventOption = (optionId: number): void => {
    changeEventValue(
      "options",
      event?.program?.options ? event.program.options.filter((o) => o.id !== optionId) : [],
      false,
      true,
    );
  };

  const creation = eventId.length === 0;

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" style={{ width: creation ? undefined : 1050 }}>
        {creation ? (
          <>
            <Typography variant="h6" className="margin-bottom">
              {t("new")}
            </Typography>
            <div className="info-column">
              <TextField label={t("title")} value={event?.title || ""} onChange={changeEvent("title")} fullWidth />
              <DateTimePicker
                label={t("eventDate")}
                value={event?.eventDate ? event.eventDate * 1000 : null}
                onChange={(value) => changeEventValue("eventDate", value ? Math.round(value / 1000) : undefined)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa hh:mm" }}
                    fullWidth
                  />
                )}
              />
              <TextField
                label={t("datePlaceholder")}
                value={event?.datePlaceholder || ""}
                onChange={changeEvent("datePlaceholder")}
                fullWidth
              />
            </div>
          </>
        ) : (
          <div style={{ overflowY: "auto", paddingBottom: 32 }}>
            <Typography variant="h6" className="margin-bottom">
              {t("general")}
            </Typography>
            <div className="row space-between margin-bottom">
              {event && (
                <AbImageUpload
                  thumbnail={event.pictures?.[0].url || ""}
                  updateImageInState={(e) => changeEventValue("url", e.url)}
                />
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  marginLeft: 32,
                  alignSelf: "stretch",
                  flex: 1,
                }}>
                <TextField label={t("title")} value={event?.title || ""} onChange={changeEvent("title")} fullWidth />
                <DateTimePicker
                  label={t("eventDate")}
                  value={event?.eventDate ? event.eventDate * 1000 : null}
                  onChange={(value) => changeEventValue("eventDate", value ? Math.round(value / 1000) : undefined)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa hh:mm" }}
                      fullWidth
                    />
                  )}
                />
                <TextField
                  label={t("datePlaceholder")}
                  value={event?.datePlaceholder || ""}
                  onChange={changeEvent("datePlaceholder")}
                  fullWidth
                />
              </div>
            </div>
            <Typography variant="h6" className="margin-bottom">
              <Place />
              {t("modalPlace")}
            </Typography>
            <div className="row margin-bottom">
              <TextField
                label={t("place.name")}
                value={event?.place?.name || ""}
                onChange={changeEventPlace("name")}
                style={{ flex: 1 }}
              />
              <TextField
                label={t("place.address")}
                value={event?.place?.address || ""}
                onChange={changeEventPlace("address")}
                className="margin-left"
                style={{ flex: 1 }}
              />
              <TextField
                label={t("place.zipCode")}
                value={event?.place?.zipCode || ""}
                onChange={changeEventPlace("zipCode")}
                className="margin-left"
                style={{ width: 100 }}
              />
              <TextField
                label={t("place.city")}
                value={event?.place?.city || ""}
                onChange={changeEventPlace("city")}
                className="margin-left"
                style={{ width: 200 }}
              />
            </div>
            <Typography variant="h6" className="margin-bottom">
              {t("body")}
            </Typography>
            <AbHtmlTextField strict={false} value={event?.body || ""} setValue={(v) => changeEventValue("body", v)} />
            <Typography variant="h6" className="margin-top margin-bottom">
              {t("modalProgram")}
            </Typography>
            <div className="row space-between">
              <DateTimePicker
                label={t("openDate")}
                value={event?.program?.openDate ? event.program.openDate * 1000 : null}
                onChange={(value) =>
                  changeEventValue("openDate", value ? Math.round(value / 1000) : undefined, false, true)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa hh:mm" }}
                    fullWidth
                  />
                )}
              />
              <div style={{ width: 64 }} />
              <DateTimePicker
                label={t("limitDate")}
                value={event?.program?.limitDate ? event.program.limitDate * 1000 : null}
                onChange={(value) =>
                  changeEventValue("limitDate", value ? Math.round(value / 1000) : undefined, false, true)
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa hh:mm" }}
                    fullWidth
                  />
                )}
              />
            </div>
            <div className="row space-between margin-top">
              <Typography variant="h6">{t("options")}</Typography>
              <Tooltip title={t("option.addOption")} arrow>
                <IconButton onClick={addEventOption}>
                  <Add color="primary" />
                </IconButton>
              </Tooltip>
            </div>
            {event?.program?.options && event.program.options.length > 0 ? (
              event.program.options.map((o) => (
                <ProgramOption
                  key={o.id}
                  option={o}
                  onOptionChange={changeEventOption}
                  onOptionRemove={removeEventOption}
                />
              ))
            ) : (
              <Typography className="center">{t("notOptionYet")}</Typography>
            )}
            <Typography variant="h6" className="margin-bottom">
              {t("nota")}
            </Typography>
            <TextField value={event?.nota || ""} onChange={changeEvent("nota")} fullWidth />
          </div>
        )}
        <div className="margin-top">
          <AbButton
            label={t(creation ? "create" : "save")}
            onClick={sendEventToApi}
            isLoading={sending}
            disabled={!modified}
          />
        </div>
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalEvent) setEvent({ ...originalEvent });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

export default EventModal;
