/* eslint-disable prefer-template */
import { FC } from "react";
import { getEnvVariable } from "../helpers/EnvHelpers";
import { getPictureOrFallbackUrl, getPictureUrlOrFallbackUrl } from "../helpers/UrlHelpers";
import { Picture } from "../data/types/ApiTypes";
import { ImageType } from "../data/types/ClientTypes";

interface ImageProps {
  alt: string;
  picture?: Picture | string;
  width?: number;
  height?: number;
  type?: ImageType;
  className?: string;
  crop?: boolean;
  forceMobile?: boolean;
  fullWidth?: boolean;
}

const FALLBACK = "fallback";

export const getFinalPictureUrl = (
  url: string,
  type?: ImageType,
  mobile?: boolean,
  crop?: boolean,
  height?: number,
  width?: number,
): string => {
  const finalUrl = getPictureUrlOrFallbackUrl(url, url === FALLBACK);
  let result = finalUrl;
  if (result.includes(getEnvVariable(process.env.REACT_APP_API_CLOUDYNARY_CLOUDNAME))) {
    // Picture is hosted on Cloudinary
    const cloudinaryPath = getEnvVariable(process.env.REACT_APP_API_CLOUDYNARY_PATH);
    const urlParts = finalUrl.split(cloudinaryPath);
    if (
      type === ImageType.Hero ||
      type === ImageType.Interstitial ||
      type === ImageType.Banner ||
      type === ImageType.GalleryFull ||
      type === ImageType.Gallery
    ) {
      // Not the same aspect ratio for mobile and desktop when it's a Hero or the Interstitial image
      result = `${urlParts[0]}${cloudinaryPath}/t_${type}${mobile ? "Mobile" : "Desktop"}${urlParts[1]}`;
    } else if (type === ImageType.Social) {
      result = `${urlParts[0]}${cloudinaryPath}/t_${type}${urlParts[1]}`;
    } else if (typeof type !== "undefined") {
      // Same aspect ratio, apply the corresponding transformation for both devices
      result = `${urlParts[0]}${cloudinaryPath}/t_${type}/q_auto,f_auto${urlParts[1]}`;
    } else if (typeof width === "number" || typeof height === "number") {
      // Same URL for both form factors
      result = `${urlParts[0]}${cloudinaryPath}/${
        typeof width === "number" ? `w_${width}${typeof height === "number" ? "," : ""}` : ""
      }${typeof height === "number" ? `h_${height}` : ""},${crop === false ? "c_pad" : "g_auto,c_fill"}/q_auto,f_auto${
        urlParts[1]
      }`;
    }
  }
  return result;
};

const Image: FC<ImageProps> = ({ picture, alt, height, width, type, className, crop, forceMobile, fullWidth }) => {
  const finalUrl = typeof picture === "string" ? picture : getPictureOrFallbackUrl(picture);
  const smallSizeUrl = getFinalPictureUrl(finalUrl, type, true, crop, height, fullWidth ? 768 : width);
  const bigSizeUrl = forceMobile
    ? smallSizeUrl
    : getFinalPictureUrl(finalUrl, type, false, crop, height, fullWidth ? 1920 : width);

  return smallSizeUrl === bigSizeUrl ? (
    <img className={className} src={smallSizeUrl} alt={alt} />
  ) : (
    <picture>
      <source srcSet={bigSizeUrl} media="(min-width: 768px)" />
      <img className={className} src={smallSizeUrl} alt={alt} />
    </picture>
  );
};

Image.defaultProps = {
  picture: undefined,
  width: undefined,
  height: undefined,
  type: undefined,
  className: undefined,
  crop: undefined,
  forceMobile: undefined,
  fullWidth: undefined,
};

export default Image;
