import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import SendIcon from "@mui/icons-material/Send";
import { Dialog } from "@mui/material";
import { CSSProperties, FC, PropsWithChildren, ReactElement, useState } from "react";
import { useTranslation } from "react-i18next";
import { getTargetForLink } from "src/helpers/UrlHelpers";
import { ButtonIconProps, ColorButtonProps } from "../../data/types/ApiTypes";
import styles from "./linkButton.module.scss";

interface ButtonProps {
  label: string;
  href?: string;
  color?: ColorButtonProps;
  icon?: ButtonIconProps | undefined;
  onClick?: () => void;
  style?: CSSProperties;
}

const LinkButton: FC<ButtonProps> = ({ label, href, color, icon, onClick, style }) => {
  const getStylesClass = (): string => {
    switch (color) {
      case ColorButtonProps.primary:
        return styles.linkButton__primary;
      case ColorButtonProps.secondary:
        return styles.linkButton__secondary;
      case ColorButtonProps.white:
        return styles.linkButton__white;
      case ColorButtonProps.whiteWithBorder:
        return styles.linkButton__whiteWithBorder;
      default:
        return "";
    }
  };
  const getIcon = (): ReactElement | undefined => {
    switch (icon) {
      case ButtonIconProps.arrowBack:
        return <ArrowBackIcon fontSize="small" className={styles.linkButton__icon} />;
      case ButtonIconProps.arrowForward:
        return <ArrowForwardIcon fontSize="small" className={styles.linkButton__icon} />;
      case ButtonIconProps.shopping:
        return <ShoppingCartIcon fontSize="inherit" className={styles.linkButton__icon} />;
      case ButtonIconProps.picture:
        return <CameraAltIcon fontSize="inherit" className={styles.linkButton__icon} />;
      case ButtonIconProps.send:
        return <SendIcon fontSize="inherit" className={styles.linkButton__icon} />;
      default:
        return undefined;
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("content");

  const children = (
    <>
      <div className={styles.linkButton__label}>{label}</div>
      {getIcon()}
    </>
  );

  const clickHandler = onClick || (() => setIsOpen(!isOpen));

  return (
    <>
      <button type="button" className={`${styles.linkButton} ${getStylesClass()}`} onClick={clickHandler} style={style}>
        {children}
      </button>
      {href !== undefined ? (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <div style={{ padding: "8px" }}>
            {t("dialogOpenSameTab")}
            <br />{" "}
            <a href={href} target={getTargetForLink(href)} rel="noreferrer">
              {href}
            </a>
          </div>
        </Dialog>
      ) : undefined}
    </>
  );
};

LinkButton.defaultProps = {
  href: undefined,
  color: ColorButtonProps.primary,
  icon: undefined,
  onClick: undefined,
  style: undefined,
};

export default LinkButton;

interface ButtonWithChildrenProps extends PropsWithChildren {
  href?: string;
  onClick?: () => void;
}

export const LinkWithChildren: FC<ButtonWithChildrenProps> = ({ children, href, onClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation("content");
  const clickHandler = onClick || (() => setIsOpen(!isOpen));

  return (
    <>
      <button type="button" onClick={clickHandler} className={styles.linkWithChildren}>
        {children}
      </button>
      {href !== undefined ? (
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
          <div style={{ padding: "8px" }}>
            {t("dialogOpenSameTab")}
            <br />{" "}
            <a href={href} target={getTargetForLink(href)} rel="noreferrer">
              {href}
            </a>
          </div>
        </Dialog>
      ) : undefined}
    </>
  );
};

LinkWithChildren.defaultProps = {
  href: undefined,
  onClick: undefined,
};
