import EJ2_LOCALE from "@syncfusion/ej2-locale/src/fr.json";
import overrides from "./fr/schedulerOverrides.json";

const fr = {
  ...EJ2_LOCALE.fr,
  schedule: {
    ...EJ2_LOCALE.fr.schedule,
    ...overrides.schedule,
  },
};

export default fr;
