/* eslint-disable react/jsx-no-constructed-context-values */
import { createContext, FC, PropsWithChildren, useState } from "react";

export const TabsContext = createContext<{
  teamTabsValue: number;
  setTeamTabsValue: (newTabsValue: number) => void;
  newsTabsValue: number;
  setNewsTabsValue: (newTabsValue: number) => void;
}>({
  teamTabsValue: 0,
  setTeamTabsValue: () => {},
  newsTabsValue: 0,
  setNewsTabsValue: () => {},
});

const TabsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [teamTabsValue, setTeamTabsValue] = useState(1);
  const [newsTabsValue, setNewsTabsValue] = useState(0);
  return (
    <TabsContext.Provider
      value={{
        teamTabsValue,
        setTeamTabsValue,
        newsTabsValue,
        setNewsTabsValue,
      }}>
      {children}
    </TabsContext.Provider>
  );
};

export default TabsContextProvider;
