import { Add, ArrowDownward, ArrowUpward, Delete, Edit, InsertLink, Settings } from "@mui/icons-material";
import { Backdrop, Badge, CircularProgress, Fab, SpeedDial, SpeedDialAction, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, ReactNode, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import AbDeleteAlert from "../../components/AbDeleteAlert";
import { DataContext } from "../../data/contexts/DataContext";
import { ComponentsConfig } from "../../data/types/ClientTypes";
import { Component, ComponentType, Page } from "../../data/types/ApiTypes";
import { getComponent, getComponentTypeIcon } from "../../helpers/TemplateHelpers";
import PageComponentModal from "../../modals/PageComponentModal";
import PageSettingsModal from "../../modals/PageSettingsModal";
import styles from "./templatesPage.module.scss";

const RenderedComponent: FC<{
  page: Page;
  component: Component;
  isFirst: boolean;
  isLast: boolean;
  onEdit: () => void;
}> = ({ page, component, isFirst, isLast, onEdit }) => {
  const [isFocused, setIsFocused] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [movingUp, setMovingUp] = useState(false);
  const [movingDown, setMovingDown] = useState(false);
  const [confirmRemove, setConfirmRemove] = useState(false);
  const { pageRemoveComponent, updatePage } = useContext(DataContext);
  const { t } = useTranslation("content");
  const { enqueueSnackbar } = useSnackbar();
  const Comp = getComponent(component.type);
  if (Comp === null) return <div>Composant introuvable</div>;
  const tryRemove = (): void => setConfirmRemove(true);
  const remove = async (): Promise<void> => {
    setConfirmRemove(false);
    setRemoving(true);
    await pageRemoveComponent(page.id, component.id);
    setRemoving(false);
  };
  const moveUp = async (): Promise<void> => {
    setMovingUp(true);
    const currentIndex = page.content.findIndex((c) => c.id === component.id);
    const newContent: Component[] = [];
    let idx = 0;
    page.content.forEach((c) => {
      // Push the component if it's the good index
      if (currentIndex - 1 === idx) newContent.push(component);
      // Push the component at the idx if it's not the edited component
      if (c.id !== component.id) newContent.push(c);
      idx += 1;
    });
    await updatePage({ id: page.id, content: newContent });
    setMovingUp(false);
  };
  const moveDown = async (): Promise<void> => {
    setMovingDown(true);
    const currentIndex = page.content.findIndex((c) => c.id === component.id);
    const newContent: Component[] = [];
    let idx = 0;
    page.content.forEach((c) => {
      // Push the component at the idx if it's not the edited component
      if (c.id !== component.id) newContent.push(c);
      // Push the component if it's the good index
      if (currentIndex + 1 === idx) newContent.push(component);
      idx += 1;
    });
    await updatePage({ id: page.id, content: newContent });
    setMovingDown(false);
  };
  const fullWidth =
    (component.type === ComponentType.staticBanner && !component.chrome) ||
    component.type === ComponentType.contentCard;
  const anchorCopy = async (): Promise<void> => {
    navigator.clipboard.writeText(`#${component.id}`);
    enqueueSnackbar(t("copyingSuccess"), { variant: "success", autoHideDuration: 3000 });
  };
  const respectPageWidth =
    component.type !== ComponentType.staticHero &&
    component.type !== ComponentType.newsHero &&
    !(component.type === ComponentType.contentCard && typeof component.backgroundColor !== "undefined");
  return (
    <div
      id={component.id}
      className={`${styles.toolbar}${fullWidth ? " full-width" : ""}`}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      style={
        page.maxWidth && respectPageWidth
          ? !component.fullWidth
            ? { maxWidth: page.maxWidth }
            : { maxWidth: "100%" }
          : respectPageWidth
          ? undefined
          : { alignSelf: "stretch" }
      }>
      {isFocused ? (
        <div className={styles.toolbar__container}>
          <Fab color="primary" className={styles.toolbar__fab} onClick={onEdit}>
            <Edit />
          </Fab>
          {ComponentsConfig[component.type].undeletable ? undefined : (
            <Fab color="primary" className={styles.toolbar__fab} onClick={tryRemove}>
              {removing ? <CircularProgress color="inherit" size={24} /> : <Delete />}
            </Fab>
          )}
          {!isFirst ? (
            <Fab color="primary" className={styles.toolbar__fab} onClick={moveUp}>
              {movingUp ? <CircularProgress color="inherit" size={24} /> : <ArrowUpward />}
            </Fab>
          ) : undefined}
          {!isLast ? (
            <Fab color="primary" className={styles.toolbar__fab} onClick={moveDown}>
              {movingDown ? <CircularProgress color="inherit" size={24} /> : <ArrowDownward />}
            </Fab>
          ) : undefined}
          <Tooltip title={t("copyAnchor")}>
            <Fab color="primary" className={styles.toolbar__fab} onClick={anchorCopy}>
              <InsertLink />
            </Fab>
          </Tooltip>
        </div>
      ) : undefined}
      <Comp {...component} pageMaxWidth={page.maxWidth} />
      <AbDeleteAlert
        open={confirmRemove}
        onClose={() => setConfirmRemove(false)}
        onConfirm={remove}
        published={false}
      />
    </div>
  );
};

const TemplatePage: FC<{ page: Page }> = ({ page }) => {
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [editedComponentId, setEditedComponentId] = useState("");
  const [componentToCreate, setComponentToCreate] = useState<ComponentType | undefined>(undefined);
  const { t } = useTranslation("content");

  const getSpeedDialAction = (type: ComponentType): ReactNode => (
    <SpeedDialAction
      key={type}
      icon={getComponentTypeIcon(type)}
      title={t(`addSpeedDial.${type}`)}
      onClick={() => setComponentToCreate(type)}
    />
  );

  let nbMissingSeoFields = 0;
  if (!page.seo?.title || page.seo.title.length === 0) nbMissingSeoFields += 1;
  if (!page.seo?.description || page.seo.description.length === 0) nbMissingSeoFields += 1;
  return (
    <div className={styles.editor}>
      <Backdrop open={showBackdrop} style={{ zIndex: 100, backgroundColor: "rgba(0,0,0,0.3)" }} />
      {page.content.map((c, idx) => (
        <RenderedComponent
          key={c.id}
          component={c}
          page={page}
          isFirst={idx === 0}
          isLast={idx === page.content.length - 1}
          onEdit={() => setEditedComponentId(c.id)}
        />
      ))}
      <div className={styles.editor__settings}>
        <Badge badgeContent={nbMissingSeoFields} color="error">
          <Fab color="primary" onClick={() => setSettingsOpen(true)}>
            <Settings />
          </Fab>
        </Badge>
      </div>
      <PageSettingsModal id={page.id} open={settingsOpen} onClose={() => setSettingsOpen(false)} />
      <PageComponentModal
        pageId={page.id}
        id={editedComponentId}
        open={editedComponentId.length > 0 || (componentToCreate?.length || 0) > 0}
        onClose={() => {
          setEditedComponentId("");
          setComponentToCreate(undefined);
        }}
        componentToCreate={componentToCreate}
      />
      <SpeedDial
        icon={<Add />}
        ariaLabel={t("addSpeedDial.ariaLabel")}
        onOpen={() => setShowBackdrop(true)}
        onClose={() => setShowBackdrop(false)}
        className={styles.editor__add}>
        {getSpeedDialAction(ComponentType.staticHero)}
        {getSpeedDialAction(ComponentType.contentCard)}
        {getSpeedDialAction(ComponentType.staticBanner)}
        {getSpeedDialAction(ComponentType.newsSlider)}
        {getSpeedDialAction(ComponentType.videoEmbed)}
        {getSpeedDialAction(ComponentType.gallery)}
        {getSpeedDialAction(ComponentType.faq)}
        {process.env.REACT_APP_FEATURE_FLIPPING_SHOP === "true"
          ? getSpeedDialAction(ComponentType.shopProductSlider)
          : undefined}
        {getSpeedDialAction(ComponentType.cardsGrid)}
      </SpeedDial>
    </div>
  );
};

export default TemplatePage;
