import { Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Tooltip } from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbTwoCards } from "../components/AbBaseCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbFilter from "../components/AbFilter";
import AbItemsList from "../components/AbItemsList";
import AbListItem from "../components/AbListItem";
import AbMatchPicture from "../components/AbMatchPicture";
import AbMatchViewer from "../components/AbMatchViewer";
import {
  CurrentSeason,
  compareWithoutAccent,
  formatDate,
  getChampionshipDay,
  getChampionshipName,
  getSeasonLabel,
  getSeasons,
} from "../data/Converters";
import { DataContext } from "../data/contexts/DataContext";
import { Championship, Match } from "../data/types/ApiTypes";
import MatchModal from "../modals/MatchModal";

const MatchesPage: FC = () => {
  const { matches, publishMatch } = useContext(DataContext);
  const seasons = getSeasons(matches);
  const [currentItem, setCurrentItem] = useState<Match | null>(null);
  const [modifying, setModifying] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [filter, setFilter] = useState("");
  const [seasonSelected, setSeasonSelected] = useState<number>(CurrentSeason);
  const [championshipSelected, setChampionshipSelected] = useState("");
  const [published, setPublished] = useState<boolean | null>(null);
  const { t } = useTranslation(["global", "match"]);

  const filteredMatches: Match[] = useMemo(() => {
    const matchTmp = matches
      .filter((m) => compareWithoutAccent(m.title || "", filter))
      .filter((m) =>
        published === null
          ? m.published || !m.published || !m.published === undefined
          : published
          ? m.published
          : !m.published || !m.published === undefined,
      )
      .filter((m) => (championshipSelected ? m.championship === championshipSelected : m))
      .filter((m) => (seasonSelected ? m.season === seasonSelected : m));
    return matchTmp;
  }, [filter, matches, published, championshipSelected, seasonSelected]);

  useEffect(() => {
    if (currentItem) {
      const newCurrent = filteredMatches.find((e) => e.id === currentItem.id);
      if (newCurrent) setCurrentItem(newCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredMatches]);

  const publish = async (): Promise<void> => {
    if (!currentItem) return;
    setPublishing(true);
    await publishMatch(currentItem.id, !currentItem.published);
    setPublishing(false);
  };
  const getSubtitle = (date?: number, time?: string, championship?: Championship, championshipDay?: number): string => {
    const subtitle: string[] = [];
    if (date) {
      subtitle.push(formatDate(date));
    }
    if (time) {
      subtitle.push(time.toUpperCase());
    }
    if (championship) {
      subtitle.push(getChampionshipDay(championship, championshipDay).toUpperCase());
    }
    return subtitle.join(" - ");
  };

  const changeSeason = (season: string): void => {
    const newSeason = parseInt(season, 10);
    setSeasonSelected(newSeason);
    setCurrentItem(null);
  };

  const changeCahmpionship = (championship: string): void => {
    setChampionshipSelected(championship);
    setCurrentItem(null);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1.4em",
        }}>
        <div style={{ display: "flex", flexDirection: "row", width: "1000px" }}>
          <FormControl style={{ width: "470px", marginRight: "22px" }}>
            <InputLabel id="seasonFilter">{t("match:seasonFilter")}</InputLabel>
            <Select
              value={seasonSelected}
              label={t("match:seasonFilter")}
              id="seasonFilter"
              onChange={(e) => changeSeason(e.target.value as string)}>
              <MenuItem value="">{t("match:noFilter")}</MenuItem>
              {seasons.map((s) => (
                <MenuItem key={s} value={s}>
                  {getSeasonLabel(s)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="championchipsFilter">{t("match:championchipsFilter")}</InputLabel>
            <Select
              value={championshipSelected}
              label={t("match:championchipsFilter")}
              id="championchipsFilter"
              onChange={(e) => changeCahmpionship(e.target.value)}>
              <MenuItem value="">{t("match:noFilter")}</MenuItem>
              {Object.values(Championship).map((c) => (
                <MenuItem key={c} value={c}>
                  {getChampionshipName(c)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <AbFilter
            textSearch
            textSearchString={filter}
            textSearchLabel={t("match:search")}
            setTextSearchString={setFilter}
            togglePublished
            published={published}
            setPublished={setPublished}
          />
        </div>
      </div>
      {matches.length > 0 ? (
        <AbTwoCards
          left={
            <AbItemsList
              items={filteredMatches}
              renderRow={(m: Match) => (
                <AbListItem
                  data={{
                    picture: <AbMatchPicture match={m} thumbnail />,
                    title: `${m.title}${m.score ? ` (${m.score})` : ""}`,
                    subtitle: getSubtitle(m.date, m.time, m.championship, m.championshipDay),
                    published: m.published,
                  }}
                  onClick={() => setCurrentItem(m)}
                  selected={currentItem?.id === m.id}
                  key={m.id}
                />
              )}
            />
          }
          right={
            currentItem ? (
              <div className="row">
                <AbMatchViewer match={currentItem} />
                <div className="margin-left" style={{ width: 40 }}>
                  <Tooltip title={t("match:modify")} arrow>
                    <IconButton onClick={() => setModifying(true)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t(`match:${currentItem.published ? "un" : ""}publish`)} arrow className="margin-top">
                    <IconButton onClick={publish} disabled={publishing}>
                      {publishing ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : currentItem.published ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
          rightWidth={550}
        />
      ) : (
        <AbCenteredContent infoText={t("noItemYet")} />
      )}
      <MatchModal matchId={currentItem?.id || ""} open={modifying} onClose={() => setModifying(false)} />
    </>
  );
};

export default MatchesPage;
