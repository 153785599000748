import { Send } from "@mui/icons-material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import {
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbTwoCards } from "../components/AbBaseCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbFilter from "../components/AbFilter";
import AbItemsList from "../components/AbItemsList";
import AbListItem from "../components/AbListItem";
import AbMessage from "../components/AbMessage";
import { DataContext } from "../data/contexts/DataContext";
import { compareWithoutAccent } from "../data/Converters";
import { Conversation } from "../data/types/ClientTypes";

const MessagesContainer = styled("div")({
  maxHeight: "calc(100% - 250px)",
  overflowY: "auto",
});

const ConversationsPage: FC = () => {
  const [currentItem, setCurrentItem] = useState<Conversation | null>(null);
  const [newMessage, setNewMessage] = useState("");
  const [sending, setSending] = useState(false);
  const [handled, setHandled] = useState<boolean | null>(null);
  const [filter, setFilter] = useState("");
  const { conversations, sendMessage, handledConversation } = useContext(DataContext);
  const { t } = useTranslation(["global", "conversations"]);
  const messagesBottom = useRef<HTMLDivElement | null>();
  const scrollToBottom = (): void => {
    if (messagesBottom.current) messagesBottom.current.scrollIntoView({ behavior: "smooth" });
  };

  const filteredConversations: Conversation[] = useMemo(() => {
    const itemsTmp = conversations
      .filter((c) => compareWithoutAccent(`${c.partnerName} ${c.partnerUserName}`, filter))
      .filter((c) =>
        handled === null
          ? c.isHandled || !c.isHandled || !c.isHandled === undefined
          : handled
          ? c.isHandled
          : !c.isHandled || !c.isHandled === undefined,
      );
    return itemsTmp;
  }, [filter, conversations, handled]);

  useEffect(() => {
    if (currentItem) {
      const newCurrent = conversations.find((c) => c.id === currentItem.id);
      if (newCurrent) setCurrentItem(newCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversations]);

  useEffect(() => scrollToBottom, [currentItem]);

  const sendTheMessage = async (): Promise<void> => {
    if (currentItem) {
      setSending(true);
      await sendMessage(newMessage, currentItem?.id);
      setSending(false);
      setNewMessage("");
    }
  };

  const handleChangeProcessed = (event: React.MouseEvent<HTMLElement>, value: boolean | null): void => {
    setHandled(value);
  };

  const handleClickConversation = (event: React.MouseEvent<HTMLElement>, id: string): void => {
    handledConversation(id);
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "1.4em" }}>
        <AbFilter
          textSearch
          textSearchString={filter}
          textSearchLabel={t("conversations:search")}
          setTextSearchString={setFilter}
          togglePublished={false}
          extraFields={
            <ToggleButtonGroup
              sx={{ height: "56px", marginLeft: "24px" }}
              value={handled}
              exclusive
              onChange={handleChangeProcessed}>
              <Tooltip title={t("conversations:handledFilter")} arrow placement="bottom">
                <ToggleButton value>
                  <MarkEmailReadIcon />
                </ToggleButton>
              </Tooltip>
              <Tooltip title={t("conversations:notHandledFilter")} arrow placement="bottom">
                <ToggleButton value={false}>
                  <MarkEmailUnreadIcon />
                </ToggleButton>
              </Tooltip>
            </ToggleButtonGroup>
          }
        />
      </div>
      {conversations.length > 0 ? (
        <AbTwoCards
          left={
            <AbItemsList
              items={filteredConversations}
              renderRow={(c: Conversation) => (
                <AbListItem
                  key={`conversation_${c.id}`}
                  data={{
                    title: t("conversations:title", {
                      replace: { userName: c.partnerUserName, partnerName: c.partnerName },
                    }),
                    subtitle: t("conversations:subtitle", { replace: { date: c.latestMessageDate } }),
                    noPublication: true,
                    noPicture: true,
                    handled: c.isHandled,
                  }}
                  onClick={() => setCurrentItem(c)}
                  selected={currentItem?.id === c.id}
                />
              )}
            />
          }
          right={
            currentItem ? (
              <div className="row" style={{ maxHeight: "100%" }}>
                <Card className="card-root article" style={{ width: "100%", maxHeight: "100%" }}>
                  <Grid display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: !currentItem.isHandled ? "600" : "400",
                        borderRadius: "8px",
                        marginRight: "16px",
                      }}>
                      {t("conversations:title", {
                        replace: { userName: currentItem.partnerUserName, partnerName: currentItem.partnerName },
                      })}
                    </Typography>
                    <Tooltip title={t("conversations:markAsHandled")} arrow placement="bottom">
                      <Button
                        color="primary"
                        disabled={currentItem.isHandled}
                        sx={{
                          margin: "16px",
                          height: "48px",
                          width: "48px",
                        }}
                        onClick={(e) => handleClickConversation(e, currentItem.id)}>
                        <MarkEmailReadIcon fontSize="large" />
                      </Button>
                    </Tooltip>
                  </Grid>
                  <MessagesContainer>
                    {currentItem.messages.map((m) => (
                      <AbMessage message={m} key={m.id} />
                    ))}
                    <div
                      style={{ height: 0 }}
                      ref={(e) => {
                        messagesBottom.current = e;
                      }}
                    />
                  </MessagesContainer>
                  <FormControl variant="outlined" className="margin-top" fullWidth>
                    <InputLabel htmlFor="outlined-adornment-message">{t("conversations:sendAMessage")}</InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-message"
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.code.toLowerCase() === "numpadenter") {
                          sendTheMessage();
                        }
                      }}
                      endAdornment={
                        sending ? (
                          <CircularProgress size={32} />
                        ) : (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="send message"
                              onClick={sendTheMessage}
                              disabled={newMessage.length === 0}
                              color="primary"
                              edge="end">
                              <Send />
                            </IconButton>
                          </InputAdornment>
                        )
                      }
                      label={t("conversations:sendAMessage")}
                      multiline
                      rows={5}
                      fullWidth
                    />
                  </FormControl>
                </Card>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
          rightWidth={550}
        />
      ) : (
        <AbCenteredContent infoText={t("noItemYet")} />
      )}
    </>
  );
};

export default ConversationsPage;
