import axios, { AxiosError } from "axios";
import { formatShortestDate } from "./Converters";
import { log } from "./Logger";
import {
  AllData,
  ApiConversation,
  Article,
  AuthResponse,
  Component,
  Conditions,
  ConversationMessageAdd,
  Event,
  EventRegistration,
  Match,
  Notification,
  Page,
  Partner,
  PartnerUpdate,
  Picture,
  Players,
  Publication,
  Room,
  RoomBooking,
  ShopProduct,
  Sponsoring,
  Staff,
  Task,
  TeamMember,
  UploadType,
  User,
  UserCreation,
} from "./types/ApiTypes";

const APP_ID = "AB_BO";
const AUTH_URL = process.env.REACT_APP_API_AUTHENTICATION || "";
const ALL_DATA_URL = process.env.REACT_APP_API_ALL_DATA || "";
const USER_URL = process.env.REACT_APP_API_USER || "";
const EVENT_URL = process.env.REACT_APP_API_EVENT || "";
const TEAM_MEMBER_URL = process.env.REACT_APP_API_TEAM_MEMBER || "";
const ROOM_URL = process.env.REACT_APP_API_ROOM || "";
const ROOM_BOOKING_URL = process.env.REACT_APP_API_ROOM_BOOKING || "";
const PUBLISH_URL = process.env.REACT_APP_API_PUBLISH || "";
const MATCH_URL = process.env.REACT_APP_API_MATCH || "";
const PLAYERS_URL = process.env.REACT_APP_API_PLAYERS || "";
const CONDITIONS_URL = process.env.REACT_APP_API_CONDITIONS || "";
const PARTNER_URL = process.env.REACT_APP_API_PARTNER || "";
const CONVERSATION_URL = process.env.REACT_APP_API_CONVERSATION || "";
const EXPORT_URL = process.env.REACT_APP_API_EXPORT || "";
const RESET_CACHE_URL = process.env.REACT_APP_API_RESET_CACHE || "";
const PASSWORD_LINK = process.env.REACT_APP_API_PASSWORD_LINK || "";
const STAFF_URL = process.env.REACT_APP_API_STAFF || "";
const NEWS_URL = process.env.REACT_APP_API_NEWS || "";
const PAGE_URL = process.env.REACT_APP_API_PAGE || "";
const GALLERY_URL = process.env.REACT_APP_API_GALLERY || "";
const SHOP_PRODUCT_URL = process.env.REACT_APP_API_SHOP_PRODUCT || "";
const NOTIFICATION_URL = process.env.REACT_APP_API_NOTIFICATION || "";
const TASK_URL = process.env.REACT_APP_API_TASK || "";
const SPONSORING_URL = process.env.REACT_APP_API_SPONSORING || "";
const TICKET_URL = process.env.REACT_APP_API_PARTNERSAPI || "";

enum ApiActions {
  allTicketsForMatch = "allTicketsForMatch",
  getTicketPdf = "getTicketPdf",
}

const addParamsToUrl = (url: string, params: Record<string, string>): string => {
  const paramsToAdd = Object.keys(params)
    .map((k) => `${k}=${params[k]}`)
    .join("&");
  return url.indexOf("?") === -1 ? `${url}?${paramsToAdd}` : `${url}&${paramsToAdd}`;
};

const logAndNumber = (retValue: number, ...args: unknown[]): number => {
  log.error(...args);
  return retValue;
};

const send = async <T>(
  url: string,
  data: Record<string, unknown>,
  method: string = "POST",
  token?: string,
  isBlob?: boolean,
  apiAction?: ApiActions,
): Promise<T | number> => {
  try {
    const result = await axios({
      url,
      data,
      method,
      responseType: isBlob ? "blob" : undefined,
      headers: {
        "content-type": "application/json",
        "app-id": APP_ID,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
        ...(apiAction ? { "api-action": apiAction } : {}),
      },
    });
    if (result.status !== 200) return logAndNumber(result.status, "Error while posting data to API", url, result);
    return result.data as T;
  } catch (err) {
    const error = err as AxiosError;
    return logAndNumber(error.response?.status || 500, "Error while posting data to API", url, err);
  }
};

const get = async <T>(url: string, token?: string, data?: Record<string, string | undefined>): Promise<T | number> => {
  try {
    const finalUrl =
      typeof data === "undefined"
        ? url
        : `${url}${url.indexOf("?") === -1 ? "?" : "&"}${Object.keys(data)
            .map((key) => (typeof data[key] === "undefined" ? "" : `${key}=${data[key]}`))
            .join("&")}`;
    const result = await axios({
      url: finalUrl,
      method: "GET",
      headers: {
        "app-id": APP_ID,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    });
    if (result.status !== 200) return logAndNumber(result.status, "Error while getting data from API", url, result);
    return result.data as T;
  } catch (err) {
    const error = err as AxiosError;
    return logAndNumber(error.response?.status || 500, "Error while getting data from API", url, err);
  }
};

export const postAuthentication = async (login: string, password: string): Promise<AuthResponse | number> =>
  send<AuthResponse>(AUTH_URL, { login, password });

export const getNewToken = async (refreshToken: string): Promise<AuthResponse | number> =>
  get<AuthResponse>(AUTH_URL, refreshToken);

export const getAllData = async (token: string): Promise<AllData | number> => get<AllData>(ALL_DATA_URL, token);

export const getGalleryPictures = async (
  token: string,
  limit: string,
  tag?: string,
  folder?: string,
): Promise<Picture[] | number> => get<Picture[]>(GALLERY_URL, token, { tag, folder, limit });

export const postUser = async (token: string, newUser: UserCreation): Promise<User | number> =>
  send<User>(USER_URL, newUser as unknown as Record<string, unknown>, "POST", token);

export const checkDigitickUser = async (token: string, email: string): Promise<User | number> =>
  get<User>(addParamsToUrl(USER_URL, { email }), token);

export const patchUser = async (token: string, newUser: User): Promise<User | number> =>
  send<User>(USER_URL, newUser as unknown as Record<string, unknown>, "PATCH", token);

export const patchPartner = async (token: string, newPartner: PartnerUpdate): Promise<Partner | number> =>
  send<Partner>(PARTNER_URL, newPartner as unknown as Record<string, unknown>, "PATCH", token);

export const postEvent = async (token: string, newEvent: Event): Promise<Event | number> =>
  send<Event>(EVENT_URL, newEvent as unknown as Record<string, unknown>, "POST", token);

export const patchEvent = async (token: string, newEvent: Event): Promise<Event | number> =>
  send<Event>(EVENT_URL, newEvent as unknown as Record<string, unknown>, "PATCH", token);

export const delEvent = async (token: string, eventId: string): Promise<Event | number> =>
  send<Event>(EVENT_URL, { eventId }, "DELETE", token);

export const postTeamMember = async (token: string, newTeamMember: TeamMember): Promise<TeamMember | number> =>
  send<TeamMember>(TEAM_MEMBER_URL, newTeamMember as unknown as Record<string, unknown>, "POST", token);

export const patchTeamMember = async (token: string, newTeamMember: TeamMember): Promise<TeamMember | number> =>
  send<TeamMember>(TEAM_MEMBER_URL, newTeamMember as unknown as Record<string, unknown>, "PATCH", token);

export const delTeamMember = async (token: string, id: string): Promise<TeamMember | number> =>
  send<TeamMember>(TEAM_MEMBER_URL, { id }, "DELETE", token);

export const getTeamMember = async (token: string, id: string): Promise<TeamMember | number> =>
  get<TeamMember>(`${TEAM_MEMBER_URL}${TEAM_MEMBER_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postRoom = async (token: string, newRoom: Room): Promise<Room | number> =>
  send<Room>(ROOM_URL, newRoom as unknown as Record<string, unknown>, "POST", token);

export const patchRoom = async (token: string, newRoom: Room): Promise<Room | number> =>
  send<Room>(ROOM_URL, newRoom as unknown as Record<string, unknown>, "PATCH", token);

export const delRoom = async (token: string, id: string): Promise<Room | number> =>
  send<Room>(ROOM_URL, { id }, "DELETE", token);

export const getRoom = async (token: string, id: string): Promise<Room | number> =>
  get<Room>(`${ROOM_URL}${ROOM_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postNotification = async (token: string, newNotification: Notification): Promise<Notification | number> =>
  send<Notification>(NOTIFICATION_URL, newNotification as unknown as Record<string, unknown>, "POST", token);

export const patchNotification = async (token: string, newNotification: Notification): Promise<Notification | number> =>
  send<Notification>(NOTIFICATION_URL, newNotification as unknown as Record<string, unknown>, "PATCH", token);

export const delNotification = async (token: string, id: string): Promise<Notification | number> =>
  send<Notification>(NOTIFICATION_URL, { id }, "DELETE", token);

export const getNotification = async (token: string, id: string): Promise<Notification | number> =>
  get<Notification>(`${NOTIFICATION_URL}${NOTIFICATION_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postTask = async (token: string, newTask: Task): Promise<Task | number> =>
  send<Task>(TASK_URL, newTask as unknown as Record<string, unknown>, "POST", token);

export const patchTask = async (token: string, newTask: Task): Promise<Task | number> =>
  send<Task>(TASK_URL, newTask as unknown as Record<string, unknown>, "PATCH", token);

export const delTask = async (token: string, id: string): Promise<Task | number> =>
  send<Task>(TASK_URL, { id }, "DELETE", token);

export const getTask = async (token: string, id: string): Promise<Task | number> =>
  get<Task>(`${TASK_URL}${TASK_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postShopProduct = async (token: string, newShopProduct: ShopProduct): Promise<ShopProduct | number> =>
  send<ShopProduct>(SHOP_PRODUCT_URL, newShopProduct as unknown as Record<string, unknown>, "POST", token);

export const patchShopProduct = async (token: string, newShopProduct: ShopProduct): Promise<ShopProduct | number> =>
  send<ShopProduct>(SHOP_PRODUCT_URL, newShopProduct as unknown as Record<string, unknown>, "PATCH", token);

export const delShopProduct = async (token: string, id: string): Promise<ShopProduct | number> =>
  send<ShopProduct>(SHOP_PRODUCT_URL, { id }, "DELETE", token);

export const getShopProduct = async (token: string, id: string): Promise<ShopProduct | number> =>
  get<ShopProduct>(`${SHOP_PRODUCT_URL}${SHOP_PRODUCT_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postRoomBooking = async (token: string, newRoomBooking: RoomBooking): Promise<RoomBooking | number> =>
  send<RoomBooking>(ROOM_BOOKING_URL, newRoomBooking as unknown as Record<string, unknown>, "POST", token);

export const patchRoomBooking = async (token: string, newRoomBooking: RoomBooking): Promise<RoomBooking | number> =>
  send<RoomBooking>(ROOM_BOOKING_URL, newRoomBooking as unknown as Record<string, unknown>, "PATCH", token);

export const delRoomBooking = async (token: string, id: string): Promise<RoomBooking | number> =>
  send<RoomBooking>(ROOM_BOOKING_URL, { id }, "DELETE", token);

export const getRoomBooking = async (token: string, id: string): Promise<RoomBooking | number> =>
  get<RoomBooking>(`${ROOM_BOOKING_URL}${ROOM_BOOKING_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postStaff = async (token: string, newStaff: Staff): Promise<Staff | number> =>
  send<Staff>(STAFF_URL, newStaff as unknown as Record<string, unknown>, "POST", token);

export const patchStaff = async (token: string, newStaff: Staff): Promise<Staff | number> =>
  send<Staff>(STAFF_URL, newStaff as unknown as Record<string, unknown>, "PATCH", token);

export const delStaff = async (token: string, id: string): Promise<Staff | number> =>
  send<Staff>(STAFF_URL, { id }, "DELETE", token);

export const postNews = async (token: string, newNews: Article): Promise<Article | number> =>
  send<Article>(NEWS_URL, newNews as unknown as Record<string, unknown>, "POST", token);

export const patchNews = async (token: string, newNews: Article): Promise<Article | number> =>
  send<Article>(NEWS_URL, newNews as unknown as Record<string, unknown>, "PATCH", token);

export const delNews = async (token: string, id: string): Promise<Article | number> =>
  send<Article>(NEWS_URL, { id }, "DELETE", token);

export const getNews = async (token: string, id: string): Promise<Article | number> =>
  get<Article>(`${NEWS_URL}${NEWS_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const postPublish = async <T>(token: string, publication: Publication): Promise<T | number> =>
  send<T>(PUBLISH_URL, publication as unknown as Record<string, unknown>, "POST", token);

export const postMatch = async (token: string, newMatch: Match): Promise<Match | number> =>
  send<Match>(MATCH_URL, newMatch as unknown as Record<string, unknown>, "POST", token);

export const patchMatch = async (token: string, newMatch: Match): Promise<Match | number> =>
  send<Match>(MATCH_URL, newMatch as unknown as Record<string, unknown>, "PATCH", token);

export const postPlayers = async (token: string, newPlayers: Players): Promise<Players | number> =>
  send<Players>(PLAYERS_URL, newPlayers as unknown as Record<string, unknown>, "POST", token);

export const patchPlayers = async (token: string, newPlayers: Players): Promise<Players | number> =>
  send<Players>(PLAYERS_URL, newPlayers as unknown as Record<string, unknown>, "PATCH", token);

export const delPlayers = async (token: string, id: string): Promise<Players | number> =>
  send<Players>(PLAYERS_URL, { id }, "DELETE", token);

export const postConditions = async (token: string, newConditions: Conditions): Promise<Conditions | number> =>
  send<Match>(CONDITIONS_URL, newConditions as unknown as Record<string, unknown>, "POST", token);

export const patchConditions = async (token: string, newConditions: Conditions): Promise<Conditions | number> =>
  send<Match>(CONDITIONS_URL, newConditions as unknown as Record<string, unknown>, "PATCH", token);

export const postSponsoring = async (token: string, newSponsoring: Sponsoring): Promise<Sponsoring | number> =>
  send<Sponsoring>(SPONSORING_URL, newSponsoring as unknown as Record<string, unknown>, "POST", token);

export const patchSponsoring = async (token: string, newSponsoring: Sponsoring): Promise<Sponsoring | number> =>
  send<Sponsoring>(SPONSORING_URL, newSponsoring as unknown as Record<string, unknown>, "PATCH", token);

export const delSponsoring = async (token: string, id: string): Promise<Sponsoring | number> =>
  send<Sponsoring>(SPONSORING_URL, { id }, "DELETE", token);

export const getSponsoring = async (token: string, id: string): Promise<Sponsoring | number> =>
  get<Sponsoring>(`${SPONSORING_URL}${SPONSORING_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

export const patchConversation = async (
  token: string,
  newMessage: ConversationMessageAdd,
): Promise<ApiConversation | number> =>
  send<ApiConversation>(CONVERSATION_URL, newMessage as unknown as Record<string, unknown>, "PATCH", token);

export const markConversationAsHandled = async (token: string, id: string): Promise<ApiConversation | number> =>
  send<ApiConversation>(CONVERSATION_URL, { id }, "PUT", token);

export const getEventRegistrations = async (token: string, eventId: string): Promise<EventRegistration[] | number> =>
  get<EventRegistration[]>(addParamsToUrl(EVENT_URL, { eventId }), token);

export const uploadFile = async (token: string, file: File, resourceId: string, type: UploadType): Promise<unknown> => {
  const data = new FormData();
  data.append("file", file);
  const result = await axios({
    url: process.env.REACT_APP_API_UPLOAD,
    method: "POST",
    data,
    headers: {
      authorization: `Bearer ${token}`,
      "app-id": APP_ID,
      "content-type": "multipart/form-data",
      "upload-type": type,
      "resource-id": resourceId,
    },
  });
  return result.data;
};

export const getExport = async (token: string): Promise<void> => {
  try {
    const res = await axios.get(EXPORT_URL, {
      responseType: "blob",
      headers: {
        "content-type": "application/json",
        "app-id": APP_ID,
        ...(token ? { authorization: `Bearer ${token}` } : {}),
      },
    });

    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("style", "display: none");
    link.setAttribute("download", `DonneesPartenaires_${formatShortestDate()}.xlsx`);
    document.body.appendChild(link);
    link.click();
  } catch (reason) {
    const axiosError = reason as AxiosError;
    log.error("Error while getting data export from API", axiosError);
  }
};

export const resetApiCache = async (token: string): Promise<string | number> => get<string>(RESET_CACHE_URL, token);

export const resetPassword = async (token: string, userId: string): Promise<string | number> =>
  get<string>(addParamsToUrl(PASSWORD_LINK, { userId }), token);

export const postPage = async (token: string, page: Partial<Page>): Promise<Page | number> =>
  send<Page>(PAGE_URL, page as unknown as Record<string, unknown>, "POST", token);

export const patchPage = async (token: string, page: Partial<Page>): Promise<Page | number> =>
  send<Page>(PAGE_URL, page as unknown as Record<string, unknown>, "PATCH", token);

export const createComponent = async (token: string, pageId: string, component: Component): Promise<Page | number> =>
  send<Page>(PAGE_URL, { pageId, ...component }, "POST", token);

export const patchComponent = async (token: string, pageId: string, component: Component): Promise<Page | number> =>
  send<Page>(PAGE_URL, { pageId, ...component }, "PATCH", token);

export const deleteComponent = async (token: string, pageId: string, id: string): Promise<Page | number> =>
  send<Page>(PAGE_URL, { pageId, id }, "DELETE", token);

export const getPage = async (token: string, id: string): Promise<Page | number> =>
  get<Page>(`${PAGE_URL}${PAGE_URL.indexOf("?") !== -1 ? "&" : "?"}id=${id}`, token);

// TODO create an url on api to download ticket in back office
export const getTicketPdfBlob = async (token: string, ticketId: number, partnerId: string): Promise<Blob | number> =>
  send<Blob>(TICKET_URL, { ticketId, partnerId }, "POST", token, true, ApiActions.getTicketPdf);

export const getTicketsArchiveBlob = async (
  token: string,
  matchId: string,
  partnerId: string,
  category?: string,
): Promise<Blob | number> =>
  send<Blob>(TICKET_URL, { matchId, category, partnerId }, "POST", token, true, ApiActions.allTicketsForMatch);
