import { FC, useState, useContext, useEffect, ChangeEvent } from "react";
import { Modal, Paper, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbButton from "src/components/AbButton";
import { DataContext } from "src/data/contexts/DataContext";
import { useSnackbar } from "notistack";
import { isValidSlug } from "src/helpers/UrlHelpers";

const ReservedPages = ["/accueil", "/news", "/equipe", "/classement", "/matchs", "/contact", "/galerie"];

interface AddTemplatePageModalProps {
  open: boolean;
  onClose: () => void;
  onPageCreated: (pageId: string) => void;
}

const AddTemplatePageModal: FC<AddTemplatePageModalProps> = ({ open, onClose, onPageCreated }) => {
  const [href, setHref] = useState("");
  const [creating, setCreating] = useState(false);
  const { createPage, pages } = useContext(DataContext);
  const { t } = useTranslation("content");
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => setHref(""), [open]);
  const tryChangeHref = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const newHref = e.target.value;
    setHref(newHref);
  };
  const createThePage = async (): Promise<void> => {
    if (isValidSlug(href) && href.length >= 4) {
      const fullHref = `/${href}`;
      if (pages.findIndex((p) => p.href === fullHref) !== -1 || ReservedPages.includes(fullHref)) {
        enqueueSnackbar(t("addPageAlreadyExists"), { variant: "error" });
      } else {
        setCreating(true);
        const pageId = uuidv4();
        await createPage({ id: pageId, href: fullHref, published: true, language: "fr", content: [] });
        setCreating(false);
        onPageCreated(pageId);
      }
    } else {
      enqueueSnackbar(t("addPageHrefInvalid"), { variant: "error" });
    }
  };
  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper">
        <Typography variant="h5" className="center big-margin-bottom">
          {t("addPageTitle")}
        </Typography>
        <Typography>{t("addPageHref")}</Typography>
        <Typography variant="caption" className="margin-bottom">
          {t("addPageHrefInvalid")}
        </Typography>
        <TextField value={href} onChange={tryChangeHref} fullWidth className="big-margin-bottom" />
        <AbButton fullWidth label={t("addPageCreate")} isLoading={creating} onClick={createThePage} />
      </Paper>
    </Modal>
  );
};

export default AddTemplatePageModal;
