/* eslint-disable import/order */
import {
  Autocomplete,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbButton from "../../components/AbButton";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import AbImageUpload from "../../components/AbImageUpload";
import AbModificationAlert from "../../components/AbModificationAlert";
import { getTodayTimestamp } from "../../data/Converters";
import { DataContext } from "../../data/contexts/DataContext";
import { Article, CategoryNewsType, Picture, PublicationChannelName, TeamMember } from "../../data/types/ApiTypes";

interface NewsModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}

const emptyArticle: Article = {
  id: uuidv4(),
  pictures: [{ url: "" }],
  title: "",
  body: "",
  published: false,
  isHomePageHero: true,
  publicationDate: getTodayTimestamp(),
  publicationChannels: [PublicationChannelName.appB2B, PublicationChannelName.web],
};

const NewsModal: FC<NewsModalProps> = ({ open, onClose, id }) => {
  const { news, updateNews, createNews, teamMembers } = useContext(DataContext);
  const creation = id.length === 0;
  const originalArticle = creation ? emptyArticle : news.find((n) => n.id === id) || emptyArticle;
  const [article, setArticle] = useState<Article>(originalArticle);
  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation("news");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (creation) {
      setArticle({ ...emptyArticle, id: uuidv4() });
    } else {
      const newOriginalArticle = news.find((n) => n.id === id);
      if (newOriginalArticle) setArticle({ ...newOriginalArticle });
    }
    setModified(false);
  }, [creation, id, news, open]);

  const sendNewsToApi = async (): Promise<void> => {
    if (article) {
      if (!article.title || article.title.length === 0 || !article.body || article.body.length === 0)
        enqueueSnackbar(t("titleAndBodyAreMandatory"), { variant: "error" });
      else if (!article.publicationChannels || article.publicationChannels.length === 0)
        enqueueSnackbar(t("noChannel"), { variant: "error" });
      else {
        setSending(true);
        if (id.length === 0) await createNews(article);
        else await updateNews(article);
        setSending(false);
        onClose();
      }
    }
  };

  const changeNewsValue = (key: string, value: unknown): void => {
    let newArticle = { ...article, [key]: value };
    if (key === "url") {
      newArticle = { ...newArticle, pictures: [{ [key]: value as string }] };
    }
    setArticle(newArticle);
    if (JSON.stringify(originalArticle) !== JSON.stringify(newArticle)) {
      setModified(true);
    }
  };

  const changeDate =
    (key: string): ((e: ChangeEvent<HTMLInputElement> | any) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      const newDate = new Date(e.toString()).getTime() / 1000;
      changeNewsValue(key, newDate);
    };

  const changeNews =
    (key: string): ((e: ChangeEvent<HTMLInputElement>) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeNewsValue(key, e.target.value);
    };

  const changeImage = (picture: Picture): void => {
    changeNewsValue("url", picture.url);
  };

  const onTryClose = (): void => {
    if (!modified) onClose();
    else setModifiedAlertOpen(true);
  };

  const handleChangeChecked = (e: React.ChangeEvent<HTMLInputElement>): void => {
    changeNewsValue("isHomePageHero", e.target.checked);
  };

  const changeAssociatedTeamMemberValue = (value: TeamMember[]): void => {
    const associatedTeamMember: string[] = [];
    value.map((v) => associatedTeamMember.push(v.id));
    changeNewsValue("associatedTeamMember", associatedTeamMember);
  };
  const changePublicationChannelsValue = (value: PublicationChannelName[]): void => {
    const publicationChannels: PublicationChannelName[] = [];
    value.map((v) => publicationChannels.push(v));
    changeNewsValue("publicationChannels", publicationChannels);
  };

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" sx={{ minWidth: "1200px", minHeight: "600px" }}>
        <>
          <Typography variant="h4" className="margin-bottom" sx={{ textAlign: "center" }}>
            {t(creation ? "new" : "modify")}
          </Typography>
          <div className="info-column">
            <div className="row">
              <div className="column" style={{ paddingTop: ".5em", marginBottom: "1em" }}>
                <AbImageUpload
                  updateImageInState={changeImage}
                  thumbnail={article?.pictures?.[0].url || ""}
                  // className="margin-bottom"
                />
                <FormControlLabel
                  sx={{ marginLeft: "1.4em" }}
                  control={<Checkbox checked={article?.isHomePageHero === true} onChange={handleChangeChecked} />}
                  label={t("infos.isHomePageHero")}
                />
              </div>
              <div className="info-column" style={{ padding: "1em", paddingTop: 0 }}>
                <TextField
                  label={`${t("infos.title")}${t("mandatory")}`}
                  value={article?.title}
                  onChange={changeNews("title")}
                  sx={{ marginBottom: ".5em" }}
                  fullWidth
                />
                <div className="row" style={{ justifyContent: "flex-start" }}>
                  <DateTimePicker
                    label={t("infos.publicationDate")}
                    value={article?.publicationDate ? article.publicationDate * 1000 : null}
                    onChange={changeDate("publicationDate")}
                    renderInput={(params) => (
                      <TextField {...params} inputProps={{ ...params.inputProps, placeholder: "jj/mm/aaaa" }} />
                    )}
                  />
                  <Autocomplete
                    multiple
                    id="size-small-outlined"
                    value={article.publicationChannels || []}
                    sx={{ width: "80%", marginLeft: "1em" }}
                    options={Object.keys(PublicationChannelName)}
                    getOptionLabel={(option) => t(`publicationChannels.${option}`)}
                    onChange={(_, value) => {
                      changePublicationChannelsValue(value as PublicationChannelName[]);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t("publicationChannels.publicationChannels")}${t("mandatory")}`}
                      />
                    )}
                  />
                </div>
                <div className="row" style={{ justifyContent: "flex-start", marginTop: ".5em" }}>
                  <FormControl sx={{ width: "20%" }}>
                    <InputLabel id="input-label-category">{t("infos.category")}</InputLabel>
                    <Select
                      value={article?.category}
                      labelId="input-label-category"
                      id="select-category"
                      label={t("infos.category")}
                      onChange={(e) => changeNewsValue("category", e.target.value)}>
                      {Object.keys(CategoryNewsType).map((c) => (
                        <MenuItem key={c} value={c}>
                          {t(`category.${c}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Autocomplete
                    multiple
                    id="size-small-outlined"
                    value={teamMembers.filter((tm) => article?.associatedTeamMember?.includes(tm.id))}
                    sx={{ width: "80%", marginLeft: "1em" }}
                    options={teamMembers}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
                    onChange={(_, value) => {
                      changeAssociatedTeamMemberValue(value);
                    }}
                    renderInput={(params) => <TextField {...params} label={t("infos.associateTeamMember")} />}
                  />
                </div>
              </div>
            </div>
            <AbHtmlTextField
              strict={false}
              placeholder={`${t("body")}${t("mandatory")}`}
              value={article?.body || ""}
              setValue={(n) => {
                changeNewsValue("body", n);
                setModified(true);
              }}
            />
          </div>
        </>

        <div className="margin-top">
          <AbButton
            label={t(creation ? "create" : "save")}
            onClick={sendNewsToApi}
            isLoading={sending}
            disabled={!modified}
          />
        </div>
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalArticle) setArticle({ ...originalArticle });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

export default NewsModal;
