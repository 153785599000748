import { Business, CorporateFare, VisibilityOff } from "@mui/icons-material";
import { Avatar, Card, CardActionArea, Typography } from "@mui/material";
import { FC } from "react";
import { Partner } from "../data/types/ApiTypes";

const AbPartner: FC<{ partner: Partner; onClick: () => void; small?: boolean }> = ({ partner, onClick, small }) => (
  <Card variant="outlined" className="partner-card">
    <CardActionArea onClick={onClick} style={{ height: "100%" }}>
      <div className={`${small ? "small-" : ""}logo-container`}>
        <div className={`${small ? "small-" : ""}logo`}>
          {partner.logo ? (
            <Avatar src={partner.logo} alt="Logo" sx={{ width: small ? 80 : 120, height: small ? 80 : 120 }} />
          ) : (
            <Business fontSize="large" htmlColor="rgba(255, 255, 255, 0.9)" />
          )}
        </div>
        {!partner.listed ? (
          <div style={{ position: "absolute", top: 16, right: 16 }}>
            <VisibilityOff color="disabled" />
          </div>
        ) : undefined}
        {partner.isGc ? (
          <div style={{ position: "absolute", top: 16, left: 16 }}>
            <CorporateFare color="disabled" />
          </div>
        ) : undefined}
      </div>
      <div className={`${small ? "small-" : ""}partner-root`}>
        <Typography
          className={`${small ? "small-" : ""}margin-bottom`}
          noWrap
          style={{ maxWidth: "100%" }}
          variant="body1"
          margin={0}>
          {partner.name}
        </Typography>
        <Typography variant="body1" margin={0}>
          {partner.city}
        </Typography>
      </div>
    </CardActionArea>
  </Card>
);

AbPartner.defaultProps = { small: false };

export default AbPartner;
