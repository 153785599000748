import { Info } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { ChangeEvent, FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "src/components/AbButton";
import AbHtmlTextField from "src/components/AbHtmlTextField";
import AbImageUpload from "src/components/AbImageUpload";
import AbModificationAlert from "src/components/AbModificationAlert";
import { DataContext } from "src/data/contexts/DataContext";
import { LinkType, Notification, NotificationType, Picture } from "src/data/types/ApiTypes";
import CloudinaryIdToImage from "src/helpers/CloudinaryIdToImage";
import { v4 as uuidv4 } from "uuid";
import LinkSelect from "./Link";

interface NotificationModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
  linkId?: string;
  linkType?: LinkType;
  picture?: Picture;
  message?: string;
}

const NotificationModal: FC<NotificationModalProps> = ({ open, onClose, id, linkId, linkType, picture, message }) => {
  const emptyNotification: Notification = {
    id: uuidv4(),
    title: "",
    picture: {
      url: "",
      id: undefined,
      title: undefined,
    },
    notificationType: NotificationType.news,
    linkType: LinkType.news,
    link: "",
    message: "",
    sent: false,
  };

  const { t } = useTranslation("notification");
  const { notifications, updateNotification, createNotification, news, videos, events, matches, shopProducts } =
    useContext(DataContext);
  const creation = id.length === 0;

  const getTitle = (idOfLink: string, type: LinkType): string => {
    switch (type) {
      case LinkType.event:
        return events.find((e) => e.id === idOfLink)?.title || "";
      case LinkType.match:
        return matches.find((m) => m.id === idOfLink)?.title || "";
      case LinkType.news:
        return news.find((n) => n.id === idOfLink)?.title || "";
      case LinkType.video:
        return videos.find((v) => v.id === idOfLink)?.title || "";
      case LinkType.shop:
        return shopProducts.find((sp) => sp.id === idOfLink)?.title || "";
      default:
        return "";
    }
  };

  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(linkId !== undefined && linkId.length > 0);
  const originalNotification = creation ? emptyNotification : notifications.find((n: Notification) => n.id === id);
  const [notification, setNotification] = useState<Notification | undefined>(originalNotification);
  const [sending, setSending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (linkId !== undefined && linkId.length > 0) {
      setNotification({
        id: uuidv4(),
        title: getTitle(linkId, linkType || LinkType.news),
        picture,
        notificationType: linkType as unknown as NotificationType,
        linkType,
        link: linkId,
        message,
        sent: false,
      });
      setModified(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId, linkType]);

  useEffect(() => {
    if (creation) {
      setNotification(emptyNotification);
    } else {
      const newOriginalNotification = notifications.find((n) => n.id === id);
      if (newOriginalNotification) setNotification({ ...newOriginalNotification });
    }
    setModified(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creation, id, notifications]);

  const validateForm = (): boolean => {
    if (notification) {
      if (
        notification.title &&
        notification.title.length > 0 &&
        notification.notificationType &&
        notification.notificationType.length > 0 &&
        notification.link &&
        notification.link.length > 0
      ) {
        return true;
      }
      return false;
    }
    return false;
  };

  const sendNewsToApi = async (): Promise<void> => {
    const validate: boolean = validateForm();
    if (notification) {
      if (!validate) enqueueSnackbar(t("missingMandatoryFields"), { variant: "error" });
      else {
        setSending(true);
        if (id.length === 0) await createNotification(notification);
        else await updateNotification(notification);
        setSending(false);
        onClose();
      }
    }
  };

  const changeNotificationValue = (key: string, value: unknown): void => {
    if (notification) {
      let newItem = { ...notification };
      newItem = { ...notification, [key]: value };
      setNotification(newItem);
      if (JSON.stringify(originalNotification) !== JSON.stringify(newItem)) {
        setModified(true);
      }
    }
  };

  const changeNotification =
    (key: string): ((e: ChangeEvent<HTMLInputElement> | any) => void) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      changeNotificationValue(key, e.target.value);
    };

  const onTryClose = (): void => {
    if (!modified) {
      onClose();
    } else setModifiedAlertOpen(true);
  };

  useEffect(() => {
    if (notification?.notificationType === "info") {
      changeNotificationValue("linkType", LinkType.url);
    } else {
      changeNotificationValue("linkType", notification?.notificationType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notification?.notificationType]);

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" sx={{ minWidth: "1000px", minHeight: "600px" }}>
        <Typography variant="h4" className="margin-bottom" sx={{ textAlign: "center" }}>
          {t(creation ? "new" : "modify")}
        </Typography>
        <div className="info-column" style={{ width: "100%", justifyContent: "center", marginBottom: "1.4em" }}>
          <div className="row" style={{ justifyContent: "center" }}>
            <AbImageUpload
              updateImageInState={(e) => changeNotificationValue("picture", e)}
              thumbnail={CloudinaryIdToImage(notification?.picture?.url || "")}
            />
            <div className="info-column" style={{ marginLeft: "1.4em" }}>
              <TextField
                label={`${t("title")}${t("mandatory")}`}
                value={notification?.title}
                onChange={changeNotification("title")}
                sx={{ width: "100%", marginBottom: "1em" }}
              />
              <div className="row">
                <FormControl sx={{ width: "300px", marginRight: ".4em" }}>
                  <InputLabel id="input-label-notificationType">
                    {`${t("notificationType.notificationType")}${t("mandatory")}`}
                  </InputLabel>
                  <Select
                    value={notification?.notificationType || ""}
                    labelId="input-label-notificationType"
                    id="select-notificationType"
                    label={t("notificationType.notificationType")}
                    onChange={changeNotification("notificationType")}>
                    {Object.keys(NotificationType).map((nt) =>
                      nt === LinkType.shop && process.env.REACT_APP_FEATURE_FLIPPING_SHOP !== "true" ? undefined : (
                        <MenuItem key={nt} value={nt}>
                          {t(`notificationType.${nt}`)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                <div className="row" style={{ color: "grey", alignItems: "center" }}>
                  <Info sx={{ marginRight: "8px" }} />
                  <Typography sx={{ lineHeight: "16px" }}>{t("notificationType.userInfo")}</Typography>
                </div>
              </div>
              <div className="row" style={{ marginTop: "1em", marginBottom: "1.2em" }}>
                <FormControl sx={{ width: "300px", marginRight: ".4em" }}>
                  <InputLabel id="input-label-linkType">{`${t("linkType.linkType")}${t("mandatory")}`}</InputLabel>
                  <Select
                    value={notification?.linkType || ""}
                    labelId="input-label-linkType"
                    id="select-linkType"
                    label={t("linkType.linkType")}
                    onChange={changeNotification("linkType")}>
                    {Object.keys(LinkType).map((lt) =>
                      lt === LinkType.shop && process.env.REACT_APP_FEATURE_FLIPPING_SHOP !== "true" ? undefined : (
                        <MenuItem key={lt} value={lt}>
                          {t(`linkType.${lt}`)}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </FormControl>
                {notification?.linkType === LinkType.url ? (
                  <TextField
                    label={`${t("link")}${t("mandatory")}`}
                    value={notification?.link}
                    onChange={changeNotification("link")}
                    sx={{ width: "60%" }}
                  />
                ) : (
                  <LinkSelect
                    link={notification?.link || ""}
                    setLink={(link: string) => changeNotificationValue("link", link)}
                    linkType={notification?.linkType || ""}
                  />
                )}
              </div>
            </div>
          </div>
          <AbHtmlTextField
            strict={false}
            placeholder={t("message")}
            value={notification?.message || ""}
            setValue={(m) => {
              changeNotificationValue("message", m);
              setModified(true);
            }}
          />
        </div>
        <div className="margin-top">
          <AbButton label={t("save")} onClick={sendNewsToApi} isLoading={sending} disabled={!modified} />
        </div>
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalNotification) setNotification({ ...originalNotification });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

NotificationModal.defaultProps = {
  linkId: undefined,
  linkType: undefined,
  picture: undefined,
  message: undefined,
};

export default NotificationModal;
