/* eslint-disable react/no-danger */
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { formatLongDate, getAge, getCountry } from "src/data/Converters";
import { TeamMember } from "src/data/types/ApiTypes";
import { ImageType } from "src/data/types/ClientTypes";
import { getProfilePictureOrFallback } from "src/helpers/UrlHelpers";
import Image from "../Image";
import FacebookSvg from "../social/FacebookSvg";
import InstagramSvg from "../social/InstagramSvg";
import TwitterSvg from "../social/TwitterSvg";
import styles from "./player.module.scss";

interface PlayerCardProps {
  player: TeamMember;
}

const PlayerCard: FC<PlayerCardProps> = ({ player }) => {
  const { t } = useTranslation("team");
  return (
    <div className={styles.onePlayer}>
      <Image
        picture={player.pictures?.hero}
        alt={player.pictures?.hero?.title || `${player.firstName} ${player.lastName}`}
        width={450}
        height={675}
        className={styles.onePlayer__bg}
      />
      <div className={styles.onePlayer__filter}>
        <div className={styles.onePlayer__firstPart}>
          <div className={styles.onePlayer__image}>
            <Image
              className={styles.onePlayer__image__profile}
              picture={getProfilePictureOrFallback(player.pictures?.profile)}
              alt={player.pictures?.profile?.title || `${player.firstName} ${player.lastName}`}
              type={ImageType.PlayerProfile}
            />
            {player.infos?.status === "jiff" ? (
              <img className={styles.onePlayer__image__jiff} src="/jiff.svg" alt="jiff" />
            ) : undefined}
          </div>
          <div className={styles.onePlayer__name}>
            <h2 className={styles.onePlayer__name__firstName}>{player.firstName}</h2>
            <h2 className={styles.onePlayer__name__lastName}>{player.lastName}</h2>
          </div>
          <div className={styles.onePlayer__position}>{t(`playerPositions.${player.infos?.position}`)}</div>
          <div className={styles.onePlayer__rs}>
            <Link
              className={styles.onePlayer__rs__link}
              target="_blank"
              to={
                player.socialNetwork?.instagramUrl?.length !== undefined && player.socialNetwork.instagramUrl.length > 0
                  ? player.socialNetwork?.instagramUrl
                  : "https://www.instagram.com/avironrugbypro/"
              }>
              <InstagramSvg width="20px" height="20px" />
            </Link>
            <Link
              className={styles.onePlayer__rs__link}
              target="_blank"
              to={
                player.socialNetwork?.facebookUrl?.length !== undefined
                  ? player.socialNetwork?.facebookUrl
                  : "https://www.facebook.com/avironbayonnaisrugbypro"
              }>
              <FacebookSvg width="20px" height="20px" />
            </Link>
            <Link
              className={styles.onePlayer__rs__link}
              target="_blank"
              to={
                player.socialNetwork?.twitterUrl?.length !== undefined
                  ? player.socialNetwork?.twitterUrl
                  : "https://twitter.com/avironrugbypro"
              }>
              <TwitterSvg width="20px" height="20px" />
            </Link>
          </div>
          <div className={styles.onePlayer__attributes}>
            <div className={styles.onePlayer__attributes__attribute}>
              <div className={styles.onePlayer__attributes__attribute__value}>
                {player.birthDate !== undefined ? getAge(player.birthDate) : undefined}
              </div>
              <div className={styles.onePlayer__attributes__attribute__unit}>{t("age")}</div>
            </div>
            <div className={styles.onePlayer__attributes__attribute}>
              <div className={styles.onePlayer__attributes__attribute__value}>
                {player.infos?.heightInCm !== undefined ? Math.round(player.infos?.heightInCm) : undefined}
              </div>
              <div className={styles.onePlayer__attributes__attribute__unit}>{t("height")}</div>
            </div>
            <div className={styles.onePlayer__attributes__attribute}>
              <div className={styles.onePlayer__attributes__attribute__value}>
                {player.infos?.weightInKg !== undefined ? Math.round(player.infos?.weightInKg) : undefined}
              </div>
              <div className={styles.onePlayer__attributes__attribute__unit}>{t("weight")}</div>
            </div>
          </div>
        </div>
        {player.birthDate === null &&
        player.country === null &&
        player.infos?.startInClub === null &&
        player.description?.bio === null &&
        player.description?.career === null ? undefined : (
          <div className={styles.onePlayer__secondPart}>
            {player.birthDate === null && player.country === null && player.infos?.startInClub === null ? undefined : (
              <div className={styles.onePlayer__infos}>
                {player.birthDate !== undefined && player.birthDate !== null ? (
                  <div className={styles.onePlayer__infos__info}>
                    <div className={styles.onePlayer__infos__info__unit}>{t("birth")}</div>
                    <div className={styles.onePlayer__infos__info__value}>
                      {formatLongDate(player.birthDate !== undefined ? player?.birthDate : -1, undefined, undefined)}
                    </div>
                  </div>
                ) : undefined}
                {player.country !== undefined && player.country !== null ? (
                  <div className={styles.onePlayer__infos__info}>
                    <div className={styles.onePlayer__infos__info__unit}>{t("country")}</div>
                    <div className={styles.onePlayer__infos__info__value}>{getCountry(player.country)}</div>
                  </div>
                ) : undefined}
                {player.infos?.startInClub !== undefined && player.infos.startInClub !== null ? (
                  <div className={styles.onePlayer__infos__info}>
                    <div className={styles.onePlayer__infos__info__unit}>{t("startInClub")}</div>
                    <div className={styles.onePlayer__infos__info__value}>
                      {formatLongDate(
                        player.infos?.startInClub !== undefined ? player?.infos?.startInClub : -1,
                        undefined,
                        undefined,
                      )}
                    </div>
                  </div>
                ) : undefined}
              </div>
            )}

            {player.description?.bio !== undefined ? (
              <div
                className={styles.onePlayer__bio}
                dangerouslySetInnerHTML={{
                  __html: player.description?.bio,
                }}
              />
            ) : undefined}

            {player.description?.career !== null && player.description?.career !== undefined ? (
              <div className={styles.onePlayer__career}>
                <h3 className={styles.onePlayer__career__title}>{t("career")}</h3>
                <div
                  className={styles.onePlayer__career__text}
                  dangerouslySetInnerHTML={{
                    __html: player.description?.career,
                  }}
                />
              </div>
            ) : undefined}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerCard;
