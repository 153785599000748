import Quill, { DeltaStatic, Sources } from "quill";
import { FC, useContext, useEffect, useRef } from "react";
import ReactQuill from "react-quill";
import { DamContext } from "src/data/contexts/DamContext";
import CloudinaryIdToImage from "src/helpers/CloudinaryIdToImage";

const Link = Quill.import("formats/link");

class MyLink extends Link {
  static create(value: string): unknown {
    const node = super.create(value);
    const newValue = this.sanitize(value);
    node.setAttribute("href", newValue);
    if (!value.startsWith("http")) {
      node.removeAttribute("target");
    }
    return node;
  }
}

Quill.register(MyLink);

const AbHtmlTextField: FC<{ value: string; setValue: (v: string) => void; placeholder?: string; strict: boolean }> = ({
  value,
  setValue,
  placeholder,
  strict,
}) => {
  const { showMediaLibrary } = useContext(DamContext);
  const quillRef = useRef<ReactQuill>(null);
  const valueChanged = (newValue: string, _: DeltaStatic, source: Sources): void => {
    if (source === "user") {
      setValue(newValue);
    }
  };

  const mediaLibraryCallback = async (result: any): Promise<void> => {
    const secureUrl =
      (result.assets[0]?.derived && result.assets[0].derived[0]?.secure_url) || result.assets[0].secure_url;
    const editor = quillRef?.current?.getEditor();
    const range = editor?.getSelection(true);
    if (range) {
      editor?.insertEmbed(
        range.index,
        "image",
        CloudinaryIdToImage(secureUrl, 1000, 1000, "limit", undefined, undefined, "q_auto,f_auto"),
        "user",
      );
    }
  };

  useEffect(() => {
    quillRef.current
      ?.getEditor()
      ?.getModule("toolbar")
      .addHandler("image", () => {
        showMediaLibrary(mediaLibraryCallback, () => {});
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quillRef]);

  const toolbarOptions = strict
    ? [
        ["bold", "italic", "underline", "strike"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ align: [] }],
      ]
    : [
        ["bold", "italic", "underline", "strike"],
        ["link", "image"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }],
        [{ header: [2, 3, 4, 5, 6, false] }],
        [{ align: [] }],
      ];

  return (
    <ReactQuill
      id="quill-editor"
      ref={quillRef}
      theme="snow"
      value={value}
      onChange={valueChanged}
      placeholder={placeholder}
      formats={[
        "header",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "image",
        "link",
        "align",
      ]}
      modules={{
        toolbar: toolbarOptions,
        clipboard: {
          matchVisual: false,
        },
      }}
    />
  );
};

AbHtmlTextField.defaultProps = { placeholder: undefined };

export default AbHtmlTextField;
