import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Typography } from "@mui/material";
import styles from "./shopProductCard.module.scss";
import Image from "../Image";
import LinkButton from "../linkButton/LinkButton";
import { ButtonIconProps, DiscountType, ShopProduct } from "../../data/types/ApiTypes";
import { addDiscountOnPrice } from "../../data/Converters";

const ShopProductCard: FC<{ shopProduct: ShopProduct }> = ({ shopProduct }) => {
  const { t } = useTranslation("shop");
  const [thereIsApplicableDiscount, setThereIsApplicableDiscount] = useState<boolean>();
  useEffect(() => {
    if (shopProduct?.discount?.type !== DiscountType.percentage && shopProduct?.discount?.type !== DiscountType.price) {
      setThereIsApplicableDiscount(false);
    } else setThereIsApplicableDiscount(true);
  }, [shopProduct?.discount?.type]);

  return (
    <div className={styles.shopProduct}>
      {thereIsApplicableDiscount ? (
        <Card className={styles.shopProduct__discount}>
          <div>
            -
            {shopProduct?.discount?.type === DiscountType.percentage
              ? shopProduct?.discount?.value
              : shopProduct?.discount?.value?.toFixed(2)}
            {shopProduct?.discount?.type === DiscountType.percentage ? "%" : t("currency")}
          </div>
        </Card>
      ) : undefined}
      <Card className={styles.shopProduct__card}>
        <Image
          className={styles.shopProduct__card__img}
          picture={shopProduct.picture}
          alt={shopProduct.title || ""}
          width={300}
          height={180}
        />
        <Typography variant="h5" className={styles.shopProduct__card__title}>
          {shopProduct?.title}
        </Typography>
        <div className={styles.shopProduct__card__priceAndDiscount}>
          <div
            className={`${
              thereIsApplicableDiscount ? styles.shopProduct__card__priceThrough : styles.shopProduct__card__price
            }`}>
            {shopProduct.price?.toFixed(2)}
          </div>
          {t("currency")}
          {thereIsApplicableDiscount ? (
            <div className={styles.shopProduct__card__discountedPrice}>
              {addDiscountOnPrice(shopProduct).toFixed(2)}
              {t("currency")}
            </div>
          ) : undefined}
        </div>
        <LinkButton
          label={t("shop")}
          href={shopProduct.url || ""}
          icon={ButtonIconProps.shopping}
          style={{ marginBottom: 16 }}
        />
      </Card>
    </div>
  );
};

export default ShopProductCard;
