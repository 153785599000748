import { Modal } from "@mui/material";
import { FC } from "react";
import styles from "../../styles/modal.module.scss";

interface VideoModalProps {
  open: boolean;
  onClose: () => void;
  videoId: string;
}

const VideoModal: FC<VideoModalProps> = ({ videoId, open, onClose }) => {
  return (
    <Modal open={open} onClose={() => onClose()} className={styles.videoModal}>
      <iframe
        key={videoId}
        className={styles.videoModal__iframe}
        src={`https://www.youtube-nocookie.com/embed/${videoId}?autoplay=1`}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Modal>
  );
};

export default VideoModal;
