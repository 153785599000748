import { FC } from "react";
import { Component } from "../../data/types/ApiTypes";
import styles from "./helloAssoModule.module.scss";

const HelloAssoModule: FC<Component> = ({ title }) => {
  return (
    <div className={styles.helloAssoModule}>
      {title ? <h2 className={styles.helloAssoModule__title}>{title}</h2> : undefined}
      <img
        className={styles.helloAssoModule__iframe}
        src="https://avironbayonnais.blob.core.windows.net/static/helloAssoModule.png"
        alt="screenshot iframe zenChefModule"
      />
    </div>
  );
};

export default HelloAssoModule;
