import { FC } from "react";
import { SocialSvgProps } from "./SocialSvgProps";

const TwitterSvg: FC<SocialSvgProps> = ({ height, width }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16" width={width} height={height}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.86784 4.92069C9.07197 0.792688 14.1115 -1.66289 17.0823 1.3155C17.0823 1.3155 18.4083 0.949743 19.5755 0.218234C19.5755 0.218234 19.151 1.6289 17.7187 2.51746C17.7187 2.51746 19.3104 2.30876 20 1.89066C20 1.89066 19.3104 3.30133 18.0369 3.82413C18.7265 13.2814 8.3817 19.0288 0 14.3263C0 14.3263 4.13806 14.535 5.88852 12.5499C5.88852 12.5499 3.34219 12.7586 2.06867 9.72856C2.06867 9.72856 3.02327 10.0943 3.9786 9.62385C3.9786 9.62385 1.00776 9.1534 0.74271 5.65291C0.74271 5.65291 1.69731 6.38442 2.75894 6.12337C2.75894 6.12337 -0.530096 4.18989 1.32668 0.689399C1.32668 0.689399 4.82833 5.07845 9.86784 4.9214V4.92069Z"
      fill="white"
    />
  </svg>
);

export default TwitterSvg;
