import { FC } from "react";
import { Component } from "../../data/types/ApiTypes";
import { ImageType } from "../../data/types/ClientTypes";
import Image from "../Image";
import styles from "./staticHero.module.scss";

const StaticHero: FC<Component> = ({ title, picture }) => {
  return (
    <div className={styles.hero__container}>
      <Image className={styles.hero__container__bg} picture={picture} type={ImageType.Hero} alt={title || ""} />
      <div className={styles.hero__box}>
        <div className={styles.hero__boxT}>
          {title ? <h1 className={styles.hero__boxT__title}>{title}</h1> : undefined}
        </div>
      </div>
    </div>
  );
};

export default StaticHero;
