import { Modal } from "@mui/material";
import "@splidejs/react-splide/css/core";
import { FC } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import styles from "../../styles/modal.module.scss";
import { ImageType } from "../../data/types/ClientTypes";
import Image from "../Image";

interface ItemsType {
  original: string;
  thumbnail: string;
}
interface GalleryModalProps {
  open: boolean;
  onClose: () => void;
  items: ItemsType[];
  index: number;
}

const GalleryModal: FC<GalleryModalProps> = ({ items, open, onClose, index }) => {
  return (
    <Modal open={open} onClose={onClose} className={styles.galleryModal}>
      <div className={styles.galleryModal__container}>
        <CancelIcon onClick={onClose} className={styles.galleryModal__container__cancelIcon} />
        <ReactImageGallery
          startIndex={index}
          showPlayButton={false}
          useBrowserFullscreen
          items={items}
          renderItem={(item) => (
            <Image picture={item.original} alt={item.originalAlt || ""} type={ImageType.GalleryFull} />
          )}
          renderThumbInner={(item) => (
            <Image picture={item.thumbnail} alt={item.originalAlt || ""} type={ImageType.GalleryMini} />
          )}
        />
      </div>
    </Modal>
  );
};

export default GalleryModal;
