import { Card, Table, TableCell, TableRow, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatNumber } from "../data/Converters";
import { DocumentType, PartnerDocument } from "../data/types/ApiTypes";
import { dedupArray } from "../data/types/TechnicalTypes";

const opportunityLink = "https://avironbayonnaisrugbypro2.lightning.force.com/lightning/r/Opportunity/{{id}}/view";
const quoteLink = "https://avironbayonnaisrugbypro2.lightning.force.com/lightning/r/Quote/{{id}}/view";
const invoiceLink = "https://avironbayonnaisrugbypro2.lightning.force.com/lightning/r/Invoice__c/{{id}}/view";

const getEntityLink = (type: DocumentType): string => {
  switch (type) {
    case DocumentType.quote:
      return quoteLink;
    case DocumentType.invoice:
      return invoiceLink;
    default:
      return "?";
  }
};

const AbDocumentsList: FC<{ documents?: PartnerDocument[] }> = ({ documents }) => {
  const { t } = useTranslation("partner");
  const opportunities = documents ? dedupArray(documents.map((d) => d.metadata.opportunityId)) : [];

  return !documents || documents.length === 0 ? null : (
    <>
      {opportunities.map((o) => {
        const docs = documents.filter((d) => d.metadata.opportunityId === o);
        const entities: {
          id: string;
          name: string;
          type: DocumentType;
          status: string;
          amount?: number;
          numerotation: string | null;
        }[] = [];
        docs.forEach((d) => {
          if (entities.findIndex((e) => e.id === d.metadata.entityId) === -1) {
            entities.push({
              id: d.metadata.entityId,
              name: d.metadata.entityName,
              type: d.metadata.type,
              status: d.metadata.status,
              amount: d.metadata.totalAmount,
              numerotation: d.metadata.numerotation,
            });
          }
        });
        return (
          <>
            <Typography className="margin-bottom center">
              <strong>
                {t("documents.opportunity")}{" "}
                <a href={opportunityLink.replace("{{id}}", o)} target="_blank" rel="noreferrer">
                  {docs[0].metadata.opportunityName}
                </a>
              </strong>
            </Typography>
            <Card variant="outlined" className="margin-bottom">
              <Table>
                {entities
                  .sort((e1, e2) => ((e1.numerotation || "") > (e2.numerotation || "") ? 1 : -1))
                  .map((e) => (
                    <TableRow key={e.id}>
                      <TableCell>
                        <Typography>
                          {t(`documents.type.${e.type}`)} :{" "}
                          <a href={getEntityLink(e.type).replace("{{id}}", e.id)} target="_blank" rel="noreferrer">
                            {e.name}
                          </a>
                        </Typography>
                        <div className="row space-between">
                          <Typography>
                            {t("documents.amount")} <b>{e.amount ? `${formatNumber(e.amount, 2, true)} € TTC` : "?"}</b>
                          </Typography>
                          {e.numerotation ? (
                            <Typography>
                              {t("documents.number")} <b>{e.numerotation}</b>
                            </Typography>
                          ) : undefined}
                          <Typography>
                            {t("documents.status")} <b>{e.status}</b>
                          </Typography>
                        </div>
                        <Typography>{t("documents.files")}</Typography>
                        <ul>
                          {docs
                            .filter((d) => d.metadata.entityId === e.id)
                            .map((d) => (
                              <li>
                                <a href={d.url} target="_blank" rel="noreferrer">
                                  {d.metadata.name}
                                </a>
                              </li>
                            ))}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))}
              </Table>
            </Card>
          </>
        );
      })}
    </>
  );
};

AbDocumentsList.defaultProps = { documents: undefined };

export default AbDocumentsList;
