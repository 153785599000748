import { Close, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { FC } from "react";

interface AbFilterProps {
  textSearch: boolean;
  textSearchString?: string;
  setTextSearchString?: (filter: string) => void;
  textSearchLabel?: string;

  togglePublished: boolean;
  published?: boolean | null;
  setPublished?: (value: boolean | null) => void;

  extraFields?: JSX.Element;
}

const AbFilter: FC<AbFilterProps> = ({
  textSearch,
  textSearchString,
  setTextSearchString,
  textSearchLabel,

  togglePublished,
  published,
  setPublished,

  extraFields,
}) => {
  const handlePublished = (event: React.MouseEvent<HTMLElement>, value: boolean | null): void => {
    if (setPublished !== undefined) setPublished(value);
  };

  return (
    <div className="row">
      {textSearch && setTextSearchString !== undefined ? (
        <FormControl style={{ width: 400, marginLeft: ".5" }} variant="outlined">
          <InputLabel htmlFor="article-filter">{textSearchLabel}</InputLabel>
          <OutlinedInput
            id="article-filter"
            value={textSearchString}
            onChange={(e) => setTextSearchString(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setTextSearchString("")} edge="end">
                  <Close />
                </IconButton>
              </InputAdornment>
            }
            label={textSearchLabel}
          />
        </FormControl>
      ) : (
        ""
      )}

      {extraFields}

      {togglePublished && (
        <ToggleButtonGroup
          sx={{ height: "56px", marginLeft: ".5em" }}
          value={published}
          exclusive
          onChange={handlePublished}>
          <ToggleButton value>
            <Visibility />
          </ToggleButton>
          <ToggleButton value={false}>
            <VisibilityOff />
          </ToggleButton>
        </ToggleButtonGroup>
      )}
    </div>
  );
};

AbFilter.defaultProps = {
  textSearchString: undefined,
  setTextSearchString: undefined,
  textSearchLabel: undefined,

  published: undefined,
  setPublished: undefined,

  extraFields: undefined,
};

export default AbFilter;
