import { Add, Campaign, Delete, Edit, Preview, Visibility, VisibilityOff } from "@mui/icons-material";
import { CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import AbPager from "src/components/AbPager";
import NotificationModal from "src/modals/NotificationModal";
import { AbTwoCards } from "../components/AbBaseCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbDeleteAlert from "../components/AbDeleteAlert";
import AbItemsList from "../components/AbItemsList";
import AbListItem from "../components/AbListItem";
import { AbOverrideSlugAlert } from "../components/AbModificationAlert";
import AbNewsViewer from "../components/AbNewsViewer";
import { compareWithoutAccent, formatDate, formatShortDate } from "../data/Converters";
import { DataContext } from "../data/contexts/DataContext";
import { Article, LinkType, PublicationChannelName } from "../data/types/ApiTypes";
import { NewsType } from "../data/types/ClientTypes";
import { getFrontUrl } from "../helpers/EnvHelpers";
import NewsModal from "../modals/NewsModal";

interface NewsProps {
  newsType: NewsType;
  filter: string;
  published: boolean | null;
  categoryFilter: string;
  channelFilter: string;
}

const NewsPage: FC<NewsProps> = ({ newsType, filter, published, categoryFilter, channelFilter }) => {
  const [currentNews, setCurrentNews] = useState<Article | null>(null);
  const [modifying, setModifying] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [sendNotification, setSendNotification] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [publicationError, setPublicationError] = useState(-1);
  const [currentPage, setCurrentPage] = useState(0);
  const { publishArticle, publishVideo, deleteNews, news, videos, refreshNews } = useContext(DataContext);
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();

  const filteredNews: Article[] = useMemo(() => {
    const newsTmp = (newsType === NewsType.news ? news : videos)
      .filter((n) => compareWithoutAccent(n.title || "", filter))
      .filter((n) =>
        published === null
          ? n.published || n.published === undefined || !n.published
          : published
          ? n.published
          : n.published === undefined || !n.published,
      )
      .filter((n) => (categoryFilter === "all" ? n : n.category === categoryFilter))
      .filter((n) =>
        channelFilter === "all" ? n : n.publicationChannels?.includes(channelFilter as PublicationChannelName),
      );
    return newsTmp;
  }, [newsType, news, videos, filter, published, categoryFilter, channelFilter]);

  const itemsOnPage = filteredNews.slice(10 * currentPage, 10 * (currentPage + 1));
  const nbPages = filteredNews.length > 10 ? Math.ceil(filteredNews.length / 10) : 1;

  useEffect(() => {
    if (currentNews) {
      const newCurrent = filteredNews.find((a: { id: string }) => a.id === currentNews.id);
      if (newCurrent) setCurrentNews(newCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredNews]);

  useEffect(() => {
    setCurrentNews(null);
    setCurrentPage(0);
  }, [newsType]);

  const publish = async (newSlug?: string): Promise<void> => {
    if (!currentNews) return;
    setPublishing(true);
    if (newsType === NewsType.news) {
      await publishArticle(
        currentNews.id,
        !currentNews.published,
        (httpStatus: number): void => {
          if (httpStatus === 400 || httpStatus === 409) {
            // Article publication has been rejected, slug must be incorrect
            setPublicationError(httpStatus);
          } else enqueueSnackbar(t("unkownError"), { variant: "error" });
        },
        newSlug,
      );
    } else {
      await publishVideo(currentNews.id, !currentNews.published);
    }
    setPublishing(false);
  };

  const deleteCurrentNews = async (): Promise<void> => {
    setDeleting(true);
    await deleteNews(currentNews?.id || "");
    setDeleting(false);
    setCurrentNews(null);
  };

  const tryDelete = async (): Promise<void> => {
    if (currentNews?.published) {
      setDeleteAlertOpen(true);
    } else {
      await deleteCurrentNews();
    }
  };

  const refreshAndEdit = async (): Promise<void> => {
    if (!currentNews) return;
    setRefreshing(true);
    await refreshNews(currentNews.id);
    setRefreshing(false);
    setModifying(true);
  };

  const previewArticle = (): void => {
    const url = getFrontUrl(`news/articles/${currentNews?.id}`);
    window.open(url);
  };

  return (
    <>
      {news.length > 0 ? (
        <AbTwoCards
          left={
            <>
              <div style={{ overflowY: "auto", width: "100%" }}>
                <AbItemsList
                  items={itemsOnPage}
                  renderRow={(a: Article) => (
                    <AbListItem
                      data={{
                        pictureUrl: a.pictures && a.pictures.length > 0 ? a.pictures[0] : undefined,
                        title: a.title !== undefined ? a.title : "",
                        subtitle: formatDate(a.publicationDate ? a.publicationDate : ""),
                        published: a.published,
                      }}
                      onClick={() => setCurrentNews(a)}
                      selected={currentNews?.id === a.id}
                      key={a.id}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  marginTop: "16px",
                  marginBottom: "-16px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}>
                <AbPager
                  page={currentPage + 1}
                  nbPages={nbPages}
                  onNext={() => setCurrentPage(currentPage + 1)}
                  onPrevious={() => setCurrentPage(currentPage - 1)}
                />
              </div>
            </>
          }
          right={
            currentNews ? (
              <div className="row">
                <AbNewsViewer news={currentNews} typeOfNews={newsType} />
                <div className="margin-left" style={{ width: 40 }}>
                  <Tooltip title={t(`news:${currentNews.published ? "un" : ""}publish`)} arrow className="margin-top">
                    <span>
                      <IconButton onClick={() => publish()} disabled={publishing}>
                        {publishing ? (
                          <CircularProgress color="secondary" size={24} />
                        ) : currentNews.published ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </span>
                  </Tooltip>
                  {currentNews.published ? undefined : (
                    <Tooltip title={t("news:preview")} arrow className="margin-top">
                      <IconButton onClick={previewArticle}>
                        <Preview />
                      </IconButton>
                    </Tooltip>
                  )}
                  {newsType === NewsType.news && (
                    <>
                      <Tooltip title={t("news:modify")} arrow className="margin-top">
                        <IconButton onClick={refreshAndEdit}>
                          {refreshing ? <CircularProgress color="secondary" size={24} /> : <Edit />}
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("news:delete")} arrow className="margin-top">
                        <IconButton onClick={tryDelete}>
                          {deleting ? <CircularProgress color="secondary" size={24} /> : <Delete />}
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {currentNews.published && process.env.REACT_APP_FEATURE_FLIPPING_NOTIFICATION === "true" ? (
                    <Tooltip title={t("createNotification")} arrow className="margin-top">
                      <IconButton onClick={() => setSendNotification(true)}>
                        <Campaign />
                      </IconButton>
                    </Tooltip>
                  ) : undefined}
                  <AbDeleteAlert
                    open={deleteAlertOpen}
                    onClose={() => {
                      setDeleteAlertOpen(false);
                    }}
                    onConfirm={() => {
                      setDeleteAlertOpen(false);
                      deleteCurrentNews();
                    }}
                  />
                  <AbOverrideSlugAlert
                    errorCode={publicationError}
                    onClose={() => setPublicationError(-1)}
                    onConfirm={(slug: string) => publish(slug)}
                    slugPrefix={formatShortDate(currentNews.publicationDate || new Date(), false, "-", true)}
                  />
                </div>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
        />
      ) : (
        <AbCenteredContent infoText={t("noItemYet")} />
      )}
      {newsType === NewsType.news && (
        <Fab
          color="primary"
          style={{ position: "absolute", bottom: 32, right: 48 }}
          onClick={() => {
            setCurrentNews(null);
            setModifying(true);
          }}>
          <Add />
        </Fab>
      )}
      <NewsModal id={currentNews?.id || ""} open={modifying} onClose={() => setModifying(false)} />
      <NotificationModal
        id=""
        open={sendNotification}
        onClose={() => setSendNotification(false)}
        linkId={currentNews?.id}
        linkType={LinkType.news}
        picture={currentNews?.pictures !== undefined ? currentNews?.pictures[0] : undefined}
        message={currentNews?.body?.split(".")[0]}
      />
    </>
  );
};

export default NewsPage;
