import { CameraAlt } from "@mui/icons-material";
import { CircularProgress, Tooltip } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { DamContext } from "src/data/contexts/DamContext";
import { getPictureUrlOrFallbackUrl } from "src/helpers/UrlHelpers";
import { Picture } from "../data/types/ApiTypes";
import CloudinaryIdToImage from "../helpers/CloudinaryIdToImage";

interface AbImageUploadProps {
  thumbnail: string;
  updateImageInState: (damPictures: Picture) => void;
  className?: string;
  teamMember?: boolean;
  width?: number;
  height?: number;
}

const AbImageUpload: FC<AbImageUploadProps> = ({
  thumbnail,
  updateImageInState,
  className,
  teamMember,
  width,
  height,
}) => {
  const [uploading, setUploading] = useState(false);
  const { showMediaLibrary } = useContext(DamContext);

  const { t } = useTranslation("global");

  const mediaLibraryCallback = async (result: any): Promise<void> => {
    const secureUrl =
      (result.assets[0]?.derived && result.assets[0].derived[0]?.secure_url) || result.assets[0].secure_url;
    const picture = {
      url: secureUrl,
      id: result.assets[0].public_id,
    };
    updateImageInState(picture);
    setUploading(false);
  };

  const mediaLibraryHid = (): void => {
    setUploading(false);
  };

  return (
    <div>
      <Tooltip title={t("changePicture")} arrow placement="top" className={className}>
        <button
          type="button"
          onClick={() => {
            setUploading(true);
            showMediaLibrary(mediaLibraryCallback, mediaLibraryHid);
          }}>
          {uploading ? (
            <span className="event-picture">
              <CircularProgress />
            </span>
          ) : (
            <span className="event-picture clickable" style={{ width: teamMember ? "250px" : width, height }}>
              {thumbnail ? (
                <img
                  src={CloudinaryIdToImage(
                    getPictureUrlOrFallbackUrl(thumbnail),
                    teamMember ? 250 : width,
                    height,
                    "fit",
                  )}
                  alt="Illustration"
                  width={teamMember ? 250 : width}
                  height={height}
                  style={{ objectFit: "contain" }}
                />
              ) : (
                <CameraAlt fontSize="large" htmlColor="rgba(255, 255, 255, 0.9)" />
              )}
            </span>
          )}
        </button>
      </Tooltip>
    </div>
  );
};

AbImageUpload.defaultProps = { className: undefined, teamMember: false, width: 350, height: 200 };

export default AbImageUpload;
