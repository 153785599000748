import { styled } from "@mui/material";

const ListRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

type ListProps<T> = {
  items: T[];
  renderRow: (item: T) => JSX.Element;
};

const AbItemsList = <T extends unknown>({ items, renderRow }: ListProps<T>): JSX.Element => {
  return <ListRoot>{items.map(renderRow)}</ListRoot>;
};

export default AbItemsList;
