import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { TeamMember, TeamMemberType } from "../data/types/ApiTypes";
import CloudinaryIdToImage from "../helpers/CloudinaryIdToImage";

const AbTeamMemberViewer: FC<{ teamMember: TeamMember }> = ({ teamMember }) => {
  const { t } = useTranslation("team");
  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "500px",
        alignItems: "center",
        padding: "10px",
        margin: "25px",
      }}>
      {teamMember.pictures?.profile?.url ? (
        <img
          src={CloudinaryIdToImage(teamMember?.pictures?.profile?.url || "")}
          width="100%"
          height="250"
          style={{ objectFit: "contain" }}
          alt="Profil"
        />
      ) : undefined}
      <Typography variant="h3" sx={{ textTransform: "uppercase", fontSize: "1.8em", fontWeight: "bolder", margin: 0 }}>
        {teamMember.firstName} {teamMember.lastName}
      </Typography>
      <Typography
        color="#8ECDED"
        sx={{ textTransform: "uppercase", fontSize: "1.4em", fontWeight: "bold", margin: 0, textAlign: "center" }}
        variant="h4">
        {teamMember.typeOfMember === TeamMemberType.staff
          ? t(`role.${teamMember.infos?.role}`)
          : t(`playerPositions.${teamMember.infos?.position}`)}
      </Typography>
    </Card>
  );
};

export default AbTeamMemberViewer;
