import { createContext, FC, PropsWithChildren, useEffect, useMemo, useRef } from "react";
import mloptions from "src/helpers/ConnexionCloudinary";
import { log } from "../Logger";

declare const window: any;

export const DamContext = createContext<{
  showMediaLibrary: (callback: (result: any) => void, hideCallback: () => void) => void;
}>({
  showMediaLibrary: () => {},
});

export const DamProvider: FC<PropsWithChildren> = ({ children }) => {
  const ml = useRef<any>();
  const pictureHandler = useRef<(result: any) => void>((result: any) => {
    log.debug(result);
  });
  const hideHandler = useRef<() => void>(() => {
    log.debug("Hide!");
  });

  useEffect(() => {
    ml.current = window.cloudinary?.createMediaLibrary?.(mloptions, {
      insertHandler: (result: any) => {
        pictureHandler.current(result);
      },
      hideHandler: () => {
        hideHandler.current();
      },
    });
  }, []);

  const contextValue = useMemo(() => {
    const showMediaLibrary = (callback: (result: any) => void, hideCallback: () => void): void => {
      pictureHandler.current = callback;
      hideHandler.current = hideCallback;
      ml.current.show();
    };
    return { showMediaLibrary };
  }, []);

  return <DamContext.Provider value={contextValue}>{children}</DamContext.Provider>;
};
