import { Modal, Paper } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import Lottie from "react-lottie";
import "./zipLoader.scss";
import zipLoader from "./zipLoader.json";

interface ZipLoaderProps {
  open: boolean;
  numberOfTickets: number;
}

const ZipLoader: FC<ZipLoaderProps> = ({ open, numberOfTickets }) => {
  const { t } = useTranslation("ticket");
  const zipLoaderOptions = {
    loop: true,
    autoplay: true,
    animationData: zipLoader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const estimatedSeconds = Math.round(numberOfTickets / 2);

  return (
    <Modal open={open} className="ab-modal-root">
      <Paper sx={{ borderRadius: "25px" }}>
        <h5 className="zipLoader__title">{t("zipLoader", { estimatedSeconds, numberOfTickets })}</h5>
        <Lottie
          options={zipLoaderOptions}
          height={window.innerWidth < 900 ? 200 : 400}
          width={window.innerWidth < 900 ? 200 : 400}
        />
      </Paper>
    </Modal>
  );
};

export default ZipLoader;
