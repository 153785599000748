import { FC, useContext, useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import SignInPage from "../auth/SignInPage";
import AbCenteredContent from "../components/AbCenteredContent";
import { AuthContext } from "../data/contexts/AuthContext";
import { DataContext } from "../data/contexts/DataContext";
import TabsContextProvider from "../data/contexts/TabsContext";
import Navigation from "../nav/Navigation";
import "./App.scss";

let launched = false;

const App: FC = () => {
  const [loading, setLoading] = useState(true);
  const { auth } = useContext(AuthContext);
  const { initApp } = useContext(DataContext);

  useEffect(() => {
    if (!launched) {
      launched = true;
      const init = async (): Promise<void> => {
        await initApp();
        setLoading(false);
      };
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {loading ? (
        <AbCenteredContent loading />
      ) : auth ? (
        <Router>
          <TabsContextProvider>
            <Navigation />
          </TabsContextProvider>
        </Router>
      ) : (
        <SignInPage />
      )}
    </div>
  );
};

export default App;
