import { Modal, Paper, Typography, FormControl, Select, InputLabel, MenuItem } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import AbButton from "../../components/AbButton";
import AbHtmlTextField from "../../components/AbHtmlTextField";
import AbModificationAlert from "../../components/AbModificationAlert";
import { DataContext } from "../../data/contexts/DataContext";
import { formatDate, getTodayTimestamp } from "../../data/Converters";
import { Conditions, ConditionsApplication, ConditionsType } from "../../data/types/ApiTypes";

interface ConditionsModalProps {
  open: boolean;
  onClose: () => void;
  conditionsId: string;
}

const ConditionsModal: FC<ConditionsModalProps> = ({ open, onClose, conditionsId }) => {
  const { conditions: allConditions, createConditions, updateConditions } = useContext(DataContext);
  const originalConditions = allConditions.find((c) => c.id === conditionsId);
  const [conditions, setConditions] = useState<Conditions | null>(originalConditions || null);
  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation(["global", "conditions"]);
  const today = getTodayTimestamp();

  useEffect(() => {
    if (conditionsId.length === 0) {
      setConditions({ id: uuidv4(), type: undefined, body: "", version: today });
    } else {
      const newOriginalConditions = allConditions.find((c) => c.id === conditionsId);
      if (newOriginalConditions) setConditions({ ...newOriginalConditions });
      setModified(false);
    }
  }, [conditionsId, today, allConditions]);

  const onTryClose = (): void => {
    if (!modified) onClose();
    else setModifiedAlertOpen(true);
  };

  const sendConditionsToApi = async (): Promise<void> => {
    if (conditions) {
      setSending(true);
      if (conditionsId.length === 0) await createConditions(conditions);
      else await updateConditions(conditions);
      setSending(false);
      onClose();
    }
  };

  const creation = conditionsId.length === 0;
  const conditionsVersionExists =
    allConditions.findIndex(
      (c) => c.version === today && c.type === conditions?.type && c.application === conditions?.application,
    ) !== -1;
  const versionAsDay = formatDate(today);

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" style={{ width: creation ? undefined : 1050, minHeight: 100 }}>
        {creation ? (
          <>
            <FormControl className="margin-bottom">
              <InputLabel id="conditionsType-select-label">{t("conditions:type")}</InputLabel>
              <Select
                labelId="conditionsType-select-label"
                label={t("conditions:type")}
                variant="outlined"
                value={conditions?.type || null}
                onChange={(e) =>
                  setConditions({
                    ...conditions,
                    type: e.target.value as ConditionsType,
                    id: conditions?.id || uuidv4(),
                  })
                }>
                <MenuItem value={ConditionsType.cgu}>
                  <em>{t("conditions:choose")}</em>
                </MenuItem>
                {ConditionsType !== null &&
                  Object.keys(ConditionsType).map((type) => (
                    <MenuItem key={type} value={type}>
                      {t(`conditions:${type}`)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl className="margin-bottom">
              <InputLabel id="conditionsApplication-select-label">{t("conditions:application")}</InputLabel>
              <Select
                labelId="conditionsApplication-select-label"
                label={t("conditions:type")}
                variant="outlined"
                value={conditions?.application || null}
                onChange={(e) =>
                  setConditions({
                    ...conditions,
                    application: e.target.value as ConditionsApplication,
                    id: conditions?.id || uuidv4(),
                  })
                }>
                <MenuItem value={ConditionsApplication.website}>
                  <em>{t("conditions:choose")}</em>
                </MenuItem>
                {ConditionsApplication !== null &&
                  Object.keys(ConditionsApplication).map((application) => (
                    <MenuItem key={application} value={application}>
                      {t(`conditions:${application}`)}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {conditions?.type && conditions?.application ? (
              conditionsVersionExists ? (
                <Typography variant="h6" className="margin-bottom returns center">
                  {t("conditions:versionExists", {
                    replace: { conditionsName: t(`conditions:${conditions.type}`) },
                  })}
                </Typography>
              ) : (
                <div>
                  <AbButton
                    label={t(conditionsVersionExists ? "cancel" : "conditions:new", {
                      replace: { conditionsName: t(`conditions:${conditions.type}`) },
                    })}
                    onClick={creation && conditionsVersionExists ? onClose : sendConditionsToApi}
                    isLoading={sending}
                    disabled={!modified && !creation}
                  />
                </div>
              )
            ) : (
              <AbButton label={t("conditions:buttonLabel")} color="inherit" isLoading={sending} />
            )}
          </>
        ) : (
          <>
            <Typography variant="h6" className="margin-bottom">
              {t("conditions:modifyConditions", {
                replace: { conditionsName: t(`conditions:${conditions?.type}`), version: versionAsDay },
              })}
            </Typography>
            <AbHtmlTextField
              strict={false}
              value={conditions?.body || ""}
              setValue={(v) => {
                setConditions({ id: conditions?.id || "", ...conditions, body: v });
                setModified(true);
              }}
            />
            <div className="margin-top">
              <AbButton
                label={t(creation ? (conditionsVersionExists ? "cancel" : "conditions:create") : "conditions:save")}
                onClick={creation && conditionsVersionExists ? onClose : sendConditionsToApi}
                isLoading={sending}
                disabled={!modified && !creation}
              />
            </div>
          </>
        )}
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalConditions) setConditions({ ...originalConditions });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

export default ConditionsModal;
