import { FC, useState } from "react";
import { Component } from "../../data/types/ApiTypes";
import { ImageType } from "../../data/types/ClientTypes";
import Image from "../Image";
import VideoModal from "../modal/VideoModal";
import styles from "./videoEmbed.module.scss";

// Magic string:
const YOUTUBE_ID_URL_KEY = "v=";

const VideoEmbed: FC<Component> = ({ videoUrl, picture, title }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  let videoId = videoUrl;

  if (videoUrl?.match("youtube.com")) {
    const getVideoId = videoUrl?.match(/(?:v=)([a-zA-Z0-9]*)/gi);
    videoId = getVideoId?.[0].slice(YOUTUBE_ID_URL_KEY.length);
  }
  return (
    <>
      <button type="button" className={styles.video__card} onClick={() => setIsPlaying(true)}>
        <Image
          picture={picture}
          alt="Image vidéo"
          className={styles.video__card__poster}
          type={ImageType.VideoThumbnail}
        />
        <div className={styles.video__card__overlay} />
        <div className={styles.video__card__body}>
          <div className={styles.video__card__content}>
            <h4 className={styles.video__card__body__title}>{title}</h4>
          </div>
        </div>
        <div className={styles.video__card__playContainer}>
          <img src="/play.svg" alt="" className={styles.video__card__play} />
        </div>
      </button>
      <VideoModal open={isPlaying} onClose={() => setIsPlaying(false)} videoId={videoId || ""} />
    </>
  );
};

export default VideoEmbed;
