import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatShortDate } from "src/data/Converters";
import { Article } from "src/data/types/ApiTypes";
import { ImageType } from "src/data/types/ClientTypes";
import Image from "../Image";
import styles from "./newsSlider.module.scss";

interface ArticleProps {
  article: Article;
  chrome?: boolean;
}

const ArticleComponent: FC<ArticleProps> = ({ article, chrome }) => {
  const { t } = useTranslation("news");

  return (
    <div className={styles.article}>
      <Image
        picture={article.pictures?.[0]}
        alt={article.title || ""}
        className={`${styles.article__picture} ${!chrome ? styles.noChrome : ""}`}
        type={ImageType.NewsThumbnail}
      />
      <div className={styles.article__filter}>
        {article.category !== undefined ? (
          <span className={styles.article__category}>{t(`category.${article.category}`)}</span>
        ) : undefined}
        <h3 className={styles.article__title}>{article.title}</h3>
        {article.publicationDate ? (
          <span className={styles.article__date}>{formatShortDate(article.publicationDate)}</span>
        ) : undefined}
      </div>
    </div>
  );
};

ArticleComponent.defaultProps = {
  chrome: undefined,
};

export default ArticleComponent;
