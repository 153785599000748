export const getEnvVariable = (envVar?: string, fallback?: string): string =>
  envVar || fallback || "missingEnvVariable";

export enum Env {
  production = "production",
  staging = "staging",
  dev = "dev",
}

export const getEnv = (): Env =>
  process.env.REACT_APP_ENV === "production"
    ? Env.production
    : process.env.REACT_APP_ENV === "staging"
    ? Env.staging
    : Env.dev;

export const getEnvName = (): string => {
  switch (getEnv()) {
    case Env.production:
      return "Production";
    case Env.staging:
      return "Staging";
    default:
      return "Dev";
  }
};

export const getFrontUrl = (suffix: string): string => {
  const baseUrl = getEnvVariable(process.env.REACT_APP_FRONT_URL);
  return `${baseUrl}${suffix}`;
};
