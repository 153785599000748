import { Splide, SplideSlide } from "@splidejs/react-splide";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Article, ButtonIconProps, Component } from "src/data/types/ApiTypes";
import LinkButton from "../linkButton/LinkButton";
import ArticleComponent from "./Article";
import styles from "./newsSlider.module.scss";

const NewsSlider: FC<Component> = ({ data, title, slider, newsCategory }) => {
  const { t } = useTranslation("news");
  const articles = data !== undefined ? (data as Article[]) : [];

  return (
    <div className={styles.newsSlider}>
      <h2 className={styles.newsSlider__title}>{title}</h2>
      {slider ? (
        <div className={styles.newsSlider__containerSlide}>
          <Splide
            options={{
              width: "100%",
              type: "slide",
              perMove: 1,
              autoplay: false,
              drag: "free",
              gap: "8px",
              pagination: false,
              snap: true,
              rewind: true,
              perPage: 3,
              fixedWidth: "340px",
              breakpoints: {
                990: { perPage: 2 },
                768: { perPage: 1, padding: { right: "20%", left: "8%" }, width: "100vw" },
              },
            }}>
            {articles.map((a) => (
              <SplideSlide key={a.id}>
                <ArticleComponent article={a} />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      ) : (
        <div className={styles.newsSlider__container}>
          {articles?.map((a) => (
            <ArticleComponent key={a.id} article={a} />
          ))}
        </div>
      )}
      <LinkButton
        label={t("allNews")}
        href={`/news/articles/${newsCategory !== undefined ? newsCategory : "tous"}/1`}
        icon={ButtonIconProps.arrowForward}
      />
    </div>
  );
};

export default NewsSlider;
