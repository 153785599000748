import {
  Article,
  Campaign,
  Chat,
  ChevronLeft,
  Dashboard,
  Gavel,
  HolidayVillage,
  LocalBar,
  MonetizationOn,
  Newspaper,
  PeopleAlt,
  PlaylistAddCheck,
  Scoreboard,
  ShoppingCart,
  SportsRugby,
} from "@mui/icons-material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import {
  Badge,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Drawer as MuiDrawer,
  Theme,
  Tooltip,
  styled,
} from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataContext } from "src/data/contexts/DataContext";
import { WithMandatoryChildren } from "../data/types/TechnicalTypes";

export const drawerWidth = 280;

const openedMixin = (theme: Theme): Record<string, unknown> => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): Record<string, unknown> => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

interface AppDrawerProps {
  open: boolean;
  closeDrawer: () => void;
  nav: (to: string) => void;
}

interface ItemWrapperProps extends WithMandatoryChildren {
  title: string;
  tooltip: boolean;
}

const ItemWrapper: FC<ItemWrapperProps> = ({ title, tooltip, children }) =>
  tooltip ? (
    <Tooltip title={title} arrow placement="right">
      {children}
    </Tooltip>
  ) : (
    children
  );

const AppDrawer: FC<AppDrawerProps> = ({ open, closeDrawer, nav }) => {
  const { t } = useTranslation("global");
  const { conversations } = useContext(DataContext);
  const [numberToProcess, setNumberToProcess] = useState<number | null>(null);

  useEffect(() => {
    setNumberToProcess(conversations.filter((c) => c.isHandled === false).length);
  }, [conversations, numberToProcess]);
  return (
    <Drawer anchor="left" open={open} onClose={closeDrawer} variant="permanent">
      <div role="presentation">
        <DrawerHeader>
          <IconButton onClick={closeDrawer}>
            <ChevronLeft />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ItemWrapper title={t("dashboard")} tooltip={!open}>
            <ListItemButton key="dashboard" onClick={() => nav("/")}>
              <ListItemIcon>
                <Dashboard />
              </ListItemIcon>
              <ListItemText primary={t("dashboard")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./news")} tooltip={!open}>
            <ListItemButton key="news" onClick={() => nav("/news")}>
              <ListItemIcon>
                <Newspaper />
              </ListItemIcon>
              <ListItemText primary={t("titles./news")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./matches")} tooltip={!open}>
            <ListItemButton key="matches" onClick={() => nav("/matches")}>
              <ListItemIcon>
                <Scoreboard />
              </ListItemIcon>
              <ListItemText primary={t("titles./matches")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./team")} tooltip={!open}>
            <ListItemButton key="team" onClick={() => nav("/team")}>
              <ListItemIcon>
                <SportsRugby />
              </ListItemIcon>
              <ListItemText primary={t("titles./team")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./partners")} tooltip={!open}>
            <ListItemButton key="partners" onClick={() => nav("/partners")}>
              <ListItemIcon>
                <PeopleAlt />
              </ListItemIcon>
              <ListItemText primary={t("titles./partners")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./conversations")} tooltip={!open}>
            <ListItemButton key="conversations" onClick={() => nav("/conversations")}>
              <ListItemIcon>
                <Badge badgeContent={numberToProcess} color="primary">
                  <Chat />
                </Badge>
              </ListItemIcon>
              <ListItemText primary={t("titles./conversations")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./events")} tooltip={!open}>
            <ListItemButton key="events" onClick={() => nav("/events")}>
              <ListItemIcon>
                <LocalBar />
              </ListItemIcon>
              <ListItemText primary={t("titles./events")} />
            </ListItemButton>
          </ItemWrapper>
          {process.env.REACT_APP_FEATURE_FLIPPING_ROOM_BOOKING === "true" ? (
            <ItemWrapper title={t("titles./roomBooking")} tooltip={!open}>
              <ListItemButton key="pages" onClick={() => nav("/roomBooking")}>
                <ListItemIcon>
                  <EditCalendarIcon />
                </ListItemIcon>
                <ListItemText primary={t("titles./roomBooking")} />
              </ListItemButton>
            </ItemWrapper>
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_ROOM === "true" ? (
            <ItemWrapper title={t("titles./rooms")} tooltip={!open}>
              <ListItemButton key="rooms" onClick={() => nav("/rooms")}>
                <ListItemIcon>
                  <HolidayVillage />
                </ListItemIcon>
                <ListItemText primary={t("titles./rooms")} />
              </ListItemButton>
            </ItemWrapper>
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_TODO === "true" ? (
            <ItemWrapper title={t("titles./todo")} tooltip={!open}>
              <ListItemButton key="todo" onClick={() => nav("/todo")}>
                <ListItemIcon>
                  <PlaylistAddCheck />
                </ListItemIcon>
              </ListItemButton>
            </ItemWrapper>
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_SHOP === "true" ? (
            <ItemWrapper title={t("titles./shop")} tooltip={!open}>
              <ListItemButton key="shop" onClick={() => nav("/shop")}>
                <ListItemIcon>
                  <ShoppingCart />
                </ListItemIcon>
                <ListItemText primary={t("titles./shop")} />
              </ListItemButton>
            </ItemWrapper>
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_NOTIFICATION === "true" ? (
            <ItemWrapper title={t("titles./notification")} tooltip={!open}>
              <ListItemButton key="notification" onClick={() => nav("/notification")}>
                <ListItemIcon>
                  <Campaign />
                </ListItemIcon>
                <ListItemText primary={t("titles./notification")} />
              </ListItemButton>
            </ItemWrapper>
          ) : undefined}
          {process.env.REACT_APP_FEATURE_FLIPPING_SPONSORING === "true" ? (
            <ItemWrapper title={t("titles./sponsoring")} tooltip={!open}>
              <ListItemButton key="rooms" onClick={() => nav("/sponsoring")}>
                <ListItemIcon>
                  <MonetizationOn />
                </ListItemIcon>
                <ListItemText primary={t("titles./sponsoring")} />
              </ListItemButton>
            </ItemWrapper>
          ) : undefined}
          <ItemWrapper title={t("titles./conditions")} tooltip={!open}>
            <ListItemButton key="conditions" onClick={() => nav("/conditions")}>
              <ListItemIcon>
                <Gavel />
              </ListItemIcon>
              <ListItemText primary={t("titles./conditions")} />
            </ListItemButton>
          </ItemWrapper>
          <ItemWrapper title={t("titles./pages")} tooltip={!open}>
            <ListItemButton key="pages" onClick={() => nav("/pages")}>
              <ListItemIcon>
                <Article />
              </ListItemIcon>
              <ListItemText primary={t("titles./pages")} />
            </ListItemButton>
          </ItemWrapper>
        </List>
        <Divider />
      </div>
    </Drawer>
  );
};

export default AppDrawer;
