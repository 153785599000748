import { CameraAlt, VisibilityOff } from "@mui/icons-material";
import { Card, CardActionArea, Typography, styled } from "@mui/material";
import { FC } from "react";
import { Picture } from "../data/types/ApiTypes";
import CloudinaryIdToImage from "../helpers/CloudinaryIdToImage";

const NewsData = styled("div")({
  display: "flex",
  flexDirection: "column",
  flex: 1,
  justifyContent: "center",
  marginLeft: "16px",
});

export interface ListItemData {
  pictureUrl?: Picture;
  title: string;
  subtitle?: string;
  published?: boolean;
  noPicture?: boolean;
  noPublication?: boolean;
  picture?: JSX.Element;
  handled?: boolean;
}

const AbListItem: FC<{ data: ListItemData; onClick: () => void; selected: boolean }> = ({
  data,
  onClick,
  selected,
}) => (
  <Card
    variant="outlined"
    className={`list-card${selected ? " selected" : ""}${data.handled === false ? " notHandled" : ""}`}>
    <CardActionArea onClick={onClick} style={{ height: "100%" }}>
      <div className={`list-card-root row ${selected ? "selected" : ""}`}>
        {data.noPicture ? undefined : (
          <div className="thumbnail">
            {data.picture ? (
              data.picture
            ) : data.pictureUrl && data.pictureUrl.url ? (
              <img
                src={CloudinaryIdToImage(data.pictureUrl?.url || "", 80, 80, "fill")}
                width="80"
                height="80"
                style={{ objectFit: "cover" }}
                alt="Article"
              />
            ) : (
              <CameraAlt htmlColor="#fff" fontSize="medium" />
            )}
          </div>
        )}
        <NewsData style={data.noPicture ? { height: 80 } : undefined}>
          <Typography sx={{ fontWeight: data.handled === false ? "600" : "400" }}>{data.title}</Typography>
          {data.subtitle ? <Typography variant="subtitle2">{data.subtitle}</Typography> : undefined}
        </NewsData>
      </div>
      {!data.published && !data.noPublication ? (
        <div style={{ position: "absolute", bottom: 8, right: 8 }}>
          <VisibilityOff color="disabled" />
        </div>
      ) : undefined}
    </CardActionArea>
  </Card>
);

export default AbListItem;
