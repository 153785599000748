import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import { FC, SyntheticEvent, useState } from "react";
import { Component } from "../../data/types/ApiTypes";
import styles from "./faq.module.scss";

const Faq: FC<Component> = ({ title, textItems }) => {
  const [expanded, setExpanded] = useState<string | false>();
  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div className={styles.faq}>
      {title ? (
        <Typography variant="h2" className={styles.faq__title}>
          {title}
        </Typography>
      ) : undefined}
      {textItems?.map((t) => (
        <Accordion expanded={expanded === t.id} onChange={handleChange(t.id)} key={t.id}>
          <AccordionSummary expandIcon={<ExpandMore />}>
            <Typography variant="h6" className={styles.faq__darkText}>
              {t.title}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <div
              className={`${styles.faq__darkText} ql-editor`}
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: t.description || "" }}
            />
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Faq;
