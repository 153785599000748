import {
  // Add,
  //  BarChart, Delete,
  Edit,
  // ViewCarousel, Visibility, VisibilityOff
} from "@mui/icons-material";
import {
  CircularProgress,
  //  Fab,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbTwoCards } from "src/components/AbBaseCard";
import AbCenteredContent from "src/components/AbCenteredContent";
// import AbDeleteAlert from "src/components/AbDeleteAlert";
import AbFilter from "src/components/AbFilter";
import AbItemsList from "src/components/AbItemsList";
import AbListItem from "src/components/AbListItem";
import B2bTicket from "src/components/banners/B2bTicketTemplate";
// import B2bFakeContent from "src/components/banners/B2bFakeContent";
// import B2bHomePage from "src/components/banners/B2bHomePage";
// import WebFakeContent from "src/components/banners/WebFakeContent";
// import WebHomePageBigBanner from "src/components/banners/WebHomePageBigBanner";
// import WebHomePageSmallBanner from "src/components/banners/WebHomePageSmallBanner";
import { compareWithoutAccent } from "src/data/Converters";
import { DataContext } from "src/data/contexts/DataContext";
import { Sponsoring } from "src/data/types/ApiTypes";
import SponsoringModal from "src/modals/SponsoringModal";
// import StatsAdsModal from "src/modals/SponsoringModal/statsAdsModal";

const SponsoringPage: FC = () => {
  const {
    sponsorings,
    // deleteSponsoring,
    refreshSponsorings,
    // publishSponsoring
  } = useContext(DataContext);
  const { t } = useTranslation(["global", "sponsoring"]);
  const [filter, setFilter] = useState("");
  const [currentItem, setCurrentItem] = useState<Sponsoring | null>(null);
  const [modifying, setModifying] = useState(false);
  // const [statsIsOpen, setStatsIsOpen] = useState(false);
  // const [deleting, setDeleting] = useState(false);
  // const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  // const [publishing, setPublishing] = useState(false);
  const [published, setPublished] = useState<boolean | null>(null);
  // const noBanner =
  //   !currentItem?.adLocations?.b2bFakeContent.isActive &&
  //   !currentItem?.adLocations?.b2bHomePage.isActive &&
  //   !currentItem?.adLocations?.webFakeContent.isActive &&
  //   !currentItem?.adLocations?.webHomePageBigBanner.isActive &&
  //   !currentItem?.adLocations?.webHomePageSmallBanner.isActive;

  const filteredSponsorings: Sponsoring[] = useMemo(() => {
    const sponsoringTmp = sponsorings
      .filter((s) => compareWithoutAccent(s.title || "", filter))
      .filter((s) =>
        published === null
          ? s.published || !s.published || !s.published === undefined
          : published
          ? s.published
          : !s.published || !s.published === undefined,
      );
    return sponsoringTmp;
  }, [filter, sponsorings, published]);

  useEffect(() => {
    if (currentItem) {
      const newCurrent = filteredSponsorings.find((s) => s.id === currentItem.id);
      if (newCurrent) setCurrentItem(newCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSponsorings]);

  // const publish = async (): Promise<void> => {
  //   if (!currentItem) return;
  //   setPublishing(true);
  //   await publishSponsoring(currentItem.id, !currentItem.published);
  //   setPublishing(false);
  // };

  const refreshAndEdit = async (): Promise<void> => {
    if (!currentItem) return;
    setRefreshing(true);
    await refreshSponsorings(currentItem.id);
    setRefreshing(false);
    setModifying(true);
  };

  // const deleteCurrentItem = async (): Promise<void> => {
  //   setDeleting(true);
  //   await deleteSponsoring(currentItem?.id || "");
  //   setDeleting(false);
  //   setCurrentItem(null);
  // };

  // const tryDelete = async (): Promise<void> => {
  //   setDeleteAlertOpen(true);
  // };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: "1.4em",
        }}>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "1000px" }}>
          <AbFilter
            textSearch
            textSearchString={filter}
            textSearchLabel={t("sponsoring:search")}
            setTextSearchString={setFilter}
            togglePublished
            published={published}
            setPublished={setPublished}
          />
        </div>
      </div>
      {filteredSponsorings.length > 0 ? (
        <AbTwoCards
          left={
            <AbItemsList
              items={filteredSponsorings}
              renderRow={(s: Sponsoring) => (
                <AbListItem
                  data={{
                    pictureUrl: s.pictures?.b2bTicket1 && s.pictures.b2bTicket2,
                    // s.pictures?.square ||
                    // s.pictures?.article ||
                    // s.pictures?.webSmallBanner ||
                    // s.pictures?.b2bHomeBanner ||
                    // s.pictures?.bigBanner,
                    title: s.title ? s.title : "",
                    published: s.published,
                  }}
                  onClick={() => setCurrentItem(s)}
                  selected={currentItem?.id === s.id}
                  key={s.id}
                />
              )}
            />
          }
          right={
            currentItem ? (
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "90%",
                  }}>
                  <Typography
                    variant="h3"
                    sx={{ textTransform: "uppercase", fontSize: "1.8em", fontWeight: "bolder", margin: 2 }}>
                    {currentItem.title}
                  </Typography>
                  {/* {noBanner ? (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                      <ViewCarousel fontSize="large" />
                      <Typography textAlign="center">{t("sponsoring:noBanner")}</Typography>
                    </div>
                  ) : undefined}
                  {currentItem.adLocations?.b2bHomePage.isActive ? <B2bHomePage sponsoring={currentItem} /> : undefined}
                  {currentItem.adLocations?.b2bFakeContent.isActive ? (
                    <B2bFakeContent sponsoring={currentItem} />
                  ) : undefined}
                  {currentItem.adLocations?.webHomePageBigBanner.isActive ? (
                    <WebHomePageBigBanner sponsoring={currentItem} />
                  ) : undefined}
                  {currentItem.adLocations?.webHomePageSmallBanner.isActive ? (
                    <WebHomePageSmallBanner sponsoring={currentItem} />
                  ) : undefined}
                  {currentItem.adLocations?.webFakeContent.isActive ? (
                    <WebFakeContent sponsoring={currentItem} />
                  ) : undefined} */}
                  {currentItem.adLocations?.b2bTicket.isActive ? <B2bTicket sponsoring={currentItem} /> : undefined}
                </div>

                <div className="margin-left" style={{ width: 40 }}>
                  <Tooltip title={t("sponsoring:modify")} arrow>
                    <IconButton onClick={refreshAndEdit}>
                      {refreshing ? <CircularProgress color="secondary" size={24} /> : <Edit />}
                    </IconButton>
                  </Tooltip>
                  {/* <Tooltip
                    title={t(`sponsoring:${currentItem?.published ? "un" : ""}publish`)}
                    arrow
                    className="margin-top">
                    <IconButton onClick={publish} disabled={publishing}>
                      {publishing ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : currentItem?.published ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("sponsoring:delete")} arrow className="margin-top">
                    <IconButton onClick={tryDelete}>
                      {deleting ? <CircularProgress color="secondary" size={24} /> : <Delete />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("sponsoring:stats:stats")} arrow className="margin-top">
                    <IconButton onClick={() => setStatsIsOpen(true)}>
                      <BarChart />
                    </IconButton>
                  </Tooltip>
                  <AbDeleteAlert
                    open={deleteAlertOpen}
                    onClose={() => {
                      setDeleteAlertOpen(false);
                    }}
                    onConfirm={() => {
                      setDeleteAlertOpen(false);
                      deleteCurrentItem();
                    }}
                  /> */}
                </div>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
          rightWidth={550}
        />
      ) : (
        <AbCenteredContent infoText={t("selectItem")} />
      )}
      {/* <Fab
        color="primary"
        style={{ position: "absolute", bottom: 32, right: 48 }}
        onClick={() => {
          setCurrentItem(null);
          setModifying(true);
        }}>
        <Add />
      </Fab> */}
      <SponsoringModal id={currentItem?.id || ""} open={modifying} onClose={() => setModifying(false)} />
      {/* <StatsAdsModal open={statsIsOpen} onClose={() => setStatsIsOpen(false)} /> */}
    </>
  );
};

export default SponsoringPage;
