import { FC } from "react";
import { ComponentWithPageWidth } from "../../data/types/ApiTypes";
import Image from "../Image";
import LinkButton from "../linkButton/LinkButton";
import styles from "./contentCard.module.scss";

const ContentCard: FC<ComponentWithPageWidth> = ({
  title,
  htmlContent,
  picturePosition,
  cropPicture,
  picture,
  actionTitle,
  actionUrl,
  chrome,
  backgroundColor,
  pageMaxWidth,
}) => {
  const fullWidth = typeof backgroundColor !== "undefined";
  return (
    <div
      className={`${styles.contentCard} ${!chrome ? styles.noChrome : ""}`}
      style={{
        background: fullWidth ? backgroundColor : "",
        padding: `0 calc((100% - ${pageMaxWidth}px) / 2)`,
      }}>
      {picture ? (
        <div
          className={`${styles.contentCard__containerImg}${picturePosition === "start" ? ` ${styles.imageLeft}` : ""}`}>
          <Image
            className={styles.contentCard__image}
            picture={picture}
            width={450}
            height={450}
            crop={cropPicture}
            alt=""
          />
        </div>
      ) : undefined}
      <div className={`${styles.contentCard__containerTxt} ${!chrome ? styles.noChrome__containerTxt : ""}`}>
        {title ? <h2 className={styles.contentCard__containerTxt__title}>{title}</h2> : undefined}
        {htmlContent ? (
          // eslint-disable-next-line react/no-danger
          <div className={styles.htmlContent} dangerouslySetInnerHTML={{ __html: htmlContent }} />
        ) : undefined}

        {actionTitle && actionUrl ? (
          <LinkButton label={actionTitle} href={actionUrl} style={!picture ? { alignSelf: "center" } : undefined} />
        ) : undefined}
      </div>
    </div>
  );
};

export default ContentCard;
