import { Typography } from "@mui/material";
import { FC, useContext } from "react";
import AbBaseCard from "../components/AbBaseCard";
import { DataContext } from "../data/contexts/DataContext";

const DashboardPage: FC = () => {
  const { partners, news, conversations, users, events, matches, partnersLists } = useContext(DataContext);
  return (
    <AbBaseCard>
      <div>
        <Typography>Nombre de partenaires : {partners.length}</Typography>
        <Typography>Nombre d&apos;actualités : {news.length}</Typography>
        <Typography>Nombre de conversations : {conversations.length}</Typography>
        <Typography>Nombre d&apos;utilisateurs : {users.length}</Typography>
        <Typography>Nombre d&apos;évènements : {events.length}</Typography>
        <Typography>Nombre de matchs : {matches.length}</Typography>
        <Typography>Nombre de listes de partenaires : {partnersLists.length}</Typography>
      </div>
    </AbBaseCard>
  );
};

export default DashboardPage;
