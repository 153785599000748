import { Modal, Paper, TextField, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "src/components/AbButton";
import { DataContext } from "src/data/contexts/DataContext";
import { User } from "src/data/types/ApiTypes";

interface UserUpdateModalProps {
  open: boolean;
  onClose: () => void;
  user?: User;
}

const UserUpdateModal: FC<UserUpdateModalProps> = ({ open, onClose, user }) => {
  const { updateUser } = useContext(DataContext);
  const { t } = useTranslation("partner");
  const tempUser = {
    id: user?.id || "",
    active: user?.active,
    admin: user?.admin,
    referent: user?.referent,
    username: user?.username,
    partnersId: user?.partnersId,
    firstName: user?.firstName,
    lastName: user?.lastName,
    digitickId: user?.digitickId,
    ticketsId: user?.ticketsId,
    todoAssigneee: user?.todoAssigneee,
  };
  const [data, setData] = useState<User>(user || tempUser);

  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    setData(user || tempUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  const sendUserData = async (): Promise<void> => {
    setUpdating(true);
    if (typeof data !== "undefined") await updateUser(data);
    setUpdating(false);
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper">
        <Typography variant="h6" className="center margin-bottom returns">
          {t("updateUser.title")}
        </Typography>
        <TextField
          label={t("updateUser.firstName")}
          className="big-margin-bottom"
          value={data.firstName}
          onChange={(e) => setData({ ...data, firstName: e.target.value })}
        />
        <TextField
          label={t("updateUser.lastName")}
          className="big-margin-bottom"
          value={data.lastName}
          onChange={(e) => setData({ ...data, lastName: e.target.value })}
        />
        <AbButton
          label={t("updateUser.save")}
          onClick={sendUserData}
          isLoading={updating}
          disabled={data?.firstName?.length === 0 || data?.lastName?.length === 0}
        />
      </Paper>
    </Modal>
  );
};

export default UserUpdateModal;

UserUpdateModal.defaultProps = {
  user: {
    id: "",
  },
};
