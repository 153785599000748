import { Add, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Card,
  CircularProgress,
  Fab,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbTwoCards } from "../components/AbBaseCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbFilter from "../components/AbFilter";
import AbItemsList from "../components/AbItemsList";
import AbListItem from "../components/AbListItem";
import { formatDate } from "../data/Converters";
import { DataContext } from "../data/contexts/DataContext";
import { Conditions, ConditionsApplication, ConditionsType } from "../data/types/ApiTypes";
import ConditionsModal from "../modals/ConditionsModal";

const selectAll = "selectAll";

const ConditionsPage: FC = () => {
  const [currentItem, setCurrentItem] = useState<Conditions | null>(null);
  const [modifying, setModifying] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [typeOfConditions, setTypeOfConditions] = useState<ConditionsType | string>(selectAll);
  const [applicationSupport, setApplicationSupport] = useState<ConditionsApplication | string>(selectAll);
  const [published, setPublished] = useState<boolean | null>(null);
  const { conditions, publishConditions } = useContext(DataContext);
  const { t } = useTranslation(["global", "conditions"]);

  const filteredConditions: Conditions[] = useMemo(() => {
    const conditionsTmp = conditions
      .filter((c) => (typeOfConditions !== selectAll ? c.type === typeOfConditions : true))
      .filter((c) => (applicationSupport !== selectAll ? c.application === applicationSupport : true))
      .filter((c) =>
        published === null
          ? c.published || !c.published || !c.published === undefined
          : published
          ? c.published
          : !c.published || !c.published === undefined,
      );
    return conditionsTmp;
  }, [conditions, typeOfConditions, applicationSupport, published]);

  useEffect(() => {
    if (currentItem) {
      const newCurrent = filteredConditions.find((c) => c.id === currentItem.id);
      if (newCurrent) setCurrentItem(newCurrent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredConditions]);

  const publish = async (): Promise<void> => {
    if (!currentItem) return;
    setPublishing(true);
    await publishConditions(currentItem.id, !currentItem.published);
    setPublishing(false);
  };

  const sortByDate = (a: Conditions, b: Conditions): number => {
    if (a?.version && b?.version) {
      if (a.version > b.version) {
        return -1;
      }
      if (a.version < b.version) {
        return 1;
      }
      return 0;
    }
    return 0;
  };

  const handleChangeApplicationSupport = (event: SelectChangeEvent): void => {
    setApplicationSupport(event.target.value === selectAll ? selectAll : (event.target.value as ConditionsApplication));
  };
  const handleChangeTypeOfConditions = (event: SelectChangeEvent): void => {
    setTypeOfConditions(event.target.value === selectAll ? selectAll : (event.target.value as ConditionsType));
  };

  const sortedConditions = filteredConditions.sort(sortByDate);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AbFilter
          textSearch={false}
          togglePublished
          published={published}
          setPublished={setPublished}
          extraFields={
            <div className="row">
              <FormControl sx={{ width: "400px", marginBottom: "1em", marginLeft: "1.2em", marginRight: "1.2em" }}>
                <InputLabel id="input-label-conditionsApplication">
                  {t("conditions:selector.conditionsApplication")}
                </InputLabel>
                <Select
                  value={applicationSupport}
                  labelId="input-label-conditionsApplication"
                  id="select-conditionsApplication"
                  label={t("conditions:selector.conditionsApplication")}
                  onChange={handleChangeApplicationSupport}>
                  <MenuItem key="all" value={selectAll}>
                    {t("conditions:allApplications")}
                  </MenuItem>
                  {Object.keys(ConditionsApplication).map((ct) => (
                    <MenuItem key={ct} value={ct}>
                      {t(`conditions:${ct}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ width: "400px", marginBottom: "1em", marginLeft: "1.2em", marginRight: "1.2em" }}>
                <InputLabel id="input-label-conditionsType">{t("conditions:selector.conditionsType")}</InputLabel>
                <Select
                  value={typeOfConditions}
                  labelId="input-label-conditionsType"
                  id="select-conditionsType"
                  label={t("conditions:selector.conditionsType")}
                  onChange={handleChangeTypeOfConditions}>
                  <MenuItem key="all" value={selectAll}>
                    {t("conditions:allTypes")}
                  </MenuItem>
                  {Object.keys(ConditionsType).map((ct) => (
                    <MenuItem key={ct} value={ct}>
                      {t(`conditions:${ct}`)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          }
        />
      </div>
      {sortedConditions.length > 0 ? (
        <AbTwoCards
          left={
            <AbItemsList
              items={sortedConditions}
              renderRow={(c: Conditions) => (
                <AbListItem
                  key={`conditions_${c.id}`}
                  data={{
                    title: t("conditions:conditionsOf", {
                      replace: {
                        conditionsName: t(`conditions:${c.type}`),
                        version: formatDate(c.version || 0),
                      },
                    }),
                    subtitle: t(`conditions:${c.application}`),
                    published: c.published || false,
                    noPicture: true,
                  }}
                  onClick={() => setCurrentItem(c)}
                  selected={currentItem?.id === c.id}
                />
              )}
            />
          }
          right={
            currentItem ? (
              <div className="row">
                <Card className="card-root article">
                  <Typography variant="h6">
                    {t("conditions:conditionsOf", {
                      replace: {
                        conditionsName: t(`conditions:${currentItem.type}`),
                        version: formatDate(currentItem.version || 0),
                      },
                    })}
                  </Typography>
                  <Typography>{t(`conditions:${currentItem.application}`)}</Typography>
                  <Typography className="margin-bottom " dangerouslySetInnerHTML={{ __html: currentItem.body || "" }} />
                </Card>
                <div className="margin-left" style={{ width: 40 }}>
                  <Tooltip title={t("conditions:modify")} arrow>
                    <IconButton onClick={() => setModifying(true)}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t(`conditions:${currentItem.published ? "un" : ""}publish`)}
                    arrow
                    className="margin-top">
                    <IconButton onClick={publish} disabled={publishing}>
                      {publishing ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : currentItem.published ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
          rightWidth={550}
        />
      ) : (
        <AbCenteredContent infoText={t("noItemYet")} />
      )}
      <Fab
        color="primary"
        style={{ position: "absolute", bottom: 32, right: 48 }}
        onClick={() => {
          setCurrentItem(null);
          setModifying(true);
        }}>
        <Add />
      </Fab>
      <ConditionsModal conditionsId={currentItem?.id || ""} open={modifying} onClose={() => setModifying(false)} />
    </>
  );
};

export default ConditionsPage;
