import { createTheme } from "@mui/material";

export const primaryAccentColor = "#8ECDED";
export const secondaryAccentColor = "#0D1A42";
export const textColor = "#222222";
export const cardsColor = "#FFFFFF";
export const backgroundColor = "#F7F8F8";

const Theme = createTheme({
  typography: {
    fontFamily: "Manrope",
  },
  palette: {
    primary: {
      main: primaryAccentColor,
    },
    secondary: {
      main: secondaryAccentColor,
    },
    info: {
      main: secondaryAccentColor,
    },
  },
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "h1" },
          style: {
            fontSize: "32px",
            fontWeight: 800,
            textTransform: "uppercase",
            margin: "16px 0",
          },
        },
        {
          props: { variant: "h2" },
          style: {
            fontSize: "32px",
            fontWeight: 600,
            margin: "8px 0",
          },
        },
        {
          props: { variant: "h2", textTransform: "uppercase" },
          style: {
            fontSize: "32px",
            fontWeight: 600,
            margin: "8px 0",
            textTransform: "uppercase",
          },
        },
        {
          props: { variant: "body1" },
          style: {
            fontSize: "16px",
            fontWeight: 400,
            margin: "8px 0",
          },
        },
      ],
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: "1em",
          backgroundColor: "rgba(0,0,0,0.7)",
          maxWidth: "400px",
        },
        arrow: {
          color: "rgba(0,0,0,0.7)",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            color: "white",
          },
        },
      ],
    },
  },
});

export default Theme;
