import { AirplaneTicket, Home, Place, Tv } from "@mui/icons-material";
import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Match } from "../data/types/ApiTypes";
import MatchPoster from "./matchPoster";

const gMapsUrl = "https://www.google.com/maps/search/?api=1&query=";

const AbMatchViewer: FC<{ match: Match }> = ({ match }) => {
  const { t } = useTranslation("match");

  return (
    <Card className="card-root article" style={{ width: "100%" }}>
      <MatchPoster match={match} />
      {typeof match.home === "boolean" ? (
        <div className="center margin-bottom">
          <Typography variant="h6" className="flex-center small-gap">
            {match.home ? <Home /> : <AirplaneTicket />} {t(match.home ? "homeGame" : "outsideGame")}
          </Typography>
        </div>
      ) : undefined}
      {match.tvChannelLogoUrl ? (
        <div className="center returns margin-bottom">
          <Typography variant="h6" className="margin-bottom flex-center small-gap">
            <Tv /> {t("tvChannel")}{" "}
            <img src={match.tvChannelLogoUrl} alt={t("tvChannelAlt")} style={{ maxWidth: 150, maxHeight: 50 }} />
          </Typography>
        </div>
      ) : undefined}
      {match.place ? (
        <div className="center returns margin-bottom">
          <Typography variant="h6" className="margin-bottom flex-center small-gap">
            <Place /> {t("place.place")}
          </Typography>
          <a
            href={`${gMapsUrl}${match.place.name}, ${match.place.address}, ${match.place.zipCode} ${match.place.city}`}
            target="_blank"
            rel="noreferrer">
            <Typography variant="subtitle1">
              {match.place.name}
              {"\n"}
              {match.place.address}
              {"\n"}
              {match.place.zipCode} {match.place.city}
            </Typography>
          </a>
          {match.home ? (
            <Typography>
              {t("parkingTicketsCode")} : <b>{match.parkingTicketsCode}</b>
            </Typography>
          ) : undefined}
        </div>
      ) : undefined}
      <Typography className="margin-bottom ql-editor" dangerouslySetInnerHTML={{ __html: match.body || "" }} />
    </Card>
  );
};

export default AbMatchViewer;
