import { Collections, ConfirmationNumber } from "@mui/icons-material";
import { FC } from "react";
import { formatMediumDate, getChampionshipDay } from "src/data/Converters";
import { getTargetForLink } from "src/helpers/UrlHelpers";
import Image from "../Image";
import styles from "./matchPoster.module.scss";

interface MatchProps {
  match: any;
}

const MatchPoster: FC<MatchProps> = ({ match }) => {
  const date = formatMediumDate(match.date);
  const { opponent } = match;

  const clubLogo = "https://abstagingstorage.blob.core.windows.net/match-picture/bayonne.svg";
  const clubName = "AVIRON BAYONNAIS";
  const homeLogo = match.home ? clubLogo : opponent.logo;
  const homeName = match.home ? clubName : opponent.name;
  const awayLogo = !match.home ? clubLogo : opponent.logo;
  const awayName = !match.home ? clubName : opponent.name;
  const headerInfos = getChampionshipDay(match.championship, match.championshipDay);
  const seasonStart = match?.season && match.season - 1;
  const season = match?.season ? `${seasonStart} - ${match?.season}` : "";
  return (
    <div className={styles.match}>
      <div className={styles.match__root}>
        <Image
          picture={match.background}
          alt="Image de fond match"
          className={styles.match__bg}
          width={280}
          height={340}
        />
        <div className={styles.match__filter}>
          <div className={styles.match__header}>
            <div className={styles.match__header__left}>
              <div className={styles.match__header__infos}>
                <p>{season}</p>
                <p>{headerInfos}</p>
              </div>
            </div>
            <div className={styles.match__header__left}>
              <h5 className={styles.match__header__date}>{date}</h5>
              <h5 className={styles.match__header__time}>{match?.time || ""}</h5>
            </div>
          </div>
          <div className={styles.match__logos}>
            <Image
              picture={homeLogo || clubLogo}
              alt={`Logo ${homeName}`}
              width={100}
              height={100}
              className={styles.match__logos__home}
            />
            <Image
              picture={awayLogo || clubLogo}
              alt={`Logo ${awayName}`}
              width={100}
              height={100}
              className={styles.match__logos__away}
            />
          </div>
          <div className={styles.match__score}>{match.score !== "" ? match.score : "VS"}</div>
          <div className={styles.match__teams}>
            <h3 className={styles.match__teams__home}>{homeName}</h3>
            <h3 className={styles.match__teams__away}>{awayName}</h3>
          </div>
          <div className={styles.match__footer}>
            <div className={styles.match__footer__content}>
              {match.ticketsLink ? (
                <a
                  href={match.ticketsLink}
                  target={getTargetForLink(match.ticketsLink)}
                  rel="noreferrer"
                  className={styles.match__ticketLink}>
                  <ConfirmationNumber sx={{ color: "#FFF" }} />
                </a>
              ) : (
                <div />
              )}
              {match?.damFolderPath ? (
                <div className={styles.match__galleryIcon}>
                  {" "}
                  <Collections sx={{ color: "#FFF" }} />
                </div>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MatchPoster;
