import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Sponsoring } from "src/data/types/ApiTypes";

interface B2bTicketProps {
  sponsoring: Sponsoring;
}

const B2bTicket: FC<B2bTicketProps> = ({ sponsoring }) => {
  const template = "https://abstagingstorage.blob.core.windows.net/sponsoring/b2bTicketTemplate.png";
  const { t } = useTranslation("sponsoring");
  return (
    <>
      <Typography variant="h6" textAlign="center" marginTop={3} marginBottom={1}>{`${t("b2bTicket")} ${t(
        "b2b",
      )}`}</Typography>
      <Card style={{ position: "relative" }}>
        <img src={template} alt={template} width={450} />
        <img
          src={sponsoring.pictures?.b2bTicket1?.url}
          alt={sponsoring.pictures?.b2bTicket1?.title}
          style={{
            position: "absolute",
            bottom: 130,
            left: 97,
            width: "125px",
            height: "100px",
            objectFit: "cover",
          }}
        />
        <img
          src={sponsoring.pictures?.b2bTicket2?.url}
          alt={sponsoring.pictures?.b2bTicket2?.title}
          style={{
            position: "absolute",
            bottom: 130,
            right: 86,
            width: "125px",
            height: "100px",
            objectFit: "cover",
          }}
        />
        <img
          src={sponsoring.pictures?.b2bTicketFooter?.url}
          alt={sponsoring.pictures?.b2bTicketFooter?.title}
          style={{
            position: "absolute",
            bottom: 42,
            right: 83,
            width: "260px",
            height: "50px",
            objectFit: "cover",
          }}
        />
      </Card>
    </>
  );
};

export default B2bTicket;
