import { Download, ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, IconButton, Tooltip } from "@mui/material";
import { FC, MouseEvent, SyntheticEvent, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { CurrentSeason, formatLongDate } from "src/data/Converters";
import { DataContext } from "src/data/contexts/DataContext";
import { Match, PartnerTicket } from "src/data/types/ApiTypes";
import AbCenteredContent from "../AbCenteredContent";
import CategoriesList from "./AbTicketsList";
import ZipLoader from "./zipLoader";
import "./tickets.scss";

interface AbMatchListProps {
  partnerId: string;
  tickets?: PartnerTicket[];
}

const presentationAccordionId = "presentation";

const AbMatchList: FC<AbMatchListProps> = ({ tickets, partnerId }) => {
  const [downloading, setDownloading] = useState<Record<string, boolean>>({});
  const [expanded, setExpanded] = useState<string | false>();
  const { matches, downloadTicketsArchive } = useContext(DataContext);
  const { t } = useTranslation("ticket");

  const handleChange = (panel: string) => (_: SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const dlTickets = (e: MouseEvent<HTMLButtonElement>, match: Match): boolean => {
    e.stopPropagation();
    setTimeout(() => {
      setDownloading({ ...downloading, [match.id]: true });
      downloadTicketsArchive(match.id, `Billets_${(match.title || "").replace(/ /g, "")}.zip`, partnerId).then(() =>
        setDownloading({ ...downloading, [match.id]: false }),
      );
    }, 100);
    return false;
  };

  const dlPresTickets = (e: MouseEvent<HTMLButtonElement>): boolean => {
    e.stopPropagation();
    setTimeout(() => {
      setDownloading({ ...downloading, [presentationAccordionId]: true });
      downloadTicketsArchive(presentationAccordionId, `Billets_PresentationEffectif.zip`, partnerId).then(() =>
        setDownloading({ ...downloading, [presentationAccordionId]: false }),
      );
    }, 100);
    return false;
  };

  const yesterday = new Date().getTime() / 1000 - 24 * 3600;

  const seasonHomeMatches = matches
    .filter((m) => m.home === true && m.season === CurrentSeason && (m.date || 0) > yesterday)
    .sort((m1, m2) => ((m1.date || 0) > (m2.date || 0) ? 1 : -1));

  const presentationTickets = tickets?.filter((ti) => ti.eventDate?.startsWith("2024-08-28"));
  const atLeastOnePresentation = (presentationTickets?.length || 0) > 0;

  return (
    <div className="tickets__container">
      {seasonHomeMatches.length === 0 ? (
        <AbCenteredContent loading />
      ) : (
        <>
          {atLeastOnePresentation ? (
            <Accordion
              className="tickets__accordion"
              expanded={expanded === presentationAccordionId}
              onChange={handleChange(presentationAccordionId)}
              key={presentationAccordionId}>
              <AccordionSummary sx={{ display: "flex", justifyContent: "space-between" }} expandIcon={<ExpandMore />}>
                <div className="tickets__match__container">
                  <div className="tickets__match__container__infos">
                    <h6 className="tickets__match__container__infos__title">
                      Présentation de l&apos;effectif 2024/2025
                    </h6>
                    <div className="tickets__match__container__infos__body">
                      {t("dateFormat", {
                        replace: { date: formatLongDate("2024-08-28" || "", true), hour: "18H30" },
                      })}
                    </div>
                  </div>
                  <div className="tickets__match__container__tickets">
                    {t("ticket", {
                      count: presentationTickets?.length,
                    })}
                  </div>
                  <Tooltip title={t("downloadMatchTickets")} arrow>
                    <IconButton
                      onClick={(e) => dlPresTickets(e)}
                      disabled={!atLeastOnePresentation || downloading[presentationAccordionId]}>
                      <Download />
                    </IconButton>
                  </Tooltip>
                </div>
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                <CategoriesList
                  key={Math.random()}
                  tickets={presentationTickets}
                  matchName={presentationAccordionId}
                  matchId={presentationAccordionId}
                  partnerId={partnerId}
                  isMatchVisible
                />
              </AccordionDetails>
              <ZipLoader
                open={downloading[presentationAccordionId] === true}
                numberOfTickets={presentationTickets?.length || 0}
              />
            </Accordion>
          ) : undefined}
          {seasonHomeMatches.map((m) => {
            const isMatchVisible = m.secutixData?.active === true;
            return (
              <Accordion
                className="tickets__accordion"
                expanded={expanded === m.id}
                onChange={handleChange(m.id || "")}
                key={m.id}>
                <AccordionSummary sx={{ display: "flex", justifyContent: "space-between" }} expandIcon={<ExpandMore />}>
                  <div className="tickets__match__container">
                    <div className="tickets__match__container__logo">
                      <img src={m.opponent?.logo} height={50} alt={`Logo de l'équipe ${m.opponent?.name}`} />
                    </div>
                    <div className="tickets__match__container__infos">
                      <h6 className="tickets__match__container__infos__title">
                        {m.title}
                        {isMatchVisible ? (
                          ""
                        ) : (
                          <span className="tickets__match__container__infos__matchNotVisible">
                            {t("matchNotVisibleInTicketing")}
                          </span>
                        )}
                      </h6>
                      <div className="tickets__match__container__infos__body">
                        {m.time === "0h00"
                          ? t("dateFormatWithoutHour", { replace: { date: formatLongDate(m.date || "", true) } })
                          : t("dateFormat", {
                              replace: { date: formatLongDate(m.date || "", true), hour: m.time || "0H00" },
                            })}
                      </div>
                    </div>
                    <div className="tickets__match__container__tickets">
                      {t("ticket", {
                        count: tickets?.filter((ticket) => ticket.matchId === m.id).length,
                      })}
                    </div>
                    <Tooltip title={t("downloadMatchTickets")} arrow>
                      <IconButton
                        onClick={(e) => dlTickets(e, m)}
                        disabled={
                          !isMatchVisible ||
                          tickets?.filter((ticket) => ticket.matchId === m.id).length === 0 ||
                          (tickets?.filter((ticket) => ticket.matchId === m.id).length || 0) > 10 ||
                          downloading[m.id]
                        }>
                        <Download />
                      </IconButton>
                    </Tooltip>
                  </div>
                </AccordionSummary>
                <AccordionDetails
                  sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  <CategoriesList
                    key={Math.random()}
                    tickets={tickets?.filter((ticket) => ticket.matchId === m.id)}
                    matchName={m.title || ""}
                    matchId={m.id}
                    partnerId={partnerId}
                    isMatchVisible={isMatchVisible}
                  />
                </AccordionDetails>
                <ZipLoader
                  open={downloading[m.id] === true}
                  numberOfTickets={tickets?.filter((ticket) => ticket.matchId === m.id).length || 0}
                />
              </Accordion>
            );
          })}
        </>
      )}
    </div>
  );
};

AbMatchList.defaultProps = {
  tickets: [],
};

export default AbMatchList;
