import { Add } from "@mui/icons-material";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "../../components/AbButton";
import { EventOption, EventQuestion, EventQuestionType } from "../../data/types/ApiTypes";

const modalWidth = 500;

const QuestionModal: FC<{
  open: boolean;
  onClose: () => void;
  option: EventOption;
  onSave: (newQuestion: EventQuestion) => void;
  question?: EventQuestion;
}> = ({ open, onClose, option, onSave, question }) => {
  const [newQuestion, setNewQuestion] = useState<EventQuestion>(question ? { ...question } : {});
  const { t } = useTranslation("event");
  const { enqueueSnackbar } = useSnackbar();
  useEffect(() => {
    setNewQuestion(question ? { ...question } : {});
  }, [question, open]);

  const saveQuestion = (): void => {
    if (!newQuestion.question || newQuestion.question.length === 0) {
      enqueueSnackbar(t("questions.questionNotEmpty"), { variant: "error" });
    } else if (typeof newQuestion.type === "undefined") {
      enqueueSnackbar(t("questions.questionTypeChoose"), { variant: "error" });
    } else if (
      newQuestion.type === EventQuestionType.choice &&
      (!newQuestion.choices || newQuestion.choices.filter((c) => c.length > 0).length < 2)
    ) {
      enqueueSnackbar(t("questions.questionChoicesTwo"), { variant: "error" });
    } else {
      onSave(newQuestion);
    }
  };

  return (
    <Modal open={open} onClose={onClose} className="ab-modal-root">
      <Paper className="ab-modal-paper">
        <div className="info-column" style={{ width: modalWidth }}>
          <Typography variant="h6">{t("questions.newQuestion", { replace: { option: option.title } })}</Typography>
          <TextField
            label={t("questions.question")}
            className="margin-top margin-bottom"
            value={newQuestion.question || ""}
            onChange={(e) => setNewQuestion({ ...newQuestion, question: e.target.value })}
            fullWidth
          />
          <FormControl>
            <FormLabel id="type-radio-buttons-group-label">{t("questions.type")}</FormLabel>
            <RadioGroup
              row
              aria-labelledby="type-radio-buttons-group-label"
              onChange={(_, value) => setNewQuestion({ ...newQuestion, type: value as EventQuestionType })}>
              <FormControlLabel
                value={EventQuestionType.choice}
                control={<Radio />}
                label={t("questions.choiceType")}
                checked={newQuestion.type === EventQuestionType.choice}
              />
              <FormControlLabel
                value={EventQuestionType.value}
                control={<Radio />}
                label={t("questions.value")}
                checked={newQuestion.type === EventQuestionType.value}
              />
            </RadioGroup>
          </FormControl>
          {newQuestion.type === EventQuestionType.choice ? (
            <div>
              <div className="row space-between">
                <Typography>{t("questions.choices")}</Typography>
                <IconButton
                  color="primary"
                  onClick={() =>
                    setNewQuestion({
                      ...newQuestion,
                      choices: newQuestion.choices ? [...newQuestion.choices, ""] : [""],
                    })
                  }>
                  <Add />
                </IconButton>
              </div>
              {!newQuestion.choices || newQuestion.choices.length === 0 ? (
                <Typography>{t("questions.noChoice")}</Typography>
              ) : (
                newQuestion.choices.map((c, i) => (
                  <TextField
                    key={c}
                    style={{ width: (modalWidth - 16) / 2, marginBottom: 16, marginRight: i % 2 === 0 ? 16 : 0 }}
                    value={c}
                    onChange={(e) =>
                      setNewQuestion({
                        ...newQuestion,
                        choices: newQuestion.choices?.map((oc, oci) => (oci === i ? e.target.value : oc)),
                      })
                    }
                  />
                ))
              )}
            </div>
          ) : undefined}
          <AbButton label={t("questions.save")} onClick={saveQuestion} />
        </div>
      </Paper>
    </Modal>
  );
};

QuestionModal.defaultProps = { question: undefined };

export default QuestionModal;
