import { FC } from "react";
import { Match } from "../data/types/ApiTypes";
import CloudinaryIdToImage from "../helpers/CloudinaryIdToImage";

const clubLogo = process.env.REACT_APP_CLUB_LOGO_URL || "";
const maxSize = 120;

const AbMatchPicture: FC<{ match: Match; thumbnail?: boolean }> = ({ match, thumbnail }) => (
  <div style={{ position: "relative" }}>
    <img
      src={CloudinaryIdToImage(match.background as string)}
      width={thumbnail ? "80" : "100%"}
      height={thumbnail ? "80" : "250"}
      style={{ objectFit: "cover" }}
      alt="Match showcase"
    />
    <div
      className="row space-between"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: thumbnail ? undefined : "0 48px",
        justifyContent: thumbnail ? "center" : undefined,
      }}>
      {thumbnail ? (
        <img
          src={CloudinaryIdToImage(match.opponent?.logo as string)}
          alt="Logo de l'adversaire"
          style={{ maxWidth: 60, maxHeight: 60 }}
        />
      ) : (
        <>
          <img
            src={CloudinaryIdToImage(match.home ? clubLogo : (match.opponent?.logo as string))}
            alt={`Logo ${match.home ? "du Club" : "de l'adversaire"}`}
            style={{ maxWidth: maxSize, maxHeight: maxSize }}
          />
          <img
            src={CloudinaryIdToImage(!match.home ? clubLogo : (match.opponent?.logo as string))}
            alt={`Logo ${!match.home ? "du Club" : "de l'adversaire"}`}
            style={{ maxWidth: maxSize, maxHeight: maxSize }}
          />
        </>
      )}
    </div>
  </div>
);

AbMatchPicture.defaultProps = { thumbnail: false };

export default AbMatchPicture;
