import { TextField, Typography } from "@mui/material";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { AbTwoCards } from "../components/AbBaseCard";
import AbButton from "../components/AbButton";
import AbPartner from "../components/AbPartner";
import { AuthContext } from "../data/contexts/AuthContext";
import { DataContext } from "../data/contexts/DataContext";
import { Partner } from "../data/types/ApiTypes";
import PartnerModal from "../modals/PartnerModal";

const ProfilePage: FC = () => {
  const [userUpdating, setUserUpdating] = useState(false);
  const [resettingCache, setResettingCache] = useState(false);
  const [partnerOpen, setPartnerOpen] = useState<Partner | null>(null);
  const { auth } = useContext(AuthContext);
  const { users, partners, updateUser, resetCache } = useContext(DataContext);
  const currentUser = users.find((u) => u.id === auth?.user.userid);
  const partnersId: string[] = currentUser && currentUser.partnersId ? currentUser.partnersId : [];
  const currentUserPartners: Partner[] =
    partnersId.length > 0 ? partners.filter((p) => partnersId.indexOf(p.id) !== -1) : [];
  const [newUser, setNewUser] = useState(currentUser);
  const { t } = useTranslation("profile");
  const modifyUser = async (): Promise<void> => {
    if (newUser) {
      setUserUpdating(true);
      await updateUser(newUser);
      setUserUpdating(false);
    }
  };
  const resetTheCache = async (): Promise<void> => {
    setResettingCache(true);
    await resetCache();
    setResettingCache(false);
  };
  return (
    <AbTwoCards
      left={
        <>
          <Typography variant="h5" className="big-margin-bottom">
            {t("profile")}
          </Typography>
          <TextField value={newUser?.username} label={t("username")} className="margin-bottom" disabled fullWidth />
          <div className="row space-between margin-bottom">
            <TextField
              value={newUser?.firstName}
              label={t("firstName")}
              onChange={(e) => setNewUser(newUser && { ...newUser, firstName: e?.target?.value })}
            />
            <TextField
              value={newUser?.lastName}
              label={t("lastName")}
              onChange={(e) => setNewUser(newUser && { ...newUser, lastName: e.target.value })}
            />
          </div>
          <AbButton label={t("modifyProfile")} onClick={modifyUser} isLoading={userUpdating} fullWidth />
          {currentUserPartners.findIndex((p) => p.id === "douze") !== -1 ? (
            <AbButton
              label={t("resetCache")}
              className="big-margin-top"
              onClick={resetTheCache}
              isLoading={resettingCache}
              color="warning"
              fullWidth
            />
          ) : undefined}
        </>
      }
      right={
        currentUserPartners ? (
          <>
            <Typography variant="h5" className="big-margin-bottom">
              {t("company", { count: currentUserPartners.length })}
            </Typography>
            {currentUserPartners.map((p) => (
              <div key={`partner_${p.id}`}>
                <AbPartner partner={p} onClick={() => setPartnerOpen(p)} />
              </div>
            ))}
            <PartnerModal
              open={Boolean(partnerOpen)}
              onClose={() => setPartnerOpen(null)}
              partner={partnerOpen || undefined}
            />
          </>
        ) : (
          <div />
        )
      }
    />
  );
};

export default ProfilePage;
