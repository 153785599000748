// Magic string:
const PICTURE_VERSION_URL_PARAM = "/v";
const CloudinaryIdToImage = (
  url: string,
  width?: number,
  heigth?: number,
  mode?: "crop" | "fill" | "fit" | "limit",
  gravity?: string,
  format?: string,
  misc?: string,
): string => {
  if (
    url.match("https://res.cloudinary.com") === null ||
    (typeof width === "undefined" && typeof heigth === "undefined" && typeof format === "undefined")
  ) {
    return url;
  }

  let updatedTransformation = "";

  if (width) {
    updatedTransformation += `w_${width},`;
  }
  if (heigth) {
    updatedTransformation += `h_${heigth},`;
  }
  if (mode) {
    updatedTransformation += `c_${mode},`;
  }
  if (gravity) {
    updatedTransformation += `g_${gravity},`;
  }
  if (misc) {
    updatedTransformation += `${misc},`;
  }

  if (updatedTransformation[updatedTransformation.length - 1] === ",") {
    updatedTransformation = updatedTransformation.slice(0, -1);
  }
  return `${url.slice(0, url.indexOf(PICTURE_VERSION_URL_PARAM))}/${updatedTransformation}${url.slice(
    url.indexOf(PICTURE_VERSION_URL_PARAM),
  )}`;
};

export default CloudinaryIdToImage;
