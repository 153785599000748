import { Button, CircularProgress } from "@mui/material";
import { ElementType, FC, ReactNode } from "react";

interface AbButtonProps {
  label: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
  className?: string;
  color?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
  justify?: "center" | "flex-end" | "flex-start";
  component?: ElementType<any>;
  width?: "large" | "normal";
  nowrap?: boolean;
  icon?: ReactNode;
}

const AbButtonDefaultProps = {
  isLoading: false,
  onClick: undefined,
  disabled: false,
  fullWidth: false,
  className: undefined,
  color: undefined,
  justify: undefined,
  component: undefined,
  width: undefined,
  nowrap: false,
  icon: undefined,
};

const AbButton: FC<AbButtonProps> = ({
  label,
  onClick,
  isLoading,
  disabled,
  fullWidth,
  className,
  color,
  justify,
  component,
  width,
  nowrap,
  icon,
}) => (
  <div
    style={{
      position: "relative",
      display: "flex",
      justifyContent: justify || "center",
      minWidth: width === "large" ? 350 : undefined,
      alignSelf: fullWidth ? "stretch" : undefined,
    }}
    className={className}>
    <Button
      variant="contained"
      component={component || "button"}
      color={color}
      onClick={onClick}
      disabled={isLoading || disabled}
      fullWidth={fullWidth}
      sx={{ whiteSpace: nowrap ? "nowrap" : "pre-line" }}
      endIcon={icon}>
      {label}
    </Button>
    {isLoading && (
      <CircularProgress
        size={24}
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          marginTop: -12,
          marginLeft: -12,
        }}
      />
    )}
  </div>
);

AbButton.defaultProps = AbButtonDefaultProps;

export default AbButton;
