import { FC } from "react";
import { Component } from "../../data/types/ApiTypes";
import styles from "./zenChefModule.module.scss";

const ZenChefModule: FC<Component> = ({ title }) => {
  return (
    <div className={styles.zenChefModule}>
      {title ? <h2 className={styles.zenChefModule__title}>{title}</h2> : undefined}
      <img
        className={styles.zenChefModule__iframe}
        src="https://avironbayonnais.blob.core.windows.net/static/zenChefModule.png"
        alt="screenshot iframe zenChefModule"
      />
    </div>
  );
};

export default ZenChefModule;
