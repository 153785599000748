import { FC } from "react";
import { Component } from "../../data/types/ApiTypes";
import { ImageType } from "../../data/types/ClientTypes";
import Image from "../Image";
import LinkButton, { LinkWithChildren } from "../linkButton/LinkButton";
import styles from "./staticBanner.module.scss";

const StaticBanner: FC<Component> = ({
  title,
  picture,
  actionTitle,
  actionUrl,
  actionImage,
  chrome,
  position,
  fullWidth,
}) => {
  const content = (
    <div className={fullWidth ? `${styles.banner__fullWidth} ${styles.banner}` : styles.banner}>
      <Image
        picture={picture}
        alt="Bannière statique"
        className={
          fullWidth
            ? `${styles.banner__picture__fullWidth} ${styles.banner__picture}`
            : `${styles.banner__picture} ${!chrome ? styles.noChrome : ""}`
        }
        type={fullWidth ? undefined : ImageType.Banner}
        fullWidth={fullWidth}
        crop={fullWidth ? false : undefined}
      />
      {chrome ? <div className={styles.banner__overlay} /> : undefined}
      <div className={styles.banner__contentContainer} style={{ alignItems: `flex-${position || "end"}` || "center" }}>
        <h3 className={styles.banner__title}>{title}</h3>
        {actionTitle && actionUrl ? <LinkButton label={actionTitle} href={actionUrl} icon={actionImage} /> : undefined}
      </div>
    </div>
  );
  return actionUrl && !actionTitle ? <LinkWithChildren href={actionUrl}>{content}</LinkWithChildren> : content;
};

export default StaticBanner;
