import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { ButtonIconProps, Component, Picture } from "../../data/types/ApiTypes";
import LinkButton from "../linkButton/LinkButton";
import { ImageType } from "../../data/types/ClientTypes";
import GalleryModal from "./GalleryModal";
import Image from "../Image";
import styles from "./gallery.module.scss";

const Gallery: FC<Component> = ({ data, title }) => {
  const [galleryIsOpen, setGalleryIsOpen] = useState(false);
  const [index, setIndex] = useState(0);
  const { t } = useTranslation("content");
  let cpt = 0;
  const items = Array.isArray(data)
    ? (data as Picture[]).map((g: Picture) => ({
        // eslint-disable-next-line no-plusplus
        id: `${cpt++}`,
        original: g.url || "",
        thumbnail: g.url || "",
        description: g.title ? <h2 className={styles.titleGalleryModal}>{g.title}</h2> : undefined,
        originalAlt: g.title,
      }))
    : [];

  return (
    <div className={styles.gallery}>
      <h2 className={styles.gallery__title}>{title !== undefined ? title : ""}</h2>
      <div className={styles.gallery__container}>
        <Splide
          options={{
            width: "100%",
            type: "slide",
            perMove: 1,
            autoplay: false,
            drag: "free",
            gap: "8px",
            pagination: false,
            snap: true,
            rewind: true,
            arrows: false,
            perPage: 3,
            fixedWidth: "200px",
            breakpoints: {
              990: { perPage: 2 },
              768: { perPage: 1, padding: { right: "20%", left: "8%" }, width: "100vw" },
            },
          }}>
          {items.map((item) =>
            items.indexOf(item) < 3 ? (
              <SplideSlide key={item.id}>
                <button
                  type="button"
                  onClick={() => {
                    setGalleryIsOpen(true);
                    setIndex(items.indexOf(item));
                  }}
                  key={item.thumbnail}
                  className={styles.gallery__container__images__button}>
                  <Image
                    className={styles.gallery__container__images}
                    picture={item.thumbnail}
                    alt={item.originalAlt || ""}
                    type={ImageType.GalleryThumbnail}
                  />
                </button>
              </SplideSlide>
            ) : undefined,
          )}
        </Splide>

        <GalleryModal open={galleryIsOpen} onClose={() => setGalleryIsOpen(false)} items={items} index={index} />
      </div>
      <LinkButton label={t("buttonGallery")} onClick={() => setGalleryIsOpen(true)} icon={ButtonIconProps.picture} />
    </div>
  );
};

export default Gallery;
