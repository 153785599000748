import { v4 as uuidv4 } from "uuid";
import {
  ApiConversation,
  Championship,
  DiscountType,
  Match,
  NewsMode,
  Partner,
  RoomBooking,
  RoomBookingStatus,
  ShopProduct,
  Task,
  User,
} from "./types/ApiTypes";
import { Conversation, KanbanDataModel, SchedulerBooking } from "./types/ClientTypes";
import Country, { Countries } from "./types/Countries";
import { log } from "./Logger";

export const getTimestamp = (d: Date): number => Math.round(d.getTime() / 1000);

export const getDateFromTimestamp = (t: number): Date => new Date(t * 1000);

const getDate = (d?: Date | number | string): Date =>
  typeof d === "undefined" ? new Date() : typeof d === "number" ? getDateFromTimestamp(d) : new Date(d);

export const formatDate = (d: Date | number | string): string => {
  const date = getDate(d);
  return date.toLocaleDateString();
};

const getMonthShort = (month: number): string => {
  switch (month) {
    case 0:
      return "janv.";
    case 1:
      return "févr.";
    case 2:
      return "mars";
    case 3:
      return "avril";
    case 4:
      return "mai";
    case 5:
      return "juin";
    case 6:
      return "juil.";
    case 7:
      return "août";
    case 8:
      return "sept.";
    case 9:
      return "oct.";
    case 10:
      return "nov.";
    case 11:
      return "déc.";
    default:
      return "";
  }
};

export const formatMediumDate = (d: number): string => {
  const date = new Date(d * 1000);
  return `${date.getDate() === 1 ? "1er" : date.getDate()} ${getMonthShort(date.getMonth())} ${date.getFullYear()}`;
};

export const formatShortestDate = (d?: Date | number | string): string => {
  const date = getDate(d);
  const year = date.getFullYear().toString();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${year}${month}${day}`;
};

export const formatShortDate = (
  d: Date | number | string,
  withTime = false,
  separator = "/",
  withFullYear = false,
): string => {
  const date = getDate(d);
  const year = date.getFullYear().toString();
  const finalYear = withFullYear ? year : year.substring(2, 4);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const sep = separator || "/";
  return `${day}${sep}${month}${sep}${finalYear}${
    withTime ? `, ${date.getHours().toString().padStart(2, "0")}h${date.getMinutes().toString().padStart(2, "0")}` : ""
  }`;
};

export const formatLongDate = (
  d: Date | number | string,
  dayName = false,
  withTime = false,
  noYear = false,
): string => {
  const date = getDate(d);
  const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
  ];
  const days = ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"];
  return `${dayName ? `${days[date.getDay()]} ` : ""}${date.getDate() === 1 ? "1er" : date.getDate()} ${
    months[date.getMonth()]
  } ${noYear ? "" : date.getFullYear()}${
    withTime ? `, ${date.getHours().toString().padStart(2, "0")}h${date.getMinutes().toString().padStart(2, "0")}` : ""
  }`;
};

export const getTodayTimestamp = (): number => {
  const today = new Date();
  today.setHours(12, 0, 0, 0);
  return getTimestamp(today);
};

export const formatNumber = (n?: number | string, fixed?: number, padEnd?: boolean): string => {
  if (!n) return "0";
  const tmpNumber = typeof n === "string" ? parseFloat(n) : n;
  // Remove insignificant decimal trailing zeros
  const finalNumber = parseFloat(tmpNumber.toFixed(fixed));
  const result = finalNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
  return result.indexOf(",") === -1 || !fixed || !padEnd ? result : result.padEnd(result.indexOf(",") + fixed + 1, "0");
};

export const compareWithoutAccent = (value: string, searchedValue: string): boolean => {
  return (
    value
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .indexOf(
        searchedValue
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      ) !== -1
  );
};

export const getSeasons = (matches: Match[]): number[] => {
  const allSeasonsList = matches.map((m) => m.season).filter((s) => typeof s === "number");
  const seasonList: number[] = [];
  allSeasonsList.forEach((s) => {
    if (s && !seasonList.includes(s)) {
      seasonList.push(s);
    }
  });
  return seasonList.sort((a, b) => b - a);
};

export const getAllSeasons = (): number[] => {
  const allSeasons: number[] = [];
  const today = new Date();
  const yearOfToday = today.getFullYear();
  const monthOfToday = today.getMonth() + 1;
  const currentSeason = monthOfToday >= 5 ? yearOfToday + 1 : yearOfToday;
  for (let i = currentSeason; i >= 2022; i -= 1) {
    allSeasons.push(i);
  }
  return allSeasons;
};

export const CurrentSeason = parseInt(process.env.REACT_APP_CURRENT_SEASON || "2023", 10);

export const getSeasonLabel = (season: number): string => `${season - 1} - ${season}`;
export const getSeasonShortLabel = (season: number): string => `${season - 2001} - ${season - 2000}`;

export const getChampionshipName = (id?: Championship): string => {
  switch (id) {
    case Championship.europeChallenge:
      return "Challenge Cup";
    case Championship.europeChampions:
      return "Champions Cup";
    case Championship.amical:
      return "Match amical";
    case Championship.proD2:
      return "PRO D2";
    case Championship.top14:
      return "TOP 14";
    default:
      return "Championnat non supporté";
  }
};

export const getChampionshipDay = (championship: Championship, championshipDay?: number): string => {
  switch (championship) {
    case Championship.europeChallenge:
      return `Challenge Cup - J${championshipDay}`;
    case Championship.europeChampions:
      return `Champions Cup - J${championshipDay}`;
    case Championship.amical:
      return "Match amical";
    case Championship.proD2:
      switch (championshipDay) {
        case 31:
          return `PRO D2 - Barrage`;
        case 32:
          return `PRO D2 - Demi-finale`;
        case 33:
          return `PRO D2 - Finale`;
        default:
          return `PRO D2 - J${championshipDay}`;
      }
    case Championship.top14:
      switch (championshipDay) {
        case 27:
          return `TOP 14 - Barrage`;
        case 28:
          return `TOP 14 - Demi-finale`;
        case 29:
          return `TOP 14 - Finale`;
        default:
          return `TOP 14 - J${championshipDay}`;
      }
    default:
      return "Championnat non supporté";
  }
};

export const getAge = (timeStampBirth: number | null): number | undefined => {
  const yearTimestamp = 365 * 24 * 3600;
  const nowTimestamp = new Date().getTime() / 1000;
  return timeStampBirth !== null ? Math.floor((nowTimestamp - timeStampBirth) / yearTimestamp) : undefined;
};

export const getCountry = (enumValue?: Country): string => {
  if (typeof enumValue === "undefined") return "";
  const label = Countries[enumValue];
  return label;
};

export const apiConversationToConversation = (
  apiConv: ApiConversation,
  users: User[],
  partners: Partner[],
  userId: string,
): Conversation => {
  const user = users.find((u) => apiConv.usersId.includes(u.id));
  const partner = partners.find((p) => user?.partnersId?.includes(p.id));
  const msgDates: number[] = [];
  const messages = apiConv.messages.map((m) => {
    msgDates.push(m.sent);
    const mUser = users.find((u) => u.id === m.senderId);
    return { ...m, isSender: m.senderId === userId, sender: mUser ? `${mUser.firstName} ${mUser.lastName}` : "" };
  });
  return {
    ...apiConv,
    partnerName: partner?.name || "",
    partnerUserName: user ? `${user.firstName} ${user.lastName}` : "",
    latestMessageDate: formatLongDate(Math.max(...msgDates), false, true),
    messages,
  };
};

export const convertSchedulerBookingToRoomBooking = (schedulerBooking: SchedulerBooking): RoomBooking => {
  return {
    id: schedulerBooking.EventId === undefined ? uuidv4() : schedulerBooking.EventId,
    roomId: schedulerBooking.RoomId,
    status: schedulerBooking.Status,
    configuration: schedulerBooking.Configuration,
    subject: schedulerBooking.Subject,
    description: schedulerBooking.Description,
    startTime: getTimestamp(schedulerBooking.StartTime),
    endTime: getTimestamp(schedulerBooking.EndTime),
  };
};

export const convertRoomBookingToSchedulerBooking = (roomBooking: RoomBooking): SchedulerBooking => {
  return {
    EventId: roomBooking.id,
    RoomId: roomBooking.roomId,
    Status: roomBooking.status,
    Configuration: roomBooking.configuration,
    Subject: roomBooking.subject,
    Description: roomBooking.description,
    StartTime: getDateFromTimestamp(roomBooking.startTime || 0),
    EndTime: getDateFromTimestamp(roomBooking.endTime || 0),
    StatusColor: roomBooking.status === RoomBookingStatus.option ? "#FFB171" : "#448E0F",
  };
};

export const convertTaskToKanbanDataModel = (task: Task): KanbanDataModel => {
  return {
    Id: task.id,
    Title: task.title,
    Status: task.status,
    Summary: task.summary,
    Assignee: task.assignee,
  };
};

export const convertKanbanDataModelToTask = (kabanDataModel: KanbanDataModel): Task => {
  return {
    id: kabanDataModel.Id,
    title: kabanDataModel.Title,
    summary: kabanDataModel.Summary,
    assignee: kabanDataModel.Assignee,
    status: kabanDataModel.Status,
  };
};

export const addDiscountOnPrice = (shopProduct: ShopProduct): number => {
  const originalPrice = shopProduct.price !== undefined ? shopProduct.price : 0;
  const discountType = shopProduct.discount?.type;
  const discountValue = shopProduct.discount?.value !== undefined ? shopProduct.discount?.value : 0;

  let newPrice = originalPrice;
  if (discountType === DiscountType.percentage) {
    newPrice = originalPrice - (originalPrice * discountValue) / 100;
  }
  if (discountType === DiscountType.price) {
    newPrice = originalPrice - discountValue;
  }
  return newPrice;
};

export const downloadFile = (content: Blob, fileName: string): boolean => {
  try {
    const finalUrl = window.URL.createObjectURL(content);
    const link = document.createElement("a");
    link.href = finalUrl;
    link.target = "_blank";
    link.setAttribute("download", fileName);
    link.setAttribute("style", "display: none");
    document.body.appendChild(link);
    link.click();
    return true;
  } catch (reason) {
    log.error(reason);
    return false;
  }
};

const articleUrl = "/news/articles/";
const videoUrl = "/news/videos/";

export const getNewsUrl = (mode?: NewsMode, id?: string): string => {
  const rootUrl = mode === NewsMode.videos ? videoUrl : articleUrl;
  return id ? `${rootUrl}${id}` : rootUrl;
};

export const validateHexaColor = (hexa: string): boolean => {
  const regexp = /^[0-9a-fA-F]+$/;
  if (hexa.charAt(1) === "#") {
    if (regexp.test(hexa.substring(1))) {
      return true;
    }
    return false;
  }
  return false;
};
