import { CircularProgress, Typography } from "@mui/material";
import { FC } from "react";
import { WithChildren } from "../data/types/TechnicalTypes";

interface CenteredContentProps extends WithChildren {
  loading?: boolean;
  errorText?: string;
  infoText?: string;
  className?: string;
  loadingSize?: number;
  normalText?: boolean;
}

const AbCenteredContent: FC<CenteredContentProps> = ({
  loading,
  errorText,
  infoText,
  children,
  className,
  loadingSize,
  normalText,
}) => (
  <div className={className ? `centered-content-root ${className}` : "centered-content-root"}>
    {loading ? (
      <CircularProgress color="secondary" size={loadingSize} />
    ) : (
      children || (
        <Typography variant={normalText ? undefined : "h6"} style={{ whiteSpace: "pre-line", textAlign: "center" }}>
          {errorText || infoText}
        </Typography>
      )
    )}
  </div>
);

AbCenteredContent.defaultProps = {
  loading: false,
  errorText: undefined,
  infoText: undefined,
  className: undefined,
  loadingSize: undefined,
  normalText: undefined,
};

export default AbCenteredContent;
