import { Card, styled } from "@mui/material";
import { FC } from "react";
import { WithChildren, WithClassName } from "../data/types/TechnicalTypes";

const Container = styled("div")({
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const OurCard = styled(Card)({
  width: 1100,
  padding: 32,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
});

const AbBaseCard: FC<WithClassName & WithChildren> = ({ children, className }) => (
  <Container className={className}>
    <OurCard elevation={3}>{children}</OurCard>
  </Container>
);

export default AbBaseCard;

const TwoContainer = styled("div")({
  width: 1100,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-evenly",
  height: "90%",
});

const HalfCard = styled(Card)({
  flex: 1,
  padding: 16,
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  overflowY: "auto",
});

const Separator = styled("div")({
  width: 32,
});

export const AbTwoCards: FC<WithClassName & { left: JSX.Element; right: JSX.Element; rightWidth?: number }> = ({
  className,
  left,
  right,
  rightWidth,
}) => (
  <TwoContainer className={className}>
    <HalfCard>{left}</HalfCard>
    <Separator />
    <HalfCard style={rightWidth ? { minWidth: rightWidth } : undefined}>{right}</HalfCard>
  </TwoContainer>
);

AbTwoCards.defaultProps = { rightWidth: undefined };
