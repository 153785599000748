import {
  ApiConversation,
  ApiMessage,
  ComponentType,
  ConfigRoomName,
  DocumentType,
  KanbanCardStatus,
  Partner,
  PartnersList,
  RoomBookingStatus,
} from "./ApiTypes";

export interface Message extends ApiMessage {
  isSender: boolean;
  sender: string;
}

export interface Conversation extends ApiConversation {
  partnerName: string;
  partnerUserName: string;
  latestMessageDate: string;
  messages: Message[];
}

export const getPartnersFromList = (partners: Partner[], list: PartnersList): Partner[] => {
  let result = [...partners];
  // GC filter
  if (list.gc) {
    result = result.filter((p) => p.isGc);
  }
  // Product name filter
  if (list.productFilter) {
    const filter = list.productFilter.toLowerCase();
    result = result.filter(
      (p) => p.products && p.products.findIndex((pp) => pp.name.toLowerCase().indexOf(filter) !== -1) !== -1,
    );
  }
  // Total amount filter
  if (list.minAmount || list.maxAmount) {
    const min = list.minAmount || 0;
    const max = list.maxAmount || 1_000_000_000;
    result = result.filter((p) => {
      // Filter out partners having no product
      if (!p.documents || p.documents.length === 0) return false;
      const quotes: string[] = [];
      let totalAmount = 0;
      p.documents.forEach((d) => {
        if (d.metadata?.type === DocumentType.quote && quotes.indexOf(d.metadata?.entityId) === -1) {
          totalAmount += d.metadata?.totalAmount || 0;
          quotes.push(d.metadata?.entityId);
        }
      });
      return totalAmount >= min && totalAmount <= max;
    });
  }
  return result;
};

export enum NewsType {
  news = "news",
  videos = "videos",
}

export type Selectability = "optional" | "mandatory";

export interface CompConfig {
  undeletable?: boolean;
  picture?: Selectability;
  title?: Selectability;
  newsType?: Selectability;
  limit?: Selectability;
  category?: Selectability;
  htmlContent?: Selectability;
  picturePosition?: Selectability;
  cropPicture?: Selectability;
  actionTitle?: Selectability;
  actionUrl?: Selectability;
  videoUrl?: Selectability;
  date?: Selectability;
  chrome?: Selectability;
  tag?: Selectability;
  folder?: Selectability;
  textItems?: Selectability;
  fullWidth?: Selectability;
  shopProductCategory?: Selectability;
  cardItems?: Selectability;
  externalId?: Selectability;
  slider?: Selectability;
  newsCategory?: Selectability;
  associatedTeamMember?: Selectability;
  backgroundColor?: Selectability;
}

export const ComponentsConfig: Record<ComponentType, CompConfig> = {
  [ComponentType.staticHero]: {
    picture: "mandatory",
    title: "optional",
  },
  [ComponentType.staticBanner]: {
    picture: "mandatory",
    title: "optional",
    actionTitle: "optional",
    actionUrl: "optional",
    chrome: "optional",
    fullWidth: "optional",
  },
  [ComponentType.contentCard]: {
    picture: "optional",
    title: "optional",
    htmlContent: "optional",
    backgroundColor: "optional",
    picturePosition: "optional",
    cropPicture: "optional",
    actionTitle: "optional",
    actionUrl: "optional",
    chrome: "optional",
  },
  [ComponentType.videoEmbed]: {
    videoUrl: "mandatory",
    picture: "mandatory",
    title: "optional",
  },
  [ComponentType.gallery]: {
    tag: "optional",
    folder: "optional",
    title: "optional",
    limit: "mandatory",
  },
  [ComponentType.faq]: {
    title: "optional",
    textItems: "optional",
  },
  [ComponentType.shopProductSlider]: {
    title: "optional",
    shopProductCategory: "optional",
  },

  [ComponentType.edito]: {
    /* TODO */
  },
  [ComponentType.newsCategories]: {
    /* TODO */
  },
  [ComponentType.newsList]: {
    /* TODO */
  },
  [ComponentType.newsHero]: {
    /* TODO */
  },
  [ComponentType.newsSlider]: {
    title: "optional",
    newsCategory: "optional",
    associatedTeamMember: "optional",
    limit: "mandatory",
    slider: "optional",
  },
  [ComponentType.matchList]: {
    /* TODO */
  },
  [ComponentType.matchsSlider]: {
    /* TODO */
  },
  [ComponentType.playersSlider]: {
    /* TODO */
  },
  [ComponentType.zenChefModule]: {
    undeletable: true,
    title: "optional",
    externalId: "mandatory",
  },
  [ComponentType.helloAssoModule]: {
    undeletable: true,
    title: "optional",
  },
  [ComponentType.cardsGrid]: {
    title: "optional",
    cardItems: "mandatory",
    limit: "mandatory",
  },
};

export enum ImageType {
  NewsThumbnail = "NewsThumbnail",
  VideoThumbnail = "VideoThumbnail",
  PlayerThumbnail = "PlayerThumbnail",
  Hero = "Hero",
  Interstitial = "Interstitial",
  Banner = "Banner",
  PlayerProfile = "PlayerProfile",
  GalleryThumbnail = "GalleryThumbnail",
  GalleryFull = "GalleryFull",
  GalleryMini = "GalleryMini",
  Gallery = "Gallery",
  Social = "Social",
}

export interface SchedulerBooking {
  EventId?: string;
  RoomId?: string;
  Status?: RoomBookingStatus;
  Configuration?: ConfigRoomName;
  Subject?: string;
  Description?: string;
  StartTime: Date;
  EndTime: Date;
  StatusColor: string;
}

export interface KanbanDataModel {
  Id?: string;
  Title?: string;
  Status?: KanbanCardStatus;
  Summary?: string;
  Type?: string;
  Priority?: string;
  Tags?: string;
  Estimate?: number;
  Assignee?: string;
  RankId?: number;
  Color?: string;
}
