import {
  AccountCircle,
  AdminPanelSettings,
  ConfirmationNumber,
  Edit,
  LocalPolice,
  LockReset,
} from "@mui/icons-material";
import { Card, CircularProgress, Table, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FC, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import UserUpdateModal from "src/modals/PartnerModal/UsersUpdateModal";
import { DataContext } from "../data/contexts/DataContext";
import { User } from "../data/types/ApiTypes";

const AbUsersList: FC<{ users: User[]; setResetPasswordUrl: (url: string) => void }> = ({
  users,
  setResetPasswordUrl,
}) => {
  const [togglingReferent, setTogglingReferent] = useState("");
  const [gettingPasswordLink, setGettingPasswordLink] = useState("");
  const [userToUpdate, setUserToUpdate] = useState<User | undefined>(undefined);
  const { t } = useTranslation("partner");
  const { updateUser, resetPasswordUrl } = useContext(DataContext);
  const toggleReferent = async (user: User): Promise<void> => {
    setTogglingReferent(user.id);
    await updateUser({ id: user.id, referent: !user.referent, firstName: user.firstName, lastName: user.lastName });
    setTogglingReferent("");
  };

  useEffect(() => {
    if (!userToUpdate) return;
    const newSelected = users.find((u) => u.id === userToUpdate.id);
    setUserToUpdate(newSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const getResetPasswordUrl = async (userId: string): Promise<void> => {
    setGettingPasswordLink(userId);
    const url = await resetPasswordUrl(userId);
    if (typeof url === "string") {
      setResetPasswordUrl(url);
    }
    setGettingPasswordLink("");
  };
  const isTicketsUser = (u: User): boolean => typeof u.digitickId !== "undefined" || typeof u.ticketsId !== "undefined";

  return (
    <Card variant="outlined">
      <Table>
        {users.map((u) => (
          <TableRow key={u.id}>
            <TableCell>
              <Typography>
                {u.firstName} {u.lastName} ({u.username})
              </Typography>
            </TableCell>
            <TableCell align="right">
              <Tooltip
                className="clickable margin-right"
                title={t("updateUser.title")}
                arrow
                onClick={() => setUserToUpdate(u)}>
                <Edit />
              </Tooltip>
              {isTicketsUser(u) ? (
                <Tooltip title={t("user.isDigitick")} arrow className="margin-right">
                  <ConfirmationNumber />
                </Tooltip>
              ) : undefined}
              {u.admin ? (
                <Tooltip title={t("user.isAdmin")} arrow className="margin-right">
                  <LocalPolice />
                </Tooltip>
              ) : undefined}
              {togglingReferent === u.id ? (
                <CircularProgress size={24} />
              ) : (
                <Tooltip
                  className={`clickable${isTicketsUser(u) ? "" : " margin-right"}`}
                  title={t(`user.is${u.referent ? "" : "Not"}Referent`)}
                  arrow
                  onClick={togglingReferent === "" ? () => toggleReferent(u) : undefined}>
                  {u.referent ? <AdminPanelSettings /> : <AccountCircle />}
                </Tooltip>
              )}
              {isTicketsUser(u) ? undefined : gettingPasswordLink === u.id ? (
                <CircularProgress size={24} />
              ) : (
                <Tooltip
                  className="clickable"
                  title={t(`user.resetPassword`)}
                  arrow
                  onClick={gettingPasswordLink === "" ? () => getResetPasswordUrl(u.id) : undefined}>
                  <LockReset />
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
        ))}
      </Table>
      <UserUpdateModal open={Boolean(userToUpdate)} onClose={() => setUserToUpdate(undefined)} user={userToUpdate} />
    </Card>
  );
};

export default AbUsersList;
