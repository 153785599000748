import { FC } from "react";
import { CardItem, Component } from "../../data/types/ApiTypes";
import Image from "../Image";
import styles from "./cardsGrid.module.scss";
import { LinkWithChildren } from "../linkButton/LinkButton";

interface OneCardProps extends CardItem {
  threeColumns: boolean;
}

const OneCard: FC<OneCardProps> = ({ picture, title, id, threeColumns }): JSX.Element => {
  return (
    <div key={id} className={`${styles.cardsGrid__oneCard} ${threeColumns ? styles.cardsGrid__oneCard__3columns : ""}`}>
      <Image
        width={threeColumns ? 280 : 450}
        height={200}
        className={`${styles.cardsGrid__oneCard__picture} ${
          threeColumns ? styles.cardsGrid__oneCard__picture__3columns : ""
        }`}
        picture={picture}
        alt={picture?.title || ""}
      />
      <div className={styles.cardsGrid__oneCard__overlay} />
      <div className={styles.cardsGrid__oneCard__contentContainer}>
        {typeof title !== "undefined" && title.length > 0 ? (
          <h3 className={styles.cardsGrid__oneCard__title}>{title}</h3>
        ) : undefined}
      </div>
    </div>
  );
};

const CardsGrid: FC<Component> = ({ cardItems, title, limit }) => {
  const threeColumns = limit === 3;
  return (
    <div className={styles.cardsGrid}>
      {typeof title !== "undefined" && title.length > 0 ? (
        <h2 className={styles.cardsGrid__title}>{title}</h2>
      ) : undefined}
      <div
        className={
          limit === 3
            ? `${styles.cardsGrid__container} ${styles.cardsGrid__container__3columns}`
            : styles.cardsGrid__container
        }>
        {typeof cardItems !== "undefined" && cardItems.length > 0
          ? cardItems.map((c) =>
              typeof c.link !== "undefined" && c.link.length > 0 ? (
                <div key={c.id} className={styles.cardsGrid__oneCard__link}>
                  <LinkWithChildren href={c.link || ""}>
                    <OneCard picture={c.picture} title={c.title} id={c.id} threeColumns={threeColumns} />
                  </LinkWithChildren>
                </div>
              ) : (
                <OneCard key={c.id} picture={c.picture} title={c.title} id={c.id} threeColumns={threeColumns} />
              ),
            )
          : undefined}
      </div>
    </div>
  );
};

export default CardsGrid;
