import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { isValidSlug } from "src/helpers/UrlHelpers";
import AbButton from "./AbButton";

const AbModificationAlert: FC<{ open: boolean; onClose: () => void; onConfirm: () => void }> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation("global");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t("modifiedTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t("modifiedDescription")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <AbButton label={t("yesNo.no")} color="inherit" onClick={onClose} />
        <AbButton label={t("yesNo.yes")} color="primary" onClick={onConfirm} />
      </DialogActions>
    </Dialog>
  );
};

export default AbModificationAlert;

export const AbOverrideSlugAlert: FC<{
  errorCode: number;
  onClose: () => void;
  onConfirm: (slug: string) => void;
  slugPrefix: string;
}> = ({ errorCode, onClose, onConfirm, slugPrefix }) => {
  const [slugSuffix, setSlugSuffix] = useState("");
  const { t } = useTranslation("global");
  const { enqueueSnackbar } = useSnackbar();
  const slug = `${slugPrefix}_${slugSuffix}`;

  const close = (): void => {
    setSlugSuffix("");
    onClose();
  };

  const confirm = (): void => {
    if (isValidSlug(slugSuffix) && slugSuffix.length >= 5) {
      onConfirm(slug);
      close();
    } else {
      enqueueSnackbar(t("modifySlugInvalid"), { variant: "error" });
    }
  };

  return (
    <Dialog open={errorCode !== -1} onClose={onClose}>
      <DialogTitle>{t("modifySlugTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(errorCode === 409 ? "modifySlugAlreadyExistsDescription" : "modifySlugDescription")}
        </DialogContentText>
        <TextField value={slugSuffix} onChange={(e) => setSlugSuffix(e.target.value)} fullWidth />
        <DialogContentText>{t("modifySlugFull", { replace: { slug } })}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <AbButton label={t("modifySlugCancel")} color="inherit" onClick={close} />
        <AbButton label={t("modifySlugConfirm")} color="primary" onClick={confirm} />
      </DialogActions>
    </Dialog>
  );
};
