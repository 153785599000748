import { Add, Delete, Edit, Visibility, VisibilityOff } from "@mui/icons-material";
import { CircularProgress, Fab, IconButton, Tooltip } from "@mui/material";
import { FC, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PlayerCard from "src/components/player";
import { getProfilePictureOrFallback } from "src/helpers/UrlHelpers";
import { AbTwoCards } from "../components/AbBaseCard";
import AbCenteredContent from "../components/AbCenteredContent";
import AbDeleteAlert from "../components/AbDeleteAlert";
import AbItemsList from "../components/AbItemsList";
import AbListItem from "../components/AbListItem";
import AbTeamMemberViewer from "../components/AbTeamMemberViewer";
import { compareWithoutAccent } from "../data/Converters";
import { DataContext } from "../data/contexts/DataContext";
import { TeamMember, TeamMemberType } from "../data/types/ApiTypes";
import TeamMemberModal from "../modals/TeamMemberModal";

interface TeamMembersPageProps {
  teamMemberType: TeamMemberType;
  filter: string;
  publishedTeamMembers: boolean | null;
  season: number;
}

const TeamMembersPage: FC<TeamMembersPageProps> = ({ teamMemberType, filter, publishedTeamMembers, season }) => {
  const [currentItem, setCurrentItem] = useState<TeamMember | null>(null);
  const [modifying, setModifying] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [publishing, setPublishing] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [deleteAlertOpen, setDeleteAlertOpen] = useState(false);
  const { t } = useTranslation(["global", "team"]);
  const { deleteTeamMember, publishTeamMember, teamMembers, refreshTeamMember } = useContext(DataContext);

  const filteredTeamMembers: TeamMember[] = useMemo(() => {
    const teamMemberTmp = teamMembers
      .filter((tm) => compareWithoutAccent(`${tm.firstName} ${tm.lastName}`, filter))
      .filter((tm) =>
        publishedTeamMembers === null
          ? tm.published || !tm.published || !tm.published === undefined
          : publishedTeamMembers
          ? tm.published
          : !tm.published || !tm.published === undefined,
      )
      .filter((tm) => (season === 0 ? tm : tm.seasons?.includes(season)));
    return teamMemberTmp;
  }, [teamMembers, filter, publishedTeamMembers, season]);
  const finalTeamMembers =
    filteredTeamMembers.length > 0
      ? filteredTeamMembers.filter((teamMember: { typeOfMember: string }) => teamMember.typeOfMember === teamMemberType)
      : [];

  useEffect(() => {
    if (currentItem) {
      const newCurrent = finalTeamMembers.find((p: TeamMember) => p.id === currentItem.id);
      if (newCurrent) setCurrentItem(newCurrent);
      else setCurrentItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalTeamMembers]);

  const publish = async (): Promise<void> => {
    if (!currentItem) return;
    setPublishing(true);
    await publishTeamMember(currentItem.id, !currentItem.published);
    setPublishing(false);
  };

  const selectItem = async (p: TeamMember): Promise<void> => {
    setCurrentItem(p);
  };

  const deleteCurrentItem = async (): Promise<void> => {
    setDeleting(true);
    await deleteTeamMember(currentItem?.id || "");
    setDeleting(false);
    setCurrentItem(null);
  };

  const tryDelete = async (): Promise<void> => {
    if (currentItem?.published) {
      setDeleteAlertOpen(true);
    } else {
      await deleteCurrentItem();
    }
  };

  const refreshAndEdit = async (): Promise<void> => {
    if (!currentItem) return;
    setRefreshing(true);
    await refreshTeamMember(currentItem.id);
    setRefreshing(false);
    setModifying(true);
  };

  const createTeamMember = async (): Promise<void> => {
    if (!currentItem) setModifying(true);
    else {
      setCurrentItem(null);
      setModifying(true);
    }
  };

  return (
    <>
      {finalTeamMembers.length > 0 ? (
        <AbTwoCards
          left={
            <AbItemsList
              items={finalTeamMembers}
              renderRow={(p: TeamMember) => (
                <AbListItem
                  data={{
                    pictureUrl: {
                      url: getProfilePictureOrFallback(p.pictures?.profile),
                    },
                    title: `${p.firstName} ${p.lastName}`,
                    subtitle:
                      p.typeOfMember === TeamMemberType.player
                        ? t(`team:playerPositions.${p.infos?.position}`)
                        : t(`team:role.${p.infos?.role}`),
                    published: p.published,
                  }}
                  onClick={() => selectItem(p)}
                  selected={currentItem?.id === p.id}
                  key={p.id}
                />
              )}
            />
          }
          right={
            currentItem ? (
              <div className="row">
                {currentItem.typeOfMember === TeamMemberType.player ? (
                  <PlayerCard player={currentItem} />
                ) : (
                  <AbTeamMemberViewer teamMember={currentItem} />
                )}
                <div className="margin-left" style={{ width: 40 }}>
                  <Tooltip
                    title={t("team:modify", {
                      replace: { tooltipSuffix: t(`team:tooltipSuffix.${currentItem.typeOfMember}`) },
                    })}
                    arrow>
                    <IconButton onClick={refreshAndEdit}>
                      {refreshing ? <CircularProgress color="secondary" size={24} /> : <Edit />}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t(`team:${currentItem.published ? "un" : ""}publish`, {
                      replace: { tooltipSuffix: t(`team:tooltipSuffix.${currentItem.typeOfMember}`) },
                    })}
                    arrow
                    className="margin-top">
                    <IconButton onClick={publish} disabled={publishing}>
                      {publishing ? (
                        <CircularProgress color="secondary" size={24} />
                      ) : currentItem.published ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title={t("team:delete", {
                      replace: { tooltipSuffix: t(`team:tooltipSuffix.${currentItem.typeOfMember}`) },
                    })}
                    arrow
                    className="margin-top">
                    <IconButton onClick={tryDelete}>
                      {deleting ? <CircularProgress color="secondary" size={24} /> : <Delete />}
                    </IconButton>
                  </Tooltip>
                  <AbDeleteAlert
                    open={deleteAlertOpen}
                    onClose={() => {
                      setDeleteAlertOpen(false);
                    }}
                    onConfirm={() => {
                      setDeleteAlertOpen(false);
                      deleteCurrentItem();
                    }}
                  />
                </div>
              </div>
            ) : (
              <AbCenteredContent infoText={t("selectItem")} />
            )
          }
          rightWidth={550}
        />
      ) : (
        <AbCenteredContent infoText={t("noItemYet")} />
      )}
      <Fab
        color="primary"
        style={{ position: "absolute", bottom: 32, right: 48 }}
        onClick={() => {
          createTeamMember();
        }}>
        <Add />
      </Fab>
      <TeamMemberModal
        id={currentItem?.id || ""}
        open={modifying}
        onClose={() => setModifying(false)}
        typeOfMember={teamMemberType}
      />
    </>
  );
};

export default TeamMembersPage;
