import { Card, styled, Typography } from "@mui/material";
import { FC } from "react";
import { formatLongDate } from "../data/Converters";
import { Message } from "../data/types/ClientTypes";

const MessageRoot = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginBottom: "8px",
});

const AbMessage: FC<{ message: Message }> = ({ message }) => (
  <MessageRoot style={message.isSender ? { alignItems: "flex-end" } : { alignItems: "flex-start" }}>
    <div>
      <Card variant="outlined" style={{ padding: 8, backgroundColor: message.isSender ? "#EEE" : undefined }}>
        <Typography style={{ marginBottom: 8, whiteSpace: "pre-wrap" }}>{message.body}</Typography>
        <Typography variant="body2">
          {!message.isSender ? `${message.sender} - ` : ""}
          {formatLongDate(message.sent, false, true)}
        </Typography>
      </Card>
    </div>
  </MessageRoot>
);

export default AbMessage;
