import { AppBar as MuiAppBar, Toolbar, IconButton, Typography, styled } from "@mui/material";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { drawerWidth } from "./AppDrawer";
import { getEnv, Env, getEnvName } from "../helpers/EnvHelpers";
import { DataContext } from "../data/contexts/DataContext";

const OurAppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, about }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(about === "opened" && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

interface AppBarProps {
  menuOpen: boolean;
  setMenuOpen: (open: boolean) => void;
  setAnchorEl: (el: Element) => void;
}

const AppBar: FC<AppBarProps> = ({ menuOpen, setMenuOpen, setAnchorEl }) => {
  const { t } = useTranslation("global");
  const { pages } = useContext(DataContext);
  const { pathname } = useLocation();
  const pathElements = pathname.split("/");
  const firstPathElement = pathElements[1] || "";
  let titleDetail = "";
  if (firstPathElement === "pages" && pathElements.length > 2) {
    // Get page href to display it
    const pageId = pathElements[2];
    const page = pages.find((p) => p.id === pageId);
    titleDetail = page?.href || "";
  }
  const openMenu = (): void => setMenuOpen(true);
  return (
    <OurAppBar position="static" about={menuOpen ? "opened" : "closed"}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={openMenu}>
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap style={{ flex: 1 }}>
          {t(`titles./${firstPathElement}`)}
          {titleDetail ? ` - ${titleDetail}` : ""}
        </Typography>
        {getEnv() === Env.production ? undefined : <Typography variant="h6">({getEnvName()})</Typography>}
        <IconButton
          edge="end"
          aria-controls="auth-menu"
          aria-haspopup="true"
          onClick={(evt) => setAnchorEl(evt.currentTarget)}
          color="inherit">
          <AccountCircle />
        </IconButton>
      </Toolbar>
    </OurAppBar>
  );
};

export default AppBar;
