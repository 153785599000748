import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import AbButton from "./AbButton";

const AbDeleteAlert: FC<{ open: boolean; onClose: () => void; onConfirm: () => void; published?: boolean }> = ({
  open,
  onClose,
  onConfirm,
  published,
}) => {
  const { t } = useTranslation("global");

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t(published ? "deletePublishedTitle" : "deleteTitle")}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(published ? "deletePublishedDescription" : "deleteDescription")}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <AbButton label={t("yesNo.no")} color="inherit" onClick={onClose} />
        <AbButton label={t("yesNo.yes")} color="primary" onClick={onConfirm} />
      </DialogActions>
    </Dialog>
  );
};

AbDeleteAlert.defaultProps = { published: true };

export default AbDeleteAlert;
