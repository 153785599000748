import { FC, useContext, useState } from "react";
import { CircularProgress, IconButton, styled, Tooltip } from "@mui/material";
import { DownloadForOffline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { Partner } from "../data/types/ApiTypes";
import AbPager from "./AbPager";
import { DataContext } from "../data/contexts/DataContext";

const PartnerList = styled("div")({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  flexFlow: "wrap",
  justifyContent: "space-between",
  marginRight: -8,
  marginLeft: -8,
});

const nbPartnersPerRow = 4;
const nbRowsPerPage = 3;
const nbPartnersPerPage = nbPartnersPerRow * nbRowsPerPage;

const AbPartnersList: FC<{
  partners: (Partner | null)[];
  renderRow: (p: Partner | null) => JSX.Element;
  currentPage: number;
  setCurrentPage: (p: number) => void;
}> = ({ partners, renderRow, currentPage, setCurrentPage }) => {
  const [exporting, setExporting] = useState(false);
  const { exportPartners } = useContext(DataContext);
  const { t } = useTranslation("partner");
  const partnersOnPage = partners.slice(nbPartnersPerPage * currentPage, nbPartnersPerPage * (currentPage + 1));
  const nbPages = partners.length > nbPartnersPerPage ? Math.ceil(partners.length / nbPartnersPerPage) : 1;
  const nbFakeCards = 4 - (partnersOnPage.length % 4);
  for (let i = 0; i < nbFakeCards; i += 1) {
    partnersOnPage.push(null);
  }
  const getPartners = async (): Promise<void> => {
    setExporting(true);
    await exportPartners();
    setExporting(false);
  };
  return (
    <>
      <PartnerList>{partnersOnPage.map(renderRow)}</PartnerList>
      <div className="row space-between">
        <Tooltip title={t("downloadPartners")} arrow>
          <IconButton color="primary" size="large" onClick={getPartners}>
            {exporting ? <CircularProgress size={32} /> : <DownloadForOffline fontSize="large" />}
          </IconButton>
        </Tooltip>
        {nbPages > 1 ? (
          <AbPager
            page={currentPage + 1}
            nbPages={nbPages}
            onPrevious={() => setCurrentPage(currentPage - 1)}
            onNext={() => setCurrentPage(currentPage + 1)}
          />
        ) : undefined}
      </div>
    </>
  );
};

export default AbPartnersList;
