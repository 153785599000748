import { Add, Delete } from "@mui/icons-material";
import { Card, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { CardItem, Picture } from "../../data/types/ApiTypes";
import AbImageUpload from "../../components/AbImageUpload";

export interface CardItemsFieldProps {
  value: CardItem[];
  onChange: (newValue: CardItem[]) => void;
}

const CardItemsField: FC<CardItemsFieldProps> = ({ value, onChange }) => {
  const { t } = useTranslation("content");

  const changeText = (itemId: string, key: string, textValue: string): void =>
    onChange(value.map((ti) => (ti.id === itemId ? { ...ti, [key]: textValue } : ti)));

  const changePicture = (itemId: string, newValue: Picture): void =>
    onChange(value.map((ti) => (ti.id === itemId ? { ...ti, picture: newValue } : ti)));

  const addItem = (): void => onChange([...value, { id: uuidv4(), title: "", link: "" }]);

  const deleteItem = (itemId: string): void => onChange(value.filter((ti) => ti.id !== itemId));

  const body =
    value.length === 0 ? (
      <div className="v-center" style={{ height: 100 }}>
        <Typography variant="subtitle1">{t("pageComponentModal.textItemsEmpty")}</Typography>
      </div>
    ) : (
      value.map((ti) => (
        <Card key={ti.id} variant="outlined" className="card-root margin-bottom">
          <div className="row">
            <div style={{ marginRight: 32 }}>
              <Typography>{`${t("pageComponentModal.picture")}${t("pageComponentModal.mandatory")}`}</Typography>
              <AbImageUpload
                thumbnail={ti.picture?.url || ""}
                updateImageInState={(newValue) => changePicture(ti.id, newValue)}
              />
            </div>
            <div style={{ flex: 1 }}>
              <div className="margin-bottom" style={{ textAlign: "right" }}>
                <Tooltip title={t("pageComponentModal.textItemDelete")} arrow style={{ marginLeft: 16 }}>
                  <IconButton onClick={() => deleteItem(ti.id)}>
                    <Delete color="primary" />
                  </IconButton>
                </Tooltip>
              </div>
              <TextField
                className="big-margin-bottom"
                value={ti.title}
                onChange={(e) => changeText(ti.id, "title", e.target.value)}
                fullWidth
                label={t("pageComponentModal.textItemTitle")}
              />
              <TextField
                value={ti.link}
                onChange={(e) => changeText(ti.id, "link", e.target.value)}
                fullWidth
                label={t("pageComponentModal.textItemLink")}
              />
            </div>
          </div>
        </Card>
      ))
    );

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <Tooltip title={t("pageComponentModal.textItemAdd")} arrow>
          <IconButton onClick={addItem}>
            <Add color="primary" />
          </IconButton>
        </Tooltip>
      </div>
      {body}
    </div>
  );
};

export default CardItemsField;
