import { Picture } from "../data/types/ApiTypes";
import { getEnvVariable } from "./EnvHelpers";

const desktopFallback = getEnvVariable(
  process.env.REACT_APP_PICTURE_FALLBACK_URL,
  "https://res.cloudinary.com/abrugby/image/upload/v1685703217/icones/placeholder.png",
);
const profileFallback = "https://avironbayonnais.blob.core.windows.net/static/person.svg";

export const getPictureUrlOrFallbackUrl = (picture: string | undefined, forceFallback?: boolean): string => {
  if (forceFallback) return desktopFallback;
  if (
    !picture?.includes(getEnvVariable(process.env.REACT_APP_API_CLOUDYNARY_CLOUDNAME)) &&
    !picture?.includes(getEnvVariable(process.env.REACT_APP_STORAGE_ROOT_URL)) &&
    !picture?.includes(getEnvVariable(process.env.REACT_APP_GLOBAL_STORAGE_ROOT_URL))
  ) {
    // Not an image from the DAM or from the Storage, fallback!
    return desktopFallback;
  }
  return typeof picture === "string" && picture.length > 0 ? picture : desktopFallback;
};
export const getPictureOrFallbackUrl = (picture: Picture | undefined, forceFallback?: boolean): string => {
  return typeof picture?.url === "string" && picture?.url.length > 0
    ? getPictureUrlOrFallbackUrl(picture.url, forceFallback)
    : desktopFallback;
};
export const getProfilePictureOrFallback = (picture: Picture | undefined): string =>
  typeof picture?.url === "string" && picture?.url.length > 0
    ? getPictureUrlOrFallbackUrl(picture.url)
    : profileFallback;
export const isValidSlug = (slug: string): boolean => /^[a-z0-9-]*$/.test(slug);

export const getTargetForLink = (url?: string): undefined | "_blank" =>
  url?.startsWith("http") ? "_blank" : undefined;
