import {
  // Checkbox, FormControlLabel,
  Modal,
  Paper,
  // TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  // ChangeEvent,
  FC,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import AbButton from "src/components/AbButton";
import AbImageUpload from "src/components/AbImageUpload";
import AbModificationAlert from "src/components/AbModificationAlert";
import { DataContext } from "src/data/contexts/DataContext";
import { AdLocation, Location, Sponsoring } from "src/data/types/ApiTypes";
import CloudinaryIdToImage from "src/helpers/CloudinaryIdToImage";
import { v4 as uuidv4 } from "uuid";

interface SponsoringModalProps {
  open: boolean;
  onClose: () => void;
  id: string;
}
const SponsoringModal: FC<SponsoringModalProps> = ({ open, onClose, id }) => {
  const emptyAdLocations: Record<Location, AdLocation> = {
    b2bTicket: {
      id: uuidv4(),
      isActive: false,
    },
    // webHomePageBigBanner: {
    //   id: uuidv4(),
    //   isActive: false,
    // },
    // webHomePageSmallBanner: {
    //   id: uuidv4(),
    //   isActive: false,
    // },
    // webFakeContent: {
    //   id: uuidv4(),
    //   isActive: false,
    // },
    // b2bHomePage: {
    //   id: uuidv4(),
    //   isActive: false,
    // },
    // b2bFakeContent: {
    //   id: uuidv4(),
    //   isActive: false,
    // },
  };

  const emptySponsoring: Sponsoring = {
    id: uuidv4(),
    title: "",
    sponsors: "",
    published: false,
    link: "",
    message: "",
    adLocations: emptyAdLocations,
  };

  const { sponsorings, updateSponsoring, createSponsoring } = useContext(DataContext);
  const creation = id.length === 0;
  const originalSponsoring = creation
    ? emptySponsoring
    : sponsorings.find((s: Sponsoring) => s.id === id) || emptySponsoring;
  const [sponsoring, setSponsoring] = useState<Sponsoring>(originalSponsoring);
  const [modified, setModified] = useState(false);
  const [modifiedAlertOpen, setModifiedAlertOpen] = useState(false);
  const [sending, setSending] = useState(false);
  const { t } = useTranslation("sponsoring");
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (creation) {
      setSponsoring(emptySponsoring);
    } else {
      const newOriginalSponsoring = sponsorings.find((s) => s.id === id);
      if (newOriginalSponsoring) setSponsoring({ ...newOriginalSponsoring });
    }
    setModified(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [creation, id, sponsorings]);

  const validateForm = (): boolean => {
    if (sponsoring) {
      if (sponsoring.title && sponsoring.title.length > 0) {
        let isValid = true;
        // if (sponsoring.adLocations?.b2bHomePage.isActive) {
        //   if (!sponsoring.pictures?.b2bHomeBanner?.url || sponsoring.pictures?.b2bHomeBanner?.url?.length <= 0) {
        //     isValid = false;
        //   }
        // }
        // if (sponsoring.adLocations?.webHomePageBigBanner.isActive) {
        //   if (!sponsoring.pictures?.bigBanner?.url || sponsoring.pictures?.bigBanner?.url?.length <= 0) {
        //     isValid = false;
        //   }
        // }
        // if (sponsoring.adLocations?.webHomePageSmallBanner.isActive) {
        //   if (!sponsoring.pictures?.webSmallBanner?.url || sponsoring.pictures?.webSmallBanner?.url?.length <= 0) {
        //     isValid = false;
        //   }
        // }
        // if (sponsoring.adLocations?.b2bFakeContent.isActive) {
        //   if (
        //     !sponsoring.pictures?.square?.url ||
        //     sponsoring.pictures?.square?.url?.length <= 0 ||
        //     !sponsoring.message ||
        //     sponsoring.message.length <= 0
        //   ) {
        //     isValid = false;
        //   }
        // }
        // if (sponsoring.adLocations?.webFakeContent.isActive) {
        //   if (
        //     !sponsoring.pictures?.article?.url ||
        //     sponsoring.pictures?.article?.url?.length <= 0 ||
        //     !sponsoring.message ||
        //     sponsoring.message.length <= 0
        //   ) {
        //     isValid = false;
        //   }
        // }
        if (sponsoring.adLocations?.b2bTicket.isActive) {
          if (
            !sponsoring.pictures?.b2bTicket1?.url ||
            sponsoring.pictures?.b2bTicket1?.url?.length <= 0 ||
            !sponsoring.pictures?.b2bTicket2?.url ||
            sponsoring.pictures?.b2bTicket2?.url?.length <= 0 ||
            !sponsoring.pictures?.b2bTicketFooter?.url ||
            sponsoring.pictures?.b2bTicketFooter?.url?.length <= 0
          ) {
            isValid = false;
          }
        }
        return isValid;
      }
      return false;
    }
    return false;
  };

  const sendRoomToApi = async (): Promise<void> => {
    const validate: boolean = validateForm();
    if (sponsoring) {
      if (!validate) enqueueSnackbar(t("missingMandatoryFields"), { variant: "error" });
      else {
        setSending(true);
        if (id.length === 0) await createSponsoring(sponsoring);
        else await updateSponsoring(sponsoring);
        setSending(false);
        onClose();
      }
    }
  };

  const changeSponsoringValue = (key: string, value: unknown): void => {
    if (sponsoring) {
      let newItem = { ...sponsoring };

      if (
        // key === "bigBanner" ||
        // key === "b2bHomeBanner" ||
        // key === "square" ||
        // key === "article" ||
        // key === "webSmallBanner" ||
        key === "b2bTicket1" ||
        key === "b2bTicket2" ||
        key === "b2bTicketFooter"
      ) {
        newItem.pictures = sponsoring.pictures
          ? { ...sponsoring.pictures, [key]: value as string }
          : { [key]: value as string };
      } else {
        newItem = { ...sponsoring, [key]: value };
      }
      setSponsoring(newItem);
      if (JSON.stringify(originalSponsoring) !== JSON.stringify(newItem)) {
        setModified(true);
      }
    }
  };
  // const changeSponsoring =
  //   (key: string): ((e: ChangeEvent<HTMLInputElement> | any) => void) =>
  //   (e: ChangeEvent<HTMLInputElement>) => {
  //     changeSponsoringValue(key, e.target.value);
  //   };

  // const changeAdLocation = (event: ChangeEvent<HTMLInputElement>): void => {
  //   const newAdLocations =
  //     sponsoring.adLocations !== undefined
  //       ? {
  //           ...sponsoring.adLocations,
  //           [event.target.name]: {
  //             ...sponsoring.adLocations[event.target.name as Location],
  //             isActive: event.target.checked,
  //           },
  //         }
  //       : undefined;
  //   changeSponsoringValue("adLocations", newAdLocations);
  // };
  const onTryClose = (): void => {
    if (!modified) {
      onClose();
    } else setModifiedAlertOpen(true);
  };

  return (
    <Modal open={open} onClose={onTryClose} className="ab-modal-root">
      <Paper className="ab-modal-paper" sx={{ minWidth: "1200px", minHeight: "600px" }}>
        <Typography variant="h4" className="margin-bottom" sx={{ textAlign: "center" }}>
          {t(creation ? "new" : "modify")}
        </Typography>
        <div className="info-column" style={{ alignItems: "center" }}>
          {/* <div className="row">
            <TextField
              label={`${t("title")}${t("mandatory")}`}
              value={sponsoring?.title || ""}
              onChange={changeSponsoring("title")}
              fullWidth
              sx={{ marginRight: "1em" }}
            />
            <TextField
              label={t("sponsor")}
              value={sponsoring?.sponsors || ""}
              onChange={changeSponsoring("sponsors")}
              fullWidth
              sx={{ marginLeft: "1em" }}
            />
          </div> */}

          {/* <div className="row" style={{ marginTop: "2em", alignItems: "flex-start" }}>
            <TextField
              label={t("link")}
              value={sponsoring?.link || ""}
              onChange={changeSponsoring("link")}
              fullWidth
              sx={{ marginRight: "1em" }}
            />
            <TextField
              label={`${t("message")}${
                sponsoring.adLocations?.b2bFakeContent.isActive || sponsoring.adLocations?.webFakeContent.isActive
                  ? t("mandatory")
                  : ""
              }`}
              value={sponsoring?.message || ""}
              onChange={changeSponsoring("message")}
              fullWidth
              sx={{ marginLeft: "1em" }}
            />
          </div> */}

          {/* <Typography variant="h6" textAlign="center" marginTop="2em">
            {t("pictures.pictures")}
          </Typography> */}
          <div className="row" style={{ alignItems: "center", justifyContent: "space-around" }}>
            {/* <div className="info-column">
              <div className="info-column" style={{ alignItems: "center" }}>
                <Typography>{`${t("pictures.bigBanner")}${
                  sponsoring.adLocations?.webHomePageBigBanner.isActive ? t("mandatory") : ""
                }`}</Typography>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("bigBanner", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.bigBanner?.url || "")}
                  className="margin-bottom"
                  width={450}
                  height={150}
                />
              </div>
              <div className="info-column" style={{ alignItems: "center", justifyContent: "center" }}>
                <Typography textAlign="center">{`${t("pictures.webSmallBanner")}${
                  sponsoring.adLocations?.webHomePageSmallBanner.isActive ? t("mandatory") : ""
                }`}</Typography>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("webSmallBanner", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.webSmallBanner?.url || "")}
                  className="margin-bottom"
                  width={340}
                  height={210}
                />
              </div>
            </div>
            <div className="info-column" style={{ margin: 20 }}>
              <div className="row" style={{ justifyContent: "center" }}>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("article", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.article?.url || "")}
                  className="margin-bottom"
                  width={230}
                  height={136}
                />
                <Typography textAlign="center" width={250}>{`${t("pictures.article")}${
                  sponsoring.adLocations?.webFakeContent.isActive ? t("mandatory") : ""
                }`}</Typography>
              </div>

              <div className="row" style={{ justifyContent: "center" }}>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("article", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.square?.url || "")}
                  className="margin-bottom"
                  width={100}
                  height={100}
                />
                <Typography textAlign="center" width={250}>{`${t("pictures.square")}${
                  sponsoring.adLocations?.b2bFakeContent.isActive ? t("mandatory") : ""
                }`}</Typography>
              </div>

              <div className="row" style={{ justifyContent: "center" }}>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("b2bHomeBanner", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.b2bHomeBanner?.url || "")}
                  className="margin-bottom"
                  width={210}
                  height={65}
                />
                <Typography textAlign="center" width={250}>{`${t("pictures.b2bHomeBanner")}${
                  sponsoring.adLocations?.b2bHomePage.isActive ? t("mandatory") : ""
                }`}</Typography>
              </div>
            </div> */}
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="info-column">
                <Typography textAlign="center">{`${t("pictures.b2bTicket1")}${t("mandatory")}`}</Typography>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("b2bTicket1", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.b2bTicket1?.url || "")}
                  className="margin-right"
                  width={520}
                  height={400}
                />
              </div>
              <div className="info-column">
                <Typography textAlign="center">{`${t("pictures.b2bTicket2")}${t("mandatory")}`}</Typography>
                <AbImageUpload
                  updateImageInState={(e) => changeSponsoringValue("b2bTicket2", e)}
                  thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.b2bTicket2?.url || "")}
                  className="margin-left"
                  width={520}
                  height={400}
                />
              </div>
            </div>
          </div>
          <div className="info-column" style={{ alignItems: "center" }}>
            <Typography textAlign="center">{`${t("pictures.b2bTicketFooter")}${t("mandatory")}`}</Typography>
            <AbImageUpload
              updateImageInState={(e) => changeSponsoringValue("b2bTicketFooter", e)}
              thumbnail={CloudinaryIdToImage(sponsoring?.pictures?.b2bTicketFooter?.url || "")}
              className="margin-left"
              width={1000}
              height={200}
            />
          </div>

          {/* <Typography variant="h6" textAlign="center" marginTop="2em">
            {t("adLocations")}
          </Typography>
          <div className="row" style={{ justifyContent: "center" }}>
            <div
              style={{
                border: "1px solid rgba(0,0,0,0.5)",
                borderRadius: "15px",
                padding: "8px 32px",
                margin: 8,
                maxWidth: "50%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
              }}>
              <Typography textAlign="center" fontWeight="600" margin="4px">
                {t("sponsoring:b2b")}
              </Typography>
              <div className="row" style={{ justifyContent: "space-around" }}>
                {Object.values(Location)
                  .filter((l) => l.includes("b2b"))
                  .map((l) => (
                    <FormControlLabel
                      key={l}
                      sx={{ marginRight: "1em" }}
                      control={
                        <Checkbox
                          checked={sponsoring.adLocations !== undefined ? sponsoring?.adLocations[l]?.isActive : false}
                          name={l}
                          onChange={changeAdLocation}
                        />
                      }
                      label={t(`${l}`)}
                    />
                  ))}
              </div>
            </div>
            <div
              style={{
                border: "1px solid rgba(0,0,0,0.5)",
                borderRadius: "15px",
                padding: "8px 32px",
                margin: 8,
                maxWidth: "50%",
                minHeight: 100,
                display: "flex",
                flexDirection: "column",
              }}>
              <Typography textAlign="center" fontWeight="600" margin="4px">
                {t("sponsoring:web")}
              </Typography>
              <div className="row" style={{ justifyContent: "space-around" }}>
                {Object.values(Location)
                  .filter((l) => l.includes("web"))
                  .map((l) => (
                    <FormControlLabel
                      key={l}
                      sx={{ marginRight: "1em" }}
                      control={
                        <Checkbox
                          checked={sponsoring.adLocations !== undefined ? sponsoring?.adLocations[l]?.isActive : false}
                          name={l}
                          onChange={changeAdLocation}
                        />
                      }
                      label={t(`${l}`)}
                    />
                  ))}
              </div>
            </div>
          </div> */}
        </div>
        <div className="margin-top">
          <AbButton
            label={t(creation ? "create" : "save")}
            onClick={sendRoomToApi}
            isLoading={sending}
            disabled={!modified}
          />
        </div>
        <AbModificationAlert
          open={modifiedAlertOpen}
          onClose={() => setModifiedAlertOpen(false)}
          onConfirm={() => {
            if (originalSponsoring) setSponsoring({ ...originalSponsoring });
            setModified(false);
            setModifiedAlertOpen(false);
            onClose();
          }}
        />
      </Paper>
    </Modal>
  );
};

export default SponsoringModal;
