import {
  Badge,
  Collections,
  Crop169,
  GridView,
  Newspaper,
  OndemandVideo,
  Panorama,
  Quiz,
  ShoppingCart,
} from "@mui/icons-material";
import { FC, ReactNode } from "react";
import HelloAssoModule from "src/components/helloAssoModule";
import NewsSlider from "src/components/newsSlider";
import ShopProductSlider from "src/components/shopProductSlider";
import CardsGrid from "../components/cardsGrid";
import ContentCard from "../components/contentCard";
import Faq from "../components/faq";
import Gallery from "../components/gallery";
import StaticBanner from "../components/staticBanner";
import StaticHero from "../components/staticHero";
import VideoEmbed from "../components/videoEmbed";
import ZenChefModule from "../components/zenChefModule";
import { ComponentType, ComponentWithPageWidth } from "../data/types/ApiTypes";

export const getComponent = (type: ComponentType): FC<ComponentWithPageWidth> | null => {
  switch (type) {
    case ComponentType.staticHero:
      return StaticHero;
    case ComponentType.contentCard:
      return ContentCard;
    case ComponentType.staticBanner:
      return StaticBanner;
    case ComponentType.newsSlider:
      return NewsSlider;
    case ComponentType.videoEmbed:
      return VideoEmbed;
    case ComponentType.gallery:
      return Gallery;
    case ComponentType.faq:
      return Faq;
    case ComponentType.shopProductSlider:
      return ShopProductSlider;
    case ComponentType.cardsGrid:
      return CardsGrid;
    case ComponentType.zenChefModule:
      return ZenChefModule;
    case ComponentType.helloAssoModule:
      return HelloAssoModule;
    default:
      return null;
  }
};

export const getComponentTypeIcon = (type: ComponentType): ReactNode => {
  switch (type) {
    case ComponentType.staticHero:
      return <Panorama />;
    case ComponentType.contentCard:
      return <Badge />;
    case ComponentType.staticBanner:
      return <Crop169 />;
    case ComponentType.newsSlider:
      return <Newspaper />;
    case ComponentType.videoEmbed:
      return <OndemandVideo />;
    case ComponentType.gallery:
      return <Collections />;
    case ComponentType.faq:
      return <Quiz />;
    case ComponentType.shopProductSlider:
      return <ShoppingCart />;
    case ComponentType.cardsGrid:
      return <GridView />;
    default:
      return null;
  }
};
